import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {FormBuilder, UntypedFormGroup} from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import {Gatekeeper} from 'gatekeeper-client-sdk';
import { EpKitchenDetails } from '@modules/event-place-management/model/epKitchenDetails';
import {EventPlace} from "@modules/event-place-management/model/eventplace"
import { EventSubPlace } from '@modules/event-place-management/model/eventsub-place';
import { EpMapKitchen } from '@modules/event-place-management/model/epMap-Kitchen';
import * as countrycitystatejson from 'countrycitystatejson';
// import { Login } from '@modules/event-place-management/model/Login';
import { EPUSerDetails} from '@modules/event-place-management/model/epuser-details'
import { EPOpenSpace } from '@modules/event-place-management/model/epopen-space';
//import { AutoGenerate } from '@modules/event-place-management/model/auto generate';
import { EPRoomsDetails } from '@modules/event-place-management/model/epRooms-details';
import { EPGeneralAmenities } from '@modules/event-place-management/model/ep-generalamenities';
import { EPMapGeneralAmenities } from '@modules/event-place-management/model/epmap-generalamenities';
import { EPMapRooms } from '@modules/event-place-management/model/epmap-rooms';
import { EPMapOpenSpace } from '@modules/event-place-management/model/epmap-openspace';
import { EPParking } from '@modules/event-place-management/model/ep-parking';
//import { EPGeneralAmenities } from '@modules/event-place-management/model/ep-generalaamenities';
import { EPActivity } from '@modules/event-place-management/model/ep-activity';
import { EPMapActivity } from '@modules/event-place-management/model/epmap-activity';
import { EPUser } from '@modules/event-place-management/model/epuser';
import { UserMapping } from '@modules/event-place-management/model/user-mapping';
import { EPIncidentalCharges } from '@modules/event-place-management/model/ep-incidentalcharges';
import {EPVendorRegister} from '@modules/event-place-management/model/ep-vendorregister';
import { EPMiscCharges } from '@modules/event-place-management/model/ep-misccharges';
import { AmenitiesMapping } from '@modules/event-place-management/model/amenities-mapping';
import { ActivitiesMapping } from '@modules/event-place-management/model/activities-mapping';
import { EventPlaceBooking } from '@modules/ep-booking-management/model/eventplace-booking';
import { EventAreaReuired } from '@modules/ep-booking-management/model/eventarea-required';
import { EventAreaActivity } from '@modules/ep-booking-management/model/eventarea-activity';
import { EventAreaAmenities } from '@modules/ep-booking-management/model/eventarea-amenities';
import { EventAreaIncidental } from '@modules/ep-booking-management/model/eventarea-incidental';
import { EventAreaMisc } from '@modules/ep-booking-management/model/eventarea-misc';
import {EventAmount} from "@modules/ep-booking-management/model/eventamount";
import {ActivityClass} from "@modules/ep-booking-management/model/activityClass";
import { AmenitiesClass } from '@modules/ep-booking-management/model/amrnities-class';
import { IncidentalClass } from '@modules/ep-booking-management/model/incidental-class';
import { MiscClass } from '@modules/ep-booking-management/model/misc-class';
import { EventPaymentDetails } from '@modules/ep-booking-management/model/event-payment-details';
import {EventSubPlaceDetails} from "@modules/event-place-management/model/allActivityDetails/EventSubPlaceDetails";

// import { AutoGenerate } from '@modules/event-place-management/model/auto generate';


@Injectable({
  providedIn: 'root'
})
export class AppService {
  private countryData = countrycitystatejson;

  getUserRegistrationDetails(arg0: string) {
    throw new Error("Method not implemented.");
  }
  public user: any = null;

  //  endpoint = "http://136.233.82.215:8080/epm-ws";
  /*endpoint = "http://136.233.82.212:8080/epm-ws";*/
  endpoint = "https://tomcat3.varnik.cloud:8443/epm-ws";
  //  endpoint = "http://localhost:8079";

  constructor(private router: Router, private toastr: ToastrService,private http: HttpClient) {
  }

  getCountries() {
    return this.countryData.getCountries();
  }

  getStatesByCountry(countryShotName: string) {
    return this.countryData.getStatesByShort(countryShotName);
  }

  getCitiesByState(country: string, state: string) {
    return this.countryData.getCities(country, state);
  }

  async loginByAuth({email, password}) {
    try {
      const token = await Gatekeeper.loginByAuth(email, password);
      localStorage.setItem('token', token);
      await this.getProfile();
      this.router.navigate(['dash']);
      this.toastr.success('Login success');
    } catch (error) {
      this.toastr.error(error.message);
    }
  }

  async registerByAuth({email, password}) {
    try {
      const token = await Gatekeeper.registerByAuth(email, password);
      localStorage.setItem('token', token);
      await this.getProfile();
      this.router.navigate(['/']);
      this.toastr.success('Register success');
    } catch (error) {
      this.toastr.error(error.message);
    }
  }

  async loginByGoogle() {
    try {
      const token = await Gatekeeper.loginByGoogle();
      localStorage.setItem('token', token);
      await this.getProfile();
      this.router.navigate(['/']);
      this.toastr.success('Login success');
    } catch (error) {
      this.toastr.error(error.message);
    }
  }

  async registerByGoogle() {
    try {
      const token = await Gatekeeper.registerByGoogle();
      localStorage.setItem('token', token);
      await this.getProfile();
      this.router.navigate(['/']);
      this.toastr.success('Register success');
    } catch (error) {
      this.toastr.error(error.message);
    }
  }

  async loginByFacebook() {
    try {
      const token = await Gatekeeper.loginByFacebook();
      localStorage.setItem('token', token);
      await this.getProfile();
      this.router.navigate(['/']);
      this.toastr.success('Login success');
    } catch (error) {
      this.toastr.error(error.message);
    }
  }

  async registerByFacebook() {
    try {
      const token = await Gatekeeper.registerByFacebook();
      localStorage.setItem('token', token);
      await this.getProfile();
      this.router.navigate(['/']);
      this.toastr.success('Register success');
    } catch (error) {
      this.toastr.error(error.message);
    }
  }

  async getProfile() {
    // try {
    //   this.user = await Gatekeeper.getProfile();
    // } catch (error) {
    //   this.logout();
    //   throw error;
    // }
  }

  logout() {
    localStorage.removeItem('token');
    // localStorage.removeItem('gatekeeper_token');
    // this.user = null;
    this.router.navigate(['/login']);
  }

  // Send Email OTP
  sendEmailOtp(userForm: UntypedFormGroup) {
    console.log("otp send json::", userForm)
    return this.http.post(
      this.endpoint + "/User/send-otp", userForm
    )
  }

  // Verify Email OTP
  verifyEmailOtp(email: string, otp: string) {
    return this.http.post(
      this.endpoint + "/User/verify-otp?email=" + email + "&otp=" + otp, ""
    )
  }

   /* EPUser Save*/
   saveEPUser(userForm: UntypedFormGroup){
		return this.http.post<EPUser>(
			this.endpoint +"/User/AddUserRegistrationDetails" , userForm
		)
	}
  /* Update EPUser Details */

  updateEPUser(userForm: UntypedFormGroup){
		return this.http.put<EPUser>(
			this.endpoint +"/User/updateUserStatus" , userForm
		)
	}
  //Update EPuser all details change api
  updateEPUserDetails(userForm : UntypedFormGroup){
    return this.http.post<EPUser>(
      this.endpoint +"/User/updateUserRegistrationDetails" , userForm
    )
  }

  /* EPUser data fetch*/
  getEPUser(){
		return this.http.get<EPUser>(
			this.endpoint +"/User/getAppliedEP/All" ,
		)
	}
  /*Email Id get*/
  getEmail(){
    return this.http.get<EventPlace>(
      this.endpoint+"/Place/EventPlaceRegistrationDetails/All" ,
    );
  }
  /*All EventPlace GetMapping */
  getAllEventPlace(){
    return this.http.get<EventPlace>(
      this.endpoint+"/Place/getEventPlace" ,
    );
  }
/* Event place dropdown*/
getEventPlaceDetails(EPID : string){
  return this.http.get<EventPlace>(
    this.endpoint+"/Place/EventPlaceRegistrationDetails/" + EPID,
  );
}

// Event Place Logo Get
  getEventPlaceLogo(epId: string) {
    return this.http.get(
      this.endpoint + "/Place/EventPlaceLogo/" + epId,
      { responseType: 'blob' }
    )
  }

  getEventSubPlaceLogo(epId: string, epsubId:string) {
    return this.http.get(
      this.endpoint + "/Place/EventsubPlaceLogo/"  + epId+ "/" + epsubId,
      { responseType: 'blob' }
    )
  }

  /* Event palce Id based Auto fetch */

  getEventPlaceByuserId(EPID : string){
    return this.http.get<EventPlace>(
      this.endpoint+"/Place/getEventPlaceByuserId/" + EPID,
    );
  }

  /* login based EPM id fetched */

  getLogin(EPUseremailIdPy: string,EPUserLoginPassword : string){
    return this.http.get<EPUSerDetails>(
      this.endpoint +"/User/getLogin/" + EPUseremailIdPy+ "/" + EPUserLoginPassword,
    );
  }
  /*Event sub place dropdown*/
  getEventSubPlaceDropDown(EventPlace: string,EventSubPlace : string){
    return this.http.get<EventSubPlace>(
      this.endpoint +"/Place/EventSubPlaceRegistrationDetails/" + EventPlace+ "/" + EventSubPlace,
    );
  }

  /*Sub place DropDown*/

  getEventSubPlace(EPID : string){
    return this.http.get<EventSubPlace>(
      this.endpoint+"/Place/EventSubPlaceRegistrationDetails/" + EPID+"/All",
    );
  }

  getEventIdBasedEventSubPlaceDetailsShow(EPID : string){
    return this.http.get<EventSubPlace[]>(
      this.endpoint+"/Place/EventSubPlaceRegistrationDetails/" + EPID+"/All",
    );
  }

  /*Rooms dropdown */

  getRoomsDropDown(Epselect: String,EpSubselect: String){
    return this.http.get<EPRoomsDetails>(
      this.endpoint+"/Rooms/GetRoomsDetails/" + Epselect+ "/" + EpSubselect +"/All",
    );
  }

/* Rooms details auto fetch */
  getRoomDetails(Epselect : string,EpSubselect : string,Roomsdrop : string){
    return this.http.get<EPRoomsDetails>(
      this.endpoint +"/Rooms/GetRoomsDetails/" + Epselect+ "/" + EpSubselect + "/" + Roomsdrop,
    );
  }


/* Kicthen dropdown */

  getKitchenDropDown(Epselect: String,EpSubselect: String){
    return this.http.get<EpMapKitchen>(
      this.endpoint+"/Kitchen/getKitchenRegistrationDetails/" + Epselect+ "/" + EpSubselect +"/All",
    );
  }

  /* Kitchen details auto fetch */

  getKitchenDetails(Epselect : string,EpSubselect : string,Kitchendrop : string){
    return this.http.get<EpKitchenDetails>(
      this.endpoint +"/Kitchen/getKitchenRegistrationDetails/" + Epselect+ "/" + EpSubselect + "/" + Kitchendrop,
    );
  }

  /* Amenities DropDown */

  getAmenitiesDropDown(eventplace: String,epsubpalace: String){
     return this.http.get<EPGeneralAmenities>(
         this.endpoint+"/Amenities/getEPGeneralAmenitiesDetails/" + eventplace+ "/" + epsubpalace +"/All",
     );
  }

  /* General Amenities Details Auto Fetch */

  getAmenitiesDetails(eventplace : string,epsubpalace : string,Amenitiesdrop : string){
      return this.http.get<EPGeneralAmenities>(
         this.endpoint +"/Amenities/getEPGeneralAmenitiesDetails/" + eventplace+ "/" + epsubpalace + "/" + Amenitiesdrop,
      );
  }

   /* Incident Charges DropDown */

      getIncidentChargesDropDown(epselect: String,epsubselect: String){
         return this.http.get<EPIncidentalCharges>(
             this.endpoint+"/GetEPIncidentalChargeDetails/" + epselect+ "/" + epsubselect +"/All",
         );
      }

   /* Incident Charges Details Auto Fetch */

        getIncidentChargesDetails(epselect : string,epsubselect : string,Incidentaldrop : string){
            return this.http.get<EPIncidentalCharges>(
               this.endpoint +"/GetEPIncidentalChargeDetails/" + epselect+ "/" + epsubselect + "/" + Incidentaldrop,
            );
        }

  /* Open space dropdown */

  getOpenSpaceDropDown(Epselect: String,EpSubselect: String){
    return this.http.get<EPOpenSpace>(
      this.endpoint+"/OpenSpace/getOpenSpaceDetails/" + Epselect+ "/" + EpSubselect +"/All",
    );
  }

  /* Open space details auto fetch */

  getOpenSpaceDetails(Epselect : string,EpSubselect : string,Kitchendrop : string){
    return this.http.get<EPOpenSpace>(
      this.endpoint +"/OpenSpace/getOpenSpaceDetails/" + Epselect+ "/" + EpSubselect + "/" + Kitchendrop,
    );
  }

  saveRooms(userForm: UntypedFormGroup){
    return this.http.post<EPRoomsDetails>(
      this.endpoint +"/Rooms/InsertRoomsDetails" , userForm
    )
  }
  updateRooms(userForm: UntypedFormGroup){
    return this.http.post<EPRoomsDetails>(
      this.endpoint +"/Rooms/UpdateRoomsDetails" , userForm
    )
  }
 /* Kitchen save API service */
  savekitchen(userForm: UntypedFormGroup){
    return this.http.post<EpKitchenDetails>(
      this.endpoint +"/Kitchen/InsertKitchenRegistration" , userForm
    )
  }
  /* Kitchen update API service */
  updatekitchen(userForm: UntypedFormGroup){
    return this.http.post<EpKitchenDetails>(
      this.endpoint +"/Kitchen/updateKitchenRegistration" , userForm
    )
  }

/*  EventPlace save API service*/
  saveEventPlace(userForm: UntypedFormGroup){
    return this.http.post<EventPlace>(
      this.endpoint +"/Place/EventPlaceRegistration" , userForm
    )
  }
  // Logo File Upload
  logoFileUpload(formData: FormData, epId: string){
    return this.http.post<String[]>(this.endpoint +
    "/Place/EventPlaceLogo/upload?epId=" + epId, formData
    )
  }

  logoSubPlaceFileUpload(formData: FormData, epId: string, epsubId:string){
    return this.http.post<String[]>(this.endpoint +
    "/Place/EventSubPlaceLogo/upload?epId="+ epId+ "&epsubId=" + epsubId  , formData
    )
  }

  /* EventPlace update API service*/
  updateEventPlace(userForm: UntypedFormGroup){
    return this.http.post<EventPlace>(
      this.endpoint +"/Place/UpdateEventPlaceRegistration" , userForm
    )
  }
   saveSubPlace(userForm: UntypedFormGroup){
      return this.http.post<EventSubPlace>(
        this.endpoint +"/SubPlace/EventSubPlaceRegistration" , userForm
      )
    }
   updateEventSubPlace(userForm: UntypedFormGroup){
      return this.http.post<EventSubPlace>(
        this.endpoint +"/SubPlace/UpdateEventSubPlaceRegistration" , userForm
      )
    }
   saveGenAmenities(userForm: UntypedFormGroup){
       return this.http.post<EPMapGeneralAmenities>(
          this.endpoint +"/Amenities/EPGeneralAmenitiesRegistration" , userForm
       )
    }
   updategenamenities(userForm: UntypedFormGroup){
       return this.http.post<EPGeneralAmenities>(
          this.endpoint +"/Amenities/UpdateEPGeneralAmenitiesRegistration" , userForm
       )
    }

    /*OpenSpace save api service */
    saveopenSpace(userForm: UntypedFormGroup){
        return this.http.post<EPOpenSpace>(
          this.endpoint +"/OpenSpace/insertOpenSpaceRegistration" , userForm
        )
      }

    /*OpenSpace Update api service */
      updateopenSpace(userForm: UntypedFormGroup){
        return this.http.post<EPOpenSpace>(
          this.endpoint +"/OpenSpace/updateOpenSpaceRegistration" , userForm
        )
      }
	//   Parking Area save API service
	saveParkingArea(userForm: UntypedFormGroup){
		return this.http.post<EPParking>(
			this.endpoint +"/ParkingArea/InsertEPParkingAreaRegistration" , userForm
		)
	}
  /* parking area update API service */
  updateParkingArea(userForm: UntypedFormGroup){
		return this.http.post<EPParking>(
			this.endpoint +"/ParkingArea/UpdateParkingAreaRegistration" , userForm
		)
	}
  /* Parking area dropdown */
	getParkingAreaDropDown(Epselect: String,EpSubselect: String){
		return this.http.get<EPParking>(
		  this.endpoint+"/ParkingArea/GetParkingAreaDetails/" + Epselect+ "/" + EpSubselect +"/All",
		);
	}

    /* Parking Area details auto fetch */
  getParkingAreaDetails(Epselect : string,EpSubselect : string,Parkingdrop : string){
    return this.http.get<EPParking>(
      this.endpoint +"/ParkingArea/GetParkingAreaDetails/" + Epselect+ "/" + EpSubselect + "/" + Parkingdrop,
    );
  }
  getActivityDropDown(Epselect: String,EpSubselect: String){
    return this.http.get<EPActivity>(
      this.endpoint+"/Activities/getEPActivitiesRegeistationDetails/"+Epselect+"/"+EpSubselect+"/All",
    );
  }
  getActivityDetails(Epselect : string,EpSubselect : string,Kitchendrop : string){
    return this.http.get<EPActivity>(
      this.endpoint +"/Activities/getEPActivitiesRegeistationDetails/" + Epselect+ "/" + EpSubselect + "/" + Kitchendrop,
    );
  }
  saveactivity(userForm: UntypedFormGroup){
    return this.http.post<EPActivity>(
      this.endpoint +"/Activities/epActivitiesRegeistationDetails" , userForm
    )
  }
  updateactivity(userForm: UntypedFormGroup){
    return this.http.post<EPActivity>(
      this.endpoint +"/Activities/updateEPActivitiesRegistrationDetails" , userForm
    )
  }

  /* Incident Charge */

    saveCharges(userForm: UntypedFormGroup){
       return this.http.post<EPIncidentalCharges>(
         this.endpoint +"/IncidentalCharge/InsertEPIncidentalChargeRegistration" , userForm
      )
    }
    updateincidentcharges(userForm: UntypedFormGroup){
           return this.http.post<EPIncidentalCharges>(
              this.endpoint +"/IncidentalCharge/UpdateEPIncidentalChargeRegistration" , userForm
       )
    }

	//   UserMapping save API service
  saveUserMapping(userForm: UntypedFormGroup){
    return this.http.post<UserMapping>(
      this.endpoint +"/Map/UserMapping" , userForm
    )
  }
      /* UserMapping details auto fetch */
  getUserMapping(Epselect : string,EpSubselect : string,Userselect : string){
    return this.http.get<UserMapping>(
      this.endpoint +"/Place/GetUserMappingDetails/" + Epselect+ "/" + EpSubselect + "/" + Userselect,
    );
  }
  saveAmenitiesMapping(userForm: UntypedFormGroup){
    return this.http.post<AmenitiesMapping>(
      this.endpoint +"/Map/AmenitiesMaping" , userForm
    )
  }
  getAmenitiesMapping(Epselect : string,EpSubselect : string,Amenitiesselect : string){
    return this.http.get<AmenitiesMapping>(
      this.endpoint +"/Place/GetAmenityMappingRegistration/" + Epselect+ "/" + EpSubselect + "/" + Amenitiesselect,
    );
  }

  saveActivitiesMapping(userForm: UntypedFormGroup){
    return this.http.post<ActivitiesMapping>(
      this.endpoint +"/Map/ActivityMaping" , userForm
    )
  }
  getActivitiesMapping(Epselect : string,EpSubselect : string,Activitiesselect : string){
    return this.http.get<ActivitiesMapping>(
      this.endpoint +"/Place/GetActivityMappingDetails/" + Epselect+ "/" + EpSubselect + "/" + Activitiesselect,
    );
  }

  //get ActivityMapping Details

getActiveMapping(EPID : String,EPSubId : String){
  return this.http.get<ActivitiesMapping>(
this.endpoint+"/Place/GetActivityMappingDetails/" +EPID+ "/" +EPSubId+ "/All",
  )
}

// Vendor registration dropdown
  getVendorDropDown(Epselect: String,EpSubselect: String){
    return this.http.get<EPVendorRegister>(
      this.endpoint+"/Place/GetThirdPartyVendorDetails/" + Epselect+ "/" + EpSubselect +"/All",    );
  }
  // Vendor Registration details auto fetch
  getVendorDetails(Epselect : string,EpSubselect : string,Vendordrop : string){
    return this.http.get<EPVendorRegister>(
      this.endpoint +"/Place/GetThirdPartyVendorDetails/" + Epselect+ "/" + EpSubselect + "/" + Vendordrop,
    );
  }
 /* Vendor Registration save API service */
  savevendor(userForm: UntypedFormGroup){
    return this.http.post<EPVendorRegister>(
      this.endpoint +"/Vendor/ThirdPartyVendorRegistration" , userForm
    )
  }
 /* Vendor Registration update API service */
  updatevendor(userForm: UntypedFormGroup){
    return this.http.post<EPVendorRegister>(
      this.endpoint +"/Vendor/UpdateThirdPartyVendorRegistration" , userForm
    )
  }

  // misc-charges dropdown
  getMiscChargeDropDown(Epselect: String,EpSubselect: String){
    return this.http.get<EPMiscCharges>(
      this.endpoint+"/MiscellaneousCharge/getEPMiscellaneousCharge/" + Epselect+ "/" + EpSubselect +"/All",    );
  }

  // misc-charges details auto fetch
  getMiscChargeDetails(Epselect : string,EpSubselect : string,Vendordrop : string){
    return this.http.get<EPMiscCharges>(
      this.endpoint +"/MiscellaneousCharge/getEPMiscellaneousCharge/" + Epselect+ "/" + EpSubselect + "/" + Vendordrop,
    );
  }

  	//   MiscCharges save API service
  saveMiscCharge(userForm: UntypedFormGroup){
    return this.http.post<EPMiscCharges>(
      this.endpoint +"/MiscellaneousCharge/insertEPMiscellaneousCharge" , userForm
    )
  }

  	//   MiscCharges update API service
  updateMiscCharge(userForm: UntypedFormGroup){
    return this.http.post<EPMiscCharges>(
      this.endpoint +"/MiscellaneousCharge/updateEPMiscellaneousCharge" , userForm
    )
  }
/*Event Booking Managament */

/*Eventplace booking save API */

saveEventBooking(userForm: UntypedFormGroup){
  return this.http.post<EventPlaceBooking>(
    this.endpoint +"/User/Eventcontactdetails/insert" , userForm
  )
}

/*EventPlace booking date get API */
getRegisterDate(){
return this.http.get<EventPlaceBooking>(
  this.endpoint +"/User/getalldetails",
)
}

/* EventPlace Booking rediured save API */
saveEventAreaReq(userForm: UntypedFormGroup){
  return this.http.post<EventAreaReuired>(
    this.endpoint +"/User/Eventrequirement/insert" , userForm
  )
}

/* EventPlace Booking rediured update API */
updatEventAreaReq(userForm: UntypedFormGroup){
  return this.http.post<EventAreaReuired>(
    this.endpoint +"/User/Eventrequirement/update" , userForm
  )
}

/*Eventarea actvity Id getmapping */
getEventActvityId(){
  return this.http.get<EventAreaActivity>(
    this.endpoint +"/User/EventActivity/ActId",
  )
  }

  /*EventArea Activity Details getmapping */
getActivity(EPId: string,EpSubId: string,EventId: string){
  return this.http.get<EventAreaActivity[]>(
    this.endpoint + "/User/getEventActivity/" +EPId+ "/" +EpSubId+ "/" +EventId
  )
}


/* Eventarea actvity save API*/
saveEventAreaActivity(userForm: ActivityClass[]){
  return this.http.post<EventAreaActivity[]>(
    this.endpoint +"/User/EventActivity/insert" , userForm
  )
}

/* Eventarea actvity update API*/
updateEventAreaActivity(userForm: ActivityClass[]){
  return this.http.post<EventAreaActivity[]>(
    this.endpoint +"/User/EventActivity/update" , userForm
  )
}


/*Eventarea Amenities Id getmapping */
getEventAmenitiesId(){
  return this.http.get<EventAreaAmenities>(
    this.endpoint +"/User/EventAmenity/ActId",
  )
  }

/* Eventarea Amenities save API*/
saveEventAreaAmenities(userForm: AmenitiesClass[]){
  return this.http.post<EventAreaAmenities[]>(
    this.endpoint +"/User/EventAmenity/insert" , userForm
  )
}

/* Eventarea Amenities update API*/
updateEventAreaAmenities(userForm: AmenitiesClass[]){
  return this.http.post<EventAreaAmenities[]>(
    this.endpoint +"/User/Eventamenity/update" , userForm
  )
}

/* EventArea Amenities Getmapping */
getAmenities(EPId : string , EPSubId : string,EventId){
  return this.http.get<EventAreaAmenities[]>(
    this.endpoint +"/User/getEventamenities/" + EPId+ "/" +EPSubId +"/"+EventId
  )
}

/*Eventarea incidental Id getmapping */
getEventIncidentalId(){
  return this.http.get<EventAreaIncidental>(
    this.endpoint +"/User/EventIncidental/ActId",
  )
  }

  getIncByEventId(eventId: string) {
  return this.http.get<EventAreaIncidental> (
    this.endpoint + "/User/eventIncidental/" + eventId,
  )

  }

/* Eventarea Incidental save API*/
saveEventAreaIncidental(userForm: IncidentalClass[]){
  return this.http.post<EventAreaIncidental[]>(
    this.endpoint +"/User/EventIncidental/Insert" , userForm
  )
}
UpdateEventAreaIncidental(userForm: IncidentalClass[]){
  return this.http.post<EventAreaIncidental[]>(
    this.endpoint +"/User/EventIncdental/update" , userForm
  )
}


/*Eventarea Misc Id getmapping */
getEventMiscId(){
  return this.http.get<EventAreaMisc>(
    this.endpoint +"/User/EventMisc/ActId",
  )
  }

/* Eventarea Misc save API*/
saveEventAreaMisc(userForm: MiscClass[]){
  return this.http.post<EventAreaMisc[]>(
    this.endpoint +"/User/EventMisc/Insert" , userForm
  )
}

/* Eventarea Misc update API*/
updateEventAreaMisc(userForm: MiscClass[]){
  return this.http.post<EventAreaMisc[]>(
    this.endpoint +"/User/Eventmisc/Update" , userForm
  )
}

/* EventArea Misc Details Getmapping */
getMisc(EPId : string,EPSubId : string,EventId : string){
  return this.http.get<EventAreaMisc[]>(
    this.endpoint +"/User/getEventMisc/" + EPId+ "/" +EPSubId +"/"+EventId
  )
}

/* Eventarea Amount save API*/
saveEventAreaAmount(userForm: UntypedFormGroup){
  return this.http.post<EventAmount>(
    this.endpoint +"/User/EventAmount/insert" , userForm
  )
}

/* Eventarea Amount upadte API*/
updateEventAreaAmount(userForm: UntypedFormGroup){
  return this.http.post<EventAmount>(
    this.endpoint +"/User/EventAmount/update" , userForm
  )
}

/*EventArea Amount GetMapping */
getAmountDetais(EPID : string,EPSubId: string,EventId : string){
  return this.http.get<EventAmount>(
    this.endpoint +"/User/EventAmount/" + EPID + "/" +EPSubId + "/" + EventId ,
  )
}
/*Save EVentPayment Amount Details */
saveEventPayment(userForm: UntypedFormGroup){
  return this.http.post<EventPaymentDetails>(
    this.endpoint+"/User/Hallpaymentinsert" , userForm
  )
}


// Get Payment details by event Id
  getPaymentDetails(eventId: string) {
    return this.http.get<EventPaymentDetails[]>(
      this.endpoint + "/User/gethallPayments/" + eventId,
    );
  }


//Update Event Payment amount details
  updateEventPayment(userForm: UntypedFormGroup) {
    return this.http.post<EventPaymentDetails>(
      this.endpoint+"/User/Hallpaymentupdate" , userForm
    )
  }


/*EventIncidental Charge GetMapping*/
getEventIncidental(IncId : string,EPID : string,EPSubId : string){
  return this.http.get<EventAreaIncidental>(
    this.endpoint +"/User/EventIncidental/" +  IncId+ "/" + EPID + "/" +EPSubId ,
  )
}
getEventList(EPID : string){
  return this.http.get<EventPlaceBooking>(
    this.endpoint+"/User/getBookingDetails/" + EPID,
  );
}


//get UserRegistration Details

getUserRegDetails(){
  return this.http.get<EPUSerDetails>(
    this.endpoint+"/Place/getUserRegistrationDetails",
  );
}

//Update EventBookingand contact details
updateEventDetails(userForm : UntypedFormGroup){
  return this.http.post<EventPlaceBooking>(
    this.endpoint +"/User/Eventcontactdetails/update" ,userForm
  )
}

//GetEventReq Details get API
getReqDetails(EvenID : string){
  return this.http.get<EventAreaReuired[]>(
    this.endpoint +"/User/Eventrequirementall/" + EvenID,
  )
}

// getUserMappingList(){
//   return this.http.get<EPUSerDetails>(
//     this.endpoint+"Place/getUserRegistrationDetails",
//   );
//}
/*Amount details Get Method */
getAmountDetails(EPID : string,EvenID : string){
  return this.http.get<EventAmount[]>(
    this.endpoint+"/User/GetEventAmount/" + EPID + "/" + EvenID,
  )
}

//Get All Sub Event Place

  getAllSubPlace() {
    return this.http.get<EventSubPlace[]>(
      this.endpoint+ "/Place/EventSubPlaceRegistrationDetails/findAll"
    )
  }


  //get all Event related details
  getSubPlaceDetails(eventID: string, eventSubId: string) {
    return this.http.get<EventSubPlaceDetails>(
      this.endpoint+ "/Place/EventSubPlaceRegistrationDetails/getAllDataById/" + eventID + "/" + eventSubId
    )
  }



}

