<div class="container-fluid">
  <form (ngSubmit)="onSubmit()" [formGroup]="epmisccharge" id="epmisccharge">
    <div class="col-md-2 mt-1"></div>
    <div class="col-md-11">
      <h4>Miscellaneous Charges</h4>
    </div>
    <div class="alert alert-light" role="alert">
      Note:<span style="color:red;">*</span> Screen to enter all the required details about your Miscellaneous Charges.
    </div>
    <div class="row">
      <label class="col-4  col-form-label"><strong>Miscellaneous Charges Details Required</strong></label>
      <div class="form-check mt-1">
        <input class="form-check-input" type="radio" formControlName="miscchargesrequired" value="yes"
          (change)="miscchargesdisable($event)">
        <label class="form-check-label"><b>Yes</b></label>
      </div>
      <div class="form-check mt-1">
        <input class="form-check-input ml-1" type="radio" formControlName="miscchargesrequired" value="no"
          (change)="miscchargesdisable($event)">
        <label class="form-check-label ml-4"><b>No</b></label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label for="epid" class="form-label">Event Place<span style="color:red;">*</span></label>
            <select class="form-control" id="epid" formControlName="epid" (change)="onShowevent($event)">
              <option *ngFor="let parms of eventDropdown" value="{{parms}}">{{parms}}</option>
              <option value="" disabled selected hidden>Select Event place</option>
            </select>
            <span *ngIf="epmiscchargeErrors.epid" class="error-text">{{epmiscchargeErrors.epid}}</span>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label for="epsubid" class="form-label">Event Sub Place<span style="color:red;">*</span></label>
            <select class="form-control" id="epsubid" disabled="false" formControlName="epsubid"
              (change)="onShowsubplace($event)">
              <option value="" selected>Select Event Sub Place</option>
              <option *ngFor="let parms of eventSubDropDown" value="{{parms}}">{{parms}}</option>
            </select>
            <span *ngIf="epmiscchargeErrors.epsubid" class="error-text">{{epmiscchargeErrors.epsubid}}</span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label for="epmiscellaneouschargeid" class="form-label">Miscellaneous Charge</label>
            <select class="form-control" id="epmiscellaneouschargeid" disabled="false"
              formControlName="epmiscellaneouschargeid" (change)="onShowMiscChargedetails($event)">
              <option *ngFor="let parms of eventMiscChargeDropDown" value="{{parms}}">{{parms}}</option>
              <option value="" disabled selected hidden>Select Miscellaneous charge</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label>Miscellaneous Charges Name<span style="color:red;">*</span></label>
            <input type="text" class="form-control  text-capitalize" placeholder="Miscellaneous Charges Name"
              id="epmiscellaneouschargename" formControlName="epmiscellaneouschargename">
            <span *ngIf="epmiscchargeErrors.epmiscellaneouschargename"
              class="error-text">{{epmiscchargeErrors.epmiscellaneouschargename}}</span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label>Miscellaneous Unit<span style="color:red;">*</span></label>
            <input type="text" maxlength="5" class="form-control" placeholder="Miscellaneous Unit"
              (keypress)="numberOnly($event)" id="epmiscellaneouschargeunit"
              formControlName="epmiscellaneouschargeunit">
            <span *ngIf="epmiscchargeErrors.epmiscellaneouschargeunit"
              class="error-text">{{epmiscchargeErrors.epmiscellaneouschargeunit}}</span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label>Miscellaneous Charges<span style="color:red;">*</span></label>
            <input type="text" maxlength="5" class="form-control" placeholder="Miscellaneous Charges"
              (keypress)="numberOnly($event)" id="epmiscellaneouschargecharges"
              formControlName="epmiscellaneouschargecharges">
            <span *ngIf="epmiscchargeErrors.epmiscellaneouschargecharges"
              class="error-text">{{epmiscchargeErrors.epmiscellaneouschargecharges}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label>Miscellaneous Description</label>
            <input class="form-control text-capitalize" row="4" cols="20" placeholder="Miscellaneous Description"
              id="epmiscellaneouschargedesc" formControlName="epmiscellaneouschargedesc">
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label class="mr-3">Miscellaneous Chargeable<span style="color:red;">*</span>:</label>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" value="Yes" name="epmiscellaneouschargechargeable"
                id="epmiscellaneouschargechargeable" formControlName="epmiscellaneouschargechargeable">
              <label class="form-check-label" for="epmiscellaneouschargechargeable"><b>Yes</b></label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" value="No" name="epmiscellaneouschargechargeable"
                id="epmiscellaneouschargechargeable" formControlName="epmiscellaneouschargechargeable">
              <label class="form-check-label" for="epmiscellaneouschargechargeable"><b>No</b></label>
            </div><br>
            <span *ngIf="epmiscchargeErrors.epmiscellaneouschargechargeable"
              class="error-text">{{epmiscchargeErrors.epmiscellaneouschargechargeable}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center">
      <button type="button" class="btn btn-primary" (click)="draftButton('Draft')" *ngIf="isdisabledraft">Save as Draft
      </button>
      <button type="submit" class="btn btn-success m-3" *ngIf="isdisablesave">{{this.isUpdate == true ? "Update"
        :"Save"}}</button>
      <button type="submit" class="btn btn-primary m-3" (click)="nextButton()" *ngIf="isdisablenext">Next</button>
    </div>
    <div class="row mt-5"></div>
  </form>
</div>
<ngx-spinner>
  <div class="custom-loader"></div>
</ngx-spinner>