import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AppService } from "@services/app.service";
import { Subject, takeUntil } from "rxjs";
import { EventPlace } from "../model/eventplace";
import { EventSubPlace } from "../model/eventsub-place";
import { EPGeneralAmenities } from "../model/ep-generalamenities";
import { AmenitiesMapping } from "../model/amenities-mapping";
import Swal from "sweetalert2";

@Component({
  selector: 'app-amenities-mapping',
  templateUrl: './amenities-mapping.component.html',
  styleUrls: ['./amenities-mapping.component.scss']
})

export class AmenitiesMappingComponent implements OnInit {

  destroy$: Subject<boolean> = new Subject<boolean>();
  eventPlaceDetails: EventPlace[] = [];
  eventPlaceShow: EventPlace[] = [];
  eventSubplaceDetails: EventSubPlace[] = [];
  eventSubplaceShow: EventSubPlace[] = [];
  generalAmenitiesDetails: EPGeneralAmenities[] = [];
  generalAmenitiesShow: EPGeneralAmenities[] = [];
  getAmenitiesMappingId: AmenitiesMapping[] = [];
  eventsubplace: EventSubPlace | undefined;
  eventDropdown: string[] = [];
  eventSubDropDown: string[] = [];
  userDropDown: string[] = [];
  eventAmenitiesDropDown: string[] = [];
  eventId: string | undefined;
  eventSubId: string | undefined;
  epAmenitiesDropDown: string | undefined;
  isDisabled: boolean = false;

  constructor(private appService: AppService, private router: Router) {

  }


  AmenitiesMapping = new UntypedFormGroup({
    epid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epnickname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epimage: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    phonenumber: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    emailid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epdescription: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epaddress1: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epaddress2: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epnearbylandmark: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epcity: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epstate: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    eppincode: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epcountry: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    activestatus: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epaircond: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epsubid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epsubname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epsubnickname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epsubdesc: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epsubemailid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epsubaircond: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    subactivestatus: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epgenamenitiesid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epgenamenitiesname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epgenamenitiesavailableqty: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epgenamenitiesuom: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epgenamenitiesunitlistprice: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    amenitiesactivestatus: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epgenamenitieschargable: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epgenamenitiesshow: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epgeneralamenitiescharges: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epgenamenitiesdesc: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    activeyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    deleteyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    createdby: new UntypedFormControl('', [Validators.required, Validators.nullValidator])
  })
  public AmenitiesMappingErrors = {
    epid: '',
    epsubid: '',
    epgenamenitiesid: '',

  }

  ngOnInit() {
    this.getEventPlaceByuserId();
  }
  onSubmit() {
    this.formValidation();
  }

  //Form validation call funtion

  formValidation() {
    this.AmenitiesMappingErrors.epid = "";
    this.AmenitiesMappingErrors.epsubid = "";
    this.AmenitiesMappingErrors.epgenamenitiesid = "";
    let hasError = false;

    if (this.AmenitiesMapping.get('epid')?.invalid) {
      this.AmenitiesMappingErrors.epid = "*EPId is required";
      hasError = true;
    }
    if (this.AmenitiesMapping.get('epsubid')?.invalid) {
      this.AmenitiesMappingErrors.epsubid = "*EPSubId is required";
      hasError = true;
    }
    if (this.AmenitiesMapping.get('epgenamenitiesid')?.invalid) {
      this.AmenitiesMappingErrors.epgenamenitiesid = "*EPAmenitiesId is required";
      hasError = true;
    }
    if (!hasError) {
      this.saveAmenitiesMapping();
    }
  }

  //EventPlace DropDown API Function  

  getEventPlaceByuserId() {
    this.appService
      .getEventPlaceByuserId(localStorage.getItem('token'))
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("Event place Dropdwon::", data);
        // @ts-ignore
        this.eventPlaceDetails = data;
        var localEventPlace: string[] = [];
        for (var ep of this.eventPlaceDetails) {
          localEventPlace.push(ep.epname)
        }
        this.eventDropdown = localEventPlace;
      })
  }

  //EventPlace Details AutoFetch API Funtion

  getEventPlaceDetails() {
    console.log("getid::", this.eventId);
    this.appService
      .getEventPlaceDetails(this.eventId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("GetKitchen Details::", data);
        // @ts-ignore
        this.eventPlaceShow = data;
        for (var event of this.eventPlaceShow) {
          this.AmenitiesMapping.controls["epname"].setValue(event.epname);
          this.AmenitiesMapping.controls["epnickname"].setValue(event.epnickname);
          this.AmenitiesMapping.controls["epimage"].setValue(event.epimage);
          this.AmenitiesMapping.controls["phonenumber"].setValue(event.phonenumber);
          this.AmenitiesMapping.controls["emailid"].setValue(event.emailid);
          this.AmenitiesMapping.controls["epdescription"].setValue(event.epdescription);
          this.AmenitiesMapping.controls["epaddress1"].setValue(event.epaddress1);
          this.AmenitiesMapping.controls["epaddress2"].setValue(event.epaddress2);
          this.AmenitiesMapping.controls["epnearbylandmark"].setValue(event.epnearbylandmark);
          this.AmenitiesMapping.controls["epcity"].setValue(event.epcity);
          this.AmenitiesMapping.controls["epstate"].setValue(event.epstate);
          this.AmenitiesMapping.controls["eppincode"].setValue(event.eppincode);
          this.AmenitiesMapping.controls["epcountry"].setValue(event.epcountry);
          this.AmenitiesMapping.controls["activestatus"].setValue(event.activestatus);
          this.AmenitiesMapping.controls["epaircond"].setValue(event.epaircond);
        }
      })
  }

  //SubPlace DropDown API Function  

  getEventSubPlace() {
    this.appService
      //@ts-ignore
      .getEventSubPlace(this.eventId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("sub dropdown::", data);
        // @ts-ignore
        this.eventSubplaceDetails = data;
        var localEventSubPlace: string[] = [];
        for (var ep of this.eventSubplaceDetails) {
          if (ep.epmainplace == "Yes") {
            localEventSubPlace.push(ep.epsubname + " - Main Hall")
            console.log("main hall", this.eventSubDropDown)
          } else {
            localEventSubPlace.push(ep.epsubname)
          }
          this.eventSubDropDown = localEventSubPlace;
        }
      })
  }

  //SubPlace Details AutoFetch API Funtion

  getSubPlaceDetails() {
    this.appService
      .getEventSubPlaceDropDown(this.eventId, this.eventSubId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("Get Sub Place Details::", data);
        // @ts-ignore
        this.eventSubplaceShow = data;
        for (var subplace of this.eventSubplaceShow) {
          this.AmenitiesMapping.controls["epsubname"].setValue(subplace.epsubname);
          this.AmenitiesMapping.controls["epsubnickname"].setValue(subplace.epsubnickname);
          this.AmenitiesMapping.controls["phonenumber"].setValue(subplace.phonenumber);
          this.AmenitiesMapping.controls["epsubemailid"].setValue(subplace.emailid);
          this.AmenitiesMapping.controls["epsubdesc"].setValue(subplace.epsubdesc);
          this.AmenitiesMapping.controls["subactivestatus"].setValue(subplace.activestatus);
          this.AmenitiesMapping.controls["epsubaircond"].setValue(subplace.epsubaircond);
          this.AmenitiesMapping.controls["epmainplace"].setValue(subplace.epmainplace);
        }
      })
  }

  //Amenities Mapping DropDown get API function

  getAmenitiesDropDown() {
    this.appService
      .getAmenitiesDropDown(this.eventId, this.eventSubId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("Test data::", data);
        // @ts-ignore
        this.generalAmenitiesDetails = data;
        var localAmenities: string[] = [];
        for (var ep of this.generalAmenitiesDetails) {
          localAmenities.push(ep.epgenamenitiesname)
        }
        this.eventAmenitiesDropDown = localAmenities;
      })
  }

  //Amenities Mapping Details AutoFetch API Funtion

  getAmenitiesDetails() {
    this.appService
      .getAmenitiesDetails(this.eventId, this.eventSubId, this.epAmenitiesDropDown)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("GetAmenities Details::", data);
        // @ts-ignore
        this.generalAmenitiesShow = data;
        for (var amenities of this.generalAmenitiesShow) {
          this.AmenitiesMapping.controls["epgenamenitiesname"].setValue(amenities.epgenamenitiesname);
          this.AmenitiesMapping.controls["epgenamenitiesavailableqty"].setValue(amenities.epgenamenitiesavailableqty);
          this.AmenitiesMapping.controls["epgenamenitiesuom"].setValue(amenities.epgenamenitiesuom);
          this.AmenitiesMapping.controls["epgenamenitiesunitlistprice"].setValue(amenities.epgenamenitiesunitlistprice);
          this.AmenitiesMapping.controls["amenitiesactivestatus"].setValue(amenities.amenitiesactivestatus);
          this.AmenitiesMapping.controls["epgenamenitiesshow"].setValue(amenities.epgenamenitiesshow);
          this.AmenitiesMapping.controls["epgenamenitieschargable"].setValue(amenities.epgenamenitieschargable);
          this.AmenitiesMapping.controls["epgenamenitiesdesc"].setValue(amenities.epgenamenitiesdesc);
          this.AmenitiesMapping.controls["epgeneralamenitiescharges"].setValue(amenities.epgeneralamenitiescharges);
        }
      })
  }

  // Amenities autofetch mapping

  getAmenitiesMapping() {
    this.appService
      .getAmenitiesMapping(this.eventId, this.eventSubId, this.epAmenitiesDropDown)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("EP Userid Dropdwon::", data);
        // @ts-ignore
        this.getAmenitiesMappingId = data;
      })
  }

  // Save Amenities Mapping 

  saveAmenitiesMapping() {

    this.AmenitiesMapping.controls["createdby"].setValue(localStorage.getItem("token"));
    this.AmenitiesMapping.controls["epid"].setValue(this.eventId);
    this.AmenitiesMapping.controls["epsubid"].setValue(this.eventSubId);
    this.AmenitiesMapping.controls["epgenamenitiesid"].setValue(this.epAmenitiesDropDown);
    this.AmenitiesMapping.controls["activeyn"].setValue("Yes");
    this.AmenitiesMapping.controls["deleteyn"].setValue("No");
    this.appService.saveAmenitiesMapping(this.AmenitiesMapping.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("saveRooms ::", data);
        // @ts-ignore
      }, (err: any) => {
      },
        () => console.log('HTTP request completed.'));
    this.successAlert();
  }


  //EventPlace DropDown AutoFetch Funtion

  onShowevent(event) {
    const value = event.target.value;
    console.log("Event Name::", value)
    for (var EPid of this.eventPlaceDetails) {
      if (value == EPid.epname) {
        this.eventId = EPid.epid;
        console.log("ep id::", this.eventId)
        this.isDisabled = true;
        this.getEventSubPlace();
        this.getEventPlaceDetails();
        this.disable();
      } else {
        this.isDisabled = false;
        console.log("ep id:: error")
      }
    }
  }

  disable() {
    if (this.isDisabled) {
      this.AmenitiesMapping.controls['epsubid'].enable();
    } else {
      this.AmenitiesMapping.controls['epsubid'].disable();
    }
  }

  //SubPlace Dropdown autofetch function

  onShowSubPlaceDetails(event) {
    const value = event.target.value.replace(" - Main Hall", "");
    console.log("Packing type::", value);
    for (var EPid of this.eventSubplaceDetails) {
      if (value == EPid.epsubname) {
        this.eventSubId = EPid.epsubid;
        console.log("ep id::", this.eventSubDropDown)
        this.isDisabled = true;
        this.getSubPlaceDetails();
        this.getAmenitiesDropDown()
        this.disable1();
      }
      else {
        this.isDisabled = false;
        console.log("ep id:: error")
      }
    }
  }

  disable1() {
    if (this.isDisabled) {
      this.AmenitiesMapping.controls['epgenamenitiesid'].enable();
    } else {
      this.AmenitiesMapping.controls['epgenamenitiesid'].disable();
    }
  }

  //AMenities Mapping Details Dropdown AutoFetch function

  onShowAmenitiesdetails(event) {
    const value = event.target.value;
    console.log("Packing type::", value)
    for (var EPid of this.generalAmenitiesDetails) {
      if (value == EPid.epgenamenitiesname) {
        this.epAmenitiesDropDown = EPid.epgenamenitiesid;
        console.log("ep id::", this.epAmenitiesDropDown);
        this.getAmenitiesDetails();
        this.getAmenitiesMapping();
      }
    }
  }

  //Amenities Mapping Success Alert Popup Message

  successAlert() {
    Swal.fire({
      title: "Your Registration Sucessfully Completed.",
      icon: "success",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.reload();
      }
    });
  }
}
