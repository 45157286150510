<div class="row col-md-12 mt-2">
  <div class="col-md-9">
      <div class="mt-1" slot="button" style="text-align-last: end">
        <b>{{username}}</b>
        <i class="far fa-user-circle mt-10 " (mouseover)="show()" data-toggle="tooltip"
        data-placement="top" title="UserName" style="margin-left: 10px"></i>
      </div>
  </div>
  <div class="row col-md-3" style="text-align-last: end">
    <div slot="button">

      <i class="fas fa-redo-alt m-1 "   style="margin-left: 10px" data-toggle="tooltip"
      data-placement="top" title="Reload" (click)="reload()"></i>
      <i class="fa fa-home m-2 "   aria-hidden="true" style="margin-left: 10px" data-toggle="tooltip"
      data-placement="top" title="Home" (click)="home()"></i>
      <i class="fas fa-sign-out-alt m-2 "  style="margin-left: 10px" data-toggle="tooltip"
      data-placement="top" title="Log-Out" (click)="logout()"></i>

    </div>
  </div>
</div>
