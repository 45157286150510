import {Component, OnInit, HostListener} from "@angular/core";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import { Router } from "@angular/router";
import {ToastrService} from 'ngx-toastr';
import { AppService } from "@services/app.service";
import { EpKitchenDetails } from "@modules/event-place-management/model/epKitchenDetails"
import {EventPlace} from "@modules/event-place-management/model/eventplace"
import { EventSubPlace } from "../model/eventsub-place";
import { EpMapKitchen } from '@modules/event-place-management/model/epMap-Kitchen';
import {pipe, Subject, takeUntil} from "rxjs";
import Swal from "sweetalert2";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-kitchen',
  templateUrl: './kitchen.component.html',
  styleUrls: ['./kitchen.component.scss']
})

export class KitchenComponent implements OnInit {
  eventPlace: EventPlace[] = [];
  eventDropdown: string[] = [];
  eventSubPlace: EventSubPlace[] = [];
  eventSubDropDown: string[] = [];
  eventKitchenPlace: EpMapKitchen[] = [];
  kitchenDetails: EpMapKitchen[] = [];
  eventKitchenDropDown: string[] = [];
  epIdDropDown: string | undefined;
  epSubDropDown: string | undefined;
  epKitchenDropDown: string | undefined;
  destroy$: Subject<boolean> = new Subject<boolean>();
  public EPID: String;
  isUpdate = false;
  isdisablesave = false;
  isdisabledraft = false;
  isdisablenext = false;
  isDisabled : boolean = false;

constructor (private router: Router, private appService : AppService, private toastr: ToastrService,
  private spinner : NgxSpinnerService){

         }

  epkitchendetails = new UntypedFormGroup({

     epid : new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epsubid:new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epkitchenid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epkitchenname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epkitchencapacity:new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epkitchendesc: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epkitchensize:new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epkitchennoofstoves:new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epkitchennoofgrinders: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epkitchennooffridge:new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epkitchennoofmixie: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epkitchenchargable: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epkitchenshow: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epkitchenactivestatus: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epkitchencharges: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    activeyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    deleteyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    createdby: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    createddate: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    kitchenactivestatus: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    Kitchenrequired: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
  })

  public epkitchenErrors ={
    epid:  '',
    epsubid: '',
    epkitchenid: '',
    epkitchenname: '',
    epkitchencapacity: '',
    epkitchensize: '',
    epkitchennoofstoves: '',
    epkitchennoofgrinders: '',
    epkitchennooffridge: '',
    epkitchennoofmixie: '',
    epkitchenchargable: '',
    epkitchenshow: '',
    kitchenactivestatus: '',
    epkitchencharges: '',

  }
  ngOnInit() {
    this.getEventPlaceByuserId();
    this.epkitchendetails.controls['epid'].disable();
    Object.keys(this.epkitchendetails.controls).forEach((controlName) => {
      if (controlName !== 'Kitchenrequired') {
        this.epkitchendetails.get(controlName).disable();
      }
    })
    // this.isdisablenext = true;
  //   window.scrollTo(0, this.FIXED_SCROLL_POSITION);
  //   document.body.style.overflow = 'hidden';
  // }
  // @HostListener('window:scroll', [])
  //     onWindowScroll() {
  //       window.scrollTo(0, this.FIXED_SCROLL_POSITION);
  //     }
  // ngOnDestroy() {
  //    document.body.style.overflow = 'auto';
   }


  onSubmit() {
    this.formValidation("Active");

}

  formValidation(status: string) {
    this.epkitchenErrors.epid = "";
    this.epkitchenErrors.epsubid = "";
    this.epkitchenErrors.epkitchenid = "";
    this.epkitchenErrors.epkitchenname = "";
    this.epkitchenErrors.epkitchencapacity = "";
    this.epkitchenErrors.epkitchensize = "";
    this.epkitchenErrors.epkitchennoofstoves = "";
    this.epkitchenErrors.epkitchennoofgrinders = "";
    this.epkitchenErrors.epkitchennooffridge = "";
    this.epkitchenErrors.epkitchennoofmixie = "";
    this.epkitchenErrors.epkitchenchargable = "",
    this.epkitchenErrors.epkitchenshow = "";
    this.epkitchenErrors.kitchenactivestatus = "";
    this.epkitchenErrors.epkitchencharges = "";

    let hasError = false;

    if (this.epkitchendetails.get('epid')?.invalid) {
        this.epkitchenErrors.epid
            = "*Event place is required";
        hasError = true;
    }

    if (this.epkitchendetails.get('epsubid')?.invalid) {
        this.epkitchenErrors.epsubid = "*Event sub Place is required";
        hasError = true;
    }
    let inputValue = this.epkitchendetails.controls['epkitchenname'].value;
         let outputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
         this.epkitchendetails.controls['epkitchenname'].setValue(outputValue)

    if (this.epkitchendetails.get('epkitchenname')?.invalid) {
        this.epkitchenErrors.epkitchenname = "*Kitchen Name is required";
        hasError = true;
    }

    if (this.epkitchendetails.get('epkitchencapacity')?.invalid) {
        this.epkitchenErrors.epkitchencapacity = "*Kitchen Capacity is required";
        hasError = true;
    }
    let inputValue1 = this.epkitchendetails.controls['epkitchendesc'].value;
         let outputValue1 = inputValue1.charAt(0).toUpperCase() + inputValue1.slice(1);
         this.epkitchendetails.controls['epkitchendesc'].setValue(outputValue1)

    // if (this.epkitchendetails.get('epkitchendesc')?.invalid) {
    //     this.epkitchenErrors.epkitchendesc = "*Kitchen Desc is required";
    //     hasError = true;
    // }

    if (this.epkitchendetails.get('epkitchensize')?.invalid) {
        this.epkitchenErrors.epkitchensize = "*Kitchen Size is required";
        hasError = true;
    }
    if (this.epkitchendetails.get('epkitchennoofstoves')?.invalid) {
        this.epkitchenErrors.epkitchennoofstoves = "*Kitchen No Of Stoves is required";
        hasError = true;
    }
    if (this.epkitchendetails.get('epkitchennoofgrinders')?.invalid) {
        this.epkitchenErrors.epkitchennoofgrinders = "*Kitchen No Of Grinders is required";
        hasError = true;
    }
    if (this.epkitchendetails.get('epkitchennooffridge')?.value == "") {
        this.epkitchenErrors.epkitchennooffridge = "*Kitchen No Of Fridge is required";
        hasError = true;
     }
    if(this.epkitchendetails.get('epkitchennoofmixie')?.invalid) {
        this.epkitchenErrors.epkitchennoofmixie = "*Kitchen No Of Mixie required";
       hasError = true;
    }
    if(this.epkitchendetails.get('epkitchenchargable')?.invalid) {
        this.epkitchenErrors.epkitchenchargable = "*Kitchen Chargable is required";
       hasError = true;
    }
    // if(this.epkitchendetails.get('epkitchenshow')?.invalid) {
    //     this.epkitchenErrors.epkitchenshow = "*Kitchen Show is required";
    //     hasError = true;
    //  }
    //  if (this.epkitchendetails.get('kitchenactivestatus')?.invalid) {
    //      this.epkitchenErrors.kitchenactivestatus = "*Active Status is required";
    //      hasError = true;
    // }
    if (this.epkitchendetails.get('epkitchencharges')?.invalid) {
      this.epkitchenErrors.epkitchencharges = "*Kitchen Charges is required";
      hasError = true;
 }
    if (!hasError) {
     this.saveKitchen(status);
}
  }
//EventPlace dropdown details autofetch function
  getEventPlaceByuserId() {
    this.appService
      .getEventPlaceByuserId(localStorage.getItem('token'))
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("Event place Dropdwon::", data);
        // @ts-ignore
        this.eventPlace = data;
        var localEventPlace : string [] = [];
        for (var ep of this.eventPlace) {
         localEventPlace.push(ep.epname)
        }
        this.eventDropdown = localEventPlace
      })
  }
  //EventSubPlace dropdown autofetch function
  getEventSubPlace() {
    this.appService
    //@ts-ignore
      .getEventSubPlace(this.epIdDropDown)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("sub dropdown::", data);
        // @ts-ignore
        this.eventSubPlace = data;
        var localEventSubPlace : string [] = [];
        for (var ep of this.eventSubPlace) {
          if (ep.epmainplace == "Yes") {
            localEventSubPlace.push(ep.epsubname + " - Main Hall")
            console.log("main hall", this.eventSubDropDown)
          } else {
           localEventSubPlace.push(ep.epsubname)
          }
          this.eventSubDropDown = localEventSubPlace
        }
      })
  }
  //Kitchen dropdown details API function
  getKitchenDropDown() {
    this.appService
      .getKitchenDropDown(this.epIdDropDown,this.epSubDropDown)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("Test data::", data);
        // @ts-ignore
        this.eventKitchenPlace = data;
        if (this.eventKitchenPlace.length > 0) {
          this.epkitchendetails.controls['epkitchenid'].enable();
      } else {
          this.epkitchendetails.controls['epkitchenid'].disable();
      }
        var localKitchen: string[] = [];
        for (var ep of this.eventKitchenPlace) {
          localKitchen.push(ep.epkitchenname)
        }
        this.eventKitchenDropDown = localKitchen;
      })
  }
//Kitchen all details autofetch function
  getKitchenDetails() {
    this.spinner.show()
    this.appService
      .getKitchenDetails(this.epIdDropDown, this.epSubDropDown, this.epKitchenDropDown)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("GetKitchen Details::", data);
        this.spinner.hide()
        // @ts-ignore
        this.kitchenDetails = data;
        for(var kitchen of this.kitchenDetails) {
          this.isUpdate = true;
          this.epkitchendetails.controls["epkitchenname"].setValue(kitchen.epkitchenname);
          this.epkitchendetails.controls["epkitchencapacity"].setValue(kitchen.epkitchencapacity);
          this.epkitchendetails.controls["epkitchendesc"].setValue(kitchen.epkitchendesc);
          this.epkitchendetails.controls["epkitchensize"].setValue(kitchen.epkitchensize);
          this.epkitchendetails.controls["epkitchennoofstoves"].setValue(kitchen.epkitchennoofstoves);
          this.epkitchendetails.controls["epkitchennoofgrinders"].setValue(kitchen.epkitchennoofgrinders);
          this.epkitchendetails.controls["epkitchennooffridge"].setValue(kitchen.epkitchennooffridge);
          this.epkitchendetails.controls["epkitchennoofmixie"].setValue(kitchen.epkitchennoofmixie);
          this.epkitchendetails.controls["epkitchenshow"].setValue(kitchen.epkitchenshow);
          this.epkitchendetails.controls["kitchenactivestatus"].setValue(kitchen.kitchenactivestatus);
          this.epkitchendetails.controls["epkitchenchargable"].setValue(kitchen.epkitchenchargable);
          this.epkitchendetails.controls["epkitchencharges"].setValue(kitchen.epkitchencharges);
        }
      },(error: any) => {
        this.spinner.hide()
        console.log("Something went wrong");
      })
  }
  /*  kitchen save and update api function */

  saveKitchen(status: string){
    this.spinner.show()
    console.log("is update::", this.isUpdate)
    if (this.isUpdate) {
        this.epkitchendetails.controls["kitchenactivestatus"].setValue(status);
      this.epkitchendetails.controls["activeyn"].setValue("Yes");
      this.epkitchendetails.controls["deleteyn"].setValue("No");
      this.epkitchendetails.controls["createdby"].setValue(localStorage.getItem("token"));
      this.epkitchendetails.controls["epid"].setValue(this.epIdDropDown);
      this.epkitchendetails.controls["epsubid"].setValue(this.epSubDropDown);
      this.epkitchendetails.controls["epkitchenid"].setValue(this.epKitchenDropDown);
    console.log("ekitchen details::", this.epkitchendetails.value);
    this.appService.updatekitchen(this.epkitchendetails.value)
                .pipe(takeUntil(this.destroy$))
                .subscribe((data) => {
                        console.log("updatekitchen details ::", data);
                        this.spinner.hide()
                        // @ts-ignore
                        if(data.kitchenactivestatus == "Draft"){
                          this.successAlertDarft();
                        }else{
                        this.UpdateAlert();
                        }
                    }, (err: any) => {
                      this.errorAlert();
                      this.spinner.hide()
                    },
                    () => console.log('HTTP request completed.'));
                  }
    else if(!this.isUpdate) {
      console.log("is update::", this.isUpdate)
      this.epkitchendetails.controls["kitchenactivestatus"].setValue(status);
      this.epkitchendetails.controls["activeyn"].setValue("Yes");
    this.epkitchendetails.controls["deleteyn"].setValue("No");
    this.epkitchendetails.controls["createdby"].setValue(localStorage.getItem("token"));
    this.epkitchendetails.controls["epid"].setValue(this.epIdDropDown);
    this.epkitchendetails.controls["epsubid"].setValue(this.epSubDropDown);
    console.log("kitchen details::", this.epkitchendetails.value);
    this.appService.savekitchen(this.epkitchendetails.value)
                .pipe(takeUntil(this.destroy$))
                .subscribe((data) => {
                        console.log("kitchenDetails ::", data);
                        this.spinner.hide()
                        // @ts-ignore
                        // this.protected = data;
                        if(data.kitchenactivestatus == "Draft"){
                          this.successAlertDarft();
                        }else{
                        this.successAlert();
                        }
                    }, (err: any) => {
                      this.spinner.hide()
                      this.errorAlert();
                    },
                    () => console.log('HTTP request completed.'));
                    // this.isdisablenext =true;
                  }

}


//eventplace autofetch function
  onShoweventsub(event) {
    const value = event.target.value;
    console.log("Event Name::", value)
    for (var EPid of this.eventPlace) {
      if (value == EPid.epname) {
        this.epIdDropDown = EPid.epid;
        console.log("ep id::", this.epIdDropDown)
        this.getEventSubPlace();
        this.isDisabled = true;
        this.disable();
      } else {
        this.isDisabled = false;
        console.log("ep id:: error")
      }
    }
  }

disable() {
    if (this.isDisabled) {
        this.epkitchendetails.controls['epsubid'].enable();
    } else {
        this.epkitchendetails.controls['epsubid'].disable();
    }
}

//EventSubPlace autofetch function
onShowkitchen(event) {
  const value = event.target.value.replace(" - Main Hall","");
  for (var EPid of this.eventSubPlace) {
    console.log("Packing type1::", value)
    console.log("Packing type2::", EPid.epsubname)
    if (value == EPid.epsubname) {
      this.epSubDropDown = EPid.epsubid;
      console.log("ep id::", this.epSubDropDown)
      this.getKitchenDropDown();
      this.isDisabled = true;
      this.disable1();
    } else {
      this.isDisabled = false;
      console.log("ep id:: error")
    }
  }
}

disable1() {
  if (this.isDisabled) {
      this.epkitchendetails.controls['epkitchenid'].enable();
  } else {
      this.epkitchendetails.controls['epkitchenid'].disable();
  }
}
//Kitchen details autofetch function
onShowkitchendetails(event) {
  const value = event.target.value;
  console.log("Packing type::", value)
  for (var EPid of this.eventKitchenPlace) {
    if (value == EPid.epkitchenname) {
      this.epKitchenDropDown = EPid.epkitchenid;
      console.log("ep id::", this.epSubDropDown)
      this.getKitchenDetails();
    }
  }
}
//Kitchen only allowed numeric value function
numberOnly(event): boolean {
  const charCode = (event.which) ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;

}
kitchendisable(event) {
  const value = event.target.value;
  console.log("Kitchen::", value);
  if (value == "no") {
    this.epkitchendetails.disable();
    this.epkitchendetails.controls['Kitchenrequired'].enable();
    this.isdisablesave = false;
    this.isdisabledraft = false;
    this.isdisablenext = true;
  } else {
    if (value == "yes") {
      this.epkitchendetails.enable();
      this.isdisablesave = true;
      this.isdisabledraft = true;
      this.isdisablenext = false;
      this.disable1();
      this.disable();
    }
  }
}

draftButton(status: string){
  let hasError = false;

    if (this.epkitchendetails.get('epid')?.invalid) {
        this.epkitchenErrors.epid
            = "*Event place is required";
        hasError = true;
    }

    if (this.epkitchendetails.get('epsubid')?.invalid) {
      this.epkitchenErrors.epsubid = "*Event sub Place is required";
      hasError = true;
  }

    // if (this.epkitchendetails.get('epkitchenid')?.invalid) {
    //     this.epkitchenErrors.epkitchenid = "*Kitchen place  is required";
    //     hasError = true;
    // }
    let inputValue = this.epkitchendetails.controls['epkitchenname'].value;
         let outputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
         this.epkitchendetails.controls['epkitchenname'].setValue(outputValue)

    if (this.epkitchendetails.get('epkitchenname')?.invalid) {
        this.epkitchenErrors.epkitchenname = "*Kitchen Name is required";
        hasError = true;
    }
    if (!hasError) {
      this.saveKitchen(status)
 }
}

nextButton(){
this.router.navigate(['/main/open-space'])
}

successAlert() {
  Swal.fire({
    title: "Your Registration Sucessfully Completed, Do you want to register another Kitchen ?",
    icon: "success",
    showDenyButton: true,
    confirmButtonColor: "#4fab69",
    denyButtonColor: "#c73636",
    confirmButtonText: "Yes",
    denyButtonText: "No",
    showClass: {
      popup: "animate_animated animate_fadeInDown",
    },
    hideClass: {
      popup: "animate_animated animate_fadeOutUp",
    },
  }).then((result) =>{
    if(result.isConfirmed) {
          this.epkitchendetails.controls["epid"].setValue("");
          this.epkitchendetails.controls["epsubid"].setValue("");
          this.epkitchendetails.controls["epkitchenname"].setValue("");
          this.epkitchendetails.controls["epkitchencapacity"].setValue("");
          this.epkitchendetails.controls["epkitchendesc"].setValue("");
          this.epkitchendetails.controls["epkitchensize"].setValue("");
          this.epkitchendetails.controls["epkitchennoofstoves"].setValue("");
          this.epkitchendetails.controls["epkitchennoofgrinders"].setValue("");
          this.epkitchendetails.controls["epkitchennooffridge"].setValue("");
          this.epkitchendetails.controls["epkitchennoofmixie"].setValue("");
          this.epkitchendetails.controls["epkitchenshow"].setValue("");
          this.epkitchendetails.controls["kitchenactivestatus"].setValue("");
          this.epkitchendetails.controls["epkitchenchargable"].setValue("");
          this.epkitchendetails.controls["epkitchencharges"].setValue("");
    }else{
      this.router.navigate(['/main/open-space'])
    }
  });
}
UpdateAlert() {
  Swal.fire({
    title: "Your Registration Updated Sucessfully.",
    icon: "success",
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "OK",
    showClass: {
      popup: "animate_animated animate_fadeInDown",
    },
    hideClass: {
      popup: "animate_animated animate_fadeOutUp",
    },
  }).then((result) =>{
    if(result.isConfirmed) {
      window.location.reload();
    }
  });
}

successAlertDarft() {
  Swal.fire({
    title: "Your Registration successfully saved as draft, Do you want to register another Kitchen ?",
    icon: "success",
    showDenyButton: true,
    confirmButtonColor: "#4fab69",
    denyButtonColor: "#c73636",
    confirmButtonText: "Yes",
    denyButtonText: "No",
    showClass: {
      popup: "animate_animated animate_fadeInDown",
    },
    hideClass: {
      popup: "animate_animated animate_fadeOutUp",
    },
  }).then((result) =>{
    if(result.isConfirmed) {
      this.epkitchendetails.controls["epid"].setValue("");
      this.epkitchendetails.controls["epsubid"].setValue("");
      this.epkitchendetails.controls["epkitchenname"].setValue("");
      this.epkitchendetails.controls["epkitchencapacity"].setValue("");
      this.epkitchendetails.controls["epkitchendesc"].setValue("");
      this.epkitchendetails.controls["epkitchensize"].setValue("");
      this.epkitchendetails.controls["epkitchennoofstoves"].setValue("");
      this.epkitchendetails.controls["epkitchennoofgrinders"].setValue("");
      this.epkitchendetails.controls["epkitchennooffridge"].setValue("");
      this.epkitchendetails.controls["epkitchennoofmixie"].setValue("");
      this.epkitchendetails.controls["epkitchenshow"].setValue("");
      this.epkitchendetails.controls["kitchenactivestatus"].setValue("");
      this.epkitchendetails.controls["epkitchenchargable"].setValue("");
      this.epkitchendetails.controls["epkitchencharges"].setValue("");
}else{
  this.router.navigate(['/main/open-space'])
}
  });
}

 //kitchen errorAlert popup message
 errorAlert() {
  Swal.fire({
    title: "Sorry, Please Try Again Your Registration Process",
    icon: "error",
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "OK",
    showClass: {
      popup: "animate_animated animate_fadeInDown",
    },
    hideClass: {
      popup: "animate_animated animate_fadeOutUp",
    },
  });
}
}
