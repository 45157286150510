<div class="row col-md-12">
  <div class="col-md-1" style="text-align-last: center">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a (click)="onToggleMenuSidebar()" class="nav-link" role="button"
        ><i class="fas fa-bars"></i
        ></a>
      </li>
    </ul>
  </div>
  <div class="col-md-5">
    <h3 class="mt-2">Event Place Management</h3>
  </div>

  <div class="col-md-6">
    <ul class="col-md-12 ml-auto">
      <app-user></app-user>
    </ul>
  </div>
</div>
