import { Component, OnInit, HostListener } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { AppService } from "@services/app.service";
import { FormArray, FormBuilder, FormControl, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { EventPlaceBooking } from "@modules/ep-booking-management/model/eventplace-booking";
import { Subject, takeUntil } from "rxjs";
import { EPRoomsDetails } from "@modules/event-place-management/model/epRooms-details";
import { EPOpenSpace } from "@modules/event-place-management/model/epopen-space";
import { EPGeneralAmenities } from "@modules/event-place-management/model/ep-generalamenities";
import { EPIncidentalCharges } from "@modules/event-place-management/model/ep-incidentalcharges";
import { EPParking } from '@modules/event-place-management/model/ep-parking';
import { EpKitchenDetails } from '@modules/event-place-management/model/epKitchenDetails';
import { EPMiscCharges } from '@modules/event-place-management/model/ep-misccharges';
import { EPVendorRegister } from "@modules/event-place-management/model/ep-vendorregister";
import { EPActivity } from '@modules/event-place-management/model/ep-activity';
import { DatePipe } from '@angular/common';
import { EventAreaActivity } from "../model/eventarea-activity";
import { EventAreaReuired } from "../model/eventarea-required";
import { ToastrService } from "ngx-toastr";
import { ActivityClass } from "@modules/ep-booking-management/model/activityClass";
import { AmenitiesClass } from "../model/amrnities-class";
import { EventAreaAmenities } from "../model/eventarea-amenities";
import { EventAreaIncidental } from "../model/eventarea-incidental";
import { IncidentalClass } from "../model/incidental-class";
import { MiscClass } from "../model/misc-class";
import { EventAreaMisc } from "../model/eventarea-misc";
import { EventAmount } from "@modules/ep-booking-management/model/eventamount";
import Swal from "sweetalert2";
import { BookingareaDataService } from "../model/bookingareaDataService";
import { ActivitiesMapping } from "@modules/event-place-management/model/activities-mapping";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-event-bookingarea',
  templateUrl: './event-bookingarea.component.html',
  styleUrls: ['./event-bookingarea.component.scss']
})

export class EventBookingAreaComponent implements OnInit {
  fields = [{ value: '' }];
  // incidentalfields = [{value: ''}];
  hideinccharges: number = 0;
  isDisabled = true;
  eventActivity: EventAreaActivity[] = [];
  eventReqiuredDetails: EventAreaReuired[] = [];
  acRooms: number = 0;
  normalRooms: number = 0;
  eventRoomPlace: EPRoomsDetails[] = [];
  OpenSpacegetDetails: EPOpenSpace[] = [];
  AminitiesDetails: EPGeneralAmenities[] = [];
  IncidentChargesDetails: EPIncidentalCharges[] = [];
  bookingAmount: EventAmount[] = [];
  openSpace: string[] = [];
  openSpacecharge: string[] = [];
  Aminities: string[] = [];
  IncidentCharges: string[] = [];
  eventVendorPlace: ActivitiesMapping[] = [];
  ActivityDetails: EPActivity[] = [];
  eventactivityPlace: string[] = [];
  eventVendorDropDown: string[] = [];
  eventParkingDropDown: string[] = [];
  eventParkingArea: EPParking[] = [];
  eventKitchenDropDown: string[] = [];
  eventKitchenPlace: EpKitchenDetails[] = [];
  eventMiscChargeDropDown: string[] = [];
  eventMiscChargePlace: EPMiscCharges[] = [];
  startDate: string = "";
  startDateFirstHF: string = "";
  startDateSecondHF: string = "";
  endDate: string = "";
  endDateFirstHF: string = "";
  endDateSecondHF: string = "";
  eventAreaReqid: string[] = [];
  eventAreaActId: string[] = [];
  eventAreaAmenitId: string[] = [];
  eventAreaIncidentId: string[] = [];
  startingUnit: string[] = [];
  endingUnit: string[] = [];
  eventAreaMiscId: string[] = [];
  eventAmenities: EventAreaAmenities[] = [];
  eventIncidental: EventAreaIncidental[] = [];
  eventMisc: EventAreaMisc[] = []
  saveButtoReq: boolean = false;
  saveButton: boolean = false;
  amenitSaveButton: boolean = false;
  incidentalSaveButton: boolean = false;
  miscSaveButton: boolean = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  multiActivity: ActivityClass[] = [];
  multiAmenities: AmenitiesClass[] = [];
  multiIncidental: IncidentalClass[] = [];
  multiMisc: MiscClass[] = [];
  amenitiesQty: string[] = [];
  amenityName: string[] = [];
  amenitiesCharge: string = "";
  amenityFinalCharge: number = 0;
  roomCharges: number = 0;
  roomsAdd: number = 0;
  acroomsAdd: number = 0;
  parkingCharges: string[] = [];
  kitchenCharges: string[] = [];
  openSpaceCharges: string[] = [];
  vendorName: string[] = [];
  epActivitycharge: string = "";
  activityName: string[] = [];
  activityCharges: number = 0;
  incidentalName: string[] = [];
  incidentalQty: string[] = [];
  incidentalCharges: string = "";
  incCharges: string = "";
  incidentalFinalCharge: number = 0;
  miscName: string[] = [];
  miscQty: string[] = [];
  miscCharges: string = "";
  miscFinalCharge: number = 0;
  getBookingCharges: EventAmount[] = [];
  isChanged = false;
  isVisible = false;
  isVisibleAct = false;
  isVisibleAmt = false;
  isVisibleInc = false;
  isVisibleMisc = false;
  isopenspaceDisabled = false;
  isParkingDisabled = true;
  roomsdetailsdisable = false;
  parkingdetailsdisable = false;
  openSpacedisable = false;
  kitchendetailsdisable = false;
  chargesdetailsdisable = false;
  Requireddisable = true;
  activitydetailsdisable = false;
  miscdetailsdisable = false;
  incidentaldetailsdisable = false;
  incidentaldetailsdisable1 = false;
  amintiesdetailsdiable = false;
  paymentdiable = false;
  isUpdate = false;
  addNewMisc = false;
  addNewIncidental = false;
  addNewAmenit = false;
  addNewVendor = false;
  isUpdateinc = false;
  // getIncidental : EventAreaIncidental | undefined;
  getcurrentdetails: EventAreaIncidental[] = [];
  getEventData: EventPlaceBooking[] = [];
  eventId: string[] = [];
  localeventId: string = ""
  updateIncCharge: String[] = [];
  epId: string = "";
  epSubId: string = "";
  updatedIncCharge: EventAreaIncidental[] = [];
  currentInc: EventAreaIncidental | undefined;
  currentEventData: EventPlaceBooking | undefined;
  MapactivityName: string | undefined;
  paymentUpdate = false;
  incidentalCharge: string = ""
  saveAcivity: EventAreaActivity[] = []
  amenitiesDatails: EventAreaAmenities[] = []
  miscDetails: EventAreaMisc[] = []
  getEventAreaReq: EventAreaReuired[] = [];
  endFirstHalf = false
  endsecondHalf = false
  isVisibleAlert = false
  getReqId: string = ""
  activityDetails: EventAreaActivity[] = []
  getActivityCharges: string = ""
  isActivityUpdate = false;
  amenitiedDetails: EventAreaAmenities[] = []
  amenityCharges: string = ""
  isAmenitiesUpdate = false
  getmiscDetails: EventAreaMisc[] = []
  getIncidentals: EventAreaIncidental[] = []
  getMiscCharges: string = ""
  isMiscsUpdate = false;
  getUpdateCharges: EventAmount[] = []
  getAmtId: string = ""
  // getUpdatCharges =

  constructor(private router: Router, private appService: AppService,
    private toaster: ToastrService, private datePipe: DatePipe,
    private dataService: BookingareaDataService,
    private spinner: NgxSpinnerService) {

  }

  bookingCharges = new UntypedFormGroup({
    reqid: new UntypedFormControl("", Validators.required),
    amtid: new UntypedFormControl("", Validators.required),
    roomamount: new UntypedFormControl("", Validators.required),
    parkingamount: new UntypedFormControl("", Validators.required),
    kitchenamount: new UntypedFormControl("", Validators.required),
    openspaceamount: new UntypedFormControl("", Validators.required),
    acitvityamount: new UntypedFormControl("", Validators.required),
    amenityamount: new UntypedFormControl("", Validators.required),
    incidentalamount: new UntypedFormControl("", Validators.required),
    miscamount: new UntypedFormControl("", Validators.required),
    activeyn: new UntypedFormControl("", Validators.required),
    deleteyn: new UntypedFormControl("", Validators.required),
    createdtime: new UntypedFormControl("", Validators.required),
    modifiedtime: new UntypedFormControl("", Validators.required),
    createdby: new UntypedFormControl("", Validators.required),
    modifiedby: new UntypedFormControl("", Validators.required),
    epsubid: new UntypedFormControl("", Validators.required),
    epid: new UntypedFormControl("", Validators.required),
    eventid: new UntypedFormControl("", Validators.required),
  })

  eventRequired = new UntypedFormGroup({
    reqid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    no_normalrooms: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    no_acrooms: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    parkingrequired: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    two_wheelar: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    four_wheelar: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    kitchenrequired: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    stoverequired: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    grainderrequired: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    fridgerequired: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    washingrequired: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    openspacerequired: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    spacedetails: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    selectkitchen: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    selectopenspace: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    selectparking: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    activeyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    deleteyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    createdby: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epsubid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    eventid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),

  })

  eventAreaActivity = new UntypedFormGroup({
    reqid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    activity: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    actid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    activityrequired: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    selectvendor: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    selectactivity: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    qtyrequired: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    activeyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    deleteyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    createdby: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epsubid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    eventid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    modifiedby: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    modifiedtime: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
  })

  eventAreaAmenities = new UntypedFormGroup({
    reqid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    amenityactivity: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    amtid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    amenitiesrequired: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    selectamenities: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    qtyrequired: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    ameactiveyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    amedeleteyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    amecreatedby: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    ameepid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    ameepsubid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    eventid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    amemodifiedby: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    amemodifiedtime: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),

  })
  eventAreaIncidental = new UntypedFormGroup({
    reqid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    incactivity: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    incid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    eventid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    incchargerequired: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    selectinccharges: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    qtyrequired: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    startingunit: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    endingunit: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    activeyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    deleteyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    createdby: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epsubid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    modifiedby: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    modifiedtime: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
  })
  eventAreaMisc = new UntypedFormGroup({
    reqid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    miscactivity: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    misid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    mischargesreq: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    selectmischarges: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    qtyrequired: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    activeyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    deleteyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    createdby: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epsubid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    eventid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    modifiedby: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    modifiedtime: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
  })

  public eventbookingareaErrors = {
    selectparking: '',
    selectkitchen: '',
    selectopenspace: '',
    no_normalrooms: '',
    no_acrooms: '',
  }


  ngOnInit() {
    if (this.dataService.bookingData != undefined) {
      this.paymentdiable = false;
      console.log("Event data::", this.dataService.bookingData)
      // @ts-ignore
      this.getEventData = this.dataService.bookingData;
      this.currentEventData = this.dataService.bookingData;
      var eventId: string[] = [];
      // @ts-ignore
      for (let event of this.getEventData) {
        eventId.push(event.eventid)
        this.localeventId = event.eventid
        console.log("EventId::", eventId)
        this.epId = event.epid;
        this.epSubId = event.epsubid;
        const startDate = event.startdate;
        this.startDate = this.datePipe.transform(startDate, 'dd-MM-yyyy');
        const endDate = event.enddate
        this.endDate = this.datePipe.transform(endDate, 'dd-MM-yyyy');
        if (this.startDate == this.endDate) {
          if (event.starthalf1 == "true") {
            this.startDateFirstHF = "Yes"
            this.endDateFirstHF = "Yes"
          } else {
            this.startDateFirstHF = "No"
            this.endDateFirstHF = "No"
          } if (event.starthalf2 == "true") {
            this.startDateSecondHF = "Yes"
            this.endDateSecondHF = "Yes"
          } else {
            this.startDateSecondHF = "No"
            this.endDateSecondHF = "No"
          }
          this.endsecondHalf = false
          this.endFirstHalf = false
        } else {
          if (event.starthalf1 == "true") {
            this.startDateFirstHF = "Yes"
          } else {
            this.startDateFirstHF = "No"
          }
          if (event.starthalf2 == "true") {
            this.startDateSecondHF = "Yes"
          } else {
            this.startDateSecondHF = "No"
          }
          if (event.endhalf1 == "true") {
            console.log("True HF SF")
            this.endDateFirstHF = 'Yes'
          }
          else {
            this.endDateFirstHF = 'No'
          }
          if (event.endhalf2 == "true") {
            this.endDateSecondHF = "Yes"
          } else {
            this.endDateSecondHF = "No"
          }
          this.endsecondHalf = true
          this.endFirstHalf = true
        }

        this.isUpdate = true
      }
      this.getReqDetails()
      // this.getAmountDetails()
      // this.getEventById(eventId);
      // this.getIncidentChargesDropDown();
      // this.Requireddisable = true;
      // this.incidentaldetailsdisable1 = true;
      console.log("epId::", localStorage.getItem('epId'))
      console.log("epSubId::", localStorage.getItem('epSubId'))
      this.eventAreaIncidental.controls["selectinccharges"].enable();
      this.eventAreaIncidental.controls["startingunit"].enable();
      this.eventAreaIncidental.controls["endingunit"].enable();
      // this.eventAreaIncidental.controls["qtyrequired"].enable();
    } else if (localStorage.getItem('token')) {
      this.isChanged = false;
      const reloadFlag = 'reloadFlag';
      const hasReloaded = sessionStorage.getItem(reloadFlag);

      if (!hasReloaded) {
        sessionStorage.setItem(reloadFlag, 'true');
        location.reload();
      } else {
        sessionStorage.removeItem(reloadFlag);
      }
      console.log("epId::", localStorage.getItem('epId'))
      console.log("epSubId::", localStorage.getItem('epSubId'))
      const startDate = localStorage.getItem('epStartDate');
      this.startDate = this.datePipe.transform(startDate, 'dd-MM-yyyy');
      const endDate = localStorage.getItem('epEndDate');
      this.endDate = this.datePipe.transform(endDate, 'dd-MM-yyyy');
      this.functionhalfdate();
      this.getRoomsDropDown();
      this.getOpenSpaceDropDown();
      this.getParkingAreaDropDown();
      this.getKitchenDropDown();
    }
    // this.amintiesdetailsdiable = true
    // this.getAmenitiesDropDown()
    // this.miscdetailsdisable = true
    // this.getMiscChargeDropDown()
  }

  ngOnDestroy() {
    // this.dataService.updateInc = this.getBookingCharges;
    this.dataService.bookingData = this.currentEventData;
  }

  // getEventById(eventId) {
  //   console.log("Booking Id::", eventId)
  //   this.appService.getIncByEventId(eventId)
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe(data => {
  //       console.log("Inc Data::", data);
  //       //@ts-ignore
  //       this.multiIncidental = data
  //       this.incidentalSaveButton = true;
  //       var localincCharge: String[] = [];
  //       var localStartUnit: number = 0;
  //       for(var inctal of this.multiIncidental){
  //         //@ts-ignore
  //         localStartUnit = inctal.startingunit
  //         localincCharge.push(inctal.qtyrequired);
  //         this.updateIncCharge = localincCharge;
  //         console.log("selectinc::",localStartUnit)
  //         console.log("selectinc::",localincCharge)
  //       }
  //       for(var inc of this.getcurrentdetails ){
  //         localincCharge.push(inc.selectinccharges);
  //         this.updateIncCharge = localincCharge;
  //         console.log("selectinc::",inc.selectinccharges)
  //         const multiIncidental: IncidentalClass = new IncidentalClass();
  //       multiIncidental.eventid = inc.eventid
  //       multiIncidental.incid = inc.incid
  //       multiIncidental.reqid = inc.reqid
  //       multiIncidental.epid =  inc.epid
  //       multiIncidental.epsubid = inc.epsubid
  //       multiIncidental.incactivity = inc.incactivity
  //       multiIncidental.createdby = localStorage.getItem('token')
  //       multiIncidental.incchargerequired = "Yes"
  //       multiIncidental.activeyn = "Yes"
  //       multiIncidental.deleteyn = "No"
  //       this.multiIncidental.push(multiIncidental);
  //       }
  //     }, (error: any) => {
  //       console.log("Something went wrong");
  //     })
  // }



  functionhalfdate() {
    if (this.startDate == this.endDate) {
      if (localStorage.getItem('startFirstHF') == "true") {
        this.startDateFirstHF = "Yes"
        this.endDateFirstHF = "Yes"
      } else {
        this.startDateFirstHF = "No"
        this.endDateFirstHF = "No"
      } if (localStorage.getItem('startSecondHF') == "true") {
        this.startDateSecondHF = "Yes"
        this.endDateSecondHF = "Yes"
      } else {
        this.startDateSecondHF = "No"
        this.endDateSecondHF = "No"
      }
      this.endsecondHalf = false
      this.endFirstHalf = false
    } else {
      if (localStorage.getItem('startFirstHF') == "true") {
        this.startDateFirstHF = "Yes"
      } else {
        this.startDateFirstHF = "No"
      }
      if (localStorage.getItem('startSecondHF') == "true") {
        this.startDateSecondHF = "Yes"
      } else {
        this.startDateSecondHF = "No"
      }
      if (localStorage.getItem('endFirstHF') == "true") {
        console.log("True HF SF")
        this.endDateFirstHF = 'Yes'
      } else {
        this.endDateFirstHF = 'No'
      }
      if (localStorage.getItem('endSecondHF') == "true") {
        this.endDateSecondHF = "Yes"
      } else {
        this.endDateSecondHF = "No"
      }
      this.endsecondHalf = true
      this.endFirstHalf = true
    }
  }


  onSubmit() {
    this.formValidation();
  }


  formValidation() {
    this.eventbookingareaErrors.selectparking = "";
    this.eventbookingareaErrors.selectkitchen = "";
    this.eventbookingareaErrors.no_normalrooms = "";
    this.eventbookingareaErrors.no_acrooms = "";

    let hasError = false;
    if (this.eventRequired.controls['no_normalrooms'].value > this.normalRooms) {
      this.eventbookingareaErrors.no_normalrooms = "*Enter the Valid Normal Room Number";
      hasError = true;
    }
    if (this.eventRequired.controls['no_acrooms'].value > this.acRooms) {
      this.eventbookingareaErrors.no_acrooms = "*Enter the Valid Ac Room Number";
      hasError = true;
    }
    if (this.eventRequired.controls['parkingrequired'].value == 'yes') {
      if (this.eventRequired.get('selectparking')?.invalid) {
        this.eventbookingareaErrors.selectparking = "*Parkingarea is required";
        hasError = true;
      }
    }
    if (this.eventRequired.controls['kitchenrequired'].value == 'yes') {
      if (this.eventRequired.get('selectkitchen')?.invalid) {
        this.eventbookingareaErrors.selectkitchen = "*Kitchen is required";
        hasError = true;
      }
    }
    if (this.eventRequired.controls['openspacerequired'].value == 'yes') {
      if (this.eventRequired.get('selectopenspace')?.invalid) {
        this.eventbookingareaErrors.selectopenspace = "*openspce is required";
        hasError = true;
      }
    }
    if (!hasError) {
      this.saveEventAreareq();
    }
  }

  roomsdisable(event) {
    const value = event.target.value;
    console.log("roomsarea::", value)
    this.isDisabled = false;
    if (value == "") {
      this.saveButtoReq = false;
    }
    else {
      this.saveButtoReq = true;
    }
  }
  parkingdisable(event) {
    const value = event.target.value;
    console.log("parkingarea::", value);
    if (value == "No") {
      this.isDisabled = false;
      this.eventRequired.controls["selectparking"].disable();
      this.eventRequired.controls["two_wheelar"].disable();
      this.eventRequired.controls["four_wheelar"].disable();
    } else {
      if (value == "Yes") {
        this.isDisabled = true;
        this.eventRequired.controls["selectparking"].enable();
        this.eventRequired.controls["two_wheelar"].enable();
        this.eventRequired.controls["four_wheelar"].enable();
        this.saveButtoReq = true;
      }
    }
  }

  kitchendisable(event) {
    const value = event.target.value;
    console.log("kitchenarea::", value);
    if (value == "No") {
      this.isDisabled = false;
      this.eventRequired.controls["selectkitchen"].disable();
      this.eventRequired.controls["stoverequired"].disable();
      this.eventRequired.controls["grainderrequired"].disable();
      this.eventRequired.controls["fridgerequired"].disable();
      this.eventRequired.controls["washingrequired"].disable();
    } else {
      if (value == "Yes") {
        this.isDisabled = true;
        this.eventRequired.controls["selectkitchen"].enable();
        this.eventRequired.controls["stoverequired"].enable();
        this.eventRequired.controls["grainderrequired"].enable();
        this.eventRequired.controls["fridgerequired"].enable();
        this.eventRequired.controls["washingrequired"].enable();
        this.saveButtoReq = true;
      }
    }
  }

  openspacedisable(event) {
    const value = event.target.value;
    console.log("openspacearea::", value);
    if (value == "No") {
      this.isDisabled = false;
      this.eventRequired.controls["selectopenspace"].disable();
      this.eventRequired.controls["spacedetails"].disable();
    } else {
      if (value == "Yes") {
        this.isDisabled = true;
        this.eventRequired.controls["selectopenspace"].enable();
        this.eventRequired.controls["spacedetails"].enable();
        this.saveButtoReq = true;
      }
    }
  }

  activitydisable(event) {
    const value = event.target.value;
    console.log("activearea::", value);
    if (value == "No") {
      this.isDisabled = false;
      this.eventAreaActivity.controls["selectvendor"].disable();
      this.eventAreaActivity.controls["selectactivity"].disable();
      this.saveButton = false;
      this.addNewVendor = false
      this.multiActivity = []
    } else {
      if (value == "Yes") {
        this.isDisabled = true;
        this.addNewVendor = true
        this.eventAreaActivity.controls["selectvendor"].enable();
        this.eventAreaActivity.controls["selectactivity"].enable();
        const multiActivity: ActivityClass = new ActivityClass();
        multiActivity.actid = localStorage.getItem('ActId')
        multiActivity.reqid = localStorage.getItem('ReqId')
        multiActivity.epid = localStorage.getItem('epId')
        multiActivity.epsubid = localStorage.getItem('epSubId')
        multiActivity.eventid = localStorage.getItem('epEventId')
        multiActivity.createdby = localStorage.getItem('token')
        multiActivity.activityrequired = "Yes"
        multiActivity.activeyn = "Yes"
        multiActivity.deleteyn = "No"
        this.multiActivity.push(multiActivity);
      }
    }
  }

  amenitiesdisable(event) {
    const value = event.target.value;
    console.log("amenitiesarea::", value);
    if (value == "No") {
      this.isDisabled = false;
      this.eventAreaAmenities.controls["selectamenities"].disable();
      this.eventAreaAmenities.controls["qtyrequired"].disable();
      this.amenitSaveButton = false;
      this.addNewAmenit = false
      this.multiAmenities = []
    } else {
      if (value == "Yes") {
        this.isDisabled = true;
        this.addNewAmenit = true
        this.eventAreaAmenities.controls["selectamenities"].enable();
        this.eventAreaAmenities.controls["qtyrequired"].enable();
        const multiAmenities: AmenitiesClass = new AmenitiesClass();
        multiAmenities.amtid = localStorage.getItem('AmenitId')
        multiAmenities.reqid = localStorage.getItem('ReqId')
        multiAmenities.ameepid = localStorage.getItem('epId')
        multiAmenities.ameepsubid = localStorage.getItem('epSubId')
        multiAmenities.eventid = localStorage.getItem('epEventId')
        multiAmenities.amecreatedby = localStorage.getItem('token')
        multiAmenities.amenitiesrequired = "Yes"
        multiAmenities.ameactiveyn = "Yes"
        multiAmenities.amedeleteyn = "No"
        this.multiAmenities.push(multiAmenities);
      }
    }
  }

  incidentaldisable(event) {
    const value = event.target.value;
    console.log("incidentalarea::", value);
    if (value == "No") {
      this.isDisabled = false;
      this.eventAreaIncidental.controls["selectinccharges"].disable();
      // this.eventAreaIncidental.controls["qtyrequired"].disable();
      this.eventAreaIncidental.controls["startingunit"].disable();
      this.eventAreaIncidental.controls["endingunit"].disable();
      this.incidentalSaveButton = false;
      this.addNewIncidental = false
      this.multiIncidental = []
    } else {
      if (value == "Yes") {
        this.isDisabled = true;
        this.addNewIncidental = true
        this.eventAreaIncidental.controls["selectinccharges"].enable();
        this.eventAreaIncidental.controls["startingunit"].disable();
        this.eventAreaIncidental.controls["endingunit"].disable();
        // this.eventAreaIncidental.controls["qtyrequired"].enable();
        const multiIncidental: IncidentalClass = new IncidentalClass();
        multiIncidental.incid = localStorage.getItem('IncidentalId')
        multiIncidental.reqid = localStorage.getItem('ReqId')
        multiIncidental.epid = localStorage.getItem('epId')
        multiIncidental.epsubid = localStorage.getItem('epSubId')
        multiIncidental.eventid = localStorage.getItem('epEventId')
        multiIncidental.createdby = localStorage.getItem('token')
        multiIncidental.incchargerequired = "Yes"
        multiIncidental.activeyn = "Yes"
        multiIncidental.deleteyn = "No"
        this.multiIncidental.push(multiIncidental);
      }
    }
  }

  miscdisable(event) {
    const value = event.target.value;
    console.log("miscarea::", value);
    if (value == "No") {
      this.isDisabled = false;
      this.eventAreaMisc.controls["selectmischarges"].disable();
      this.eventAreaMisc.controls["qtyrequired"].disable();
      this.miscSaveButton = false;
      this.addNewMisc = false
      this.multiMisc = []
    } else {
      if (value == "Yes") {
        this.isDisabled = true;
        this.eventAreaMisc.controls["selectmischarges"].enable();
        this.eventAreaMisc.controls["qtyrequired"].enable();
        const multiMisc: MiscClass = new MiscClass();
        multiMisc.misid = localStorage.getItem('MiscId')
        multiMisc.reqid = localStorage.getItem('ReqId')
        multiMisc.epid = localStorage.getItem('epId')
        multiMisc.epsubid = localStorage.getItem('epSubId')
        multiMisc.eventid = localStorage.getItem('epEventId')
        multiMisc.createdby = localStorage.getItem('token')
        multiMisc.mischargesreq = "Yes"
        multiMisc.activeyn = "Yes"
        multiMisc.deleteyn = "No"
        this.multiMisc.push(multiMisc);
        this.addNewMisc = true
      }
    }
  }


  /* Rooms get Api Call */
  getRoomsDropDown() {
    this.appService
      .getRoomsDropDown(localStorage.getItem('epId'), localStorage.getItem('epSubId'))
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("Test data::", data);
        // @ts-ignore
        this.eventRoomPlace = data;
        var localRooms: EPRoomsDetails[] = [];
        var localNormalRooms: EPRoomsDetails[] = [];
        for (var ep of this.eventRoomPlace) {
          if (ep.eproomaircond == 'Yes') {
            localRooms.push(ep)
          } else if (ep.eproomaircond == 'No') {
            localNormalRooms.push(ep)
          }
          this.roomsdetailsdisable = true;
        }
        this.acRooms = localRooms.length
        this.normalRooms = localNormalRooms.length;
        if (this.acRooms == 0) {
          this.eventRequired.controls["no_acrooms"].disable();
        } else {
          this.eventRequired.controls["no_acrooms"].enable();
        }
        if (this.normalRooms == 0) {
          this.eventRequired.controls["no_normalrooms"].disable();
        } else {
          this.eventRequired.controls["no_normalrooms"].enable();
        }
      })
  }

  getOpenSpaceDropDown() {
    this.appService
      .getOpenSpaceDropDown(localStorage.getItem('epId'), localStorage.getItem('epSubId'))
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("Test data::", data);
        // @ts-ignore
        this.OpenSpacegetDetails = data;
        for (var ep of this.OpenSpacegetDetails) {
          if (ep.epopenspacename == "" && ep.epopenspacename == null) {
            this.isopenspaceDisabled = false;
          } else {
            this.openSpace.push(ep.epopenspacename)
            this.openSpacecharge.push(ep.epopenspacecharges)
            console.log("charges ::", this.openSpacecharge)
            this.isopenspaceDisabled = true;
          }
          this.openSpacedisable = true;
        }
      })
  }

  getAmenitiesDropDown() {
    this.appService
      .getAmenitiesDropDown(localStorage.getItem('epId'), localStorage.getItem('epSubId'))
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("Test data::", data);
        // @ts-ignore
        this.AminitiesDetails = data;
        for (var ep of this.AminitiesDetails) {
          // this.Aminities.push(ep.epgenamenitiesname)
          this.amintiesdetailsdiable = true;
        }
      })
  }


  getIncidentChargesDropDown() {
    if (!this.isUpdate) {
      this.appService
        .getIncidentChargesDropDown(localStorage.getItem('epId'), localStorage.getItem('epSubId'))
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          console.log("Test data::", data);
          // @ts-ignore
          this.IncidentChargesDetails = data;
          for (var ep of this.IncidentChargesDetails) {
            this.IncidentCharges.push(ep.epincidentalname)
            this.incidentaldetailsdisable = true;
          }
        })
    } else {
      this.appService
        .getIncidentChargesDropDown(this.epId, this.epSubId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          console.log("Test data::", data);
          // @ts-ignore
          this.IncidentChargesDetails = data;
          var localincidental : String [] = []
          for (var ep of this.IncidentChargesDetails) {
            localincidental.push(ep.epincidentalname)
            // this.IncidentCharges.push(ep.epincidentalname)
            console.log("currentinccharge::", this.IncidentCharges)
            if (this.updateIncCharge != localincidental) {
              this.incidentalCharges = ep.epincidentalchargecharges
              console.log("currentinccharge2::", this.incidentalCharges)
            }
          }
          //@ts-ignore
          this.IncidentCharges = localincidental
        })
    }
  }

  getMiscChargeDropDown() {
    this.appService
      .getMiscChargeDropDown(localStorage.getItem('epId'), localStorage.getItem('epSubId'))
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("Test data::", data);
        // @ts-ignore
        this.eventMiscChargePlace = data;
        for (var ep of this.eventMiscChargePlace) {
          this.eventMiscChargeDropDown.push(ep.epmiscellaneouschargename)
          this.miscdetailsdisable = true;
        }
      })
  }

  getParkingAreaDropDown() {
    this.appService
      .getParkingAreaDropDown(localStorage.getItem('epId'), localStorage.getItem('epSubId'))
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("Test data::", data);
        // @ts-ignore
        this.eventParkingArea = data;
        for (var ep of this.eventParkingArea) {
          if (ep.epparkingname == "" && ep.epparkingname == null) {
            this.isParkingDisabled = false
          } else {
            this.eventParkingDropDown.push(ep.epparkingname)
            this.isParkingDisabled = true
          }
          this.parkingdetailsdisable = true;
        }
      })
  }

  getKitchenDropDown() {
    this.appService
      .getKitchenDropDown(localStorage.getItem('epId'), localStorage.getItem('epSubId'))
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("Test data::", data);
        // @ts-ignore
        this.eventKitchenPlace = data;
        for (var ep of this.eventKitchenPlace) {
          this.eventKitchenDropDown.push(ep.epkitchenname)
          this.kitchendetailsdisable = true;
        }
      })
  }

  // getVendorDropDown() {
  //   this.appService
  //     .getVendorDropDown(localStorage.getItem('epId'), localStorage.getItem('epSubId'))
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe((data) => {
  //       console.log("Test data::", data);
  //       // @ts-ignore
  //       this.eventVendorPlace = data;
  //       for (var ep of this.eventVendorPlace) {
  //         this.eventVendorDropDown.push(ep.ep3rdpartyvendorname)
  //         this.activitydetailsdisable = true;
  //       }
  //     })
  // }

  getActivityDropDown() {
    this.appService
      .getActivityDropDown(localStorage.getItem('epId'), localStorage.getItem('epSubId'))
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("Test data::", data);
        // @ts-ignore
        this.ActivityDetails = data;
        for (var ep of this.ActivityDetails) {
          this.eventactivityPlace.push(ep.epactivityname)
          this.activitydetailsdisable = true;
        }
      })
  }

  /*ActivitiesMapping get Vandor Name*/
  getActivityMapping() {
    this.appService
      .getActiveMapping(localStorage.getItem('epId'), localStorage.getItem('epSubId'))
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("Test data::", data);
        // @ts-ignore
        this.eventVendorPlace = data;
        for (var ep of this.eventVendorPlace) {
          if (this.MapactivityName == ep.epactivityname)
            this.eventVendorDropDown.push(ep.epvendorname)
          this.activitydetailsdisable = true;
        }
      })
  }

  saveEventAreareq() {
    if (this.getEventAreaReq.length !== 0) {
      this.spinner.show()
      this.eventRequired.controls["activeyn"].setValue("Yes");
      this.eventRequired.controls["deleteyn"].setValue("No");
      this.eventRequired.controls["createdby"].setValue(localStorage.getItem("token"));
      this.eventRequired.controls["epid"].setValue(localStorage.getItem('epId'));
      this.eventRequired.controls["epsubid"].setValue(localStorage.getItem('epSubId'));
      this.eventRequired.controls["eventid"].setValue(this.localeventId);
      this.eventRequired.controls["reqid"].setValue(this.getReqId)
      console.log("getDetailsforevent::", this.eventRequired.value)
      this.spinner.hide()
      this.appService.updatEventAreaReq(this.eventRequired.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          this.spinner.hide()
          console.log("updatEventAreaReq details ::", data);
          this.toaster.success("Event Reqiured details Updated successfully")
          this.isVisible = true;
          this.isVisibleAlert = true;
          this.chargesdetailsdisable = true;
          this.isVisibleAlert = false;
          this.getActivityDropDown();
          // this.getVendorDropDown();
          this.getMiscChargeDropDown();
          this.getAmenitiesDropDown();
          this.getIncidentChargesDropDown();
          this.getAcivityCharges()
          this.getAmenities()
          this.getEventAmenitiesId()
          this.getEventMiscId();
          this.getEventAmenitiesId();
          this.getEventIncidentalId();
          this.getMisc()
          this.paymentdiable = true;
          // this.getReqDetails()
          this.getEventById(this.localeventId);
          // this.getIncidentChargesDropDown();
          this.Requireddisable = true;
          this.incidentaldetailsdisable1 = true;
          this.saveButton = false;
          this.Requireddisable = false;
          this.eventRequired.controls["parkingrequired"].setValue("");
          this.eventRequired.controls["two_wheelar"].setValue("");
          this.eventRequired.controls["four_wheelar"].setValue("");
          this.eventRequired.controls["selectparking"].setValue("");
          this.eventRequired.controls["kitchenrequired"].setValue("");
          this.eventRequired.controls["stoverequired"].setValue("");
          this.eventRequired.controls["grainderrequired"].setValue("");
          this.eventRequired.controls["fridgerequired"].setValue("");
          this.eventRequired.controls["washingrequired"].setValue("");
          this.eventRequired.controls["selectkitchen"].setValue("");
          this.eventRequired.controls["openspacerequired"].setValue("");
          this.eventRequired.controls["spacedetails"].setValue("");
          this.eventRequired.controls["selectopenspace"].setValue("");
          this.eventReqiuredDetails.push(data)
          for (let epid of this.eventReqiuredDetails) {
            this.eventAreaReqid.push(epid.reqid)
            console.log("eventrequiredId::", this.eventAreaReqid)
            localStorage.setItem('ReqId', String(this.eventAreaReqid))
            var localRoomCharges: string[] = [];
            var localacRoomCharges: string[] = [];
            for (var room of this.eventRoomPlace) {
              if (room.eproomaircond == "Yes") {
                localacRoomCharges.push(room.eproomscharges)
                console.log("getlocal::", localacRoomCharges)
              } else if (room.eproomaircond == 'No') {
                localRoomCharges.push(room.eproomscharges);
                console.log("getmappinglocal::", localRoomCharges)
              }
            }
            const normalRoom = this.eventRequired.get("no_normalrooms").value
            const acRoom = this.eventRequired.get("no_acrooms").value
            this.roomsAdd = Number(normalRoom) * Number(localRoomCharges[0])
            console.log("getroomslocal::", this.roomsAdd)
            this.acroomsAdd = Number(acRoom) * Number(localacRoomCharges[0])
            console.log("getacroomslocal::", this.acroomsAdd)
            if(!isNaN(this.roomsAdd) && isNaN(this.acroomsAdd)){
              this.roomCharges = Number(this.roomsAdd)
              console.log("roomCharges1::", (this.roomCharges))
            }else if(!isNaN(this.acroomsAdd) && isNaN(this.roomsAdd) ){
              this.roomCharges = Number(this.acroomsAdd)
              console.log("roomCharges2::", (this.roomCharges))
            }else if(!isNaN(this.roomsAdd) && !isNaN(this.acroomsAdd)){
            this.roomCharges = Number(this.roomsAdd) + Number(this.acroomsAdd)
            console.log("roomCharges3::", (this.roomCharges))
            }
            //@ts-ignore
            localStorage.setItem("roomCharge", Number(this.roomCharges))
            var localParkingCharges: string[] = [];
            for (var park of this.eventParkingArea) {
              if (epid.selectparking == park.epparkingname) {
                localParkingCharges.push(park.epparkingareacharges)
                this.parkingCharges = localParkingCharges;
                console.log("parkingCharges::", this.parkingCharges)
                localStorage.setItem("parkingCharge", String(this.parkingCharges))
              }
            }
            var localKitchenCharges: string[] = [];
            for (var kitchen of this.eventKitchenPlace) {
              if (epid.selectkitchen == kitchen.epkitchenname) {
                localKitchenCharges.push(kitchen.epkitchencharges)
                this.kitchenCharges = localKitchenCharges;
                console.log("kitchenCharges::", this.kitchenCharges)
                localStorage.setItem("kitchenCharge", String(this.kitchenCharges))
              }
            }
            var localOpenCharges: string[] = [];
            for (var open of this.OpenSpacegetDetails) {
              if (epid.selectopenspace == open.epopenspacename) {
                localOpenCharges.push(open.epopenspacecharges)
                this.openSpaceCharges = localOpenCharges;
                console.log("openSpaceCharges::", this.openSpaceCharges)
                localStorage.setItem("openSpaceCharge", String(this.openSpaceCharges))
              }
            }
            this.eventRequired.controls["no_normalrooms"].setValue("");
            this.eventRequired.controls["no_acrooms"].setValue("");
          }
        }, (err: any) => {
          this.spinner.hide()
        },
          () => console.log('HTTP request completed.'));
      // window.location.reload();
      this.saveButtoReq = false;
    }
     else {

      this.spinner.show()
      this.eventRequired.controls["activeyn"].setValue("Yes");
      this.eventRequired.controls["deleteyn"].setValue("No");
      this.eventRequired.controls["createdby"].setValue(localStorage.getItem("token"));
      this.eventRequired.controls["epid"].setValue(localStorage.getItem('epId'));
      this.eventRequired.controls["epsubid"].setValue(localStorage.getItem('epSubId'));
      this.eventRequired.controls["eventid"].setValue(localStorage.getItem("epEventId"));
      this.appService.saveEventAreaReq(this.eventRequired.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          this.spinner.hide()
          console.log("saveEventAreaReq details ::", data);
          this.toaster.success("Event Reqiured details saved successfully")
          this.isVisible = true;
          this.isVisibleAlert = true;
          this.chargesdetailsdisable = true;
          this.getActivityDropDown();
          // this.getVendorDropDown();
          this.getMiscChargeDropDown();
          this.getAmenitiesDropDown();
          this.getIncidentChargesDropDown();
          this.getEventActivityId();
          this.getEventAmenitiesId();
          this.getEventIncidentalId();
          this.getEventMiscId();
          this.paymentdiable = true;
          this.Requireddisable = false;
          this.saveButton = false;
          this.eventRequired.controls["parkingrequired"].setValue("");
          this.eventRequired.controls["two_wheelar"].setValue("");
          this.eventRequired.controls["four_wheelar"].setValue("");
          this.eventRequired.controls["selectparking"].setValue("");
          this.eventRequired.controls["kitchenrequired"].setValue("");
          this.eventRequired.controls["stoverequired"].setValue("");
          this.eventRequired.controls["grainderrequired"].setValue("");
          this.eventRequired.controls["fridgerequired"].setValue("");
          this.eventRequired.controls["washingrequired"].setValue("");
          this.eventRequired.controls["selectkitchen"].setValue("");
          this.eventRequired.controls["openspacerequired"].setValue("");
          this.eventRequired.controls["spacedetails"].setValue("");
          this.eventRequired.controls["selectopenspace"].setValue("");
          this.eventReqiuredDetails.push(data)
          for (let epid of this.eventReqiuredDetails) {
            this.eventAreaReqid.push(epid.reqid)
            console.log("eventrequiredId::", this.eventAreaReqid)
            localStorage.setItem('ReqId', String(this.eventAreaReqid))
            var localRoomCharges: string[] = [];
            var localacRoomCharges: string[] = [];
            for (var room of this.eventRoomPlace) {
              if (room.eproomaircond == "Yes") {
                localacRoomCharges.push(room.eproomscharges)
                console.log("getlocal::", localacRoomCharges)
              } else if (room.eproomaircond == 'No') {
                localRoomCharges.push(room.eproomscharges);
                console.log("getmappinglocal::", localRoomCharges)
              }
            }
            const normalRoom = this.eventRequired.get("no_normalrooms").value
            const acRoom = this.eventRequired.get("no_acrooms").value
            if(!isNaN(this.roomsAdd) && isNaN(this.acroomsAdd)){
              this.roomCharges = Number(this.roomsAdd)
              console.log("roomCharges1::", (this.roomCharges))
            }else if(!isNaN(this.acroomsAdd) && isNaN(this.roomsAdd) ){
              this.roomCharges = Number(this.acroomsAdd)
              console.log("roomCharges2::", (this.roomCharges))
            }else if(!isNaN(this.roomsAdd) && !isNaN(this.acroomsAdd)){
            this.roomCharges = Number(this.roomsAdd) + Number(this.acroomsAdd)
            console.log("roomCharges3::", (this.roomCharges))
            }
            //@ts-ignore
            localStorage.setItem("roomCharge", Number(this.roomCharges))
            var localParkingCharges: string[] = [];
            for (var park of this.eventParkingArea) {
              if (epid.selectparking == park.epparkingname) {
                localParkingCharges.push(park.epparkingareacharges)
                this.parkingCharges = localParkingCharges;
                console.log("parkingCharges::", this.parkingCharges)
                localStorage.setItem("parkingCharge", String(this.parkingCharges))
              }
            }
            var localKitchenCharges: string[] = [];
            for (var kitchen of this.eventKitchenPlace) {
              if (epid.selectkitchen == kitchen.epkitchenname) {
                localKitchenCharges.push(kitchen.epkitchencharges)
                this.kitchenCharges = localKitchenCharges;
                console.log("kitchenCharges::", this.kitchenCharges)
                localStorage.setItem("kitchenCharge", String(this.kitchenCharges))
              }
            }
            var localOpenCharges: string[] = [];
            for (var open of this.OpenSpacegetDetails) {
              if (epid.selectopenspace == open.epopenspacename) {
                localOpenCharges.push(open.epopenspacecharges)
                this.openSpaceCharges = localOpenCharges;
                console.log("openSpaceCharges::", this.openSpaceCharges)
                localStorage.setItem("openSpaceCharge", String(this.openSpaceCharges))
              }
            }
            this.eventRequired.controls["no_normalrooms"].setValue("");
            this.eventRequired.controls["no_acrooms"].setValue("");
          }
        }, (err: any) => {
          this.spinner.hide()
        },
          () => console.log('HTTP request completed.'));
      // window.location.reload();
      this.saveButtoReq = false;
    }
  }


  getEventActivityId() {
    this.appService
      .getEventActvityId()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("EP User Dropdwon::", data);
        this.eventActivity.push(data)
        var actId: string[] = [];
        for (let act of this.eventActivity) {
          actId.push(act.actid)
        }
        this.eventAreaActId = actId
        localStorage.setItem('ActId', String(this.eventAreaActId))
        console.log("evenactvitiedID::", this.eventAreaActId)
      })


  }


  saveEventAreaActivity() {
    this.spinner.show()
    if (this.isActivityUpdate) {
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
      console.log("getdateform::", formattedDate)
      for (let ep of this.activityDetails) {
        this.eventAreaActivity.controls["activity"].setValue(ep.activity);
        this.eventAreaActivity.controls["modifiedtime"].setValue(formattedDate)
      }
      console.log("updateactivitydetails::", this.multiActivity)
      this.appService.updateEventAreaActivity(this.multiActivity)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          this.spinner.hide()
          console.log("updateEventActivity details ::", data);
          this.saveAcivity = data
          this.toaster.success("Vendor Activity details update successfully")
          this.isVisibleAct = true;
          this.multiActivity = [];
          this.addNewVendor = false
          this.eventAreaActivity.controls["activityrequired"].setValue("");
          var localActivityCharges: string[] = [];
          for (var act of this.saveAcivity) {
            localActivityCharges.push(act.activityrequired)
            console.log("localActivityCharges::", localActivityCharges)

          }
          const totalValue = localActivityCharges
            .map(value => parseInt(value, 10))
            .reduce((sum, value) => sum + value, 0);
          console.log("Total Value:", totalValue);
          this.activityCharges = totalValue
          console.log("localActivityCharges::", this.activityCharges)
          localStorage.setItem("activityCharge", String(this.activityCharges))
        }, (err: any) => {
          this.spinner.hide()
          // this.toaster.error("Something went wrong. Try Again later")
          console.log("updateEventActivity error details ::", this.multiActivity);
          this.isVisibleAct = false;
        },
          () => console.log('HTTP request completed.'));
      this.saveButton = false;
      if (this.activityDetails.length !== this.multiActivity.length) {
        const activityClassObjects = this.multiActivity.filter(item => item instanceof ActivityClass);
        if (activityClassObjects.length > 0) {
          this.eventAreaActivity.controls["activeyn"].setValue("Yes");
          this.eventAreaActivity.controls["deleteyn"].setValue("No");
          this.eventAreaActivity.controls["createdby"].setValue(localStorage.getItem("token"));
          this.eventAreaActivity.controls["epid"].setValue(localStorage.getItem('epId'));
          this.eventAreaActivity.controls["epsubid"].setValue(localStorage.getItem('epSubId'));
          this.eventAreaActivity.controls["actid"].setValue(localStorage.getItem('ActId')[0]);
          this.eventAreaActivity.controls["reqid"].setValue(localStorage.getItem('ReqId')[0]);
          this.eventAreaActivity.controls["eventid"].setValue(localStorage.getItem("epEventId"));

          this.appService.saveEventAreaActivity(activityClassObjects)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
              this.spinner.hide()
              console.log("saveEventActivity details ::", data);
              this.saveAcivity = data
              this.toaster.success("Vendor Activity details saved successfully")
              this.isVisibleAct = true;
              this.multiActivity = [];
              this.addNewVendor = false
              this.eventAreaActivity.controls["activityrequired"].setValue("");
              var localActivityCharges: string[] = [];
              for (var act of this.saveAcivity) {
                localActivityCharges.push(act.activityrequired)
                console.log("localActivityCharges::", localActivityCharges)

              }
              const totalValue = localActivityCharges
                .map(value => parseInt(value, 10))
                .reduce((sum, value) => sum + value, 0);
              console.log("Total Value:", totalValue);
              this.activityCharges = totalValue
              console.log("localActivityCharges::", this.activityCharges)
              localStorage.setItem("activityCharge", String(this.activityCharges))
            }, (err: any) => {
              this.spinner.hide()
              this.toaster.error("Something went wrong. Try Again later")
              this.isVisibleAct = false;
            },
              () => console.log('HTTP request completed.'));
          // window.location.reload();
          this.saveButton = false;
        }
      }
    }
    else {
      this.eventAreaActivity.controls["activeyn"].setValue("Yes");
      this.eventAreaActivity.controls["deleteyn"].setValue("No");
      this.eventAreaActivity.controls["createdby"].setValue(localStorage.getItem("token"));
      this.eventAreaActivity.controls["epid"].setValue(localStorage.getItem('epId'));
      this.eventAreaActivity.controls["epsubid"].setValue(localStorage.getItem('epSubId'));
      this.eventAreaActivity.controls["actid"].setValue(localStorage.getItem('ActId'));
      this.eventAreaActivity.controls["reqid"].setValue(localStorage.getItem('ReqId'));
      this.eventAreaActivity.controls["eventid"].setValue(localStorage.getItem("epEventId"));

      this.appService.saveEventAreaActivity(this.multiActivity)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          this.spinner.hide()
          console.log("saveEventActivity details ::", data);
          this.saveAcivity = data
          this.toaster.success("Vendor Activity details saved successfully")
          this.isVisibleAct = true;
          this.multiActivity = [];
          this.addNewVendor = false
          this.eventAreaActivity.controls["activityrequired"].setValue("");
          var localActivityCharges: string[] = [];
          for (var act of this.saveAcivity) {
            localActivityCharges.push(act.activityrequired)
            console.log("localActivityCharges::", localActivityCharges)

          }
          const totalValue = localActivityCharges
            .map(value => parseInt(value, 10))
            .reduce((sum, value) => sum + value, 0);
          console.log("Total Value:", totalValue);
          this.activityCharges = totalValue
          console.log("localActivityCharges::", this.activityCharges)
          localStorage.setItem("activityCharge", String(this.activityCharges))
        }, (err: any) => {
          this.spinner.hide()
          this.toaster.error("Something went wrong. Try Again later")
          this.isVisibleAct = false;
        },
          () => console.log('HTTP request completed.'));
      // window.location.reload();
      this.saveButton = false;
    }
  }


  getEventAmenitiesId() {
    this.appService
      .getEventAmenitiesId()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("EP User Dropdwon::", data);
        this.eventAmenities.push(data)
        var localamenitId: string[] = [];
        for (let amenit of this.eventAmenities) {
          localamenitId.push(amenit.amtid)
        }
        this.eventAreaAmenitId = localamenitId;
        localStorage.setItem('AmenitId', String(this.eventAreaAmenitId[0]))
        console.log("evenactvitiedID::", this.eventAreaAmenitId)
      })

  }

  saveEventAreaAmenities() {
    this.spinner.show()
    if (this.isAmenitiesUpdate) {
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
      this.eventAreaAmenities.controls["amemodifiedtime"].setValue(formattedDate);
      for (let ep of this.amenitiedDetails) {
        this.eventAreaAmenities.controls["amenityactivity"].setValue(ep.amenityactivity);
      }
      console.log("updateAmenitiesDetails::", this.multiAmenities)
      this.appService.updateEventAreaAmenities(this.multiAmenities)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          this.spinner.hide()
          console.log("saveEventActivity details ::", data);
          this.amenitiesDatails = data
          this.toaster.success("Amenities details update successfully")
          this.isVisibleAmt = true;
          this.eventAreaAmenities.controls["amenitiesrequired"].setValue("");
          this.multiAmenities = [];
          this.addNewAmenit = false
          this.eventAreaAmenities.controls["selectamenities"].setValue("");
          this.eventAreaAmenities.controls["qtyrequired"].setValue("");
          this.eventAreaAmenities.controls["amenitiesrequired"].setValue("");
          var localAmenitiesCharges: string[] = [];
          for (var ep of this.amenitiesDatails) {
            localAmenitiesCharges.push(ep.qtyrequired);

            const totalValue = localAmenitiesCharges
              .map(value => parseInt(value, 10))
              .reduce((sum, value) => sum + value, 0);
            console.log("Total Value:", totalValue);
            //@ts-ignore
            this.amenitiesCharge = totalValue
            console.log("amenityFinalCharge::", this.amenitiesCharge)
            localStorage.setItem("amenityCharge", String(this.amenitiesCharge))
          }

        }, (err: any) => {
          this.spinner.hide()
          // this.toaster.error("Something went wrong. Try Again later")
          this.isVisibleAmt = false;
        },
          () => console.log('HTTP request completed.'));
      // window.location.reload();
      this.amenitSaveButton = false
      if (this.amenitiedDetails.length !== this.multiAmenities.length) {
        const amenitiesClassObjects = this.multiAmenities.filter(item => item instanceof AmenitiesClass);
        if (amenitiesClassObjects.length > 0) {
          this.eventAreaAmenities.controls["ameactiveyn"].setValue("Yes");
          this.eventAreaAmenities.controls["amedeleteyn"].setValue("No");
          this.eventAreaAmenities.controls["amecreatedby"].setValue(localStorage.getItem("token"));
          this.eventAreaAmenities.controls["ameepid"].setValue(localStorage.getItem('epId'));
          this.eventAreaAmenities.controls["ameepsubid"].setValue(localStorage.getItem('epSubId'));
          this.eventAreaAmenities.controls["amtid"].setValue(localStorage.getItem('AmenitId')[0]);
          this.eventAreaAmenities.controls["reqid"].setValue(localStorage.getItem('ReqId'));
          this.eventAreaAmenities.controls["eventid"].setValue(localStorage.getItem("epEventId"));

          this.appService.saveEventAreaAmenities(amenitiesClassObjects)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
              this.spinner.hide()
              console.log("saveEventActivity details ::", data);
              this.amenitiesDatails = data
              this.toaster.success("Amenities details saved successfully")
              this.isVisibleAmt = true;
              this.eventAreaAmenities.controls["amenitiesrequired"].setValue("");
              this.multiAmenities = [];
              this.addNewAmenit = false
              this.eventAreaAmenities.controls["selectamenities"].setValue("");
              this.eventAreaAmenities.controls["qtyrequired"].setValue("");
              this.eventAreaAmenities.controls["amenitiesrequired"].setValue("");
              var localAmenitiesCharges: string[] = [];
              for (var ep of this.amenitiesDatails) {
                localAmenitiesCharges.push(ep.qtyrequired);

                const totalValue = localAmenitiesCharges
                  .map(value => parseInt(value, 10))
                  .reduce((sum, value) => sum + value, 0);
                console.log("Total Value:", totalValue);
                //@ts-ignore
                this.amenitiesCharge = totalValue
                console.log("amenityFinalCharge::", this.amenitiesCharge)
                localStorage.setItem("amenityCharge", String(this.amenitiesCharge))
              }

            }, (err: any) => {
              this.spinner.hide()
              this.toaster.error("Something went wrong. Try Again later")
              this.isVisibleAmt = false;
            },
              () => console.log('HTTP request completed.'));
          // window.location.reload();
          this.amenitSaveButton = false
        }
      }
    } else {
      this.eventAreaAmenities.controls["ameactiveyn"].setValue("Yes");
      this.eventAreaAmenities.controls["amedeleteyn"].setValue("No");
      this.eventAreaAmenities.controls["amecreatedby"].setValue(localStorage.getItem("token"));
      this.eventAreaAmenities.controls["ameepid"].setValue(localStorage.getItem('epId'));
      this.eventAreaAmenities.controls["ameepsubid"].setValue(localStorage.getItem('epSubId'));
      this.eventAreaAmenities.controls["amtid"].setValue(localStorage.getItem('AmenitId'));
      this.eventAreaAmenities.controls["reqid"].setValue(localStorage.getItem('ReqId'));
      this.eventAreaAmenities.controls["eventid"].setValue(localStorage.getItem("epEventId"));

      this.appService.saveEventAreaAmenities(this.multiAmenities)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          this.spinner.hide()
          console.log("saveEventActivity details ::", data);
          this.amenitiesDatails = data
          this.toaster.success("Amenities details saved successfully")
          this.isVisibleAmt = true;
          this.eventAreaAmenities.controls["amenitiesrequired"].setValue("");
          this.multiAmenities = [];
          this.addNewAmenit = false
          this.eventAreaAmenities.controls["selectamenities"].setValue("");
          this.eventAreaAmenities.controls["qtyrequired"].setValue("");
          this.eventAreaAmenities.controls["amenitiesrequired"].setValue("");
          var localAmenitiesCharges: string[] = [];
          for (var ep of this.amenitiesDatails) {
            localAmenitiesCharges.push(ep.qtyrequired);

            const totalValue = localAmenitiesCharges
              .map(value => parseInt(value, 10))
              .reduce((sum, value) => sum + value, 0);
            console.log("Total Value:", totalValue);
            //@ts-ignore
            this.amenitiesCharge = totalValue
            console.log("amenityFinalCharge::", this.amenitiesCharge)
            localStorage.setItem("amenityCharge", String(this.amenitiesCharge))
          }

        }, (err: any) => {
          this.spinner.hide()
          this.toaster.error("Something went wrong. Try Again later")
          this.isVisibleAmt = false;
        },
          () => console.log('HTTP request completed.'));
      // window.location.reload();
      this.amenitSaveButton = false
    }
  }

  getEventIncidentalId() {
    this.appService
      .getEventIncidentalId()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("EP User Dropdwon::", data);
        this.eventIncidental.push(data)
        var localincidId: string[] = [];
        for (let incident of this.eventIncidental) {
          localincidId.push(incident.incid)
        }
        this.eventAreaIncidentId = localincidId;
        localStorage.setItem('IncidentalId', String(this.eventAreaIncidentId[0]))
        console.log("eventIncidentalID::", this.eventAreaIncidentId)
      })

  }

  saveEventAreaIncidental() {
    this.spinner.show()
    if (this.isUpdateinc) {
      for (let ep of this.getIncidentals) {
        this.eventAreaIncidental.controls["incactivity"].setValue(ep.incactivity);
        this.eventAreaIncidental.controls['modifiedby'].setValue(localStorage.getItem('token'))
        const currentDate = new Date();
        const formattedDate = currentDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
        this.eventAreaIncidental.controls["modifiedtime"].setValue(formattedDate);
      }
      console.log("Updated values::", this.multiIncidental)
      this.appService.UpdateEventAreaIncidental(this.multiIncidental)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          this.spinner.hide()
          console.log("saveEventActivity details ::", data);
          // @ts-ignore
          this.updatedIncCharge = data;
          // @ts-ignore
          this.currentInc = this.updatedIncCharge;
          console.log("current Inc values:", this.currentInc)
          // this.paymentUpdate = true;
          console.log("currentIncdental::", this.updatedIncCharge)
          this.toaster.success("Incidental details Update successfully")
          this.isVisibleInc = true;
          this.multiIncidental = [];
          var localIncidentalCharges: string[] = [];
          for (let inc of this.updatedIncCharge) {
            localIncidentalCharges.push(inc.qtyrequired)
            const totalValue = localIncidentalCharges
              .map(value => parseInt(value, 10))
              .reduce((sum, value) => sum + value, 0);
            console.log("Total Value:",);
            //@ts-ignore
            this.incidentalCharge = totalValue;
            console.log("incidentalFinalCharge::", this.incidentalCharge)
            localStorage.setItem("incidentalCharge", String(this.incidentalCharge))
          }
        }, (err: any) => {
          this.spinner.hide()
          // this.toaster.error("Something went wrong. Try Again later")
          this.isVisibleInc = false;
        },
          () => console.log('HTTP request completed.'));
      // window.location.reload();
      this.incidentalSaveButton = false;
      if (this.getIncidentals !== this.multiIncidental) {
        const incClassObjects = this.multiIncidental.filter(item => item instanceof IncidentalClass);
        if (incClassObjects.length > 0) {
          this.eventAreaIncidental.controls['modifiedby'].setValue(localStorage.getItem('token'))
          const currentDate = new Date();
          const formattedDate = currentDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
          this.eventAreaIncidental.controls["modifiedtime"].setValue(formattedDate);
          this.eventAreaIncidental.controls["activeyn"].setValue("Yes");
          this.eventAreaIncidental.controls["deleteyn"].setValue("No");
          this.eventAreaIncidental.controls["createdby"].setValue(localStorage.getItem("token"));
          this.eventAreaIncidental.controls["epid"].setValue(localStorage.getItem('epId'));
          this.eventAreaIncidental.controls["epsubid"].setValue(localStorage.getItem('epSubId'));
          this.eventAreaIncidental.controls["incid"].setValue(this.eventAreaIncidentId[0]);
          this.eventAreaIncidental.controls["reqid"].setValue(localStorage.getItem('ReqId'));
          this.eventAreaIncidental.controls["eventid"].setValue(localStorage.getItem("epEventId"));

          console.log("Save inc details:", this.multiIncidental);
          this.appService.saveEventAreaIncidental(incClassObjects)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
              this.spinner.hide()
              console.log("saveEventActivity details ::", data);
              // this.paymentdiable = true;
              this.toaster.success("Incidental details saved successfully")
              this.isVisibleInc = true;
              this.multiIncidental = [];
              this.addNewIncidental = false
              this.eventAreaIncidental.controls["incchargerequired"].setValue("");
              var localIncidentalCharges: string[] = [];
            }, (err: any) => {
              this.spinner.hide()
              this.toaster.error("Something went wrong. Try Again later")
              this.isVisibleInc = false;
            },
              () => console.log('HTTP request completed.'));
          // window.location.reload();
          this.incidentalSaveButton = false;
        }
      }
    } else if(!this.isUpdateinc) {
      this.spinner.show()
      this.eventAreaIncidental.controls['modifiedby'].setValue(localStorage.getItem('token'))
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
      this.eventAreaIncidental.controls["modifiedtime"].setValue(formattedDate);
      this.eventAreaIncidental.controls["activeyn"].setValue("Yes");
      this.eventAreaIncidental.controls["deleteyn"].setValue("No");
      this.eventAreaIncidental.controls["createdby"].setValue(localStorage.getItem("token"));
      this.eventAreaIncidental.controls["epid"].setValue(localStorage.getItem('epId'));
      this.eventAreaIncidental.controls["epsubid"].setValue(localStorage.getItem('epSubId'));
      this.eventAreaIncidental.controls["incid"].setValue(localStorage.getItem('IncidentalId')[0]);
      this.eventAreaIncidental.controls["reqid"].setValue(localStorage.getItem('ReqId'));
      this.eventAreaIncidental.controls["eventid"].setValue(localStorage.getItem("epEventId"));

      console.log("Save inc details:", this.multiIncidental);
      this.appService.saveEventAreaIncidental(this.multiIncidental)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          this.spinner.hide()
          console.log("saveEventActivity details ::", data);
          // this.paymentdiable = true;
          this.toaster.success("Incidental details saved successfully")
          this.isVisibleInc = true;
          this.multiIncidental = [];
          this.addNewIncidental = false
          this.eventAreaIncidental.controls["incchargerequired"].setValue("");
          var localIncidentalCharges: string[] = [];
        }, (err: any) => {
          this.spinner.hide()
          this.toaster.error("Something went wrong. Try Again later")
          this.isVisibleInc = false;
        },
          () => console.log('HTTP request completed.'));
      // window.location.reload();
      this.incidentalSaveButton = false;
    }
  }
  // updateincdetails(){
  //   this.spinner.show()
  //   for(var inc of this.getcurrentdetails){
  //   this.eventAreaIncidental.controls["activeyn"].setValue("Yes");
  //   this.eventAreaIncidental.controls["deleteyn"].setValue("No");
  //   this.eventAreaIncidental.controls["createdby"].setValue(localStorage.getItem("token"));
  //   this.eventAreaIncidental.controls["epid"].setValue(inc.epid);
  //   this.eventAreaIncidental.controls["epsubid"].setValue(inc.epsubid);
  //   console.log("selectincup::",inc.epsubid)
  //   this.eventAreaIncidental.controls["incid"].setValue(inc.incid);
  //   this.eventAreaIncidental.controls["reqid"].setValue(inc.reqid);
  //   this.eventAreaIncidental.controls["eventid"].setValue(inc.eventid);
  //   }
  //   console.log("Updated values::", this.multiIncidental)
  //   this.appService.UpdateEventAreaIncidental(this.multiIncidental)
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe((data) => {
  //       this.spinner.hide()
  //       console.log("saveEventActivity details ::", data);
  //       // @ts-ignore
  //         this.updatedIncCharge = data;
  //         // @ts-ignore
  //         this.currentInc = this.updatedIncCharge;
  //         console.log("current Inc values:", this.currentInc)
  //         this.paymentUpdate = true;
  //         console.log("currentIncdental::",this.updatedIncCharge)
  //       this.toaster.success("Incidental details Update successfully")
  //       this.isVisibleInc = true;
  //       this.multiIncidental = [];
  //       var localIncidentalCharges: string[] = [];
  //       for (let inc of this.updatedIncCharge) {
  //         localIncidentalCharges.push(inc.qtyrequired)
  //         const totalValue = localIncidentalCharges
  //       .map(value => parseInt(value, 10))
  //       .reduce((sum, value) => sum + value, 0);
  //     console.log("Total Value:", );
  //     //@ts-ignore
  //     this.incidentalCharge = totalValue;
  //         console.log("incidentalFinalCharge::", this.incidentalCharge)
  //         localStorage.setItem("incidentalCharge", String(this.incidentalCharge))
  //       }
  //     }, (err: any) => {
  //       this.spinner.hide()
  //       this.toaster.error("Something went wrong. Try Again later")
  //       this.isVisibleInc = false;
  //     },
  //       () => console.log('HTTP request completed.'));
  //   // window.location.reload();
  //   this.incidentalSaveButton = false;
  // }

  getEventMiscId() {
    this.appService
      .getEventMiscId()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("EP User Dropdwon::", data);
        this.eventMisc.push(data)
        var localMiscdId: string[] = [];
        for (let misc of this.eventMisc) {
          localMiscdId.push(misc.misid)
        }
        this.eventAreaMiscId = localMiscdId;
        localStorage.setItem('MiscId', String(this.eventAreaMiscId))
        console.log("eventIncidentalID::", this.eventAreaMiscId)
      })

  }

  saveEventAreaMisc() {
    this.spinner.show()
    if (this.isMiscsUpdate) {
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
      this.eventAreaMisc.controls["modifiedtime"].setValue(formattedDate);
      for (let misc of this.getmiscDetails) {
        this.eventAreaMisc.controls['miscactivity'].setValue(misc.miscactivity)
      }
      console.log("upadteMiscDetails::", this.multiMisc)
      this.appService.updateEventAreaMisc(this.multiMisc)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          this.spinner.hide()
          console.log("saveMisc details ::", data);
          this.miscDetails = data
          this.toaster.success("Miscellaneous update saved successfully")
          this.isVisibleMisc = true;
          this.multiMisc = [];
          this.addNewMisc = false
          this.eventAreaMisc.controls["mischargesreq"].setValue("");
          var localMiscCharges: string[] = [];
          for (var ep of this.miscDetails) {
            localMiscCharges.push(ep.qtyrequired);

            const totalValue = localMiscCharges
              .map(value => parseInt(value, 10))
              .reduce((sum, value) => sum + value, 0);
            console.log("Total Value:", totalValue);
            //@ts-ignore
            this.miscCharges = totalValue
            console.log("miscFinalCharge::", this.miscCharges)
            localStorage.setItem("miscCharge", String(this.miscCharges))
          }
        }, (err: any) => {
          this.spinner.hide()
          // this.toaster.error("Something went wrong. Try Again later")
          this.isVisibleMisc = false;
        },
          () => console.log('HTTP request completed.'));
      // window.location.reload();
      this.miscSaveButton = false;
      if (this.getmiscDetails.length !== this.multiMisc.length) {
        const miscClassObjects = this.multiMisc.filter(item => item instanceof MiscClass);
        if (miscClassObjects.length > 0) {
          this.eventAreaMisc.controls["activeyn"].setValue("Yes");
          this.eventAreaMisc.controls["deleteyn"].setValue("No");
          this.eventAreaMisc.controls["createdby"].setValue(localStorage.getItem("token"));
          this.eventAreaMisc.controls["epid"].setValue(localStorage.getItem('epId'));
          this.eventAreaMisc.controls["epsubid"].setValue(localStorage.getItem('epSubId'));
          this.eventAreaMisc.controls["misid"].setValue(this.eventAreaMiscId[0]);
          this.eventAreaMisc.controls["reqid"].setValue(localStorage.getItem('ReqId'));
          this.eventAreaMisc.controls["eventid"].setValue(localStorage.getItem("epEventId"));
          console.log("updatetimesave::", miscClassObjects)
          this.appService.saveEventAreaMisc(miscClassObjects)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
              this.spinner.hide()
              console.log("saveMisc details ::", data);
              this.miscDetails = data
              this.toaster.success("Miscellaneous details saved successfully")
              this.isVisibleMisc = true;
              this.multiMisc = [];
              this.addNewMisc = false
              this.eventAreaMisc.controls["mischargesreq"].setValue("");
              var localMiscCharges: string[] = [];
              for (var ep of this.miscDetails) {
                localMiscCharges.push(ep.qtyrequired);

                const totalValue = localMiscCharges
                  .map(value => parseInt(value, 10))
                  .reduce((sum, value) => sum + value, 0);
                console.log("Total Value:", totalValue);
                //@ts-ignore
                this.miscCharges = totalValue
                console.log("miscFinalCharge::", this.miscCharges)
                localStorage.setItem("miscCharge", String(this.miscCharges))
              }
            }, (err: any) => {
              this.spinner.hide()
              this.toaster.error("Something went wrong. Try Again later")
              this.isVisibleMisc = false;
            },
              () => console.log('HTTP request completed.'));
          // window.location.reload();
          this.miscSaveButton = false;
        }
      }
    } else {
      this.eventAreaMisc.controls["activeyn"].setValue("Yes");
      this.eventAreaMisc.controls["deleteyn"].setValue("No");
      this.eventAreaMisc.controls["createdby"].setValue(localStorage.getItem("token"));
      this.eventAreaMisc.controls["epid"].setValue(localStorage.getItem('epId'));
      this.eventAreaMisc.controls["epsubid"].setValue(localStorage.getItem('epSubId'));
      this.eventAreaMisc.controls["misid"].setValue(localStorage.getItem('MiscId'));
      this.eventAreaMisc.controls["reqid"].setValue(localStorage.getItem('ReqId'));
      this.eventAreaMisc.controls["eventid"].setValue(localStorage.getItem("epEventId"));

      this.appService.saveEventAreaMisc(this.multiMisc)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          this.spinner.hide()
          console.log("saveMisc details ::", data);
          this.miscDetails = data
          this.toaster.success("Miscellaneous details saved successfully")
          this.isVisibleMisc = true;
          this.multiMisc = [];
          this.addNewMisc = false
          this.eventAreaMisc.controls["mischargesreq"].setValue("");
          var localMiscCharges: string[] = [];
          for (var ep of this.miscDetails) {
            localMiscCharges.push(ep.qtyrequired);

            const totalValue = localMiscCharges
              .map(value => parseInt(value, 10))
              .reduce((sum, value) => sum + value, 0);
            console.log("Total Value:", totalValue);
            //@ts-ignore
            this.miscCharges = totalValue
            console.log("miscFinalCharge::", this.miscCharges)
            localStorage.setItem("miscCharge", String(this.miscCharges))
          }
        }, (err: any) => {
          this.spinner.hide()
          this.toaster.error("Something went wrong. Try Again later")
          this.isVisibleMisc = false;
        },
          () => console.log('HTTP request completed.'));
      // window.location.reload();
      this.miscSaveButton = false;
    }
  }

  selectVendorValue(multiActivity: ActivityClass, event: any) {
    const index = this.multiActivity.indexOf(multiActivity);
    multiActivity.selectvendor = event.target.value;
    this.vendorName = event.target.value;
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    multiActivity.modifiedtime = formattedDate
    multiActivity.modifiedby = localStorage.getItem('token')
    this.multiActivity[index] = multiActivity;
    this.saveButton = true;
  }

  selectActivityValue(multiActivity: ActivityClass, event: any) {
    const index = this.multiActivity.indexOf(multiActivity);
    multiActivity.selectactivity = event.target.value;
    this.activityName = event.target.value;
    var localActivityCharges: string;
    for (var active of this.ActivityDetails) {
      if (multiActivity.selectactivity == active.epactivityname) {
        this.MapactivityName = active.epactivityname
        localActivityCharges = active.epactivitiescharges
      }
      this.epActivitycharge = localActivityCharges
      console.log("activitycharge::", this.epActivitycharge)
    }
    this.getActivityMapping();
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    multiActivity.modifiedtime = formattedDate
    multiActivity.modifiedby = localStorage.getItem('token')
    multiActivity.qtyrequired = this.epActivitycharge
    this.multiActivity[index] = multiActivity;
    this.saveButton = true;
  }

  addVendor() {
    const multiActivity: ActivityClass = new ActivityClass();
    multiActivity.actid = localStorage.getItem('ActId')
    multiActivity.reqid = localStorage.getItem('ReqId')
    multiActivity.epid = localStorage.getItem('epId')
    multiActivity.epsubid = localStorage.getItem('epSubId')
    multiActivity.createdby = localStorage.getItem('token')
    multiActivity.eventid = localStorage.getItem('epEventId')
    multiActivity.activityrequired = "Yes"
    multiActivity.activeyn = "Yes"
    multiActivity.deleteyn = "No"
    this.multiActivity.push(multiActivity);
    // this.getActivityDropDown();
    this.getEventActivityId();
  }

  selectAmenitiesValue(multiAmenities: AmenitiesClass, event: any) {
    const index = this.multiAmenities.indexOf(multiAmenities);
    multiAmenities.selectamenities = event.target.value;
    this.amenityName = event.target.value;
    // this.multiAmenities[index] = multiAmenities;
    console.log("amename::", this.amenityName)
    var localAmenitiesCharges: string;
    for (let ep of this.AminitiesDetails) {
      //@ts-ignore
      if (this.amenityName == ep.epgenamenitiesname) {
        localAmenitiesCharges = ep.epgeneralamenitiescharges
      }
      //@ts-ignore
      this.amenitiesCharge = localAmenitiesCharges
      console.log("ame::", this.amenitiesCharge)
    }
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    multiAmenities.amemodifiedtime = formattedDate
    multiAmenities.amemodifiedby = localStorage.getItem('token')
    multiAmenities.qtyrequired = this.amenitiesCharge;
    this.multiAmenities[index] = multiAmenities;
    this.amenitSaveButton = true
  }

  // selectAmenitiesQtyValue(multiAmenities: AmenitiesClass, event: any) {
  //   const index = this.multiAmenities.indexOf(multiAmenities);
  //   //@ts-ignore
  //   multiAmenities.qtyrequired = this.amenitiesCharge;
  //   //@ts-ignore
  //   this.amenitiesQty = this.amenitiesCharge;
  //   console.log("Amenity Qty::", this.amenitiesQty)
  //   this.multiAmenities[index] = multiAmenities;
  // }

  addAmenities() {
    const multiAmenities: AmenitiesClass = new AmenitiesClass();
    multiAmenities.amtid = localStorage.getItem('AmenitId')
    multiAmenities.reqid = localStorage.getItem('ReqId')
    multiAmenities.ameepid = localStorage.getItem('epId')
    multiAmenities.ameepsubid = localStorage.getItem('epSubId')
    multiAmenities.amecreatedby = localStorage.getItem('token')
    multiAmenities.eventid = localStorage.getItem('epEventId')
    multiAmenities.amenitiesrequired = "Yes"
    multiAmenities.ameactiveyn = "Yes"
    multiAmenities.amedeleteyn = "No"
    this.multiAmenities.push(multiAmenities);
    // this.getAmenitiesDropDown();
    this.getEventAmenitiesId();
  }

  selectincidentalValue(multiIncidental: IncidentalClass, event: any) {
    const index = this.multiIncidental.indexOf(multiIncidental);
    multiIncidental.selectinccharges = event.target.value;
    this.incidentalName = event.target.value;
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    multiIncidental.modifiedtime = formattedDate
    multiIncidental.modifiedby = localStorage.getItem('token')
    var localincidentalcharges: string = ""
    for (let ep of this.IncidentChargesDetails) {
      if (multiIncidental.selectinccharges === ep.epincidentalname) {
        console.log("select::", multiIncidental.selectinccharges)
        console.log("incidentalName::", ep.epincidentalname)
        localincidentalcharges = ep.epincidentalchargecharges
        this.incCharges = localincidentalcharges
        console.log("inccharges::", this.incCharges)
        multiIncidental.qtyrequired = this.incCharges
        multiIncidental.startingunit = " ";
        multiIncidental.endingunit = " ";
      }

    }
    // this.getEventIncidentalId();
    // this.getIncidentChargesDropDown();
    this.multiIncidental[index] = multiIncidental;
    this.incidentalSaveButton = true;
  }


  selectIncidentalStartingUnitValue(multiIncidental: IncidentalClass, event: any) {
    const index = this.multiIncidental.indexOf(multiIncidental);
    multiIncidental.startingunit = event.target.value;
    this.startingUnit = event.target.value;
    console.log("startunit::", this.startingUnit)
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    multiIncidental.modifiedtime = formattedDate
    multiIncidental.modifiedby = localStorage.getItem('token')
    this.addfuntion(multiIncidental)
    this.multiIncidental[index] = multiIncidental;
  }
  selectIncidentalEndingUnitValue(multiIncidental: IncidentalClass, event: any) {
    const index = this.multiIncidental.indexOf(multiIncidental);
    multiIncidental.endingunit = event.target.value;
    this.endingUnit = event.target.value;
    console.log("startunit::", this.endingUnit)
    const currentDate = new Date();
    this.addfuntion(multiIncidental)
    const formattedDate = currentDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    multiIncidental.modifiedtime = formattedDate
    multiIncidental.modifiedby = localStorage.getItem('token')
    console.log("mutiinc::", multiIncidental.selectinccharges)
    // for(let inc of this.multiIncidental){
    //   if(multiIncidental.selectinccharges == inc.selectinccharges){
    //     const charges = (Number(this.endingUnit) - Number(this.startingUnit))
    //     console.log("qty::", inc.qtyrequired)
    //     const total  = (charges * Number(inc.qtyrequired ))
    //     console.log("incidentaCharge::", charges)
    //     this.incidentalFinalCharge = total;
    //     console.log("incidentalFinalCharge::", this.incidentalFinalCharge)

    //     localStorage.setItem("incidentalCharge", String(this.incidentalFinalCharge))
    //   }
    // }
    //@ts-ignore
    // multiIncidental.qtyrequired = this.incidentalFinalCharge
    this.multiIncidental[index] = multiIncidental;
  }

  addfuntion(multiIncidental: IncidentalClass) {
    var localincidentalcharges: string = ""
    for (let ep of this.IncidentChargesDetails) {
      if (multiIncidental.selectinccharges === ep.epincidentalname) {
        console.log("select::", multiIncidental.selectinccharges)
        console.log("incidentalName::", ep.epincidentalname)
        localincidentalcharges = ep.epincidentalchargecharges
        this.incCharges = localincidentalcharges
        console.log("inccharges::", this.incCharges)
        multiIncidental.qtyrequired = this.incCharges
      }
      // const charge = (Number(this.endingUnit) - Number(this.startingUnit))
      // console.log("incidentalunitCharge::", charge)

      for (let inc of this.multiIncidental) {
        if (multiIncidental.selectinccharges == inc.selectinccharges) {
          const charges = (Number(this.endingUnit) - Number(this.startingUnit))
          console.log("incidentalunitCharge::", charges)
          console.log("qty::", inc.qtyrequired)
          const total = (charges * Number(inc.qtyrequired))
          console.log("incidentaCharge::", total)
          this.incidentalFinalCharge = total;
          console.log("incidentalFinalCharge::", this.incidentalFinalCharge)
        }
        localStorage.setItem("incidentalCharge", String(this.incidentalFinalCharge))
      }
    }

    this.getEventIncidentalId();
    //@ts-ignore
    multiIncidental.qtyrequired = this.incidentalFinalCharge
  }

  // selectIncidentalQtyValue(multiIncidental: IncidentalClass, event: any) {
  //   const index = this.multiIncidental.indexOf(multiIncidental);
  //   multiIncidental.qtyrequired = event.target.value;
  //   this.incidentalQty = event.target.value;
  //   this.multiIncidental[index] = multiIncidental;
  // }

  addIncidental() {
    const multiIncidental: IncidentalClass = new IncidentalClass();
    multiIncidental.incid = localStorage.getItem('IncidentalId')
    multiIncidental.reqid = localStorage.getItem('ReqId')
    multiIncidental.epid = localStorage.getItem('epId')
    multiIncidental.epsubid = localStorage.getItem('epSubId')
    multiIncidental.createdby = localStorage.getItem('token')
    multiIncidental.eventid = localStorage.getItem('epEventId')
    multiIncidental.incchargerequired = "Yes"
    multiIncidental.activeyn = "Yes"
    multiIncidental.deleteyn = "No"
    this.multiIncidental.push(multiIncidental);
    console.log("Add inc::", this.multiIncidental);
    // this.getEventIncidentalId();
    // this.getIncidentChargesDropDown();
  }

  selectMiscValue(multiMisc: MiscClass, event: any) {
    const index = this.multiMisc.indexOf(multiMisc);
    multiMisc.selectmischarges = event.target.value;
    this.miscName = event.target.value;
    // this.multiMisc[index] = multiMisc;
    this.miscSaveButton = true;
    var localMiscCharges: string;
    for (let ep of this.eventMiscChargePlace) {
      //@ts-ignore
      if (this.miscName == ep.epmiscellaneouschargename) {
        localMiscCharges = ep.epmiscellaneouschargecharges
      }
      //@ts-ignore
      this.miscFinalCharge = localMiscCharges
      console.log("micCharges::", this.miscFinalCharge)
    }
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    multiMisc.modifiedtime = formattedDate
    multiMisc.modifiedby = localStorage.getItem('token')
    //@ts-ignore
    multiMisc.qtyrequired = this.miscFinalCharge;
    this.multiMisc[index] = multiMisc;
  }

  // selectMiscQtyValue(multiMisc: MiscClass, event: any) {
  //   const index = this.multiMisc.indexOf(multiMisc);
  //   multiMisc.qtyrequired = event.target.value;
  //   this.miscQty = event.target.value;
  //   this.multiMisc[index] = multiMisc;
  // }

  addMisc() {
    const multiMisc: MiscClass = new MiscClass();
    multiMisc.misid = localStorage.getItem('MiscId')
    multiMisc.reqid = localStorage.getItem('ReqId')
    multiMisc.epid = localStorage.getItem('epId')
    multiMisc.epsubid = localStorage.getItem('epSubId')
    multiMisc.createdby = localStorage.getItem('token')
    multiMisc.eventid = localStorage.getItem('epEventId')
    multiMisc.mischargesreq = "Yes"
    multiMisc.activeyn = "Yes"
    multiMisc.deleteyn = "No"
    this.multiMisc.push(multiMisc);
    // this.getActivityDropDown();
    this.getEventMiscId();
  }

  // navigate() {
  //   // this.saveCharges()
  //   this.router.navigate(["/event-bookingpayment"]);
  // }

  saveCharges() {
    if (this.getUpdateCharges.length == 0) {
      this.bookingCharges.controls["activeyn"].setValue("Yes");
      this.bookingCharges.controls["deleteyn"].setValue("No");
      this.bookingCharges.controls["createdby"].setValue(localStorage.getItem("token"));
      this.bookingCharges.controls["epid"].setValue(localStorage.getItem('epId'));
      this.bookingCharges.controls["epsubid"].setValue(localStorage.getItem('epSubId'));
      this.bookingCharges.controls["reqid"].setValue(localStorage.getItem('ReqId'));
      this.bookingCharges.controls["eventid"].setValue(localStorage.getItem('epEventId'));
      // Charges
      //@ts-ignore
      if (localStorage.getItem("roomCharge") == null || localStorage.getItem("roomCharge") == "") {
        this.bookingCharges.controls["roomamount"].setValue("")
        console.log("Null value Rooms::", this.bookingCharges.get("roomamount").value)
      } else {
        this.bookingCharges.controls["roomamount"].setValue(localStorage.getItem("roomCharge"))
      }
      if (localStorage.getItem("amenityCharge") == null || localStorage.getItem("amenityCharge") == "") {
        this.bookingCharges.controls["amenityamount"].setValue("")
      } else {
        this.bookingCharges.controls["amenityamount"].setValue(localStorage.getItem("amenityCharge"))
      }
      if (localStorage.getItem("parkingCharge") == null || localStorage.getItem("parkingCharge") == "") {
        this.bookingCharges.controls["parkingamount"].setValue("")
      } else {
        this.bookingCharges.controls["parkingamount"].setValue(localStorage.getItem("parkingCharge"))
      }
      if (localStorage.getItem("kitchenCharge") == null || localStorage.getItem("kitchenCharge") == "") {
        this.bookingCharges.controls["kitchenamount"].setValue("")
      } else {
        this.bookingCharges.controls["kitchenamount"].setValue(localStorage.getItem("kitchenCharge"))
      }
      if (localStorage.getItem("openSpaceCharge") == null || localStorage.getItem("openSpaceCharge") == "") {
        this.bookingCharges.controls["openspaceamount"].setValue("")
      } else {
        this.bookingCharges.controls["openspaceamount"].setValue(localStorage.getItem("openSpaceCharge"))
      }
      if (localStorage.getItem("activityCharge") == null || localStorage.getItem("activityCharge") == "") {
        this.bookingCharges.controls["acitvityamount"].setValue("")
      } else {
        this.bookingCharges.controls["acitvityamount"].setValue(localStorage.getItem("activityCharge"))
      }
      if (localStorage.getItem("incidentalCharge") == null || localStorage.getItem("incidentalCharge") == "") {
        this.bookingCharges.controls["incidentalamount"].setValue("")
      } else {
        this.bookingCharges.controls["incidentalamount"].setValue(localStorage.getItem("incidentalCharge"))
      }
      if (localStorage.getItem("miscCharge") == null || localStorage.getItem("miscCharge") == "") {
        this.bookingCharges.controls["miscamount"].setValue("")
      } else {
        this.bookingCharges.controls["miscamount"].setValue(localStorage.getItem("miscCharge"))
      }


      console.log("Charges Data::", this.bookingCharges.value)
      // @ts-ignore
      this.appService.saveEventAreaAmount(this.bookingCharges.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          console.log("Charges Details1::", data)
          this.getBookingCharges.push(data)
          var AMountId: string[] = [];
          for (let charge of this.getBookingCharges) {
            AMountId.push(charge.amtid)
            localStorage.setItem("epAmtId", String(AMountId))
          }
          // this.successAlert()
          this.toaster.success("Charges Updated Successfully");
          this.router.navigate(["event-bookingpayment"])
          localStorage.removeItem("roomCharge")
          localStorage.removeItem("amenityCharge")
          localStorage.removeItem("parkingCharge")
          localStorage.removeItem("kitchenCharge")
          localStorage.removeItem("openSpaceCharge")
          localStorage.removeItem("activityCharge")
          localStorage.removeItem("incidentalCharge")
          localStorage.removeItem("miscCharge")
        })
      }
     else {
      this.bookingCharges.controls["activeyn"].setValue("Yes");
      this.bookingCharges.controls["deleteyn"].setValue("No");
      this.bookingCharges.controls["amtid"].setValue(this.getAmtId)
      this.bookingCharges.controls["createdby"].setValue(localStorage.getItem("token"));
      this.bookingCharges.controls["epid"].setValue(localStorage.getItem('epId'));
      this.bookingCharges.controls["epsubid"].setValue(localStorage.getItem('epSubId'));
      this.bookingCharges.controls["reqid"].setValue(localStorage.getItem('ReqId'));
      this.bookingCharges.controls["eventid"].setValue(localStorage.getItem('epEventId'));
      // Charges
      //@ts-ignore
      if (localStorage.getItem("roomCharge") == null || localStorage.getItem("roomCharge") == "") {
        this.bookingCharges.controls["roomamount"].setValue("")
        console.log("Null value Rooms::", this.bookingCharges.get("roomamount").value)
      } else {
        this.bookingCharges.controls["roomamount"].setValue(localStorage.getItem("roomCharge"))
      }
      if (localStorage.getItem("amenityCharge") == null || localStorage.getItem("amenityCharge") == "") {
        this.bookingCharges.controls["amenityamount"].setValue("")
      } else {
        this.bookingCharges.controls["amenityamount"].setValue(localStorage.getItem("amenityCharge"))
      }
      if (localStorage.getItem("parkingCharge") == null || localStorage.getItem("parkingCharge") == "") {
        this.bookingCharges.controls["parkingamount"].setValue("")
      } else {
        this.bookingCharges.controls["parkingamount"].setValue(localStorage.getItem("parkingCharge"))
      }
      if (localStorage.getItem("kitchenCharge") == null || localStorage.getItem("kitchenCharge") == "") {
        this.bookingCharges.controls["kitchenamount"].setValue("")
      } else {
        this.bookingCharges.controls["kitchenamount"].setValue(localStorage.getItem("kitchenCharge"))
      }
      if (localStorage.getItem("openSpaceCharge") == null || localStorage.getItem("openSpaceCharge") == "") {
        this.bookingCharges.controls["openspaceamount"].setValue("")
      } else {
        this.bookingCharges.controls["openspaceamount"].setValue(localStorage.getItem("openSpaceCharge"))
      }
      if (localStorage.getItem("activityCharge") == null || localStorage.getItem("activityCharge") == "") {
        this.bookingCharges.controls["acitvityamount"].setValue("")
      } else {
        this.bookingCharges.controls["acitvityamount"].setValue(localStorage.getItem("activityCharge"))
      }
      if (localStorage.getItem("incidentalCharge") == null || localStorage.getItem("incidentalCharge") == "") {
        this.bookingCharges.controls["incidentalamount"].setValue("")
      } else {
        this.bookingCharges.controls["incidentalamount"].setValue(localStorage.getItem("incidentalCharge"))
      }
      if (localStorage.getItem("miscCharge") == null || localStorage.getItem("miscCharge") == "") {
        this.bookingCharges.controls["miscamount"].setValue("")
      } else {
        this.bookingCharges.controls["miscamount"].setValue(localStorage.getItem("miscCharge"))
      }


      console.log("Charges Data::", this.bookingCharges.value)
      // @ts-ignore
      this.appService.updateEventAreaAmount(this.bookingCharges.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          console.log("Charges Details123::", data)
          this.getBookingCharges.push(data)
          var AMountId: string[] = [];
          for (let charge of this.getBookingCharges) {
            AMountId.push(charge.amtid)
            localStorage.setItem("epAmtId", String(AMountId))
          }
          this.toaster.success("Charges Updated Successfully");
          this.router.navigate(["event-bookingpayment"])
          localStorage.removeItem("roomCharge")
          localStorage.removeItem("amenityCharge")
          localStorage.removeItem("parkingCharge")
          localStorage.removeItem("kitchenCharge")
          localStorage.removeItem("openSpaceCharge")
          localStorage.removeItem("activityCharge")
          localStorage.removeItem("incidentalCharge")
          localStorage.removeItem("miscCharge")
        })
    }
  }
  isInputNumber(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  saveType() {
    this.isVisibleAlert = false
  }


  /* Rooms disable functionality*/

  successAlert() {
    Swal.fire({
      title: "Your Registration Completed,Our Event Place Manager Will Contact You.",
      icon: "success",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate([""])
      }
    });
  }



  // Update Funtionality

  getReqDetails() {
    console.log("eventid::", this.localeventId)
    this.appService.getReqDetails(this.localeventId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("getReqDetails::", data)
        this.Requireddisable = true
        this.roomsdetailsdisable = true
        this.openSpacedisable = true
        this.parkingdetailsdisable = true
        this.kitchendetailsdisable = true
        this.getEventAreaReq = data;
        for (var req of this.getEventAreaReq) {
          this.epId = req.epid
          this.epSubId = req.epsubid
          this.getReqId = req.reqid
          this.eventRequired.controls['no_acrooms'].setValue(req.no_acrooms)
          this.eventRequired.controls['no_normalrooms'].setValue(req.no_normalrooms)
          this.eventRequired.controls['parkingrequired'].setValue(req.parkingrequired)
          if (req.parkingrequired == "No") {
            this.isDisabled = false;
            this.eventRequired.controls["selectparking"].disable();
            this.eventRequired.controls["two_wheelar"].disable();
            this.eventRequired.controls["four_wheelar"].disable();
          }
          this.eventParkingDropDown.push(req.selectparking)
          this.eventRequired.controls['selectparking'].setValue(req.selectparking)
          this.eventRequired.controls['kitchenrequired'].setValue(req.kitchenrequired)
          if (req.kitchenrequired == "No") {
            this.isDisabled = false;
            this.eventRequired.controls["selectkitchen"].disable();
            this.eventRequired.controls["stoverequired"].disable();
            this.eventRequired.controls["grainderrequired"].disable();
            this.eventRequired.controls["fridgerequired"].disable();
            this.eventRequired.controls["washingrequired"].disable();
          }
          this.eventRequired.controls['washingrequired'].setValue(req.washingrequired)
          this.eventRequired.controls['grainderrequired'].setValue(req.grainderrequired)
          this.eventRequired.controls['fridgerequired'].setValue(req.fridgerequired)
          this.eventRequired.controls['stoverequired'].setValue(req.stoverequired)
          this.eventRequired.controls['selectkitchen'].setValue(req.selectkitchen)
          this.eventKitchenDropDown.push(req.selectkitchen)
          this.eventRequired.controls['openspacerequired'].setValue(req.openspacerequired)
          if (req.openspacerequired == "No") {
            this.isDisabled = false;
            this.eventRequired.controls["selectopenspace"].disable();
            this.eventRequired.controls["spacedetails"].disable();
          }
          this.eventRequired.controls['selectopenspace'].setValue(req.selectopenspace)
          this.openSpace.push(req.selectopenspace)
          this.eventRequired.controls['spacedetails'].setValue(req.spacedetails)
          console.log("getEventRooms::", req.no_acrooms)
        }
        this.saveButtoReq = true;
        this.getAmountDetails()
        this.getRoomsDropDown();
        this.getOpenSpaceDropDown();
        this.getParkingAreaDropDown();
        this.getKitchenDropDown();
      }, (err: any) => {
        console.log("Something Went Wrong")
      })
  }


  getAmountDetails() {
    this.appService.getAmountDetails(this.epId, this.localeventId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("All getcharges::", data)
        this.getUpdateCharges = data
        for (let ep of this.getUpdateCharges) {
          this.getAmtId = ep.amtid
        }
        console.log("currentamtid::", this.getAmtId)
      }, (err: any) => {
        console.log("something went wrong")
      })

  }

  getAcivityCharges() {
    this.appService.getActivity(this.epId, this.epSubId, this.localeventId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("eventActivity::", data)
        this.activityDetails = data
        console.log("multiActivity::", this.multiActivity)
        for (let event of this.multiActivity) {
          console.log("getselct::", event.selectactivity)
          console.log("getselct2::", event.selectvendor)
        }
        var localAcitvity: string[] = []
        for (let ep of this.activityDetails) {
          localAcitvity.push(ep.qtyrequired)
          console.log("MiscCharge::", localAcitvity)
          this.eventAreaActivity.controls['activityrequired'].setValue(ep.activityrequired)
          if (ep.activityrequired == "No") {
            this.isDisabled = false;
            this.eventAreaActivity.controls["selectvendor"].disable();
            this.eventAreaActivity.controls["selectactivity"].disable();
            this.saveButton = false;
            this.addNewVendor = false
            // this.multiActivity = []
          }
        }
        for (let i = 0; i < this.activityDetails.length; i++) {
          const control = new FormControl(this.activityDetails[i].selectactivity);
          const vendorcontrol = new FormControl(this.activityDetails[i].selectvendor)
          this.eventAreaActivity.addControl('selecteactivityValue' + i, control);
          this.eventAreaActivity.addControl('selectedvendorValue' + i, vendorcontrol);
          //@ts-ignore
          this.multiActivity.push(this.activityDetails[i]);
        }
        this.getActivityMapping();
        const totalValue = localAcitvity
          .map(value => parseInt(value, 10))
          .reduce((sum, value) => sum + value, 0);
        console.log("Total Value:", totalValue);
        //@ts-ignore
        this.getActivityCharges = totalValue
        this.isActivityUpdate = true
        this.isVisibleAct = false
        this.saveButton = false
        console.log("Total Misc Value:", this.getActivityCharges);
      }, (error: any) => {
        console.log("Something went worng")
      },
        () => console.log('HTTP request completed.'))
  }

  getAmenities() {
    this.appService.getAmenities(this.epId, this.epSubId, this.localeventId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("eventAmnites::", data)
        //@ts-ignore
        this.amenitiedDetails = data;
        var localAmenit: string[] = []
        for (let amt of this.amenitiedDetails) {
          localAmenit.push(amt.qtyrequired)
          console.log("AmenitCharge::", localAmenit)
          this.eventAreaAmenities.controls['amenitiesrequired'].setValue(amt.amenitiesrequired)
        }
        for (let i = 0; i < this.amenitiedDetails.length; i++) {
          const control = new FormControl(this.amenitiedDetails[i].selectamenities);
          this.eventAreaAmenities.addControl('selectedamenitiesValue' + i, control);
          //@ts-ignore
          this.multiAmenities.push(this.amenitiedDetails[i]);
        }
        //@ts-ignore
        this.allAmenityCharges = localAmenit
        this.isAmenitiesUpdate = true;
        this.amenitSaveButton = false;
        const totalValue = localAmenit
          .map(value => parseInt(value, 10))
          .reduce((sum, value) => sum + value, 0);
        console.log("Total Value:", totalValue);
        //@ts-ignore
        this.amenityCharges = totalValue
        console.log("Total Amenities Value:", this.amenityCharges);
      }, (error: any) => {
        console.log("Something went worng")
      },
        () => console.log('HTTP request completed.'))
  }

  getMisc() {
    this.appService.getMisc(this.epId, this.epSubId, this.localeventId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("eventAmnites::", data)
        //@ts-ignore
        this.getmiscDetails = data;
        var localmisc: string[] = []
        for (let ep of this.getmiscDetails) {
          localmisc.push(ep.qtyrequired)
          console.log("MiscCharge::", localmisc)
          this.eventAreaMisc.controls['mischargesreq'].setValue(ep.mischargesreq)
        }
        for (let i = 0; i < this.getmiscDetails.length; i++) {
          const control = new FormControl(this.getmiscDetails[i].selectmischarges);
          this.eventAreaMisc.addControl('selectedValue' + i, control);
          //@ts-ignore
          this.multiMisc.push(this.getmiscDetails[i]);
        }
        this.isMiscsUpdate = true;
        this.miscSaveButton = false;
        const totalValue = localmisc
          .map(value => parseInt(value, 10))
          .reduce((sum, value) => sum + value, 0);
        console.log("Total Value:", totalValue);
        //@ts-ignore
        this.getMiscCharges = totalValue
        console.log("Total Misc Value:", this.getMiscCharges);
      }, (error: any) => {
        console.log("Something went worng")
      },
        () => console.log('HTTP request completed.'))
  }


  getEventById(eventId) {
    console.log("Booking Id::", eventId)
    this.appService.getIncByEventId(eventId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        console.log("Inc Data::", data);
        //@ts-ignore
        this.getIncidentals = data
        this.incidentalSaveButton = true;
        var localincCharge: String[] = [];
        var localStartUnit: number = 0;
        for (let ep of this.getIncidentals) {
          this.eventAreaIncidental.controls['incchargerequired'].setValue(ep.incchargerequired)
        }

        for (let i = 0; i < this.getIncidentals.length; i++) {
          const control = new FormControl(this.getIncidentals[i].selectinccharges);
          const startUnits = new FormControl(this.getIncidentals[i].startingunit);
          const endUnits = new FormControl(this.getIncidentals[i].endingunit)
          this.eventAreaIncidental.addControl('selectIncidental' + i, control);
          this.eventAreaIncidental.addControl('selectStartingunit' + i, startUnits);
          this.eventAreaIncidental.addControl('selectEndingunit' + i, endUnits);
          //@ts-ignore
          this.multiIncidental.push(this.getIncidentals[i]);
        }
        this.isUpdateinc = true;
        this.incidentaldetailsdisable = true;
        this.incidentalSaveButton = false;
        this.addNewIncidental = true;
      }, (error: any) => {
        console.log("Something went wrong");
      })
  }

}








