import {Component, HostListener, OnInit, ViewEncapsulation} from "@angular/core";
import {DateTime} from 'luxon';
import AOS from "aos";
import {AppService} from "@services/app.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Country} from "countrycitystatejson/src/countries-list";
import {Subject, takeUntil} from "rxjs";
import {EventPlace} from "@modules/event-place-management/model/eventplace";
import SwiperCore, {Navigation, Pagination, Swiper, EffectFade, SwiperOptions} from 'swiper';
import {Router} from "@angular/router";
import {value} from "dom7";
import {ad, ev} from "@fullcalendar/core/internal-common";
import {EventSubPlace} from "@modules/event-place-management/model/eventsub-place";
import {str} from "ajv";
SwiperCore.use([Navigation, Pagination]);
SwiperCore.use([EffectFade]);
@Component({
  selector: 'website',
  templateUrl: './website.component.html',
  styleUrls: ['./website.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class WebsiteComponent  implements OnInit{
  destroy$: Subject<boolean> = new Subject<boolean>();
  public currentYear: string = DateTime.now().toFormat('y');

  showContent = false;
    showContentTwo = false;
  showContentThree = false;
  countries: Country[];
  states: string[];
  cities: string[];
  form: FormGroup;
  allVenuePlaces: EventPlace[] = [];
  firstFiveVenues: EventPlace[] = [];
  searchByCity: EventPlace[] = [];
  searchByTopCities: EventPlace[] = [];
  allSubPlaces: EventSubPlace[] =[];
  getEventSubIdDetails: EventSubPlace[] = [];
  cityName: string = "";
  topCityNames: string = ""
  showCity = false;
  showTopCities = false;
  noResult = false;
  noResultForTopCities = false;
  showAll = true;
  maduraiVenueLength: number;
  trichyVenueLength: number;
  eventSubName: string;
  eventSubNameShow = false;
  eventNameShow = true;
  eventCity: string;
  eventState: string;
  eventMainName: string;
  showSubPlace = false;
  countrySortList: string[] =[];
  stateSortList: string[] =[];
  citySortList: string[] =[];
  defaultCountry: string;
  defaultState: string;
  filteredVenues: any[];

  images: any[] = [
    { value: 'Madurai', src: 'assets/img/mdu-200.png' },
    { value: 'Trichy', src: 'assets/img/city-images/trichy.png' },
    { value: 'Coimbatore', src: 'assets/img/city-images/coimbatore.png' },
    { value: 'Chennai', src: 'assets/img/city-images/chennai.png' },
    { value: 'Kanyakumari', src: 'assets/img/city-images/kanyakumari.png' },
    { value: 'Salem', src: 'assets/img/city-images/salem.png' },
    { value: 'Thiruppur', src: 'assets/img/city-images/thiruppur.png' },
    { value: 'Erode', src: 'assets/img/city-images/erode.png' },
    { value: 'Thirunelveli', src: 'assets/img/city-images/thirunelveli.png' },
    { value: 'Ooty', src: 'assets/img/city-images/ooty.png' },
    { value: 'Virudhunagar', src: 'assets/img/city-images/viruthunagar.png' },
    { value: 'Thoothukudi', src: 'assets/img/city-images/Thoothukudi.png' },
  ];

  constructor(private appService: AppService, private router: Router) {
   /* this.countries = this.appService.getCountries();
    this.form = new FormGroup({
      country: this.country,
      state: this.state,
      city: this.city,
    });*/
  }

  private filterVenues(): void {
    // Filter out the item with epid 'EPI-000008'
    this.filteredVenues = this.firstFiveVenues.filter(venue => venue.epid !== 'EPI-000008');
  }

  generateImageUrl(epid: string): string {
    return `https://tomcat3.varnik.cloud:8443/epm-ws/Place/EventPlaceLogo/${epid}`;
  }

  generateAltText(epname: string): string {
    return epname;
  }

  country = new FormControl('India', [Validators.required]);
  state = new FormControl('Tamil Nadu', [Validators.required]);
  city= new FormControl(null, [Validators.required]);
  area= new FormControl(null, [Validators.required]);
  /*city = new FormControl({ value: null, disabled: true }, [
    Validators.required,
  ]);*/

  @HostListener('window:scroll', [])
  onScroll() {
    // Adjust this threshold as needed for when you want the content to appear.
    // @ts-ignore
    if (window.scrollY) {
      this.showContent = true;
    } if(window.scrollY) {
      this.showContentTwo = true;
    }if(window.scrollY) {
      this.showContentThree = true;
    }
  }

  config: SwiperOptions = {
    pagination: { el: '.swiper-pagination', clickable: true },
    allowTouchMove: true,
    breakpoints: {
      1024: {
        slidesPerView: 5
      },
      500: {
        slidesPerView: 2
      },
      400: {
        slidesPerView: 1
      },
      300: {
        slidesPerView: 1
      }
    },

  };

  configCity: SwiperOptions = {
    pagination: { el: '.swiper-pagination', clickable: true },
    allowTouchMove: true,
    breakpoints: {
      1024: {
        slidesPerView: 5
      },
      500: {
        slidesPerView: 4
      },
      400: {
        slidesPerView: 3
      },
      300: {
        slidesPerView: 2
      }
    },

  };

  ngOnInit() {
    this.filterVenues();
    AOS.init();
    /*this.country.valueChanges.subscribe((country) => {
      this.state.reset();
      this.state.disable();
      if (country) {
        this.states = this.appService.getStatesByCountry(country);
        this.state.enable();
      }
    });

    this.state.valueChanges.subscribe((state) => {
      this.city.reset();
      this.city.disable();
      if (state) {
        this.cities = this.appService.getCitiesByState(this.country.value, state);
        this.city.enable();
      }
    });*/
    this.getAllVenueDetails();
    /*this.getAllVenueSubPlaceDetails();*/
  }


  getAllVenueDetails() {
    this.appService.getAllEventPlace()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data)=> {
        // @ts-ignore
        this.allVenuePlaces = data;
        console.log("all Mahal details::", this.allVenuePlaces)
        this.displayFirstFiveItems();
        var maduraiCityLength: EventPlace[] = [];
        var trichyCityLength: EventPlace[] = [];
        for (let cityCount of this.allVenuePlaces) {
          if(cityCount.epcity == 'Madurai'){
            maduraiCityLength.push(cityCount);
          } if (cityCount.epcity == 'Trichy') {
            trichyCityLength.push(cityCount)
          }
        }
        var countryList: string[] =[];
        var stateList: string[] =[];
        var cityList: string[] =[];
        var areaList: string[] =[];
        for(let country of this.allVenuePlaces) {
          countryList.push(country.epcountry)
          stateList.push(country.epstate)
          cityList.push(country.epcity)
        }
        countryList.sort((a, b) => a.localeCompare(b));
        stateList.sort((a, b) => a.localeCompare(b));
        cityList.sort((a, b) => a.localeCompare(b));
        this.countrySortList = countryList.filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        });
        this.stateSortList = stateList.filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        });
        this.citySortList = cityList.filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        });
        this.maduraiVenueLength=maduraiCityLength.length;
        this.trichyVenueLength=trichyCityLength.length;
        console.log("Madurai::", this.maduraiVenueLength)
        console.log("Trichy::", this.trichyVenueLength)
        console.log("Country List::", this.countrySortList)
      }, (error: any) => {
        console.log("something went wrong:", error)
      });
  }

  /*get uniqueStates() {
    return Array.from(new Set(this.allVenuePlaces.map(state => state.epstate)));
  }

  get uniqueCities() {
    return Array.from(new Set(this.allVenuePlaces.map(city => city.epcity)));
  }*/

  //Get All Venue Sub Place Details

  /*getAllVenueSubPlaceDetails() {
    this.appService.getAllSubPlace()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data)=> {
        // @ts-ignore
        this.allSubPlaces = data;
        console.log("Get All Sub Places::", this.allSubPlaces);
      }, (error: any) => {
        console.log("something went wrong:", error)
      })
  }*/

 /* displayFirstFiveItems() {
    var venue: EventPlace[] = [];
    // Use a for loop to iterate through the first 5 items in allVenuePlaces
    this.allVenuePlaces.sort((a, b) => a.epname.localeCompare(b.epname));
    for (let i = 0; i < this.allVenuePlaces.length; i++) {
       venue.push(this.allVenuePlaces[i]);
      // Now you can use 'venue' to display or work with the data
    }
    this.firstFiveVenues = venue;
    console.log('Venue:', venue);

  }*/

  displayFirstFiveItems() {
    this.allVenuePlaces.sort((a, b) => a.epname.localeCompare(b.epname));

    this.firstFiveVenues = [];
    this.filteredVenues = [];

    for (let i = 0; i < this.allVenuePlaces.length; i++) {
      const currentVenue = this.allVenuePlaces[i];

      // Check if the current venue has epid equal to "EPI-000008"
      if (currentVenue.epid === 'EPI-000008') {
        // Push to hiddenVenues list
        this.filteredVenues.push(currentVenue);
      } else {
        // Push to firstFiveVenues list
        this.firstFiveVenues.push(currentVenue);
      }
    }

    console.log('First Five Venues:', this.firstFiveVenues);
    console.log('Hidden Venues:', this.filteredVenues);
  }


  selectCity(event) {
    const value = event.target.value;
    this.cityName = value;
    console.log("city name::", this.cityName)
  }

  selectArea(event) {
    const value = event.target.value;
  }

  findVenue(cityName: string) {
    console.log("click city Name:", cityName);
    var filterByCity: EventPlace[] = [];
    var filterByCities: EventSubPlace[] =[];
    for(let city of this.allVenuePlaces) {
      if(cityName == city.epcity) {
        filterByCity.push(city)
      }
    }
    this.showCity = true;
    this.noResult = true;
    this.showAll = false;
    this.searchByCity = filterByCity;
    console.log("search By City::",this.searchByCity)
  }

    venueDetails(eventId: string) {
    localStorage.setItem("EventPlaceId", eventId)
      this.showSubPlace = true;
      window.scrollTo(500,500)
      for(let address of this.allVenuePlaces) {
        if (eventId == address.epid) {
          this.eventCity = address.epcity;
          this.eventState = address.epstate;
          this.eventMainName = address.epname
        }
      }
      this.appService.getEventIdBasedEventSubPlaceDetailsShow(eventId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data)=> {
          this.getEventSubIdDetails = data;
          this.showSubPlace = true;
          this.showTopCities = false;
          this.showAll = false;
          console.log("EventIfBasedSubPlace::", this.getEventSubIdDetails)
        }, (error: any) => {
          console.log("something went wrong:", error)
        });
  }

  backCity() {
    this.showTopCities = true;
    this.showSubPlace = false;
    this.showAll = true;
  }

  topCityVenues(cityName: string) {
    this.topCityNames = cityName;
    console.log("city Name::", cityName);
    var filterByCity: EventPlace[] = [];
    this.allVenuePlaces.sort((a, b) => a.epname.localeCompare(b.epname));
    for(let city of this.allVenuePlaces) {
      if(cityName == city.epcity) {
        filterByCity.push(city)
      }
    }
    this.showTopCities = true;
    this.noResultForTopCities = true;
    this.showAll = false;
    this.searchByTopCities = filterByCity;
    console.log("search By City::",this.searchByTopCities)
  }

  /*SubVenueDetails(subVenueId: string) {
    localStorage.setItem("EventSubPlaceId", subVenueId);
    console.log("get Sub ID::", subVenueId);
    for(let showSubPlace of this.getEventSubIdDetails) {
      if (subVenueId == showSubPlace.epsubid) {
        this.eventSubName = showSubPlace.epsubname;
        window.scrollTo(0, 0);
        this.eventNameShow = false;
        this.eventSubNameShow =true;
      }
    }

    console.log("Sub Place Name::", this.eventSubName);
  }*/

  subIdBasedDetailsShowInViewScreen(eventSubId) {
    localStorage.setItem("EventSubPlaceId", eventSubId);
   this.router.navigate(['/venue-view']).then(() => {
     window.scrollTo(0, 0);
   });
  }

}
