export class IncidentalClass{
    forEach(arg0: (value: any, index: any) => void) {
        throw new Error('Method not implemented.');
      }
      splice(index: any, arg1: number) {
        throw new Error('Method not implemented.');
      }
    reqid : string;
    incactivity : string;
    incid : string;
    eventid: string;
    incchargerequired : string;
    selectinccharges : string;
    qtyrequired : string;
    startingunit : string;
    endingunit : string;
    activeyn : string;
    deleteyn : string;
    createdtime : string;
    createdby : string;
    modifiedtime : string;
    modifiedby : string;
    epsubid : string;
    epid : string;
    selectIncidental : string;
    selectStartingunit : string;
    selectEndingunit : string;
}