import {Component, OnInit} from '@angular/core';
import {AppService} from '@services/app.service';
import {DateTime} from 'luxon';
import { EPUSerDetails } from '@modules/event-place-management/model/epuser-details';
import {pipe, Subject, takeUntil} from "rxjs";
import { Token } from '@angular/compiler';
import { Router } from '@angular/router';
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  public user;
  destroy$: Subject<boolean> = new Subject<boolean>();
  username: string | undefined;
  userid : string | undefined;
  showTooltip : boolean = false;

  constructor(private appService: AppService, private router : Router) {
  }

  ngOnInit(): void {
    this.user = this.appService.user;
    this.userid=localStorage.getItem('token')
    console.log("Userid::",this.userid)
    this.username=localStorage.getItem('username')
  }

  show() {
    this.showTooltip=true;
  }


  logout() {
    this.appService.logout();
    localStorage.removeItem('token')
    localStorage.removeItem('ReqId')
    localStorage.removeItem('epEndDate')
    localStorage.removeItem('epStartDate')
    localStorage.removeItem('ActId')
    localStorage.removeItem('username')
    localStorage.removeItem('userId')
    localStorage.removeItem('epId')
    localStorage.removeItem('epSubId')
    localStorage.removeItem('role')
    localStorage.removeItem('authToken')
    localStorage.removeItem('epEventId')
    localStorage.removeItem("roomCharge")
    localStorage.removeItem("amenityCharge")
    localStorage.removeItem("parkingCharge")
    localStorage.removeItem("kitchenCharge")
    localStorage.removeItem("openSpaceCharge")
    localStorage.removeItem("activityCharge")
    localStorage.removeItem("incidentalCharge")
    localStorage.removeItem("miscCharge")
    localStorage.removeItem("customerEventId")
    localStorage.removeItem("ownerId")
    localStorage.removeItem("capacity")
    localStorage.removeItem("epAmtId")
    localStorage.removeItem("startsecondHF")
    localStorage.removeItem("endFirstHF")
    localStorage.removeItem("startFirstHf")
    localStorage.removeItem("IncidentalId")
    localStorage.removeItem("userRole")
    localStorage.removeItem("endSecondHF")
    localStorage.removeItem("AmenitId")
    localStorage.removeItem("MiscId")
    localStorage.removeItem('eventEmailId')
  }

  formatDate(date) {
    return DateTime.fromISO(date).toFormat('dd LLL yyyy');
  }
  reload(){
    window.location.reload();
  }
  home(){
    this.router.navigate(['/dash'])
  }
}
