<div class="container-fluid">
<form (ngSubmit)="onSubmit()" [formGroup]="epparking">
  <div class="col-md-1 mt-1"></div>
  <div class="col-md-11">
    <h4 class="">Parking Area</h4>
  </div>
  <div class="alert alert-light" role="alert">
    Note:<span style="color:red;">*</span> Screen to enter all the required details about your Parking Area.
  </div>
  <div class="row">
    <label class="col-3  col-form-label"><strong>Parking Area Details Required</strong></label>
    <div class="form-check mt-1">
      <input class="form-check-input" type="radio" formControlName="parkingarearequired" value="yes"
             (change)="parkingareadisable($event)" >
      <label class="form-check-label"><b>Yes</b></label>
    </div>
    <div class="form-check mt-1">
      <input class="form-check-input ml-1" type="radio" formControlName="parkingarearequired" value="no"
             (change)="parkingareadisable($event)" >
      <label class="form-check-label ml-4"><b>No</b></label>
    </div>
  </div>
        <div class="row">
          <div class="col-md-4">
            <div class="col-md-1"></div>
            <div class="col-md-11">
              <div class="form-group">
					<label for="epid" class="form-label">Event Place<span style="color:red;">*</span></label>
					<select class="form-control" id="epid" formControlName="epid" (change)="onShoweventsub($event)">
						<option *ngFor="let parms of eventDropdown" value="{{parms}}">{{parms}}</option>
						<option value="" disabled selected hidden>Select Event place</option>
					</select>
					<span *ngIf="epparkingErrors.epid" class="error-text">{{epparkingErrors.epid}}</span>
				       </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="col-md-1"></div>
            <div class="col-md-11">
              <div class="form-group">
					<label for="epsubid" class="form-label">Event Sub Place<span style="color:red;">*</span></label>
					<select class="form-control" id="epsubid" formControlName="epsubid" disabled="false"
						(change)="onShowParkingArea($event)">
						<option value="" selected>Select EventSub Place</option>
						<option *ngFor="let parms of eventSubDropDown" value="{{parms}}">{{parms}}</option>
					</select>
					<span *ngIf="epparkingErrors.epsubid" class="error-text">{{epparkingErrors.epsubid}}</span>
				    </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="col-md-1"></div>
            <div class="col-md-11">
              <div class="form-group">
					<label for="epparkingid" class="form-label">Parking Area</label>
					<select class="form-control" id="epparkingid" disabled="false" formControlName="epparkingid"
						(change)="onShowOpenParkingAreadetails($event)">
						<option value="" selected>Select parking Area</option>
						<option *ngFor="let parms of eventParkingDropDown" value="{{parms}}">{{parms}}</option>
					</select>
				      </div>
            </div>
          </div>
        </div>
			<div class="row">
        <div class="col-md-4">
          <div class="col-md-1"></div>
          <div class="col-md-11">
            <div class="form-group">
					<label for="epparkingname">Parking Area Name<span style="color:red;">*</span></label>
					<input type="text" class="form-control text-capitalize" id="epparkingname"
						formControlName="epparkingname" placeholder="Parking Area Name">
					<span *ngIf="epparkingErrors.epparkingname"
						class="error-text">{{epparkingErrors.epparkingname}}</span>
				   </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="col-md-1"></div>
          <div class="col-md-11">
            <div class="form-group">
					<label for="epparkingbicyclecapacity">Parking Bi-Cycle Capacity<span
							style="color:red;">*</span></label>
					<input type="text" class="form-control" id="epparkingbicyclecapacity"
						(keypress)="numberOnly($event)" maxlength="3" formControlName="epparkingbicyclecapacity"
						placeholder="Parking Bi-Cycle Capacity">
					<span *ngIf="epparkingErrors.epparkingbicyclecapacity"
						class="error-text">{{epparkingErrors.epparkingbicyclecapacity}}</span>
				     </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="col-md-1"></div>
          <div class="col-md-11">
            <div class="form-group">
					<label for="epparking2wheelercapacity">Parking Two Wheeler Capacity<span
							style="color:red;">*</span></label>
					<input type="text" class="form-control" id="epparking2wheelercapacity"
						(keypress)="numberOnly($event)" maxlength="3" formControlName="epparking2wheelercapacity"
						placeholder="Parking Two Wheeler Capacity">
					<span *ngIf="epparkingErrors.epparking2wheelercapacity"
						class="error-text">{{epparkingErrors.epparking2wheelercapacity}}</span>
				    </div>
			    </div>
        </div>
      </div>
			<div class="row">
        <div class="col-md-4">
          <div class="col-md-1"></div>
          <div class="col-md-11">
            <div class="form-group">
					<label for="epparking4wheelercapacity">Parking Four Wheeler Capacity<span
							style="color:red;">*</span></label>
					<input type="text" class="form-control" id="epparking4wheelercapacity"
						(keypress)="numberOnly($event)" maxlength="3" formControlName="epparking4wheelercapacity"
						placeholder="Parking Four Wheeler Capacity">
					<span *ngIf="epparkingErrors.epparking4wheelercapacity"
						class="error-text">{{epparkingErrors.epparking4wheelercapacity}}</span>
				     </div>
           </div>
        </div>
        <div class="col-md-4">
          <div class="col-md-1"></div>
          <div class="col-md-11">
            <div class="form-group">
					<label for="epparkingtruckcapacity">Parking Truck Capacity<span style="color:red;">*</span></label>
					<input type="text" class="form-control" id="epparkingtruckcapacity" (keypress)="numberOnly($event)"
						maxlength="4" formControlName="epparkingtruckcapacity" placeholder="Parking Truck Capacity">
					<span *ngIf="epparkingErrors.epparkingtruckcapacity"
						class="error-text">{{epparkingErrors.epparkingtruckcapacity}}</span>
				    </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="col-md-1"></div>
          <div class="col-md-11">
            <div class="form-group">
					<label for="epparkingareacharges">Parking Charges<span style="color:red;">*</span></label>
					<input type="text" class="form-control" id="epparkingareacharges" (keypress)="numberOnly($event)"
						maxlength="6" formControlName="epparkingareacharges" placeholder="Parking Charges">
					<span *ngIf="epparkingErrors.epparkingareacharges"
						class="error-text">{{epparkingErrors.epparkingareacharges}}</span>
				  </div>
			   </div>
        </div>
      </div>
			<div class="row">
        <div class="col-md-4">
          <div class="col-md-1"></div>
            <div class="col-md-11">
              <div class="form-group">
					<label for="epparkingdesc">Parking Description</label>
					<input class="form-control text-capitalize" id="epkitchendesc" formControlName="epparkingdesc"
						placeholder="Parking Description">
					<!--<span *ngIf="epparkingErrors.epparkingdesc"
						class="error-text">{{epparkingErrors.epparkingdesc}}</span>-->
				      </div>
            </div>
          </div>
        <div class="col-md-4">
          <div class="col-md-1"></div>
          <div class="col-md-11">
					<div class="form-group">
            <label class="mr-3" for="epparkingchargable">Parking Chargeable<span
              style="color:red;">*</span>:</label>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" value="Yes" name="epparkingchargable"
                     id="epparkingchargable" formControlName="epparkingchargable">
              <label class="form-check-label" for="epparkingchargable"><b>Yes</b></label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" value="No" name="epparkingchargable"
                     id="epparkingchargable" formControlName="epparkingchargable">
              <label class="form-check-label" for="epparkingchargable"><b>No</b></label>
            </div><br>
						<span *ngIf="epparkingErrors.epparkingchargable"
                  class="error-text">{{epparkingErrors.epparkingchargable}}</span>
            </div>
          </div>
          </div>
      </div>

				<!--<div class="col-md-4">
					<label class="mr-3" for="epparkingchargable">Parking Chargable<span
							style="color:red;">*</span>:</label>
					<div class="form-check form-check-inline">
						<input class="form-check-input" type="radio" value="Yes" name="epparkingchargable"
							id="epparkingchargable" formControlName="epparkingchargable">
						<label class="form-check-label" for="epparkingchargable">Yes</label>
					</div>
					<div class="form-check form-check-inline">
						<input class="form-check-input" type="radio" value="No" name="epparkingchargable"
							id="eproomchargable" formControlName="epparkingchargable">
						<label class="form-check-label" for="epparkingchargable">No</label>
					</div>
					<div>
						<span *ngIf="epparkingErrors.epparkingchargable"
							class="error-text">{{epparkingErrors.epparkingchargable}}</span>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-4">
					<label class="mr-3" for="epparkingshow">parking Visibility<span style="color:red;">*</span>:</label>
					<div class="form-check form-check-inline">
						<input class="form-check-input" type="radio" value="Yes" name="epparkingshow" id="epparkingshow"
							formControlName="epparkingshow">
						<label class="form-check-label" for="epparkingshow">Yes</label>
					</div>
					<div class="form-check form-check-inline">
						<input class="form-check-input" type="radio" value="No" name="epparkingshow" id="epparkingshow"
							formControlName="epparkingshow">
						<label class="form-check-label" for="epparkingshow">No</label>
					</div>
					<div>
						<span *ngIf="epparkingErrors.epparkingshow"
							class="error-text">{{epparkingErrors.epparkingshow}}</span>
					</div>
				</div>
			</div>-->
			<div class="text-center">
        <button type="button" class="btn btn-primary" (click)="draftButton('Draft')" *ngIf="isdisabledraft" >Save as Draft </button>
				<button type="submit" class="btn btn-success m-3" *ngIf="isdisablesave">{{this.isUpdate == true ? "Update" : "Save"}}</button>
        <button type="submit" class="btn btn-primary m-3" (click)="nextButton()" *ngIf="isdisablenext">Next</button>
			</div>
			<div class="row mt-5"></div>
</form>
</div>
<ngx-spinner>
	<div class="custom-loader"></div>
</ngx-spinner>
