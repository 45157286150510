<section class="gradient-custom mt-3">
  <div class="container">
    <div class="row justify-content-center align-items-center">
      <div class="col-12 col-lg-9 col-xl-7">
        <div class="card card-outline card-primary ">
  <div class="text-center">
    <div class="row">
      <div class="col-md-1"></div>
      <img src="assets/img/logo1.png" style="height:70px;width:70px;" >
            <span>
               <h4 class=" mt-3 " style="font-weight: bold;"><b>E</b>vent <b>P</b>lace <b>M</b>anagement</h4>
            </span>

    </div>
    <hr class="mt-1">
  </div>
   <h5 class="p-2" style="text-align-last: center">Register New User</h5>
   <form (ngSubmit)="onSubmit()" [formGroup]="epUser" id="epUser">
    <div class="row m-1">
    <div class="col-md-6">
        <input type="text" class="form-control text-capitalize" placeholder="First Name" id="epuserfirstname" formControlName="epuserfirstname">
        <span *ngIf="epUserErrors.epuserfirstname"
      class="error-text">{{epUserErrors.epuserfirstname}}</span>
    </div>
    <div class="col-md-6">
        <input type="text" class="form-control text-capitalize" placeholder="Last Name" id="epuserlastname" formControlName="epuserlastname">
        <span *ngIf="epUserErrors.epuserlastname"
        class="error-text">{{epUserErrors.epuserlastname}}</span>
    </div>
    <div class="col-md-6 mt-2">
        <input type="email" class="form-control" placeholder="EmailID Primary" id="epuseremailidpy" formControlName="epuseremailidpy">
        <span *ngIf="epUserErrors.epuseremailidpy"
        class="error-text">{{epUserErrors.epuseremailidpy}}</span>
    </div>
     <div class="col-md-6 mt-2">
       <div class="col-md-12" style="margin-left: 75px;">
        <button type="button" class="btn btn-success" (click)="sendOtp()">Send OTP</button>
       </div>
    </div>
      <div class="col-md-6 mt-2">
        <input type="text"  class="form-control" id="phonenumber" formControlName="phonenumber" placeholder="Phone Number" (keypress)="numberOnly($event)" maxlength="10">
        <span *ngIf="epUserErrors.phonenumber"
              class="error-text">{{epUserErrors.phonenumber}}</span>
      </div>
      <div class="col-md-6 mt-2">
        <div class="col-md-12" style="margin-left: 75px;">
          <button type="button" class="btn btn-success">Send OTP</button>
        </div>
      </div>
    <div class="col-md-6 mt-2">
      <div class="input-group">
                  <input type="{{ passwordShown ? 'text' : 'password' }}" class="form-control "  style="border-right:0px;"  formControlName="epuserloginpassword" placeholder="Password" id="epuserloginpassword">
                  <div class="input-group-append">
                    <span class="input-group-text rounded-0"><i class="fa fa-eye" id="eye" (click)="passwordShown = !passwordShown"></i></span>
                  </div>
              </div>
              <span *ngIf="epUserErrors.epuserloginpassword"
        class="error-text">{{epUserErrors.epuserloginpassword}}</span>
            </div>
    <div class="col-md-6 mt-2">
      <input type="text"  class="form-control" id="epusername" formControlName="epusername" placeholder="User Name">
      <span *ngIf="epUserErrors.epusername"
      class="error-text">{{epUserErrors.epusername}}</span>
 </div>

    <div class="col-md-6 mt-2">
        <textarea class="form-control" placeholder="Address 1"  id="epuseraddress1" formControlName="epuseraddress1"></textarea>
        <span *ngIf="epUserErrors.epuseraddress1"
        class="error-text">{{epUserErrors.epuseraddress1}}</span>
    </div>
    <!--<div class="col-md-6 mt-2">
        <textarea class="form-control" placeholder="Address 2"  id="epuseraddress2" formControlName="epuseraddress2"></textarea>
        <span *ngIf="epUserErrors.epuseraddress2"
        class="error-text">{{epUserErrors.epuseraddress2}}</span>
    </div>-->
    <div class="col-md-6 mt-2">
        <input type="text" class="form-control text-capitalize" placeholder="City" id="epusercity" formControlName="epusercity">
        <span *ngIf="epUserErrors.epusercity"
        class="error-text">{{epUserErrors.epusercity}}</span>
    </div>
    <div class="col-md-6 mt-2">

        <input type="text" class="form-control text-capitalize" placeholder="State" id="epuserstate" formControlName="epuserstate">
        <span *ngIf="epUserErrors.epuserstate"
        class="error-text">{{epUserErrors.epuserstate}}</span>
    </div>
    <div class="col-md-6 mt-2">
        <input type="text"  class="form-control" placeholder=" Zip/Postal Code" id="epuserpincode" formControlName="epuserpincode" (keypress)="numberOnly($event)" maxlength="6">
        <span *ngIf="epUserErrors.epuserpincode"
        class="error-text">{{epUserErrors.epuserpincode}}</span>
      </div>
    <div class="col-md-6 mt-2">
      <input type="text" class="form-control text-capitalize" placeholder="Country" id="userCountry" formControlName="userCountry">
        <!-- <select class="form-control" id="userCountry" formControlName="userCountry">
        <option value="">Select Country</option>
        <option value="India">India</option>
        <option value="Malaysia">Malaysia</option>
        <option value="Singapore">Singapore</option>
        <option value="UnitedArabEmirates">United Arab Emirates</option>
        <option value="UnitedStates">United States</option>
        </select> -->

        <span *ngIf="epUserErrors.userCountry"
        class="error-text">{{epUserErrors.userCountry}}</span>
      </div>
    <div class="col-md-6 mt-2">
        <select class="form-control" id="epusertype" formControlName="role">
        <option value="">Select User Type</option>
        <option value="OWNER">Owner</option>
        <option value="ADMIN">Admin</option>
        </select>
        <span *ngIf="epUserErrors.role"
        class="error-text">{{epUserErrors.role}}</span>
      </div>

      <div class="col-md-12 mt-2 p-1" style="margin-left: 205px">
        <div class="form-check d-flex">
          <input class="form-check-input" type="checkbox" value="Enable" formControlName="acknowledgement" />
          <label class="form-check-label">
            I agree on <a href="#!">Terms and Conditions</a>
          </label>
        </div>
        <span *ngIf="epUserErrors.acknowledgement"
        class="error-text">{{epUserErrors.acknowledgement}}</span>
      </div>
  </div>
  <div class="row justify-content-center mt-3">
    <div class="form-group">
    <button type="submit" class="btn btn-primary">Register</button>
  </div>
  </div>
  <div class="row justify-content-center">
  <p class="form-group">
    <a [routerLink]="['/login']" class="text-center">
      Login
    </a>
  </p>
</div>
</form>
</div>
</div>
</div>
</div>

  <div [style.display]="openOTP ? 'block' : 'none'" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <!--<h4 class="modal-title mt-3" style="text-align: center;">NOTE</h4>-->
        <div class="modal-header">
          <h5 class="modal-title" style="margin-left: 165px" id="otpModalLabel"><b>Enter the OTP</b></h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" (click)="close()">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="otpInput" id="otpInput">
            <div class="form-group">
              <!--<label for="otp">OTP</label>-->
              <input type="text" class="form-control" id="otp" formControlName="otp" name="otp" maxlength="4" required>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="submitOtp()">Submit OTP</button>
        </div>
      </div>
    </div>
  </div>

</section>
<ngx-spinner>
  <div class="custom-loader"></div>
</ngx-spinner>
