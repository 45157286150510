import {Component, ElementRef, HostListener, OnInit, Renderer2, ViewEncapsulation} from "@angular/core";
import SwiperCore, {Navigation, Pagination, Scrollbar, A11y, SwiperOptions} from 'swiper';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
import AOS from "aos";
import {Router} from "@angular/router";
import {AppService} from "@services/app.service";
import {Subject, takeUntil} from "rxjs";
import {EventPlace} from "@modules/event-place-management/model/eventplace";
import {EPRoomsDetails} from "@modules/event-place-management/model/epRooms-details";
import {EventSubPlace} from "@modules/event-place-management/model/eventsub-place";
import {NgxSpinnerService} from "ngx-spinner";
import {EventSubPlaceDetails} from "@modules/event-place-management/model/allActivityDetails/EventSubPlaceDetails";
import {DateTime} from 'luxon';

@Component({
  selector: 'venue-view',
  templateUrl: './venue-view.component.html',
  styleUrls: ['./venue-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class VenueViewComponent implements OnInit{
  destroy$: Subject<boolean> = new Subject<boolean>();
  public currentYear: string = DateTime.now().toFormat('y');
  showContentOnMobile = false;
  showContentOnDesktop = false;
  eventSubNameShow = false;
  eventNameShow = true;
  eventId : string | undefined;
  eventPlace: string | undefined;
  eventSubPlace: string | undefined;
  eventSubName: string;
  eventName: string;
  eventDescription: string;
  eventAddressOne: string;
  eventAddressTwo: string;
  eventLandMark: string;
  eventCity: string;
  eventState: string;
  eventCountry: string;
  eventPinCode: string;
  eventAC:string;
  eventPhoneNumber: string;
  totalRooms: number;
  acRoomsCount: number;
  kitchenCount: number;
  parkingCount: number;
  nonAcRoomsCount: number;
  bikeParking: number;
  carParking: number;
  biCycleParking: number;
  truckParking: number;
  openSpaceName: string [] = [];
  activityNameList: string [] = [];
  getEventSubIdDetails: EventSubPlace[] = [];
  getVenueDetails: EventPlace | undefined;
  allSubPlaceData: EventSubPlaceDetails | undefined
  subPlaceId: string;
  mainPlaceId: string;

  constructor(private renderer: Renderer2, private el: ElementRef, private router: Router,
              private appService: AppService, private spinner: NgxSpinnerService
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.checkScreenSize();
  }

  config: SwiperOptions = {
    pagination: { el: '.swiper-pagination', clickable: true },
    allowTouchMove: true,
    breakpoints: {
      1024: {
        slidesPerView: 5
      },
      500: {
        slidesPerView: 2
      },
      400: {
        slidesPerView: 2
      },
      300: {
        slidesPerView: 1
      }
    },

  };


  checkScreenSize() {
    const windowWidth = window.innerWidth;
    this.showContentOnMobile = windowWidth <= 768;
    this.showContentOnDesktop = windowWidth > 769;
  }

  ngOnInit() {
    AOS.init();
    this.checkScreenSize();
    const eventId = localStorage.getItem("EventPlaceId");
    const eventSubId = localStorage.getItem("EventSubPlaceId");
    this.mainPlaceId = localStorage.getItem("EventPlaceId");
    this.subPlaceId = localStorage.getItem("EventSubPlaceId")
    /*this.getEventIdDetails(eventId);*/
    this.getAllSubEventDetails(eventId, eventSubId);
    this.getEventIdBasedSubIdDetails(eventId);
   /* this.getRoomCountDetails(eventId);*/
  }

  ngOnDestroy(): void {
    /*localStorage.removeItem("EventId")*/
  }

  /*SubVenueDetails(subVenueId: string) {
    localStorage.setItem("EventSubPlaceId", subVenueId);
    console.log("get Sub ID::", subVenueId);
    for(let showSubPlace of this.getEventSubIdDetails) {
      if (subVenueId == showSubPlace.epsubid) {
        this.eventSubName = showSubPlace.epsubname;
        window.scrollTo(0, 0);
        this.eventSubNameShow =true;
      }
    }

    console.log("Sub Place Name::", this.eventSubName);
  }*/

  /*getEventIdDetails(eventId: string) {
    this.spinner.show();
    this.appService.getEventPlaceDetails(eventId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data)=> {
        this.getVenueDetails = data[0];
        this.spinner.hide();
        console.log("Venue Details::", this.getVenueDetails);
        this.eventName = this.getVenueDetails.epname;
        this.eventCity = this.getVenueDetails.epcity;
        this.eventDescription = this.getVenueDetails.epdescription;
        this.eventAddressOne = this.getVenueDetails.epaddress1;
        this.eventAddressTwo = this.getVenueDetails.epaddress2;
        this.eventLandMark = this.getVenueDetails.epnearbylandmark;
        this.eventState = this.getVenueDetails.epstate;
        this.eventCountry = this.getVenueDetails.epcountry;
        this.eventPinCode = this.getVenueDetails.eppincode;
        this.eventAC = this.getVenueDetails.epaircond;
        this.eventPhoneNumber = this.getVenueDetails.phonenumber;
        console.log("Venue Name::", this.eventName);
      }, (error: any) => {
        console.log("something went wrong:", error)
      })
  }*/

  getEventIdBasedSubIdDetails(eventId: string) {
    this.appService.getEventIdBasedEventSubPlaceDetailsShow(eventId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data)=> {
        this.getEventSubIdDetails = data;
        console.log("EventIfBasedSubPlace::", this.getEventSubIdDetails)
      }, (error: any) => {
        console.log("something went wrong:", error)
      });

  }

  checkAvailability() {
    this.spinner.show();
    /*localStorage.getItem("EventId")*/
    this.router.navigate(['/customer-booking']);
    this.spinner.hide();
  }

  getAllSubEventDetails(eventId: string, eventSubId: string) {
    this.spinner.show();
    this.appService.getSubPlaceDetails(eventId, eventSubId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data)=> {
        this.spinner.hide();
        this.allSubPlaceData = data;
        console.log("SubPlace ALL Details::", data)
        this.eventSubName = this.allSubPlaceData.eventSubPlaceRegistrationEntity.epsubname;
        this.eventDescription = this.allSubPlaceData.eventmainPlace.epdescription;
        this.eventAddressOne = this.allSubPlaceData.eventmainPlace.epaddress1;
        this.eventAddressTwo = this.allSubPlaceData.eventmainPlace.epaddress2;
        this.eventLandMark = this.allSubPlaceData.eventmainPlace.epnearbylandmark;
        this.eventState = this.allSubPlaceData.eventmainPlace.epstate;
        this.eventCountry = this.allSubPlaceData.eventmainPlace.epcountry;
        this.eventPinCode = this.allSubPlaceData.eventmainPlace.eppincode;
        this.eventAC = this.allSubPlaceData.eventmainPlace.epaircond;
        this.eventPhoneNumber = this.allSubPlaceData.eventmainPlace.phonenumber;
        this.totalRooms = this.allSubPlaceData.epRoomsEntity.length;
        this.kitchenCount = this.allSubPlaceData.epKitchenEntity.length;
        this.parkingCount = this.allSubPlaceData.epParkingEntity.length;
        this.eventRoomsCounts();
        this.eventParkingCount();
        this.openSpaceListCount();
        this.venueActivities();
        console.log("Sub Name::",this.eventSubName)
      },(error: any) => {
        this.spinner.hide();
      console.log("something went wrong:", error)
    });
  }

  eventRoomsCounts() {
    var acRoomCont: string[] = [];
    var nonAcRoomCont: string[] = [];
    for (let roomsCount of this.allSubPlaceData.epRoomsEntity) {
      if (roomsCount.eproomaircond == "Yes") {
        acRoomCont.push(roomsCount.eproomaircond);
      }
      if (roomsCount.eproomaircond == "No") {
        nonAcRoomCont.push(roomsCount.eproomaircond);
      }
    }

    this.acRoomsCount = acRoomCont.length;
    this.nonAcRoomsCount = nonAcRoomCont.length;
    console.log("AC Rooms::", acRoomCont)
    console.log("non AC Rooms::", nonAcRoomCont)
  }

  eventParkingCount() {
   var twoWheelerParking: string[] = [];
    var fourWheelerParking: string[] = [];
    var biCycle: string[] = [];
    var truck: string[] = [];
    for(let parking of this.allSubPlaceData.epParkingEntity) {
     twoWheelerParking.push(parking.epparking2wheelercapacity);
     fourWheelerParking.push(parking.epparking4wheelercapacity);
     biCycle.push(parking.epparkingbicyclecapacity);
     truck.push(parking.epparkingtruckcapacity);
    }
    this.biCycleParking = biCycle.reduce((total, value) => total + parseInt(value, 10), 0);
    // this.biCycleParking = biCycle.length;
    this.bikeParking = twoWheelerParking.reduce((total, value) => total + parseInt(value, 10), 0);
    this.carParking = fourWheelerParking.reduce((total, value) => total + parseInt(value, 10), 0);
    this.truckParking = truck.reduce((total, value) => total + parseInt(value, 10), 0);

    console.log("Bike::", this.biCycleParking)
  }

  openSpaceListCount() {
    var openSpaceList: string [] = [];
    for(let openSpace of this.allSubPlaceData.epOpenSpaceEntity) {
      openSpaceList.push(openSpace.epopenspacename)
    }
    this.openSpaceName = openSpaceList
  }

  venueActivities() {
    var activityList: string [] = [];
    for(let activities of this.allSubPlaceData.epActivities) {
      activityList.push(activities.epactivityname)
    }
    this.activityNameList = activityList
  }

  /*getRoomCountDetails(eventId: string) {
    this.appService.getRoomDetails(eventId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data)=> {
        this.getRoomsDetails = data;
        console.log("Get Room Details::", this.getRoomsDetails)
      });
  }*/
}
