import {Component, OnInit} from "@angular/core";
import { UntypedFormGroup,UntypedFormControl,Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { AppService } from "@services/app.service";
import { EventPlace } from '@modules/event-place-management/model/eventplace';
import { EventSubPlace } from '@modules/event-place-management/model/eventsub-place';
import {pipe, Subject, takeUntil} from "rxjs";
import { EPUSerDetails } from "../model/epuser-details";
import { UserMapping } from "../model/user-mapping";
import Swal from "sweetalert2";
@Component({
  selector: 'app-user-mapping',
  templateUrl: './user-mapping.component.html',
  styleUrls: ['./user-mapping.component.scss']
})
export class UserMappingComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  eventPlaceDetails : EventPlace [] = [];
  eventPlaceShow : EventPlace [] = [];
  eventSubplaceDetails: EventSubPlace [] = [];
  eventSubplaceShow: EventSubPlace [] = [];
   userdetails: EPUSerDetails [] = [];
   userdetailsShow: EPUSerDetails [] = [];
   getUserMappingId : UserMapping [] [];
    eventsubplace: EventSubPlace | undefined;
    eventDropdown: string [] = [];
    eventSubDropDown: string[] = [];
    userDropDown: EPUSerDetails [] = [];
    eventId : string | undefined;
    eventSubId : string | undefined;
    epUserId : string | undefined;
    userId: string | undefined;
    isDisabled : boolean = false;
    // isUpdate = false;
  constructor (private appService : AppService,private router : Router){

  }

  userMapping = new UntypedFormGroup({
    epid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epnickname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epimage: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    phonenumber: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    emailid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epdescription: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epaddress1: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epaddress2: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epnearbylandmark: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epcity: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epstate: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    eppincode: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epcountry: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    activestatus: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epaircond: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epsubid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epsubname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epsubnickname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epsubdesc: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epsubemailid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epsubaircond: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    subactivestatus: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epuserid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epuserfirstname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epuserlastname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epuseremailidpy: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epuseremailidsy: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epuserloginid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epuserloginpassword: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epusertype: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epuseraddress1: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epuseraddress2: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epusercity: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epuserstate: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epuserpincode: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    usercountry: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    activeYn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    deleteYn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    role: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    activeyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    deleteyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    createdby: new UntypedFormControl('', [Validators.required, Validators.nullValidator])
  })
  public EpUserMappinErrors = {
    epid: '',
    epsubid: '',
    epuserid: '',

  }

  ngOnInit() {
    this.getEventPlaceByuserId();
    this.epUserId = localStorage.getItem('token')
  }


  onSubmit() {
    this.formValidation();
  }


  formValidation() {
    this.EpUserMappinErrors.epid = "";
    this.EpUserMappinErrors.epsubid = "";
    this.EpUserMappinErrors.epuserid = "";
    let hasError = false;

    if (this.userMapping.get('epid')?.invalid) {
      this.EpUserMappinErrors.epid = "*EPId is required";
      hasError = true;
    }
    if (this.userMapping.get('epsubid')?.invalid) {
      this.EpUserMappinErrors.epsubid = "*EPSubId is required";
      hasError = true;
    }
    if (this.userMapping.get('epuserid')?.invalid) {
      this.EpUserMappinErrors.epuserid = "*EPUserId is required";
      hasError = true;
    }
    if (!hasError) {
      this.saveUserMapping();
    }
  }

   getEventPlaceByuserId() {
        this.appService
          .getEventPlaceByuserId(localStorage.getItem('token'))
          .pipe(takeUntil(this.destroy$))
          .subscribe((data) => {
            console.log("Event place Dropdwon::", data);
            // @ts-ignore
            this.eventPlaceDetails = data;
            var localEventPlace : string [] = [];
            for (var ep of this.eventPlaceDetails) {
              localEventPlace.push(ep.epname)
            }
            this.eventDropdown = localEventPlace;
          })
      }
      getEventPlaceDetails() {
        console.log("getid::",this.eventId);
        this.appService
          .getEventPlaceDetails(this.eventId)
          .pipe(takeUntil(this.destroy$))
          .subscribe((data) => {
            console.log("GetKitchen Details::", data);
            // @ts-ignore
            this.eventPlaceShow = data;
            for(var event of this.eventPlaceShow) {
              this.userMapping.controls["epname"].setValue(event.epname);
              this.userMapping.controls["epnickname"].setValue(event.epnickname);
              this.userMapping.controls["epimage"].setValue(event.epimage);
              this.userMapping.controls["phonenumber"].setValue(event.phonenumber);
              this.userMapping.controls["emailid"].setValue(event.emailid);
              this.userMapping.controls["epdescription"].setValue(event.epdescription);
              this.userMapping.controls["epaddress1"].setValue(event.epaddress1);
              this.userMapping.controls["epaddress2"].setValue(event.epaddress2);
              this.userMapping.controls["epnearbylandmark"].setValue(event.epnearbylandmark);
              this.userMapping.controls["epcity"].setValue(event.epcity);
              this.userMapping.controls["epstate"].setValue(event.epstate);
              this.userMapping.controls["eppincode"].setValue(event.eppincode);
              this.userMapping.controls["epcountry"].setValue(event.epcountry);
              this.userMapping.controls["activestatus"].setValue(event.activestatus);
              this.userMapping.controls["epaircond"].setValue(event.epaircond);
            }
          })
      }
      getEventSubPlace() {
        this.appService
        //@ts-ignore
          .getEventSubPlace(this.eventId)
          .pipe(takeUntil(this.destroy$))
          .subscribe((data) => {
            console.log("sub dropdown::", data);
            // @ts-ignore
            this.eventSubplaceDetails = data;
            var localEventSubPlace : string [] = [];
            for (var ep of this.eventSubplaceDetails) {
              if (ep.epmainplace == "Yes") {
                localEventSubPlace.push(ep.epsubname + " - Main Hall")
                console.log("main hall", this.eventSubDropDown)
              } else {
               localEventSubPlace.push(ep.epsubname)
              }
              this.eventSubDropDown = localEventSubPlace;
            }
          })
      }

      getSubPlaceDetails() {
        this.appService
          .getEventSubPlaceDropDown(this.eventId, this.eventSubId)
          .pipe(takeUntil(this.destroy$))
          .subscribe((data) => {
            console.log("Get Sub Place Details::", data);
            // @ts-ignore
            this.eventSubplaceShow = data;

            for(var subplace of this.eventSubplaceShow) {
              this.userMapping.controls["epsubname"].setValue(subplace.epsubname);
              this.userMapping.controls["epsubnickname"].setValue(subplace.epsubnickname);
              this.userMapping.controls["phonenumber"].setValue(subplace.phonenumber);
              this.userMapping.controls["epsubemailid"].setValue(subplace.emailid);
              this.userMapping.controls["epsubdesc"].setValue(subplace.epsubdesc);
              this.userMapping.controls["subactivestatus"].setValue(subplace.activestatus);
              this.userMapping.controls["epsubaircond"].setValue(subplace.epsubaircond);
              this.userMapping.controls["epmainplace"].setValue(subplace.epmainplace);
            }
          })
      }
      getEPUser() {
        this.appService
          .getEPUser()
          .pipe(takeUntil(this.destroy$))
          .subscribe((data) => {
            console.log("EP User Dropdwon::", data);
            // @ts-ignore
            this.userdetails = data;
            var localUser : EPUSerDetails [] = [];
            for (var ep of this.userdetails) {
              if(ep.epuserid == this.epUserId){
              localUser.push(ep)
            }
          }
          this.userDropDown = localUser;
          })
      }
      getUserMapping() {
        this.appService
          .getUserMapping(this.eventId, this.eventSubId,this.userId)
          .pipe(takeUntil(this.destroy$))
          .subscribe((data) => {
            console.log("EP Userid Dropdwon::", data);
            // @ts-ignore
            this.getUserMappingId = data;

          })
      }

saveUserMapping(){

      this.userMapping.controls["createdby"].setValue(localStorage.getItem("token"));
      this.userMapping.controls["epid"].setValue(this.eventId);
      this.userMapping.controls["epsubid"].setValue(this.eventSubId);
      this.userMapping.controls["epuserid"].setValue(this.epUserId);
      this.userMapping.controls["activeyn"].setValue("Yes");
      this.userMapping.controls["deleteyn"].setValue("No");
      this.appService.saveUserMapping(this.userMapping.value)
                            .pipe(takeUntil(this.destroy$))
                            .subscribe((data) => {
                                    console.log("saveRooms ::", data);
                                    // @ts-ignore

                                }, (err: any) => {
                                },
                                () => console.log('HTTP request completed.'));

                                this.successAlert();
                              }

  /*EventPlace Dropdown Autofetch function*/
  onShowevent(event) {
    const value = event.target.value;
    console.log("Event Name::", value)
    for (var EPid of this.eventPlaceDetails) {
      if (value == EPid.epname) {
        this.eventId = EPid.epid;
        console.log("ep id::", this.eventId)
        this.isDisabled = true;
        this.getEventSubPlace();
        this.getEventPlaceDetails();
        this.disable();
      } else {
        this.isDisabled = false;
        console.log("ep id:: error")
      }
    }
  }

  disable() {
    if (this.isDisabled) {
      this.userMapping.controls['epsubid'].enable();
    } else {
      this.userMapping.controls['epsubid'].disable();
    }
  }
  /*EventSubPlace Dropdown Autofetch function*/
  onShowSubPlaceDetails(event) {
    const value = event.target.value.replace(" - Main Hall", "");
    console.log("Packing type::", value);
    for (var EPid of this.eventSubplaceDetails) {
      if (value == EPid.epsubname) {
        this.eventSubId = EPid.epsubid;
        console.log("ep id::", this.eventSubDropDown)
        this.isDisabled = true;
        this.getSubPlaceDetails();
        this.getEPUser();
        this.disable1();
      }
      else {
        this.isDisabled = false;
        console.log("ep id:: error")
      }
    }
  }

  disable1() {
    if (this.isDisabled) {
      this.userMapping.controls['epuserid'].enable();
    } else {
      this.userMapping.controls['epuserid'].disable();
    }
  }
  /*usermapping Userdetails Dropdown Autofetch function*/
  onShowUserDetails(event) {
    const value = event.target.value;
    console.log("Event Name::", value)
    for (var EPUesdid of this.userdetails) {
      if (value == EPUesdid.epuserid) {
        this.userId = EPUesdid.epuserid
        console.log("ep id::", this.eventId)
        this.getUserMapping();
        console.log("userdetails::", EPUesdid);
        this.userMapping.controls["epuserid"].setValue(EPUesdid.epuserid);
        this.userMapping.controls["epuserfirstname"].setValue(EPUesdid.epuserfirstname);
        this.userMapping.controls["epuserlastname"].setValue(EPUesdid.epuserlastname);
        this.userMapping.controls["role"].setValue(EPUesdid.role);
        this.userMapping.controls["epuseremailidpy"].setValue(EPUesdid.epuseremailidpy);
        this.userMapping.controls["epuseremailidsy"].setValue(EPUesdid.epuseremailidsy);
        this.userMapping.controls["epuserloginpassword"].setValue(EPUesdid.epuserloginpassword);
        this.userMapping.controls["epuseraddress1"].setValue(EPUesdid.epuseraddress1);
        this.userMapping.controls["epuseraddress2"].setValue(EPUesdid.epuseraddress2);
        this.userMapping.controls["epusercity"].setValue(EPUesdid.epusercity);
        this.userMapping.controls["epuserstate"].setValue(EPUesdid.epuserstate);
        this.userMapping.controls["epuserpincode"].setValue(EPUesdid.epuserpincode);
        this.userMapping.controls["usercountry"].setValue(EPUesdid.usercountry);
      }
    }
  }
  //user mapping successalert popup message function
  successAlert() {
    Swal.fire({
      title: "Your Registration Sucessfully Completed.",
      icon: "success",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.reload();
      }
    });
  }
}
