import { Component, OnInit, HostListener } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { EventPlace } from "@modules/event-place-management/model/eventplace";
import { AppService } from "@services/app.service";
import { pipe, Subject, takeUntil } from "rxjs";
import { EPUSerDetails } from "../model/epuser-details";
import Swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-user-mapping-management',
  templateUrl: './user-mapping-management.component.html',
  styleUrls: ['./user-mapping-management.component.scss']
})
export class UserMappingManagementComponent implements OnInit {
  eventPlace: EventPlace[] = [];
  eventDropdown: string[] = [];
  EPAdminId: string | undefined;
  UserRegistrationDetails: EPUSerDetails[] = [];
  users: EPUSerDetails[]=[];
  currentdetailas : EPUSerDetails[] = [];
  getUserDetails : EventPlace [] = [];
  selectedCheckbox: any;
  buttondisable = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  userStatusShow = false;


//   events = [
//     { eventPlace: '',  address: '', status: '', userName: '', },
//     { eventPlace: '',  address: '', status: '', userName: '', },
//     { eventPlace: '',  address: '', status: '', userName: '', },
//     { eventPlace: '',  address: '', status: '', userName: '', },
//
//   ];

  constructor(private router : Router, private appService: AppService, private toster: ToastrService,
    private spinner : NgxSpinnerService) {

  }

  epUser = new UntypedFormGroup ({
    epuserid : new UntypedFormControl ('', [Validators.required, Validators.nullValidator]),
    epuserfirstname : new UntypedFormControl ('', [Validators.required, Validators.nullValidator]),
    epuserlastname : new UntypedFormControl ('', [Validators.required, Validators.nullValidator]),
    epuseremailidpy : new UntypedFormControl ('', [Validators.required, Validators.nullValidator,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
    epuseremailidsy : new UntypedFormControl ('', [Validators.required, Validators.nullValidator,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
    epuserloginpassword : new UntypedFormControl ('', [Validators.required, Validators.nullValidator,Validators.minLength(8)]),
    epusername: new UntypedFormControl ('', [Validators.required, Validators.nullValidator]),
    epusertype : new UntypedFormControl ('', [Validators.required, Validators.nullValidator]),
    epuseraddress1 : new UntypedFormControl ('', [Validators.required, Validators.nullValidator]),
    epuseraddress2 : new UntypedFormControl ('', [Validators.required, Validators.nullValidator]),
    epusercity : new UntypedFormControl ('', [Validators.required, Validators.nullValidator]),
    epuserstate : new UntypedFormControl ('', [Validators.required, Validators.nullValidator]),
    epuserpincode : new UntypedFormControl ('', [Validators.required, Validators.nullValidator,Validators.pattern("^((\\+91-?)|0)?[0-9]{6}$")]),
    userCountry : new UntypedFormControl ('', [Validators.required, Validators.nullValidator]),
    activeYn : new UntypedFormControl ('', [Validators.required, Validators.nullValidator]),
    deleteYn : new UntypedFormControl ('', [Validators.required, Validators.nullValidator]),
    phonenumber : new UntypedFormControl ('', [Validators.required, Validators.nullValidator, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
    role : new UntypedFormControl ('', [Validators.required, Validators.nullValidator]),
    createdBy : new UntypedFormControl ('', [Validators.required, Validators.nullValidator]),
    modifiedby : new UntypedFormControl ('', [Validators.required, Validators.nullValidator]),
    acknowledgement: new UntypedFormControl ('', [Validators.required, Validators.nullValidator]),
  })


  ngOnInit() {
    if (localStorage.getItem('userRole') == "VSSPL ADMIN") {
      this.userStatusShow = true;
      this.getEventPlace();
      this.getUserRegDetails();
    } else {
      this.getEventPlaceByuserId();
      this.getUserRegDetails();
    }
  }

  onSubmit() {
    }

  usermapping(){
    this.router.navigate(['user-mapping-management']);
  }
  userstatus(){
    this.router.navigate(['user-status']);
  }

  getEventPlace(){
    this.spinner.show()
    this.appService
    .getAllEventPlace()
    .pipe(takeUntil(this.destroy$))
    .subscribe((data) => {
      this.spinner.hide()
    //@ts-ignore
    this.eventPlace = data;
    console.log("EventID::",this.UserRegistrationDetails)
    },(error: any) => {
      this.spinner.hide()
      console.log("Something went wrong");
    })

  }

  getEventPlaceByuserId() {

      this.appService
        .getEventPlaceByuserId(localStorage.getItem('token'))
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          console.log("EPid::", data);
          // @ts-ignore
          this.eventPlace = data;
          console.log("eventplace::",this.eventPlace)
          var localEventPlace: string [] = [];
          for (var ep of this.eventPlace) {
                localEventPlace.push(ep.epname)
              }
            this.eventDropdown = localEventPlace

                },(error: any) => {
                  console.log("Something went wrong");
                })
            }

  getUserRegDetails() {
       this.appService
          .getUserRegDetails()
          .pipe(takeUntil(this.destroy$))
          .subscribe((data) => {
             console.log("EP id::", data);
             // @ts-ignore
             this.UserRegistrationDetails = data;
             var localUser: EPUSerDetails [] = [];
             console.log("UserRegistration::",this.UserRegistrationDetails)
                 for (var user of this.UserRegistrationDetails){
                  if(user.activeyn == "Active" && user.role == "ADMIN"){
                   localUser.push(user)
              }
            }
                    this.users=localUser
          },(error: any) => {
            console.log("Something went wrong");
          })

        }

        // selectCheckbox(id: string, isChecked: boolean) {
        //   console.log("useridgeeting::", id)
        //   console.log("checked::", isChecked)
        //   if (id != null) {
        //     if (isChecked) {
        //       this.selectedCheckbox = id;
        //       console.log("user id getting::", this.selectedCheckbox)
        //       this.buttondisable = true;
        //     } else {
        //       this.buttondisable = false;
        //     }
        //   }
        // }
        selectCheckbox(id: string, isChecked: boolean) {
          console.log("useridgeeting::", id);
          console.log("checked::", isChecked);
          if (isChecked) {
            this.eventPlace.forEach((event) => {
              if (event.epname != id) {
                event.isChecked = false;
              }else{
                event.isChecked = true;
              }
            });
            this.selectedCheckbox = id;
            this.buttondisable = true;
          } else {
            this.selectedCheckbox = null;
            this.buttondisable = false;
          }
        }

    adminselect(event) {
  const value = event.target.value;
  console.log("admindetails::",value)
  for(var Admin of this.users){
    if(value === Admin.epuserfirstname){
      this.EPAdminId = Admin.epuserid;
      console.log("EPAdminId::", this.EPAdminId)
    }
  }
}

mappingfunction(){
  var localcurrentUser: EPUSerDetails [] = [];
  for(var ep of this.UserRegistrationDetails){
    if(ep.epuserid == this.EPAdminId){
      localcurrentUser.push(ep)
      console.log("updae::",localcurrentUser)
      this.currentdetailas = localcurrentUser
      this.updatefunction();
    }
  }
}

updatefunction(){
  this.spinner.show()
  for(var current of this.currentdetailas){
  this.epUser.controls['epuserid'].setValue(current.epuserid);
  this.epUser.controls['createdBy'].setValue(localStorage.getItem('token'))
  this.epUser.controls['epuserfirstname'].setValue(current.epuserfirstname)
  this.epUser.controls['epuserlastname'].setValue(current.epuserlastname)
  this.epUser.controls['epuseremailidpy'].setValue(current.epuseremailidpy)
  this.epUser.controls['epuserloginpassword'].setValue(current.epuserloginpassword)
  // this.epUser.controls['epuserloginid'].setValue(current.epuserloginid)
  this.epUser.controls['epuseraddress1'].setValue(current.epuseraddress1)
  this.epUser.controls['epuseraddress2'].setValue(current.epuseraddress2)
  this.epUser.controls['epusercity'].setValue(current.epusercity)
  this.epUser.controls['epuserpincode'].setValue(current.epuserpincode)
  this.epUser.controls['epuserstate'].setValue(current.epuserstate)
  this.epUser.controls['epusertype'].setValue(current.epusertype)
  this.epUser.controls['modifiedby'].setValue(localStorage.getItem('token'))
  // this.epUser.controls['createddate'].setValue(current.createddate)
  this.epUser.controls['phonenumber'].setValue(current.phonenumber)
  this.epUser.controls['role'].setValue(current.role)
  this.epUser.controls['userCountry'].setValue(current.usercountry)
  this.epUser.controls['activeYn'].setValue(current.activeyn)
  this.epUser.controls['deleteYn'].setValue(current.deleteyn)
  this.epUser.controls['acknowledgement'].setValue('Enable')
  console.log("updateepuser::",this.epUser)
  this.appService
  .updateEPUserDetails(this.epUser.value)
  .pipe(takeUntil(this.destroy$))
  .subscribe((data) => {
    this.spinner.hide()
    console.log("updateEPuserDetails::",data)
    this.successAlert()
  }, (err: any) => {
    this.spinner.hide()
    this.errorAlert();
     },
   () => console.log('HTTP request completed.'));
}
}

mappingButton(){
  console.log("getting::",this.epUser.get("epuserfirstname").value);
    if(this.epUser.get('epuserfirstname').value == "" ){
      this.toster.error("Select the Amdin Name ");
    }else{
      this.MappingAlert();
    }
  }
  // if(this.eventDropdown[0] == ""){
  // this.successAlert()
  // console.log("getting::",this.epUser.get("epuserfirstname").value);
  // }
  // else {
  //   // this.MappingAlert();
  //   console.log("getting::",this.eventDropdown[0]);
  // }


  //UserMapping success popup message
  successAlert() {
    Swal.fire({
      title: "User Mapping Added Successfully",
      icon: "success",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    }).then((result) =>{
      if(result.isConfirmed) {
        window.location.reload();
      }
    });
  }
    //UserMapping errorAlert popup message
  errorAlert() {
    Swal.fire({
      title: "Sorry, Please Try Again Your Registration Process",
      icon: "error",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    });
  }
 
  MappingAlert() {
    Swal.fire({
      title: "Do you want to Add this Admin to your Event Place?",
      showDenyButton: true,
      confirmButtonColor: "#4fab69",
      denyButtonColor: "#c73636",
      confirmButtonText: "Yes",
      denyButtonText: "No",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.mappingfunction()
      }
    });
}



}
