export class AmenitiesClass{
    forEach(arg0: (value: any, index: any) => void) {
        throw new Error('Method not implemented.');
      }
      splice(index: any, arg1: number) {
        throw new Error('Method not implemented.');
      }
      reqid : string;
     amenityactivity : number;
     amtid : string;
     amenitiesrequired : string;
     selectamenities : string;
     qtyrequired : string;
     ameactiveyn : string;
     amedeleteyn : string;
     amecreatedtime : string;
     amemodifiedtime : string;
     amecreatedby : string;
     amemodifiedby : string;
     ameepid : string;
     ameepsubid : string;
     eventid: string;
     selectedamenitiesValue: string;
     selecteactivityValue : string;
}