import { Component, OnInit, HostListener } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { AppService } from "@services/app.service";
import { Router } from "@angular/router";

import { ToastrService } from 'ngx-toastr';
import { pipe, Subject, takeUntil } from "rxjs";
import { EPMapGeneralAmenities } from '@modules/event-place-management/model/epmap-generalamenities';
import { EventPlace } from "@modules/event-place-management/model/eventplace"
import { EventSubPlace } from "@modules/event-place-management/model/eventsub-place";
import { EPGeneralAmenities } from "../model/ep-generalamenities";
import Swal from "sweetalert2";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-general-amenities',
  templateUrl: './general-amenities.component.html',
  styleUrls: ['./general-amenities.component.scss']
})

export class GeneralAmenitiesComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  generalAmenitiesDetails: EPMapGeneralAmenities[] = [];
  eventPlace: EventPlace[] = [];
  eventSubPlace: EventSubPlace[] = [];
  eventDropdown: string[] = [];
  eventSubDropDown: string[] = [];
  epIdDropDown: string | undefined;
  epSubDropDown: string | undefined;
  epAmenitiesDropDown: string | undefined;
  eventGeneralAmenities: EPMapGeneralAmenities[] = [];
  eventAmenitiesDropDown: string[] = [];
  getkitchenDetails: EPGeneralAmenities | undefined;
  public EPID: String;
  isdisabledraft = false;
  isdisablesave = false;
  isdisablenext = false;
  isDisabled: boolean = false;
  isUpdate = false;

  constructor(private router: Router, private appService: AppService, private toastr: ToastrService,
    private spinner : NgxSpinnerService) { }
  epGeneralAmenities = new UntypedFormGroup({
    epid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epsubid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epgenamenitiesid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epgenamenitiesname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epgenamenitiesavailableqty: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epgenamenitiesuom: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epgenamenitiesunitlistprice: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    amenitiesactivestatus: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epgenamenitieschargable: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epgenamenitiesshow: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epgeneralamenitiescharges: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epgenamenitiesdesc: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    activeyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    deleteyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    createdby: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    amenitieschargesrequired: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
  })

  public epGeneralAmenitiesErrors = {
    epid: '',
    epsubid: '',
    epgenamenitiesid: '',
    epgenamenitiesname: '',
    amenitiesactivestatus: '',
    epgenamenitieschargable: '',
    epgeneralamenitiescharges: '',
  }

  ngOnInit() {
    this.getEventPlaceByUserId();
    this.epGeneralAmenities.controls['epid'].disable();
    Object.keys(this.epGeneralAmenities.controls).forEach((controlName) => {
      if (controlName !== 'amenitieschargesrequired') {
        this.epGeneralAmenities.get(controlName).disable();
      }
    })
    // this.isdisablenext = true;

  }
  ngOnDestroy() {
    document.body.style.overflow = 'auto';
  }

  onSubmit() {
    this.formValidation("Active");
  }

//form validation funtion call

  formValidation(status: string) {
    this.epGeneralAmenitiesErrors.epid = "";
    this.epGeneralAmenitiesErrors.epsubid = "";
    this.epGeneralAmenitiesErrors.epgenamenitiesid = "";
    this.epGeneralAmenitiesErrors.epgenamenitiesname = "";
    this.epGeneralAmenitiesErrors.amenitiesactivestatus = "";
    this.epGeneralAmenitiesErrors.epgenamenitieschargable = "";
    this.epGeneralAmenitiesErrors.epgeneralamenitiescharges = "";
    let hasError = false;

    let inputValue = this.epGeneralAmenities.controls['epgenamenitiesname'].value;
    let outputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
    this.epGeneralAmenities.controls['epgenamenitiesname'].setValue(outputValue)


    if (this.epGeneralAmenities.get('epid')?.invalid) {
      this.epGeneralAmenitiesErrors.epid = "*Event Place is required";
      hasError = true;
    }
    if (this.epGeneralAmenities.get('epsubid')?.invalid) {
      this.epGeneralAmenitiesErrors.epsubid = "*Event Sub Place is required";
      hasError = true;
    }

    if (this.epGeneralAmenities.get('epgenamenitiesname')?.invalid) {
      this.epGeneralAmenitiesErrors.epgenamenitiesname = "*Amenities Name is required";
      hasError = true;
    }

    if (this.epGeneralAmenities.get('epgenamenitieschargable')?.invalid) {
      this.epGeneralAmenitiesErrors.epgenamenitieschargable = "*Amenities Chargeable is required";
      hasError = true;
    }
    let inputValue1 = this.epGeneralAmenities.controls['epgenamenitiesdesc'].value;
    let outputValue1 = inputValue1.charAt(0).toUpperCase() + inputValue1.slice(1);
    this.epGeneralAmenities.controls['epgenamenitiesdesc'].setValue(outputValue1)

    if (this.epGeneralAmenities.get('epgeneralamenitiescharges')?.invalid) {
      this.epGeneralAmenitiesErrors.epgeneralamenitiescharges = "*Amenities Charges is required";
      hasError = true;
    }
    if (!hasError) {
      this.saveGeneralAmenities(status);
    }
  }

//EventPlace DropDown API Function

  getEventPlaceByUserId() {
    this.appService
      .getEventPlaceByuserId(localStorage.getItem('token'))
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("Event Place Dropdown::", data);
        // @ts-ignore
        this.eventPlace = data;
        var localEventPlace: string[] = [];
        for (var ep of this.eventPlace) {
          localEventPlace.push(ep.epname)
        }
        this.eventDropdown = localEventPlace
      })
  }

//SubPlace DropDown API Function

  getEventSubPlace() {
    this.appService
      //@ts-ignore
      .getEventSubPlace(this.epIdDropDown)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("Sub Dropdown::", data);
        // @ts-ignore
        this.eventSubPlace = data;
        var localEventSubPlace: string[] = [];
        for (var ep of this.eventSubPlace) {
          if (ep.epmainplace == "Yes") {
            localEventSubPlace.push(ep.epsubname + " - Main Hall")
            console.log("main hall", this.eventSubDropDown)
          } else {
            localEventSubPlace.push(ep.epsubname)
          }
          this.eventSubDropDown = localEventSubPlace
        }
      })
  }

//Amenities DropDown get API function

  getAmenitiesDropDown() {
    this.appService
      .getAmenitiesDropDown(this.epIdDropDown, this.epSubDropDown)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("Test data::", data);
        // @ts-ignore
        this.eventGeneralAmenities = data;
        if (this.eventGeneralAmenities.length > 0) {
          this.epGeneralAmenities.controls['epgenamenitiesid'].enable();
        } else {
          this.epGeneralAmenities.controls['epgenamenitiesid'].disable();
        }
        var localAmenities: string[] = [];
        for (var ep of this.eventGeneralAmenities) {
          localAmenities.push(ep.epgenamenitiesname)
        }
        this.eventAmenitiesDropDown = localAmenities;
      })
  }

//Amenitites Details AutoFetch API Funtion

  getAmenitiesDetails() {
    this.spinner.show()
    this.appService
      .getAmenitiesDetails(this.epIdDropDown, this.epSubDropDown, this.epAmenitiesDropDown)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide()
        console.log("GetAmenities Details::", data);
        // @ts-ignore
        this.generalAmenitiesDetails = data;
        for (var amenities of this.generalAmenitiesDetails) {
          this.isUpdate = true;
          this.epGeneralAmenities.controls["epgenamenitiesname"].setValue(amenities.epgenamenitiesname);
          this.epGeneralAmenities.controls["epgenamenitiesavailableqty"].setValue(amenities.epgenamenitiesavailableqty);
          this.epGeneralAmenities.controls["epgenamenitiesuom"].setValue(amenities.epgenamenitiesuom);
          this.epGeneralAmenities.controls["epgenamenitiesunitlistprice"].setValue(amenities.epgenamenitiesunitlistprice);
          this.epGeneralAmenities.controls["amenitiesactivestatus"].setValue(amenities.amenitiesactivestatus);
          this.epGeneralAmenities.controls["epgenamenitiesshow"].setValue(amenities.epgenamenitiesshow);
          this.epGeneralAmenities.controls["epgenamenitieschargable"].setValue(amenities.epgenamenitieschargable);
          this.epGeneralAmenities.controls["epgenamenitiesdesc"].setValue(amenities.epgenamenitiesdesc);
          this.epGeneralAmenities.controls["epgeneralamenitiescharges"].setValue(amenities.epgeneralamenitiescharges);
        }
      },(error: any) => {
        this.spinner.hide()
        console.log("Something went wrong");
      })
  }

// Save and Update Funtion in Amenities

  saveGeneralAmenities(status: string) {
    this.spinner.show()
    console.log("is update::", this.isUpdate)
    if (this.isUpdate) {
      this.epGeneralAmenities.controls["amenitiesactivestatus"].setValue(status);
      this.epGeneralAmenities.controls["createdby"].setValue(localStorage.getItem("token"));
      this.epGeneralAmenities.controls["epid"].setValue(this.epIdDropDown);
      this.epGeneralAmenities.controls["epsubid"].setValue(this.epSubDropDown);
      this.epGeneralAmenities.controls["epgenamenitiesid"].setValue(this.epAmenitiesDropDown);
      console.log("epamenities details::", this.epGeneralAmenities.value);
      this.appService.updategenamenities(this.epGeneralAmenities.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          this.spinner.hide()
          console.log("updategenamenities details ::", data);
          // @ts-ignore
          if(data.amenitiesactivestatus == "Draft"){
            this.successAlertDarft()
          }else{
          this.UpdateAlert();
          }
        }, (err: any) => {
          this.spinner.hide()
          this.errorAlert();
        },
          () => console.log('HTTP request completed.'));
    }
    else if (!this.isUpdate) {
      console.log("is update::", this.isUpdate)
      this.epGeneralAmenities.controls["amenitiesactivestatus"].setValue(status);
      this.epGeneralAmenities.controls["activeyn"].setValue("Yes");
      this.epGeneralAmenities.controls["deleteyn"].setValue("No");
      this.epGeneralAmenities.controls["createdby"].setValue(localStorage.getItem("token"));
      this.epGeneralAmenities.controls["epid"].setValue(this.epIdDropDown);
      this.epGeneralAmenities.controls["epsubid"].setValue(this.epSubDropDown);
      console.log("Amenities details::", this.epGeneralAmenities.value);
      this.appService.saveGenAmenities(this.epGeneralAmenities.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          this.spinner.hide()
          console.log("AmenitiesDetails ::", data);
          // @ts-ignore
          if(data.amenitiesactivestatus == "Draft"){
            this.successAlertDarft()
          }else{
          this.successAlert();
          }
        }, (err: any) => {
          this.spinner.hide()
          this.errorAlert();
        },
          () => console.log('HTTP request completed.'));
    }
  }
      //genaralamenities required functionality
  genaralamenitiesdisable(event) {
    const value = event.target.value;
    console.log("'general amenities'::", value);
    if (value == "no") {
      this.epGeneralAmenities.disable();
      this.epGeneralAmenities.controls['amenitieschargesrequired'].enable();
      this.isdisablesave = false;
      this.isdisabledraft = false;
      this.isdisablenext = true;
    } else {
      if (value == "yes") {
        this.epGeneralAmenities.enable();
        this.isdisablesave = true;
        this.isdisabledraft = true;
        this.isdisablenext = false;
        this.disable1();
        this.disable();
      }
    }
  }

  draftButton(status: string){
    let hasError = false;

    if (this.epGeneralAmenities.get('epid')?.invalid) {
      this.epGeneralAmenitiesErrors.epid = "*Event place is required";
      hasError = true;
    }
    if (this.epGeneralAmenities.get('epsubid')?.invalid) {
      this.epGeneralAmenitiesErrors.epsubid = "*Event Sub Place is required";
      hasError = true;
    }
    let inputValue = this.epGeneralAmenities.controls['epgenamenitiesname'].value;
    let outputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
    this.epGeneralAmenities.controls['epgenamenitiesname'].setValue(outputValue)


    if (this.epGeneralAmenities.get('epgenamenitiesname')?.invalid) {
      this.epGeneralAmenitiesErrors.epgenamenitiesname = "*Amenities Name is required";
      hasError = true;
    }
      if (!hasError) {
        this.saveGeneralAmenities(status)
   }
  }
  nextButton(){
    this.router.navigate(['/main/activities'])
    }


//EventPlace DropDown AutoFetch Funtion

  onShoweventsub(event) {
    const value = event.target.value;
    console.log("Event Name::", value)
    for (var EPid of this.eventPlace) {
      if (value == EPid.epname) {
        this.epIdDropDown = EPid.epid;
        console.log("ep id::", this.epIdDropDown)
        this.getEventSubPlace();
        this.isDisabled = true;
        this.disable();
      } else {
        this.isDisabled = false;
        console.log("ep id:: error")
      }
    }
  }

  disable() {
    if (this.isDisabled) {
      this.epGeneralAmenities.controls['epsubid'].enable();
    } else {
      this.epGeneralAmenities.controls['epsubid'].disable();
    }
  }

//SubPlace Dropdown autofetch function

  onShowAmenities(event) {
    const value = event.target.value.replace(" - Main Hall", "");
    console.log("Packing type::", value)
    for (var EPid of this.eventSubPlace) {
      if (value == EPid.epsubname) {
        this.epSubDropDown = EPid.epsubid;
        console.log("ep id::", this.epSubDropDown)
        this.getAmenitiesDropDown();
        this.isDisabled = true;
        this.disable1();
      } else {
        this.isDisabled = false;
        console.log("ep id:: error")
      }
    }
  }

  disable1() {
    if (this.isDisabled) {
      this.epGeneralAmenities.controls['epgenamenitiesid'].enable();
    } else {
      this.epGeneralAmenities.controls['epgenamenitiesid'].disable();
    }
  }

//Amenities Details Dropdown AutoFetch function

  onShowAmenitiesdetails(event) {
    const value = event.target.value;
    console.log("Packing type::", value)
    for (var EPid of this.eventGeneralAmenities) {
      if (value == EPid.epgenamenitiesname) {
        this.epAmenitiesDropDown = EPid.epgenamenitiesid;
        console.log("ep id::", this.epSubDropDown);
        this.getAmenitiesDetails();
      }
    }
  }

// Only Allow Numeric Values

  OnlyNumbersAllowed(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

//Amenities Success Alert Popup Message

  successAlert() {
    Swal.fire({
      title: "Your Registration Sucessfully Completed, Do you want to register another Amenities ?",
      icon: "success",
      showDenyButton: true,
      confirmButtonColor: "#4fab69",
      denyButtonColor: "#c73636",
      confirmButtonText: "Yes",
      denyButtonText: "No",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.epGeneralAmenities.controls["epid"].setValue("");
        this.epGeneralAmenities.controls["epsubid"].setValue("");
        this.epGeneralAmenities.controls["epgenamenitiesname"].setValue("");
        this.epGeneralAmenities.controls["epgenamenitiesavailableqty"].setValue("");
        this.epGeneralAmenities.controls["epgenamenitiesuom"].setValue("");
        this.epGeneralAmenities.controls["epgenamenitiesunitlistprice"].setValue("");
        this.epGeneralAmenities.controls["amenitiesactivestatus"].setValue("");
        this.epGeneralAmenities.controls["epgenamenitiesshow"].setValue("");
        this.epGeneralAmenities.controls["epgenamenitieschargable"].setValue("");
        this.epGeneralAmenities.controls["epgenamenitiesdesc"].setValue("");
        this.epGeneralAmenities.controls["epgeneralamenitiescharges"].setValue("");
      }else{
        this.router.navigate(['/main/activities'])
      }
    });
  }

//Amenities successAlertDarft popup message
successAlertDarft() {
  Swal.fire({
    title: "Your Registration successfully saved as draft, Do you want to register another Amenities ?",
    icon: "success",
    showDenyButton: true,
    confirmButtonColor: "#4fab69",
    denyButtonColor: "#c73636",
    confirmButtonText: "Yes",
    denyButtonText: "No",
    showClass: {
      popup: "animate_animated animate_fadeInDown",
    },
    hideClass: {
      popup: "animate_animated animate_fadeOutUp",
    },
  }).then((result) => {
    if (result.isConfirmed) {
      this.epGeneralAmenities.controls["epid"].setValue("");
      this.epGeneralAmenities.controls["epsubid"].setValue("");
      this.epGeneralAmenities.controls["epgenamenitiesname"].setValue("");
      this.epGeneralAmenities.controls["epgenamenitiesavailableqty"].setValue("");
      this.epGeneralAmenities.controls["epgenamenitiesuom"].setValue("");
      this.epGeneralAmenities.controls["epgenamenitiesunitlistprice"].setValue("");
      this.epGeneralAmenities.controls["amenitiesactivestatus"].setValue("");
      this.epGeneralAmenities.controls["epgenamenitiesshow"].setValue("");
      this.epGeneralAmenities.controls["epgenamenitieschargable"].setValue("");
      this.epGeneralAmenities.controls["epgenamenitiesdesc"].setValue("");
      this.epGeneralAmenities.controls["epgeneralamenitiescharges"].setValue("");
    }else{
      this.router.navigate(['/main/activities'])
    }
  });
}
//Amenities Update Alert Popup Message

  UpdateAlert() {
    Swal.fire({
      title: "Your Registration Updated Sucessfully.",
      icon: "success",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.reload();
      }
    });
  }

  errorAlert() {
    Swal.fire({
      title: "Sorry, Please Try Again Your Registration Process",
      icon: "error",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    });
  }
}
