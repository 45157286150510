import { Component, OnInit, HostListener } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { EventPlace } from "@modules/event-place-management/model/eventplace"
import { AppService } from "@services/app.service";
import { EventSubPlace } from "../model/eventsub-place";
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { EPMapActivity } from "@modules/event-place-management/model/epmap-activity";
import { EPActivity } from '@modules/event-place-management/model/ep-activity';
import { pipe, Subject, takeUntil } from "rxjs";
import Swal from "sweetalert2";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss']
})

export class ActivitiesComponent implements OnInit {

  eventPlace: EventPlace[] = [];
  eventSubPlace: EventSubPlace[] = [];
  eventDropdown: string[] = [];
  epIdDropDown: string | undefined;
  eventSubDropDown: string[] = [];
  eventactivityPlace: EPMapActivity[] = [];
  activityDetails: EPActivity[] = [];
  eventactivityDropDown: string[] = [];
  epactivityDropDown: string | undefined;
  epSubDropDown: string | undefined;
  destroy$: Subject<boolean> = new Subject<boolean>();
  isDisabled: boolean = false;
  isUpdate = false;
  isdisabledraft = false;
  isdisablesave = false;
  isdisablenext = false;
  public EPID: String;

  constructor(private router: Router, private appService: AppService, private toastr: ToastrService,
    private spinner : NgxSpinnerService) {

  }

  epactivity = new UntypedFormGroup({
    epid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epsubid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epactivityid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epactivityname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epactivitiescharges: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    activitiesactivestatus: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epactivity3rdparty: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epactivitychargable: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epactivityshow: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epactivitydesc: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    activeyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    deleteyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    createdby: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    Activitesrequired: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
  })

  public epactivityErrors = {
    epid: '',
    epsubid: '',
    epactivityname: '',
    epactivitiescharges: '',
    epactivity3rdparty: '',
    epactivitychargable: '',
    epactivityshow: '',
    activitiesactivestatus: '',
    // epactivitydesc: '',
  }

  ngOnInit() {
    this.getEventPlaceByuserId();
    this.epactivity.controls['epid'].disable();
    Object.keys(this.epactivity.controls).forEach((controlName) => {
      if (controlName !== 'Activitesrequired') {
        this.epactivity.get(controlName).disable();
      }
    })
    // this.isdisablenext = true;
  }

  ngOnDestroy() {
    document.body.style.overflow = 'auto';
  }


  onSubmit() {
    this.formValidation("Active");
  }

  // form validation funtion call

  formValidation(status: string) {
    this.epactivityErrors.epid = "";
    this.epactivityErrors.epsubid = "";
    this.epactivityErrors.epactivityname = "";
    this.epactivityErrors.epactivitiescharges = "";
    this.epactivityErrors.epactivity3rdparty = "";
    this.epactivityErrors.epactivitychargable = "";
    this.epactivityErrors.epactivityshow = "";
    this.epactivityErrors.activitiesactivestatus = "";
    // this.epactivityErrors.epactivitydesc = "";

    let hasError = false;

    let inputValue = this.epactivity.controls['epactivityname'].value;
    let outputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
    this.epactivity.controls['epactivityname'].setValue(outputValue)

    if (this.epactivity.get('epid')?.invalid) {
      this.epactivityErrors.epid = "*Event Place is required";
      hasError = true;
    }

    if (this.epactivity.get('epsubid')?.invalid) {
      this.epactivityErrors.epsubid = "*Event Sub Place is required";
      hasError = true;
    }

    if (this.epactivity.get('epactivityname')?.invalid) {
      this.epactivityErrors.epactivityname = "*Activity Name is required";
      hasError = true;
    }

    if (this.epactivity.get('epactivitiescharges')?.invalid) {
      this.epactivityErrors.epactivitiescharges = "*Activity Charges is required";
      hasError = true;
    }

    if (this.epactivity.get('epactivity3rdparty')?.invalid) {
      this.epactivityErrors.epactivity3rdparty = "*Activity 3rd Party is required";
      hasError = true;
    }

    if (this.epactivity.get('epactivitychargable')?.invalid) {
      this.epactivityErrors.epactivitychargable = "*Activity Chargeable is required";
      hasError = true;
    }

    // if (this.epactivity.get('epactivityshow')?.invalid) {
    //   this.epactivityErrors.epactivityshow = "*Activity Show is required";
    //   hasError = true;
    // }

    // if (this.epactivity.get('activitiesactivestatus')?.invalid) {
    //   this.epactivityErrors.activitiesactivestatus = "*Activity Active Status is required";
    //   hasError = true;
    // }

    // if (this.epactivity.get('epactivitydesc')?.invalid) {
    //   this.epactivityErrors.epactivitydesc = "*Activity Description is required";
    //   hasError = true;
    // }
    if (!hasError) {
      this.saveActivity(status);
    }

  }

  // Event place dropdown API function

  getEventPlaceByuserId() {
    this.appService
      .getEventPlaceByuserId(localStorage.getItem('token'))
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("Event place Dropdwon::", data);
        // @ts-ignore
        this.eventPlace = data;
        var localEventPlace: string[] = [];
        for (var ep of this.eventPlace) {
          localEventPlace.push(ep.epname)
        }
        this.eventDropdown = localEventPlace

      })
  }

  //Sub place dropdown API function

  getEventSubPlace() {
    this.appService
      //@ts-ignore
      .getEventSubPlace(this.epIdDropDown)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("sub dropdown::", data);
        // @ts-ignore
        this.eventSubPlace = data;
        var localEventSubPlace: string[] = [];
        for (var ep of this.eventSubPlace) {
          if (ep.epmainplace == "Yes") {
            localEventSubPlace.push(ep.epsubname + " - Main Hall")
            console.log("main hall", this.eventSubDropDown)
          } else {
            localEventSubPlace.push(ep.epsubname)
          }
          this.eventSubDropDown = localEventSubPlace
        }
      })
  }

  //Activites dropdown get API Function

  getActivityDropDown() {
    this.appService
      .getActivityDropDown(this.epIdDropDown, this.epSubDropDown)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("Test data::", data);
        // @ts-ignore
        this.eventactivityPlace = data;
        if (this.eventactivityPlace.length > 0) {
          this.epactivity.controls['epactivityid'].enable();
        } else {
          this.epactivity.controls['epactivityid'].disable();
        }
        var localActivies: string[] = [];
        for (var ep of this.eventactivityPlace) {
          localActivies.push(ep.epactivityname)
        }
        this.eventactivityDropDown = localActivies;
      })
  }

  //Activites Details autofetch API function

  getActivityDetails() {
    this.spinner.show()
    this.appService
      .getActivityDetails(this.epIdDropDown, this.epSubDropDown, this.epactivityDropDown)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide()
        console.log("GetActivity Details::", data);
        // @ts-ignore
        this.activityDetails = data;
        for (var activity of this.activityDetails) {
          this.isUpdate = true
          this.epactivity.controls["epactivityname"].setValue(activity.epactivityname);
          this.epactivity.controls["epactivitiescharges"].setValue(activity.epactivitiescharges);
          this.epactivity.controls["activitiesactivestatus"].setValue(activity.activitiesactivestatus);
          this.epactivity.controls["epactivity3rdparty"].setValue(activity.epactivity3rdparty);
          this.epactivity.controls["epactivitychargable"].setValue(activity.epactivitychargable);
          this.epactivity.controls["epactivityshow"].setValue(activity.epactivityshow);
          this.epactivity.controls["epactivitydesc"].setValue(activity.epactivitydesc);
        }
      },(error: any) => {
        this.spinner.hide()
        console.log("Something went wrong");
      })
  }

  //EventPLace Dropdown AutoFetch function

  onShoweventsub(event) {
    const value = event.target.value;
    console.log("Event Name::", value)
    for (var EPid of this.eventPlace) {
      if (value == EPid.epname) {
        this.epIdDropDown = EPid.epid;
        console.log("ep id::", this.epIdDropDown)
        this.getEventSubPlace();
        this.isDisabled = true;
        this.disable();
      } else {
        this.isDisabled = false;
        console.log("ep id:: error")
      }
    }
  }

  disable() {
    if (this.isDisabled) {
      this.epactivity.controls['epsubid'].enable();
    } else {
      this.epactivity.controls['epsubid'].disable();
    }
  }

  //SubPlace Details Dropdown AutoFetch function

  onShowActivity(event) {
    const value = event.target.value.replace(" - Main Hall", "");
    console.log("Packing type::", value)
    for (var EPid of this.eventSubPlace) {
      if (value == EPid.epsubname) {
        this.epSubDropDown = EPid.epsubid;
        console.log("ep id::", this.epSubDropDown)
        this.getActivityDropDown();
        this.isDisabled = true;
        this.disable1();
      } else {
        this.isDisabled = false;
        console.log("ep id:: error")
      }
    }
  }

  disable1() {
    if (this.isDisabled) {
      this.epactivity.controls['epactivityid'].enable();
    } else {
      this.epactivity.controls['epactivityid'].disable();
    }
  }

  //Save & update function in Rooms

  saveActivity(status: string) {
    this.spinner.show()
    console.log("is update::", this.isUpdate)
    if (this.isUpdate) {
      this.epactivity.controls["activitiesactivestatus"].setValue(status);
      this.epactivity.controls["activeyn"].setValue("Yes");
      this.epactivity.controls["deleteyn"].setValue("No");
      this.epactivity.controls["createdby"].setValue(localStorage.getItem("token"));
      this.epactivity.controls["epid"].setValue(this.epIdDropDown);
      this.epactivity.controls["epsubid"].setValue(this.epSubDropDown);
      this.epactivity.controls["epactivityid"].setValue(this.epactivityDropDown);
      console.log("activity details::", this.epactivity.value);
      this.appService.updateactivity(this.epactivity.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          this.spinner.hide()
          console.log("updateactivity details ::", data);
          // @ts-ignore
          if(data.activitiesactivestatus == "Draft"){
            this.successAlertDarft();
          }else{
          this.UpdateAlert();
          }
        }, (err: any) => {
          this.spinner.hide()
          this.errorAlert();
        },
          () => console.log('HTTP request completed.'));

    }
    else if (!this.isUpdate) {
      console.log("is update::", this.isUpdate)
      this.epactivity.controls["activitiesactivestatus"].setValue(status);
      this.epactivity.controls["activeyn"].setValue("Yes");
      this.epactivity.controls["deleteyn"].setValue("No");
      this.epactivity.controls["createdby"].setValue(localStorage.getItem("token"));
      this.epactivity.controls["epid"].setValue(this.epIdDropDown);
      this.epactivity.controls["epsubid"].setValue(this.epSubDropDown);
      console.log("Activity details::", this.epactivity.value);
      this.appService.saveactivity(this.epactivity.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          this.spinner.hide()
          console.log("activityDetails ::", data);
          // @ts-ignore
          if(data.activitiesactivestatus == "Draft"){
            this.successAlertDarft();
          }else{
          this.successAlert();
          }
        }, (err: any) => {
          this.spinner.hide()
          this.errorAlert();
        },
          () => console.log('HTTP request completed.'));
    }

  }

  Activitesdisable(event) {
    const value = event.target.value;
    console.log("'Activites'::", value);
    if (value == "no") {
      this.epactivity.disable();
      this.epactivity.controls['Activitesrequired'].enable();
      this.isdisablesave = false;
      this.isdisabledraft = false;
      this.isdisablenext = true;
    } else {
      if (value == "yes") {
        this.epactivity.enable();
        this.isdisablesave = true;
        this.isdisabledraft = true;
        this.isdisablenext = false;
        this.disable1();
        this.disable();
      }
    }
  }

  draftButton(status: string) {
    let hasError = false;

    if (this.epactivity.get('epid')?.invalid) {
      this.epactivityErrors.epid
        = "*Event Place is required";
      hasError = true;
    }

    if (this.epactivity.get('epsubid')?.invalid) {
      this.epactivityErrors.epsubid
        = "*Event Sub Place is required";
      hasError = true;
    }
    let inputValue = this.epactivity.controls['epactivityname'].value;
    let outputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
    this.epactivity.controls['epactivityname'].setValue(outputValue)
    if (this.epactivity.get('epactivityname')?.invalid) {
      this.epactivityErrors.epactivityname = "*Activity Name is required";
      hasError = true;
    }
    if (!hasError) {
      this.saveActivity(status);
    }
  }
  nextButton() {
    this.router.navigate(['/main/incident-charges'])
  }

  //Activites Details autofetch funtion

  onActivitydetails(event) {
    const value = event.target.value;
    console.log("Packing type::", value)
    for (var EPid of this.eventactivityPlace) {
      if (value == EPid.epactivityname) {
        this.epactivityDropDown = EPid.epactivityid;
        console.log("ep id::", this.epSubDropDown)
        this.getActivityDetails();
      }
    }
  }

  // Only allow numeric values

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  //Activites successAlert popup message

  successAlert() {
    Swal.fire({
      title: "Your Registration Sucessfully Completed, Do you want to register another Activities ?",
      icon: "success",
      showDenyButton: true,
      confirmButtonColor: "#4fab69",
      denyButtonColor: "#c73636",
      confirmButtonText: "Yes",
      denyButtonText: "No",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.epactivity.controls["epid"].setValue("");
        this.epactivity.controls["epsubid"].setValue("");
        this.epactivity.controls["epactivityname"].setValue("");
        this.epactivity.controls["epactivitiescharges"].setValue("");
        this.epactivity.controls["activitiesactivestatus"].setValue("");
        this.epactivity.controls["epactivity3rdparty"].setValue("");
        this.epactivity.controls["epactivitychargable"].setValue("");
        this.epactivity.controls["epactivityshow"].setValue("");
        this.epactivity.controls["epactivitydesc"].setValue("");
      } else {
        this.router.navigate(['/main/incident-charges'])
      }
    });
  }

  //Activities successAlertDarft popup message
  successAlertDarft() {
    Swal.fire({
      title: "Your Registration successfully saved as draft, Do you want to register another Activities ?",
      icon: "success",
      showDenyButton: true,
      confirmButtonColor: "#4fab69",
      denyButtonColor: "#c73636",
      confirmButtonText: "Yes",
      denyButtonText: "No",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.epactivity.controls["epid"].setValue("");
        this.epactivity.controls["epsubid"].setValue("");
        this.epactivity.controls["epactivityname"].setValue("");
        this.epactivity.controls["epactivitiescharges"].setValue("");
        this.epactivity.controls["activitiesactivestatus"].setValue("");
        this.epactivity.controls["epactivity3rdparty"].setValue("");
        this.epactivity.controls["epactivitychargable"].setValue("");
        this.epactivity.controls["epactivityshow"].setValue("");
        this.epactivity.controls["epactivitydesc"].setValue("");
      } else {
        this.router.navigate(['/main/incident-charges'])
      }
    });
  }

  // Activites Alert popup message

  UpdateAlert() {
    Swal.fire({
      title: "Your Registration Updated Sucessfully",
      icon: "success",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.reload();
      }
    });
  }
  errorAlert() {
    Swal.fire({
      title: "Sorry, Please Try Again Your Registration Process",
      icon: "error",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    });
  }

}

