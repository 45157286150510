<div class="container-fluid">
  <form (ngSubmit)="onSubmit()" [formGroup]="epGeneralAmenities" id="epGeneralAmenities">
    <div class="col-md-1 mt-1"></div>
    <div class="col-md-11">
      <h4>General Amenities</h4>
    </div>
    <div class="alert alert-light" role="alert">
      Note:<span style="color:red;">*</span> Screen to enter all the required details about your General Amenities.
    </div>
    <div class="form-group row">
      <label class="col-3  col-form-label"><strong>General Amenities Details Required</strong></label>
      <div class="form-check mt-1">
        <input class="form-check-input" type="radio" formControlName="amenitieschargesrequired" value="yes"
          (change)="genaralamenitiesdisable($event)">
        <label class="form-check-label"><b>Yes</b></label>
      </div>
      <div class="form-check mt-1">
        <input class="form-check-input ml-1" type="radio" formControlName="amenitieschargesrequired" value="no"
          (change)="genaralamenitiesdisable($event)">
        <label class="form-check-label ml-4"><b>No</b></label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label class="form-label">Event Place<span style="color:red;">*</span></label>
            <select class="form-control  " formControlName="epid"
              (change)="onShoweventsub($event)">
              <option *ngFor="let parms of eventDropdown" value="{{parms}}">{{parms}}</option>
              <option value="" disabled selected hidden>Select Event place</option>
            </select>
            <span *ngIf="epGeneralAmenitiesErrors.epid" class="error-text">{{epGeneralAmenitiesErrors.epid}}</span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label for="epsubid" class="form-label">Event Sub Place<span style="color:red;">*</span></label>
            <select class="form-control  " id="epsubid" formControlName="epsubid"
              disabled="false" (change)="onShowAmenities($event)">
              <option value="" selected>Select Event Sub Place</option>
              <option *ngFor="let parms of eventSubDropDown" value="{{parms}}">{{parms}}</option>
            </select>
            <span *ngIf="epGeneralAmenitiesErrors.epsubid"
              class="error-text">{{epGeneralAmenitiesErrors.epsubid}}</span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label for="epgenamenitiesid" class="form-label">General Amenities</label>
            <select class="form-control  " id="epgenamenitiesid"
              formControlName="epgenamenitiesid" disabled="false" (change)="onShowAmenitiesdetails($event)">
              <option value="" selected>Select Amenities</option>
              <option *ngFor="let parms of eventAmenitiesDropDown" value="{{parms}}">{{parms}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label>Amenities Name<span style="color:red;">*</span></label>
            <input type="text" class="form-control text-capitalize" id="epgenamenitiesname"
              placeholder="Amenities Name" formControlName="epgenamenitiesname">
            <span *ngIf="epGeneralAmenitiesErrors.epgenamenitiesname"
              class="error-text">{{epGeneralAmenitiesErrors.epgenamenitiesname}}</span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label>Amenities Charges<span style="color:red;">*</span></label>
            <input type="text" class="form-control" id="epgeneralamenitiescharges"
              (keypress)="OnlyNumbersAllowed($event)" placeholder="Amenities Charges "
              formControlName="epgeneralamenitiescharges" maxlength="5">
            <span *ngIf="epGeneralAmenitiesErrors.epgeneralamenitiescharges"
              class="error-text">{{epGeneralAmenitiesErrors.epgeneralamenitiescharges}}</span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label>Amenities Description</label>
            <input class="form-control text-capitalize" id="epgenamenitiesdesc"
              placeholder="Amenities Description " formControlName="epgenamenitiesdesc">
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label class="mr-3">Amenities Chargeable<span style="color:red;">*</span></label>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" value="Yes" name="epgenamenitieschargable"
                id="epgenamenitieschargable" formControlName="epgenamenitieschargable">
              <label class="form-check-label" for="epgenamenitieschargable"><b>Yes</b></label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" value="No" name="epgenamenitieschargable"
                id="epgenamenitieschargable" formControlName="epgenamenitieschargable">
              <label class="form-check-label" for="epgenamenitieschargable"><b>No</b></label>
            </div><br>

            <span *ngIf="epGeneralAmenitiesErrors.epgenamenitieschargable"
              class="error-text">{{epGeneralAmenitiesErrors.epgenamenitieschargable}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center">
      <button type="button" class="btn btn-primary" (click)="draftButton('Draft')" *ngIf="isdisabledraft">Save as Draft
      </button>
      <button type="submit" class="btn btn-success m-3" *ngIf="isdisablesave">{{this.isUpdate == true ? "Update" :
        "Save"}}</button>
      <button type="submit" class="btn btn-primary m-3" (click)="nextButton()" *ngIf="isdisablenext">Next</button>
    </div>
    <div class="row mt-5"></div>
  </form>
</div>
<ngx-spinner>
  <div class="custom-loader"></div>
</ngx-spinner>