<div class="container-fluid">
  <!--<div class="col-md-10">
      <h3 class="font-italic my-3">Event Place Registration</h3>-->
  <form (ngSubmit)="onSubmit()" [formGroup]="eventPlace" id="eventPlace">
    <div class="col-md-2 mt-2"></div>
    <div class="col-md-11">
      <h4 class="">Event Place Registration</h4>
    </div>
    <div class="alert alert-light" role="alert">
      Note:<span style="color:red;">*</span> Screen to enter all the required details about your Event Place.
    </div>
    <div class="row mt-4">
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label for="epname" class="form-label">Name<span style="color:red;">*</span></label>
            <input type="text" class="form-control text-capitalize" id="epname" formControlName="epname"
              placeholder="Name">
            <div *ngIf="eventplacementErrors.epname" class="error-text">{{eventplacementErrors.epname}}
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label for="epnickname">Nick Name<span style="color:red;">*</span></label>
            <input type="text" class="form-control text-capitalize" id="epnickname" formControlName="epnickname"
              placeholder="Nick Name">
            <div *ngIf="eventplacementErrors.epnickname" class="error-text">
              {{eventplacementErrors.epnickname}}</div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label>Logo<span style="color:red;">*</span></label>
            <div class="form-check">
              <label>
                <input type="radio" class="form-check-input" formControlName="logotype" id="square" value="square"
                  checked="checked" (change)="onShapeChanged('square')">Square(100*100px)
              </label>
            </div>
            <div class="form-check">
              <label>
                <input type="radio" class="form-check-input" formControlName="logotype" id="rectangle" value="rectangle"
                  (change)="onShapeChanged('rectangle')">Rectangle(200*100px)
              </label>
            </div>
            <div class="form-check">
              <label>
                <input type="radio" class="form-check-input" formControlName="logotype" id="circle" value="circle"
                  (change)="onShapeChanged('circle')">Circle(100*100px)
              </label>
              <div class="pt-3 d-flex justify-content-center">
                <div *ngIf="isSquare" id="imagesUrl" class="card"
                  style="width: 100px; height: 100px; margin-top: -120px; margin-left: 100px;">
                  <div *ngIf="images" style="width: 100px; height: 100px;">
                    <img class="image-preview" [src]="images" width="100" height="100">
                  </div>
                </div>
                <div *ngIf="isRectangle" id="imagesUrl" class="card"
                  style="width: 200px; height: 100px; margin-top: -120px; margin-left: 100px;">
                  <div *ngIf="images" style="width: 200px; height: 100px;">
                    <img class="image-preview" [src]="images" width="200" height="100">
                  </div>
                </div>
                <div *ngIf="isCircle" id="imagesUrl" class="card"
                  style="width: 100px; height: 100px; margin-top: -120px; margin-left: 100px; border-radius: 50%">
                  <div *ngIf="images" style="width: 100px; height: 100px; border-radius: 50%">
                    <img class="image-preview circle" [src]="images" width="100" height="100">
                  </div>
                </div>
              </div>
            </div>

            <div class="custom-file">
              <input type="file" class="custom-file-input" id="epimage" (change)="onFileChange($event)"
                formControlName="epimage">
              <div *ngIf="eventplacementErrors.epimage" class="error-text">
                {{eventplacementErrors.epimage}}
              </div>
              <label class="custom-file-label" for="epimage">Choose file</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label for="phonenumber">Phone Number<span style="color:red;">*</span></label>
            <input (keypress)="keyPress($event, 'phone')" type="text" class="form-control" id="phonenumber"
              formControlName="phonenumber" placeholder="Phone Number" maxlength="10">
            <div *ngIf="eventplacementErrors.phonenumber" class="error-text">
              {{eventplacementErrors.phonenumber}}</div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label for="emailid">Email Address<span style="color:red;">*</span></label>
            <input type="email" class="form-control" id="emailid" formControlName="emailid" placeholder="Email Address">
            <div *ngIf="eventplacementErrors.emailid" class="error-text">{{eventplacementErrors.emailid}}
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label for="epdescription">Description</label>
            <textarea class="form-control text-capitalize" id="epdescription" formControlName="epdescription"
              placeholder="Description"></textarea>
            <!--<div *ngIf="eventplacementErrors.epdescription" class="error-text">{{eventplacementErrors.epdescription}}</div>-->
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label for="epaddress1">Address Line 1<span style="color:red;">*</span></label>
            <textarea class="form-control" id="epaddress1" formControlName="epaddress1"
              placeholder="Address Line 1"></textarea>
            <div *ngIf="eventplacementErrors.epaddress1" class="error-text">
              {{eventplacementErrors.epaddress1}}</div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label for="epaddress2">Address Line 2<span style="color:red;">*</span></label>
            <textarea class="form-control" id="epaddress2" formControlName="epaddress2"
              placeholder="Address Line 2"></textarea>
            <span *ngIf="eventplacementErrors.epaddress2" class="error-text">{{eventplacementErrors.epaddress2}}</span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label for="epnearbylandmark">Landmark<span style="color:red;">*</span></label>
            <input type="text" class="form-control" id="epnearbylandmark" formControlName="epnearbylandmark"
              placeholder="Landmark">
            <span *ngIf="eventplacementErrors.epnearbylandmark"
              class="error-text">{{eventplacementErrors.epnearbylandmark}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label for="epcity">City<span style="color:red;">*</span></label>
            <!-- <input type="text" class="form-control text-capitalize" id="epcity" formControlName="epcity"
                   placeholder="City"> -->
            <select class="form-control text-capitalize" id="epcity" formControlName="epcity" placeholder="City">
              <option value="Chennai">Chennai</option>
              <option value="Coimbatore">Coimbatore</option>
              <option value="Madurai">Madurai</option>
              <option value="Trichy">Trichy</option>
              <option value="Salem">Salem</option>
              <option value="Vellore">Vellore</option>
              <option value="Tirunelveli">Tirunelveli</option>
              <option value="Erode">Erode</option>
              <option value="Thanjavur">Thanjavur</option>
              <option value="Karur">Karur</option>
              <option value="Nagercoil">Nagercoil</option>
              <option value="Hosur">Hosur</option>
              <option value="Ooty">Ooty</option>
              <option value="Kanyakumari">Kanyakumari</option>
              <option value="Pollachi">Pollachi</option>
              <option value="Cuddalore">Cuddalore</option>
              <option value="Tiruvannamalai">Tiruvannamalai</option>
              <option value="Kovilpatti">Kovilpatti</option>
              <option value="Nagapattinam">Nagapattinam</option>
              <option value="Dindigul">Dindigul</option>
              <option value="Thoothukudi">Thoothukudi</option>
              <option value="Kanchipuram">Kanchipuram</option>
              <option value="Perambalur">Perambalur</option>
              <option value="Virudhunagar">Virudhunagar</option>
              <option value="Sivakasi">Sivakasi</option>
              <option value="Tambaram">Tambaram</option>
              <option value="Ambattur">Ambattur</option>
              <option value="Avadi">Avadi</option>
              <option value="Tiruvallur">Tiruvallur</option>
              <option value="Tiruppur">Tiruppur</option>
              <option value="Dharmapuri">Dharmapuri</option>
              <option value="Krishnagiri">Krishnagiri</option>
              <option value="Arakkonam">Arakkonam</option>
              <option value="Vaniyambadi">Vaniyambadi</option>
            </select>
            <span *ngIf="eventplacementErrors.epcity" class="error-text">{{eventplacementErrors.epcity}}</span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label for="epstate">State<span style="color:red;">*</span></label>
            <!-- <input type="text" class="form-control text-capitalize" id="epstate" formControlName="epstate"
                   placeholder="State"> -->
            <select class="form-control text-capitalize" id="epstate" formControlName="epstate" placeholder="State">
              <option value="Andhra Pradesh">Andhra Pradesh</option>
              <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
              <option value="Arunachal Pradesh">Arunachal Pradesh</option>
              <option value="Assam">Assam</option>
              <option value="Bihar">Bihar</option>
              <option value="Chandigarh">Chandigarh</option>
              <option value="Chhattisgarh">Chhattisgarh</option>
              <option value="Dadar and Nagar Haveli">Dadar and Nagar Haveli</option>
              <option value="Daman and Diu">Daman and Diu</option>
              <option value="Delhi">Delhi</option>
              <option value="Lakshadweep">Lakshadweep</option>
              <option value="Puducherry">Puducherry</option>
              <option value="Goa">Goa</option>
              <option value="Gujarat">Gujarat</option>
              <option value="Haryana">Haryana</option>
              <option value="Himachal Pradesh">Himachal Pradesh</option>
              <option value="Jammu and Kashmir">Jammu and Kashmir</option>
              <option value="Jharkhand">Jharkhand</option>
              <option value="Karnataka">Karnataka</option>
              <option value="Kerala">Kerala</option>
              <option value="Madhya Pradesh">Madhya Pradesh</option>
              <option value="Maharashtra">Maharashtra</option>
              <option value="Manipur">Manipur</option>
              <option value="Meghalaya">Meghalaya</option>
              <option value="Mizoram">Mizoram</option>
              <option value="Nagaland">Nagaland</option>
              <option value="Odisha">Odisha</option>
              <option value="Punjab">Punjab</option>
              <option value="Rajasthan">Rajasthan</option>
              <option value="Sikkim">Sikkim</option>
              <option value="Tamil Nadu">Tamil Nadu</option>
              <option value="Telangana">Telangana</option>
              <option value="Tripura">Tripura</option>
              <option value="Uttar Pradesh">Uttar Pradesh</option>
              <option value="Uttarakhand">Uttarakhand</option>
              <option value="West Bengal">West Bengal</option>
            </select>
            <span *ngIf="eventplacementErrors.epstate" class="error-text">{{eventplacementErrors.epstate}}</span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label for="eppincode">PIN Code<span style="color:red;">*</span></label>
            <input (keypress)="keyPress($event, 'pincode')" type="text" class="form-control" id="eppincode"
              formControlName="eppincode" placeholder="PIN Code" maxlength="6">
            <span *ngIf="eventplacementErrors.eppincode" class="error-text">{{eventplacementErrors.eppincode}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label for="epcountry">Country<span style="color:red;">*</span></label>
            <input class="form-control" type="text" formControlName="epcountry" id="epcountry">
            <span *ngIf="eventplacementErrors.epcountry" class="error-text">{{eventplacementErrors.epcountry}}</span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label>Event Place Registration No</label>
            <input type="text" class="form-control" formControlName="eventregistrationno"
              placeholder="Event Place Registration No">
            <!-- <span *ngIf="eventplacementErrors.eventregistrationno" class="error-text">{{eventplacementErrors.eventregistrationno}}</span> -->
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label>You have Sub Place? <span style="color:red;">*</span></label>
            <select class="form-control" id="subplaceavailable" formControlName="subplaceavailable">
              <option value="" selected>Select Yes/No</option>
              <option>Yes</option>
              <option>No</option>
            </select>
            <span *ngIf="eventplacementErrors.subplaceavailable"
              class="error-text">{{eventplacementErrors.subplaceavailable}}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label class="mr-3">GST No</label>
            <input type="text" class="form-control" (ngModelChange)="onGSTNo($event)" formControlName="gstno"
              placeholder="GST No">
            <!-- <span *ngIf="eventplacementErrors.epgstno" class="error-text">{{eventplacementErrors.epgstno}}</span> -->
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label>GST %</label>
            <input (keypress)="numberOnly($event)" type="text" disabled="false" class="form-control"
              formControlName="gst" placeholder="GST %">
            <!-- <span *ngIf="eventplacementErrors.gst" class="error-text">{{eventplacementErrors.gst}}</span> -->
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label>Pancard No</label>
            <input type="text" class="form-control" formControlName="pancardno" placeholder="Pancard No">
            <!-- <span *ngIf="eventplacementErrors.pancardno" class="error-text">{{eventplacementErrors.pancardno}}</span> -->
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label class="mr-3">Air Conditioned<span style="color:red;">*</span>:</label>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" value="Yes" name="epaircond" formControlName="epaircond">
              <label class="form-check-label">Yes</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" value="No" name="epaircond" formControlName="epaircond">
              <label class="form-check-label">No</label>
            </div>
            <br>
            <span *ngIf="eventplacementErrors.epaircond" class="error-text">{{eventplacementErrors.epaircond}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="card-body text-center">
        <button type="button" class="btn btn-primary mr-3" (click)="formValidation1()"><span style="margin:center;">Save
            As Draft</span>
        </button>
        <button type="submit" class="btn btn-success ml-4"><span style="margin:center;">{{this.isUpdate == true ?
            "Update" : "Save"}}</span></button>
        <button type="button" class="btn btn-primary ml-5" *ngIf="nextButton" (click)="Navigate()"><span
            style="margin:center;">Next</span></button>
      </div>
    </div>
    <div class="row mt-4"></div>
    <div class="row mt-4"></div>
    <div class="row mt-4"></div>
    <!--<div class="row mb-3">
    <div class="col-12 text-center">
      <button type="submit" class="btn btn-primary">{{this.isUpdate == true ? "Update" : "Save"}}</button>
    </div>
  </div>-->
  </form>

  <div [style.display]="isVisible ? 'block' : 'none'" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <h4 class="modal-title mt-3" style="text-align: center">
          <span style="border-bottom: 3px double;">NOTE</span>
        </h4>
        <div class="modal-header">
          <h6 class="modal-title">
            <!--<p style="font-size: 90%;">To update your existing event place details please select the name in below dropdown and click 'ok'
              For new registration, please skip this message by clicking 'close' button</p>-->
            <li style="font-size: 90%;">
              To update your existing event place details please select the name in below dropdown and click 'Ok'
            </li>
            <li style="font-size: 90%;">
              For new registration, please skip this message by clicking 'Close' button
            </li>
          </h6>
        </div>
        <div class="modal-body">
          <select class="form-control slct shadow-none " id="epid" formControlName="epid"
            (change)="onShowevent($event)">
            <option value="" disabled selected>Select Event place</option>
            <option *ngFor="let parms of eventDropdown" value="{{parms}}">{{parms}}</option>
          </select>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="saveType1()">Close
          </button>
          <button type="button" class="btn btn-primary" (click)="saveType()">Ok</button>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner>
  <div class="custom-loader"></div>
</ngx-spinner>