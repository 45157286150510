import { Component, OnInit, HostListener } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { AppService } from "@services/app.service";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Subject, takeUntil } from "rxjs";
import { BookingareaDataService } from "../model/bookingareaDataService";
import { EventPaymentDetails } from "../model/event-payment-details";
import { EventPlace } from "@modules/event-place-management/model/eventplace";
import { EventPlaceBooking } from "../model/eventplace-booking";
import { DatePipe } from "@angular/common";

@Component({
  selector: 'app-event-booking-payment-print',
  templateUrl: './event-booking-payment-print.component.html',
  styleUrls: ['./event-booking-payment-print.component.scss']
})

export class EventBookingPaymentPrintComponent implements OnInit {
  roomdisable = false;
  kitchendisable = false;
  openspacedisable = false;
  parkingdisable = false;
  activitydisable = false;
  amenitiesdisable = false;
  incidentaldisable = false;
  miscdisable = false;
  othersdisable = false;
  getPaymentDetails: EventPaymentDetails | undefined;
  getAllBookingDetails: EventPlaceBooking[] = []
  getbookingDetails: EventPlaceBooking[] = [];
  eventAutoDetails: EventPlace[] = []
  eventPlaceDetails: EventPlaceBooking[] = []
  eventhallpayment: string = ""
  roomcharges: string = ""
  kitchencharges: string = ""
  openspacecharges: string = ""
  parkingcharges: string = ""
  activitycharges: string = ""
  amenitiescharges: string = ""
  incidentalcharges: string = ""
  misccharges: string = ""
  totalcapacity: string = ""
  totalamountpay: string = ""
  cgst: string = ""
  sgst: string = ""
  totalinclusiveoftax: string = ""
  advancepaid: string = ""
  netvaluetopay: string = ""
  EPId: string = ""
  eventId: string = ""
  customername: string = ""
  myDate = new Date();
  currentDate: string = "";
  BillNo: string = ""
  advancepaidDisable = false;
  emptydisable = true;
  destroy$: Subject<boolean> = new Subject<boolean>();
  othercharges: string = "";



  constructor(private dataService: BookingareaDataService, private appService: AppService, private router: Router,
    private datePipe: DatePipe) {
    //@ts-ignore
    this.myDate = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
  }
  eventPlace = new UntypedFormGroup({
    epid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    phonenumber: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    emailid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
  })

  bookingpaymentprint = new UntypedFormGroup({
    contactperson: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    emailid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    address: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    phonenumber2: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    activeyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    deleteyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    createdby: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
  })


  eventbookingpaymentprint = new UntypedFormGroup({
    eventhallpayment: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    roomcharges: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    kitchencharges: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    openspacecharges: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    parkingcharges: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    activitycharges: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    amenitiescharges: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    incidentalcharges: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    misccharges: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    totalcapacity: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    totalamountpay: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    cgst: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    sgst: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    totalinclusiveoftax: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    advancepaid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    netvaluetopay: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    activeyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    deleteyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    createdby: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    othercharges: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
  })



  ngOnInit() {
    if (this.dataService.paymentdata != undefined) {
      console.log("Event payment data::", this.dataService.paymentdata)
      console.log("EventBooking::", this.dataService.bookingData)
      //@ts-ignore
      this.getPaymentDetails = this.dataService.paymentdata
      console.log("Event payment::", this.getPaymentDetails)
      //@ts-ignore
      this.getbookingDetails = this.dataService.bookingData
      console.log("Event booking::", this.getbookingDetails)
      this.advancepaidDisable = true;
      this.emptydisable = false;
      for (let ep of this.getbookingDetails) {
        console.log("Event booking123::", ep)
        this.bookingpaymentprint.controls['contactperson'].setValue(ep.contactperson)
        this.bookingpaymentprint.controls['emailid'].setValue(ep.emailid)
        this.bookingpaymentprint.controls['phonenumber2'].setValue(ep.phonenumber2)
        this.bookingpaymentprint.controls['address'].setValue(ep.address)
        const datetimeString = ep.createdtime;
        const datePart = datetimeString.split(' ')[0];
        this.currentDate = this.datePipe.transform(datePart, 'dd-MM-yyyy');
      }
      this.EPId = this.getPaymentDetails.epid;
      this.eventId = this.getPaymentDetails.eventid;
      this.getEventPlaceDetails()
      this.getEventPlaceLogo();
      this.eventhallpayment = Number(this.getPaymentDetails.eventhallpayment).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.roomcharges = Number(this.getPaymentDetails.roomcharges).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      if (this.roomcharges == null || this.roomcharges == "") {
        this.roomdisable = false
      } else {
        this.roomdisable = true
      }
      this.kitchencharges = Number(this.getPaymentDetails.kitchencharges).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      if (this.kitchencharges == null || this.kitchencharges == "") {
        this.kitchendisable = false
      } else {
        this.kitchendisable = true
      }
      this.openspacecharges = Number(this.getPaymentDetails.openspacecharges).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      if (this.openspacecharges == null || this.openspacecharges == "") {
        this.openspacedisable = false
      } else {
        this.openspacedisable = true
      }
      this.parkingcharges = Number(this.getPaymentDetails.parkingcharges).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      if (this.parkingcharges == null || this.parkingcharges == "") {
        this.parkingdisable = false
      } else {
        this.parkingdisable = true
      }
      this.activitycharges = Number(this.getPaymentDetails.activitycharges).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      if (this.activitycharges == null || this.activitycharges == "") {
        this.activitydisable = false
      } else {
        this.activitydisable = true
      }
      this.amenitiescharges = Number(this.getPaymentDetails.amenitiescharges).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      if (this.amenitiescharges == null || this.amenitiescharges == "") {
        this.amenitiesdisable = false
      } else {
        this.amenitiesdisable = true
      }
      console.log("ameenities::", this.amenitiescharges)
      this.incidentalcharges = Number(this.getPaymentDetails.incidentalcharges).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      if (this.incidentalcharges == null || this.incidentalcharges == "") {
        this.incidentaldisable = false
      } else {
        this.incidentaldisable = true
      }
      this.misccharges = Number(this.getPaymentDetails.misccharges).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      if (this.misccharges == null || this.misccharges == "") {
        this.miscdisable = false
      } else {
        this.miscdisable = true
      }
      this.othercharges = Number(this.getPaymentDetails.othercharges).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      if (this.othercharges == null || this.othercharges == "") {
        this.othersdisable = false
      } else {
        this.othersdisable = true
      }
      this.totalcapacity = this.getPaymentDetails.totalcapacity.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.totalamountpay = Number(this.getPaymentDetails.totalamountpay).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.cgst = this.getPaymentDetails.cgst.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.sgst = this.getPaymentDetails.sgst.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.totalinclusiveoftax = this.getPaymentDetails.totalinclusiveoftax.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.advancepaid = Number(this.getPaymentDetails.advancepaid).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.netvaluetopay = this.getPaymentDetails.netvaluetopay.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.getBillNo()
      this.printupadteNavigate()
      // setTimeout(function () {
      //   window.print();
      // }, 2000);
      // setTimeout(() => {
      //   this.router.navigate(["event-bookinglist"]);
      // }, 5000);
    } else {
      console.log("Event payment data::", this.dataService.savepaymentdata)
      //@ts-ignore
      this.getPaymentDetails = this.dataService.savepaymentdata
      console.log("Event payment::", this.getPaymentDetails)
      this.EPId = this.getPaymentDetails.epid;
      this.eventId = this.getPaymentDetails.eventid;
      this.advancepaidDisable = false;
      this.emptydisable = true;
      console.log("Event id::", this.getPaymentDetails)
      this.getEventPlaceDetails();
      this.getEventPlaceLogo();
      this.eventhallpayment = Number(this.getPaymentDetails.eventhallpayment).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.roomcharges = Number(this.getPaymentDetails.roomcharges).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      if (this.roomcharges == null || this.roomcharges == "") {
        this.roomdisable = false
      } else {
        this.roomdisable = true
      }
      this.kitchencharges = Number(this.getPaymentDetails.kitchencharges).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      if (this.kitchencharges == null || this.kitchencharges == "") {
        this.kitchendisable = false
      } else {
        this.kitchendisable = true
      }
      this.openspacecharges = Number(this.getPaymentDetails.openspacecharges).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      if (this.openspacecharges == null || this.openspacecharges == "") {
        this.openspacedisable = false
      } else {
        this.openspacedisable = true
      }
      this.parkingcharges = Number(this.getPaymentDetails.parkingcharges).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      if (this.parkingcharges == null || this.parkingcharges == "") {
        this.parkingdisable = false
      } else {
        this.parkingdisable = true
      }
      this.activitycharges = Number(this.getPaymentDetails.activitycharges).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      if (this.activitycharges == null || this.activitycharges == "") {
        this.activitydisable = false
      } else {
        this.activitydisable = true
      }
      this.amenitiescharges = Number(this.getPaymentDetails.amenitiescharges).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      if (this.amenitiescharges == null || this.amenitiescharges == "") {
        this.amenitiesdisable = false
      } else {
        this.amenitiesdisable = true
      }
      console.log("ameenities::", this.amenitiescharges)
      this.incidentalcharges = Number(this.getPaymentDetails.incidentalcharges).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      if (this.incidentalcharges == null || this.incidentalcharges == "") {
        this.incidentaldisable = false
      } else {
        this.incidentaldisable = true
      }
      this.misccharges = Number(this.getPaymentDetails.misccharges).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      if (this.misccharges == null || this.misccharges == "") {
        this.miscdisable = false
      } else {
        this.miscdisable = true
      }
      this.othercharges = Number(this.getPaymentDetails.othercharges).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      if (this.othercharges == null || this.othercharges == "") {
        this.othersdisable = false
      } else {
        this.othersdisable = true
      }
      this.totalcapacity = this.getPaymentDetails.totalcapacity.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.totalamountpay = Number(this.getPaymentDetails.totalamountpay).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.cgst = this.getPaymentDetails.cgst.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.sgst = this.getPaymentDetails.sgst.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.totalinclusiveoftax = this.getPaymentDetails.totalinclusiveoftax.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.advancepaid = Number(this.getPaymentDetails.advancepaid).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.netvaluetopay = this.getPaymentDetails.netvaluetopay.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.getEventList();
      this.getBillNo();
      this.printAndNavigate()
    }
  }

  printAndNavigate() {
    setTimeout(() => {
      window.print();
  
      setTimeout(() => {
        this.router.navigate(["dash"]);
      }, 5000);
    }, 2000);
  }

  printupadteNavigate() {
    setTimeout(() => {
      window.print();
  
      setTimeout(() => {
        this.router.navigate(["event-bookinglist"]);
      }, 5000);
    }, 2000);
  }
  


  onSubmit() {
  }

  getEventPlaceDetails() {
    console.log("getid::", this.EPId);
    this.appService
      .getEventPlaceDetails(this.EPId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("Evenplace Details::", data);
        // @ts-ignore
        this.eventAutoDetails = data;
        for (var event of this.eventAutoDetails) {
          this.eventPlace.controls["epname"].setValue(event.epname);
          this.eventPlace.controls["phonenumber"].setValue(event.phonenumber);
          this.eventPlace.controls["emailid"].setValue(event.emailid);
        }
      })
  }

  getEventPlaceLogo() {
    this.appService.getEventPlaceLogo(this.EPId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: Blob) => {
        const imageBlob: Blob = data;
        const imageUrl = URL.createObjectURL(imageBlob);
        this.displayImage(imageUrl);
        console.log("Logo Get", imageBlob)
      }, (error: any) => {
        console.log("something went wrong", error)
      })
  }

  displayImage(imageUrl: string) {
    const imgElement = document.createElement('img');
    imgElement.src = imageUrl;
    const imagePlace = document.getElementById("imageUrl")
    imagePlace.appendChild(imgElement)
    // document.body.appendChild(imgElement);
  }

  //Get EventBooking details
  getEventList() {

    this.appService
      .getEventList(localStorage.getItem('token'))
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("EP id::", data);
        // @ts-ignore
        this.getAllBookingDetails = data;
        var localGetData: EventPlaceBooking[] = []
        console.log("eventbookinglist::", this.getbookingDetails)
        for (let ep of this.getAllBookingDetails) {
          if (this.eventId == ep.eventid) {
            localGetData.push(ep)
            console.log("getid::", localGetData)
          }
        }
        this.getbookingDetails = localGetData
        console.log("getdata::", this.getAllBookingDetails)
      })

  }

  getBillNo() {
    const tempBillNo: string = this.eventId;
    const numericPart: string = tempBillNo.slice(4);

    const formattedNumericValue: string = numericPart.replace(/^0+/, '');

    const baseBillNoUlr: string = tempBillNo.slice(3, 3);
    const updatedBillNo: string = `${baseBillNoUlr}${formattedNumericValue}`;

    console.log("Updated Sample No:", updatedBillNo);
    this.BillNo = updatedBillNo;
    console.log("Final Sample No:", this.BillNo);
  }

}









