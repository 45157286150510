<!-- <div class="float-right d-none d-sm-block"><b>Version</b> {{ appVersion }}</div> -->
<strong>
    <span>Support - support@msservicesinc.com | +0431 - 2351100 | </span>
    <a
        href="https://varnikcloud.com/"
        target="_blank"
        rel="noopener noreferrer"
        style="margin: 0"
    >
    www.varnikcloud.com</a
    >
</strong>
<!-- <span> All rights reserved.</span> -->
