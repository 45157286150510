import {Component, OnInit, HostListener, OnDestroy} from "@angular/core";
import {Router, RouterLink} from "@angular/router";
import {AppService} from "@services/app.service";
import {FormControl, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import { Subject, takeUntil } from "rxjs";
import {EventPlaceBooking} from "@modules/ep-booking-management/model/eventplace-booking";
import {BookingareaDataService} from "@modules/ep-booking-management/model/bookingareaDataService";
import { NgxSpinnerService } from "ngx-spinner";
import { EventPlace } from "@modules/event-place-management/model/eventplace";
import { EventSubPlace } from "@modules/event-place-management/model/eventsub-place";
import Swal from "sweetalert2";



@Component({
  selector: 'app-event-bookinglist',
  templateUrl: './event-bookinglist.component.html',
  styleUrls: ['./event-bookinglist.component.scss']
})

export class EventBookingListComponent implements OnInit, OnDestroy{

  searchText
  eventId: string | undefined;
  epIdDropDown: string | undefined;
 eventBooking: EventPlaceBooking [] = [];
 eventPlaceDetails: EventPlaceBooking [] = [];
  eventDropdown: string[] = [];
  localEventId: String[] = [];
  EventId : string = ""
  destroy$: Subject<boolean> = new Subject<boolean>();
  currentData: EventPlaceBooking | undefined;
  eventPlace: EventPlace [] = [];
  eventSubPlace: EventSubPlace[] = [];
  isSubDisabled = false;
  eventPlaceName: string | undefined;
  eventsubPlaceName: string = "";
  eventSubDropDown: string[] = [];
  filteredEventPlaceDetails: any[] = []; 
  bookingDetails : EventPlaceBooking[] = [];
  inputValue : string = ""
  isSelectDisabled = true;
  EventPlaceBooking = new UntypedFormGroup({
    
    eventid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epsubid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    eventname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    eventsubname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    startdate: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    starthalf1: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    starthalf2: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    enddate: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    endhalf1: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    endhalf2: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    typeoffunction: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    noofmembers: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    contactperson: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    phonenumber1: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    phonenumber2: new UntypedFormControl('', [Validators.required, Validators.nullValidator, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
    customerid : new UntypedFormControl('',[Validators.required, Validators.nullValidator]),
    emailid: new UntypedFormControl('', [Validators.required, Validators.nullValidator, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
    address: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    activeyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    deleteyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    createdby: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    createddate: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    otherOption: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    eventbookingtype: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    searchText: new FormControl('')

  })

  // tableData = [
  //   { column1: 'Row 1', column2: 'Data 1', column3: 'Info 1' },
  //   { column1: 'Row 2', column2: 'Data 2', column3: 'Info 2' },
  //   { column1: 'Row 3', column2: 'Data 3', column3: 'Info 3' }
  // ];

  constructor(private router: Router,private appService: AppService,
              private dataService: BookingareaDataService,private spinner : NgxSpinnerService) {

  }


  booking(){
    this.router.navigate(['eventplace-booking'])
  }
  bookinglist(){
    this.router.navigate(['event-bookinglist'])
  }


  ngOnInit() {
    this.getEventList();
    this.getEventPlaceByuserId();
  }


  onSubmit() {

  }

    //EventPlace dropdown details autofetch function
    getEventPlaceByuserId() {
      this.appService
        .getEventPlaceByuserId(localStorage.getItem('token'))
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          console.log("Event place Dropdwon::", data);
          // @ts-ignore
          this.eventPlace = data;
          var localEventPlace: string[] = [];
          for (var ep of this.eventPlace) {
            localEventPlace.push(ep.epname)
          }
          this.eventDropdown = localEventPlace
        })
    }

  getEventSubPlace() {
    this.appService
      .getEventSubPlace(this.epIdDropDown)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("sub dropdown::", data);
        // @ts-ignore
        this.eventSubPlace = data;
        var localeventsubplace : string [] = [];
        for (var ep of this.eventSubPlace) {
          if (ep.epmainplace == "Yes") {
            localeventsubplace.push(ep.epsubname + " - Main Hall")
            console.log("main hall", this.eventSubDropDown)
          } else {
            localeventsubplace.push(ep.epsubname)
          }
          this.eventSubDropDown = localeventsubplace
        }
      },(error: any) => {
				console.log("Something went wrong");
			  })
  }

  getEventList() {

    this.appService
      .getEventList(localStorage.getItem('token'))
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("EP id::", data);
        // @ts-ignore
        this.eventPlaceDetails = data;
        // this.filteredEventPlaceDetails = this.eventPlaceDetails;
        console.log("eventbookinglist::",this.eventPlaceDetails)
        var localUser: EventPlaceBooking [] = []
        for(var event of this.eventPlaceDetails){
          if(event.activeyn == "Yes"){
          localUser.push(event)
          this.bookingDetails = localUser
          }
        }
        this.filteredEventPlaceDetails = localUser.sort((a,b) => {
          const idA = parseInt(a.eventid.split('-')[1]);
          const idB = parseInt(b.eventid.split('-')[1]);

          // Compare the numeric IDs in descending order
          return idB - idA;
        })

      })

  }

  disable() {
    if (this.isSubDisabled) {
      this.EventPlaceBooking.controls['epsubid'].enable();
    } else {
      this.EventPlaceBooking.controls['epsubid'].disable();
    }
  }

  onShowevent(event) {
    const value = event.target.value;
    console.log("Event Name::", value)
     
    for (var EPid of this.eventPlace) {
      if (value == EPid.epname) {
        this.epIdDropDown = EPid.epid;
        this.eventPlaceName = EPid.epname;        
        this.getEventSubPlace();
        this.isSelectDisabled = false;
        this.disable();
      } else {
        this.isSubDisabled = true;
        console.log("ep id:: error")
      }
    }
    var localDetails : EventPlaceBooking [] = []
    for(let re of this.bookingDetails){
      if(this.eventPlaceName == re.eventname){
        localDetails.push(re)
        console.log("geteventfilter::",localDetails)
      }
    }
    this.filteredEventPlaceDetails = localDetails.sort((a,b) => {
      const idA = parseInt(a.eventid.split('-')[1]);
      const idB = parseInt(b.eventid.split('-')[1]);
      return idB - idA;
    })
  }


  getEventPlace(event) {
    const value = event.target.value.replace(" - Main Hall", "");
    console.log("geteventfilter::",value)
    // this.filteredEventPlaceDetails = this.eventPlaceDetails.filter(eventPlace => eventPlace.eventsubname === value);
    for (var EPsubid of this.eventSubPlace) {
      if (value == EPsubid.epsubname) {
        this.eventsubPlaceName = EPsubid.epsubname;        
      } else {
        this.isSubDisabled = false;
        console.log("ep id:: error")
      }
    }
    var localDetails : EventPlaceBooking [] = []
    for(let re of this.bookingDetails){
      if(this.eventsubPlaceName == re.eventsubname){
        localDetails.push(re)
        console.log("geteventfilter::",localDetails)
      }
    }
    this.filteredEventPlaceDetails = localDetails.sort((a,b) => {
      const idA = parseInt(a.eventid.split('-')[1]);
      const idB = parseInt(b.eventid.split('-')[1]);
      return idB - idA;
    })
  }

  getEvent(eventId: String) {
    console.log("select Event::", eventId);
    var localEvent: EventPlaceBooking[] = [];
    for (let event of this.eventPlaceDetails) {
      if (eventId == event.eventid) {
        localEvent.push(event);

        this.localEventId.push(event.eventid);
        // @ts-ignore
        this.currentData = localEvent;
        this.localEventId.sort();
       this.localEventId = this.localEventId.filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        });
        
        console.log("current data::", this.currentData);
        this.router.navigate(["/eventplace-booking"]);
      }
    }
  }

  getEventCancel(eventId : String) {
    var localEventDetails : EventPlaceBooking [] = [];
    for (let event of this.eventPlaceDetails){
      if(eventId == event.eventid){
        localEventDetails.push(event)
        this.EventPlaceBooking.controls['eventid'].setValue(event.eventid)
        this.EventPlaceBooking.controls['epid'].setValue(event.epid)
        this.EventPlaceBooking.controls['epsubid'].setValue(event.epsubid)
        this.EventPlaceBooking.controls['eventname'].setValue(event.eventname)
        this.EventPlaceBooking.controls['eventsubname'].setValue(event.eventsubname)
        this.EventPlaceBooking.controls['startdate'].setValue(event.startdate)
        this.EventPlaceBooking.controls['starthalf1'].setValue(event.starthalf1)
        this.EventPlaceBooking.controls['starthalf2'].setValue(event.starthalf2)
        this.EventPlaceBooking.controls['enddate'].setValue(event.enddate)
        this.EventPlaceBooking.controls['endhalf1'].setValue(event.endhalf1)
        this.EventPlaceBooking.controls['endhalf2'].setValue(event.endhalf2)
        this.EventPlaceBooking.controls['typeoffunction'].setValue(event.typeoffunction)
        this.EventPlaceBooking.controls['noofmembers'].setValue(event.noofmembers)
        this.EventPlaceBooking.controls['phonenumber1'].setValue(event.phonenumber1)
        this.EventPlaceBooking.controls['phonenumber2'].setValue(event.phonenumber2)
        this.EventPlaceBooking.controls['emailid'].setValue(event.emailid)
        this.EventPlaceBooking.controls['address'].setValue(event.address)
        this.EventPlaceBooking.controls['createdby'].setValue(event.createdby)
        this.EventPlaceBooking.controls['createddate'].setValue(event.createdtime)
        this.DeleteAlert()
      }
    }
     console.log("getdate::",this.EventPlaceBooking.value)
  }

updateEventDetails(){
  this.EventPlaceBooking.controls['activeyn'].setValue("No")
  this.EventPlaceBooking.controls['deleteyn'].setValue("Yes")
this.appService.updateEventDetails(this.EventPlaceBooking.value)
.pipe(takeUntil(this.destroy$))
.subscribe((data) => {
console.log("UpdateData::",data)
var localdata : EventPlaceBooking [] = []
localdata.push(data)
console.log("UpdateData112::",localdata)
this.successAlert()
},(err : any) =>{
console.log("Something went Wrong")
this.errorAlert()
})
}

  ngOnDestroy() {
    this.dataService.bookingData = this.currentData;
    console.log("data service data:", this.dataService.bookingData);
  }


  successAlert() {
    Swal.fire({
      title: "Your Booking Details are Cancelled",
      icon: "success",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    }).then((result) =>{
      if(result.isConfirmed) {
        window.location.reload()
      }
    });
  }

  errorAlert() {
    Swal.fire({
      title: "Something Went Wrong",
      icon: "error",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    }).then((result) =>{
      if(result.isConfirmed) {
        window.location.reload()
      }
    });
  }


  DeleteAlert() {
    Swal.fire({
      title: "Do you want to Cancel this Booking details?",
      showDenyButton: true,
      confirmButtonColor: "#4fab69",
      denyButtonColor: "#c73636",
      confirmButtonText: "Yes",
      denyButtonText: "No",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.updateEventDetails()
      }
    });
}


}









