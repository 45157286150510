import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { EventPlace } from "@modules/event-place-management/model/eventplace"
import { AppService } from "@services/app.service";
import { EventSubPlace } from "../model/eventsub-place";
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { EPMapParking } from "../model/epmap-parking";
import { EPParking } from '@modules/event-place-management/model/ep-parking';
import { pipe, Subject, takeUntil } from "rxjs";
import Swal from "sweetalert2";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
	selector: 'app-parking-area',
	templateUrl: './parking-area.component.html',
	styleUrls: ['./parking-area.component.scss']
})

export class ParkingAreaComponent implements OnInit {
	eventPlace: EventPlace[] = [];
	eventSubPlace: EventSubPlace[] = [];
	eventParkingArea: EPParking[] = [];
	eventDropdown: string[] = [];
	epIdDropDown: string | undefined;
	eventSubDropDown: string[] = [];
	eventParkingPlace: EPMapParking[] = [];
	parkingDetails: EPParking[] = [];
	eventParkingDropDown: string[] = [];
	epParkingDropDown: string | undefined;
	epSubDropDown: string | undefined;
	destroy$: Subject<boolean> = new Subject<boolean>();
	isDisabled: boolean = false;
	isUpdate = false;
	localParking: string[];
	isdisablesave = false;
  isdisabledraft = false;
  isdisablenext = false;

	constructor(private router: Router, private appService: AppService, private toastr: ToastrService,
		private spinner : NgxSpinnerService) {

	}

	epparking = new UntypedFormGroup({
		epid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
		epsubid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
		epparkingid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
		epparkingname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
		epparkingbicyclecapacity: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
		epparking2wheelercapacity: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
		epparking4wheelercapacity: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
		epparkingtruckcapacity: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
		parkingactivestatus: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
		epparkingchargable: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
		epparkingshow: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
		epparkingareacharges: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
		epparkingdesc: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
		activeyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
		deleteyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
		createdby: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
		parkingarearequired: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
	})

	public epparkingErrors = {
		epid: '',
		epsubid: '',
		epparkingname: '',
		epparkingbicyclecapacity: '',
		epparking2wheelercapacity: '',
		epparking4wheelercapacity: '',
		epparkingtruckcapacity: '',
		//parkingactivestatus: '',
		epparkingchargable: '',
		//epparkingshow: '',
		epparkingareacharges: '',
		//epparkingdesc: '',
	}

	ngOnInit() {
		this.getEventPlaceByuserId();
		this.epparking.controls['epid'].disable();
        Object.keys(this.epparking.controls).forEach((controlName) => {
          if (controlName !== 'parkingarearequired') {
            this.epparking.get(controlName).disable();
          }
        })
        // this.isdisablenext = true;

      }
	ngOnDestroy() {
      document.body.style.overflow = 'auto';
    }


	onSubmit() {
		this.formValidation("Active");
	}

	/* parking Area formvalidation function */
	formValidation(status: string) {
		this.epparkingErrors.epid = "";
		this.epparkingErrors.epsubid = "";
		this.epparkingErrors.epparkingname = "";
		this.epparkingErrors.epparkingbicyclecapacity = "";
		this.epparkingErrors.epparking2wheelercapacity = "";
		this.epparkingErrors.epparking4wheelercapacity = "";
		this.epparkingErrors.epparkingtruckcapacity = "";
		//this.epparkingErrors.parkingactivestatus = "";
		this.epparkingErrors.epparkingchargable = "";
		//this.epparkingErrors.epparkingshow = "";
		this.epparkingErrors.epparkingareacharges = "";
		//this.epparkingErrors.epparkingdesc = "";


		let hasError = false;

		let inputValue = this.epparking.controls['epparkingname'].value;
		let outputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
		this.epparking.controls['epparkingname'].setValue(outputValue)

		if (this.epparking.get('epid')?.invalid) {
			this.epparkingErrors.epid = "*Event place is required";
			hasError = true;
		}

		if (this.epparking.get('epsubid')?.invalid) {
			this.epparkingErrors.epsubid = "*Event Subplace is required";
			hasError = true;
		}

		if (this.epparking.get('epparkingname')?.invalid) {
			this.epparkingErrors.epparkingname = "*Parking Area Name is required";
			hasError = true;
		}

		if (this.epparking.get('epparkingbicyclecapacity')?.invalid) {
			this.epparkingErrors.epparkingbicyclecapacity = "*Parking Bi-Cycle Capacity is required";
			hasError = true;
		}

		if (this.epparking.get('epparking2wheelercapacity')?.invalid) {
			this.epparkingErrors.epparking2wheelercapacity = "*Parking Two Wheeler Capacity is required";
			hasError = true;
		}

		if (this.epparking.get('epparking4wheelercapacity')?.invalid) {
			this.epparkingErrors.epparking4wheelercapacity = "*Parking Four Wheeler Capacity is required";
			hasError = true;
		}

		if (this.epparking.get('epparkingtruckcapacity')?.invalid) {
			this.epparkingErrors.epparkingtruckcapacity = "*Parking Truck Capacity is required";
			hasError = true;
		}

// 		if (this.epparking.get('parkingactivestatus')?.invalid) {
// 			this.epparkingErrors.parkingactivestatus = "*Parking Active Status is required";
// 			hasError = true;
// 		}

		if (this.epparking.get('epparkingchargable')?.invalid) {
			this.epparkingErrors.epparkingchargable = "*Parking Chargable is required";
			hasError = true;
		}

// 		if (this.epparking.get('epparkingshow')?.invalid) {
// 			this.epparkingErrors.epparkingshow = "*Parking Visibility is required";
// 			hasError = true;
// 		}

		if (this.epparking.get('epparkingareacharges')?.invalid) {
			this.epparkingErrors.epparkingareacharges = "*Parking Charges is required";
			hasError = true;
		}

// 		if (this.epparking.get('epparkingdesc')?.invalid) {
// 			this.epparkingErrors.epparkingdesc = "*Parking Description is required";
// 			hasError = true;
// 		}
		//console.log(hasError);
		if (!hasError) {
			this.saveParkingArea(status);
		}
	}

	/*EventPlace dropdown details API function*/
	getEventPlaceByuserId() {
		this.appService
			.getEventPlaceByuserId(localStorage.getItem('token'))
			.pipe(takeUntil(this.destroy$))
			.subscribe((data) => {
				console.log("Event place Dropdwon::", data);
				// @ts-ignore
				this.eventPlace = data;
				var localEventPlace: string[] = [];
				for (var ep of this.eventPlace) {
					localEventPlace.push(ep.epname)
				}
				this.eventDropdown = localEventPlace;
			})
	}

	/*parking area dropdown details API function*/
	getParkingAreaDropDown() {
		this.appService
			.getParkingAreaDropDown(this.epIdDropDown, this.epSubDropDown)
			.pipe(takeUntil(this.destroy$))
			.subscribe((data) => {
				console.log("Test data::", data);
				// @ts-ignore
				this.eventParkingArea = data;
				if (this.eventParkingArea.length > 0) {
					this.epparking.controls['epparkingid'].enable();
				} else {
					this.epparking.controls['epparkingid'].disable();
				}
				var localParking: string[] = [];
				for (var ep of this.eventParkingArea) {
					localParking.push(ep.epparkingname)
				}
				this.eventParkingDropDown = localParking;
			})
	}

	/*Parking area all details Autofetch API function*/
	getParkingAreaDetails() {
		this.spinner.show()
		this.appService
			.getParkingAreaDetails(this.epIdDropDown, this.epSubDropDown, this.epParkingDropDown)
			.pipe(takeUntil(this.destroy$))
			.subscribe((data) => {
				console.log("ParkingArea Details::", data);
				this.spinner.hide()
				// @ts-ignore
				this.parkingDetails = data;
				for (var parking of this.parkingDetails) {
					this.isUpdate = true;
					this.epparking.controls["epparkingname"].setValue(parking.epparkingname);
					this.epparking.controls["epparkingbicyclecapacity"].setValue(parking.epparkingbicyclecapacity);
					this.epparking.controls["epparking2wheelercapacity"].setValue(parking.epparking2wheelercapacity);
					this.epparking.controls["epparking4wheelercapacity"].setValue(parking.epparking4wheelercapacity);
					this.epparking.controls["epparkingtruckcapacity"].setValue(parking.epparkingtruckcapacity);
					this.epparking.controls["parkingactivestatus"].setValue(parking.parkingactivestatus);
					this.epparking.controls["epparkingchargable"].setValue(parking.epparkingchargable);
					this.epparking.controls["epparkingshow"].setValue(parking.epparkingshow);
					this.epparking.controls["epparkingareacharges"].setValue(parking.epparkingareacharges);
					this.epparking.controls["epparkingdesc"].setValue(parking.epparkingdesc);
				}
			},(error: any) => {
				this.spinner.hide()
				console.log("Something went wrong");
			  })
	}

	/*EventSubPlace dropdown details API function*/
	getEventSubPlace() {
		this.appService
			//@ts-ignore
			.getEventSubPlace(this.epIdDropDown)
			.pipe(takeUntil(this.destroy$))
			.subscribe((data) => {
				console.log("sub dropdown::", data);
				// @ts-ignore
				this.eventSubPlace = data;
				var localEventSubPlace: string[] = [];
				for (var ep of this.eventSubPlace) {
					if (ep.epmainplace == "Yes") {
						localEventSubPlace.push(ep.epsubname + " - Main Hall")
						console.log("main hall", this.eventSubDropDown)
					} else {
						localEventSubPlace.push(ep.epsubname)
					}
					this.eventSubDropDown = localEventSubPlace;
				}

			})
	}

	/*EventPlace Dropdown Autofetch function*/
	onShoweventsub(event) {
		const value = event.target.value;
		console.log("Event Name::", value)
		for (var EPid of this.eventPlace) {
			if (value == EPid.epname) {
				this.epIdDropDown = EPid.epid;
				console.log("ep id::", this.epIdDropDown)
				this.getEventSubPlace();
				this.isDisabled = true;
				this.disable();
			} else {
				this.isDisabled = false;
				console.log("ep id:: error")
			}
		}
	}
	disable() {
		if (this.isDisabled) {
			this.epparking.controls['epsubid'].enable();
		} else {
			this.epparking.controls['epsubid'].disable();
		}
	}

	/*EventSubPlace Dropdown Autofetch function*/
	onShowParkingArea(event) {
		const value = event.target.value.replace(" - Main Hall", "");
		for (var EPid of this.eventSubPlace) {
			console.log("Packing type1::", value)
			if (value == EPid.epsubname) {
				this.epSubDropDown = EPid.epsubid;
				console.log("ep id::", this.epSubDropDown)
				this.getParkingAreaDropDown();
				this.isDisabled = true;
				this.disable1();
			} else {
				this.isDisabled = false;
				console.log("ep id:: error")
			}
		}
	}
	disable1() {
		if (this.isDisabled) {
			this.epparking.controls['epparkingid'].enable();
		} else {
			this.epparking.controls['epparkingid'].disable();
		}
	}

	/*Parkingarea Dropdown Autofetch function*/
	onShowOpenParkingAreadetails(event) {
		const value = event.target.value;
		console.log("Packing type::", value)
		for (var EPid of this.eventParkingArea) {
			if (value == EPid.epparkingname) {
				this.epParkingDropDown = EPid.epparkingid;
				console.log("ep id::", this.epSubDropDown)
				this.getParkingAreaDetails();
			}
		}
	}

	/* parking area save and update api functionality */
	saveParkingArea(status: string) {
		this.spinner.show()
		console.log("is update::", this.isUpdate)
		if (this.isUpdate) {
		  this.epparking.controls["parkingactivestatus"].setValue(status);
			this.epparking.controls["createdby"].setValue(localStorage.getItem("token"));
			this.epparking.controls["activeyn"].setValue("Yes");
			this.epparking.controls["deleteyn"].setValue("No");
			this.epparking.controls["epid"].setValue(this.epIdDropDown);
			this.epparking.controls["epsubid"].setValue(this.epSubDropDown);
			this.epparking.controls["epparkingid"].setValue(this.epParkingDropDown);
			console.log("parking details::", this.epparking.value);
			this.appService.updateParkingArea(this.epparking.value)
				.pipe(takeUntil(this.destroy$))
				.subscribe((data) => {
					this.spinner.hide()
					console.log("parking details ::", data);
					// @ts-ignore
					if(data.parkingactivestatus == "Draft"){
						this.successAlertDarft()
					}else{
					this.UpdateAlert();
					}
				}, (err: any) => {
					this.spinner.hide()
					this.errorAlert();
				},
					() => console.log('HTTP request completed.'));
					this.isdisablenext =true;
		} else if (!this.isUpdate) {
			console.log("is update::", this.isUpdate)
			this.epparking.controls["parkingactivestatus"].setValue(status);
			this.epparking.controls["createdby"].setValue(localStorage.getItem("token"));
			this.epparking.controls["activeyn"].setValue("Yes");
			this.epparking.controls["deleteyn"].setValue("No");
			this.epparking.controls["epid"].setValue(this.epIdDropDown);
			this.epparking.controls["epsubid"].setValue(this.epSubDropDown);
			console.log("parking details::", this.epparking.value);
			this.appService.saveParkingArea(this.epparking.value)
				.pipe(takeUntil(this.destroy$))
				.subscribe((data) => {
					this.spinner.hide()
					console.log("EventParking ::", data);
					// @ts-ignore
					if(data.parkingactivestatus == "Draft"){
						this.successAlertDarft()
					}else{
					this.successAlert();
					}
				}, (err: any) => {
					this.spinner.hide()
					this.errorAlert();
				},
					() => console.log('HTTP request completed.'));			
			// this.isdisablenext =true;
		}
	}

	parkingareadisable(event) {
      const value = event.target.value;
      console.log("'parking area'::", value);
      if (value == "no") {
        this.epparking.disable();
        this.epparking.controls['parkingarearequired'].enable();
        this.isdisablesave = false;
        this.isdisabledraft = false;
        this.isdisablenext = true;
      } else {
        if (value == "yes") {
          this.epparking.enable();
          this.isdisablesave = true;
          this.isdisabledraft = true;
          this.isdisablenext = false;
          this.disable1();
          this.disable();
        }
      }
    }

    draftButton(status: string){
      let hasError = false;

        if (this.epparking.get('epid')?.invalid) {
            this.epparkingErrors.epid
                = "*Event place is required";
            hasError = true;
        }

        if (this.epparking.get('epsubid')?.invalid) {
            this.epparkingErrors.epsubid = "*Event sub Place is required";
            hasError = true;
        }

        let inputValue = this.epparking.controls['epparkingname'].value;
             let outputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
             this.epparking.controls['epparkingname'].setValue(outputValue)

        if (this.epparking.get('epparkingname')?.invalid) {
            this.epparkingErrors.epparkingname = "*Parking Area Name is required";
            hasError = true;
        }
        if (!hasError) {
          this.saveParkingArea(status)
     }
    }

    nextButton(){
    this.router.navigate(['/main/general-amenities'])
    }

	/* parking area only allowed numeric value function */
	numberOnly(event): boolean {
		const charCode = (event.which) ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;

	}


	/* parking area successAlert popup message function */
	successAlert() {
		Swal.fire({
			title: "Your Registration Sucessfully Completed, Do you want to register another Parking Area ?",
			icon: "success",
			showDenyButton: true,
            confirmButtonColor: "#4fab69",
            denyButtonColor: "#c73636",
            confirmButtonText: "Yes",
            denyButtonText: "No",
			showClass: {
				popup: "animate_animated animate_fadeInDown",
			},
			hideClass: {
				popup: "animate_animated animate_fadeOutUp",
			},
		}).then((result) => {
			if (result.isConfirmed) {
				this.epparking.controls["epid"].setValue("");
				this.epparking.controls["epsubid"].setValue("");
				this.epparking.controls["epparkingname"].setValue("");
				this.epparking.controls["epparkingbicyclecapacity"].setValue("");
				this.epparking.controls["epparking2wheelercapacity"].setValue("");
				this.epparking.controls["epparking4wheelercapacity"].setValue("");
				this.epparking.controls["epparkingtruckcapacity"].setValue("");
				this.epparking.controls["parkingactivestatus"].setValue("");
				this.epparking.controls["epparkingchargable"].setValue("");
				this.epparking.controls["epparkingshow"].setValue("");
				this.epparking.controls["epparkingareacharges"].setValue("");
				this.epparking.controls["epparkingdesc"].setValue("");	
			}else{
				this.router.navigate(['/main/general-amenities'])
			}
		});
	}

//Parking Area successAlertDarft popup message
successAlertDarft() {
    Swal.fire({
      title: "Your Registration successfully saved as draft, Do you want to register another Parking Area ?",
      icon: "success",
      showDenyButton: true,
      confirmButtonColor: "#4fab69",
      denyButtonColor: "#c73636",
      confirmButtonText: "Yes",
      denyButtonText: "No",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    }).then((result) => {
     if (result.isConfirmed) {
				this.epparking.controls["epid"].setValue("");
				this.epparking.controls["epsubid"].setValue("");
				this.epparking.controls["epparkingname"].setValue("");
				this.epparking.controls["epparkingbicyclecapacity"].setValue("");
				this.epparking.controls["epparking2wheelercapacity"].setValue("");
				this.epparking.controls["epparking4wheelercapacity"].setValue("");
				this.epparking.controls["epparkingtruckcapacity"].setValue("");
				this.epparking.controls["parkingactivestatus"].setValue("");
				this.epparking.controls["epparkingchargable"].setValue("");
				this.epparking.controls["epparkingshow"].setValue("");
				this.epparking.controls["epparkingareacharges"].setValue("");
				this.epparking.controls["epparkingdesc"].setValue("");	
			}else{
				this.router.navigate(['/main/general-amenities'])
			}
    });
  }
	/* parking area UpdateAlert popup message function */
	UpdateAlert() {
		Swal.fire({
			title: "Your Registration Updated Sucessfully.",
			icon: "success",
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "OK",
			showClass: {
				popup: "animate_animated animate_fadeInDown",
			},
			hideClass: {
				popup: "animate_animated animate_fadeOutUp",
			},
		}).then((result) => {
			if (result.isConfirmed) {
				window.location.reload();
			}
		});
	}
		/* parking area successAlertDraft popup message function */

	successAlertDraft() {
    Swal.fire({
      title: "Your details saved as a draft",
      icon: "success",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    }).then((result) =>{
      if(result.isConfirmed) {
        this.epparking.reset()
      }
    });
  }

   //Parkingarea errorAlert popup message
   errorAlert() {
    Swal.fire({
      title: "Sorry, Please Try Again Your Registration Process",
      icon: "error",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    });
  }
}
