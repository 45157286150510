<div class="container-fluid sticky-top">
  <app-event-header></app-event-header>
  <nav class="navbar  navbar-light bg-light"  style="height: 45px;">
    <div class="col-md-2">
  <a class="nav-link" routerLink="/user-mapping-management" data-toggle="tooltip"
  data-placement="top" title="User Mapping Id Creates to Event Place">User Mapping</a>
</div>
<div class="col-md-2">
  <a class="nav-link" routerLink="/user-status" data-toggle="tooltip"
  data-placement="top" title="User Status Approval Screen">User Status</a>
  </div>
  <div class="col-md-8"></div>
</nav>
  </div>
  <div class="container">
    <form (ngSubmit)="onSubmit()" [formGroup]="events" id="events">
  <div class="row mt-3"></div>
    <div style="text-align: center" *ngIf="buttondisable">
      <button class="btn btn-primary" style="margin: 5px" type="button" (click)="UpdateAlert('Active')"> Active</button>
      <button class="btn btn-danger" style="margin: 5px" type="button" (click)="UpdateDeActiveAlert('In Active')">Deactive</button>
    </div>
  </form>

    <div class="row mt-3"></div>
    <div class="card table-container">
      <table class="table table-hover mb-0">
      <thead class="sticky-top">
        <tr class="fixed-row">
          <th scope="col"></th>
          <th scope="col">User Id</th>
          <th scope="col">User Name</th>
          <th scope="col">User Type</th>
          <th scope="col">Email Id</th>
          <th scope="col">Phone No</th>
          <th scope="col">Status</th>
        </tr>
      </thead>
      <tbody class="table-bordered">
      <tr *ngFor="let event of users" class="text-center">
        <td><input type="checkbox"  (change)="selectCheckbox(event.epuserid, $event.target.checked)" [checked]= "event.isChecked" /></td>
        <td>{{event.epuserid}}</td>
        <td>{{event.epuserfirstname}}</td>
        <td>{{event.role}}</td>
        <td>{{event.epuseremailidpy}}</td>
        <td>{{event.phonenumber}}</td>
        <td>{{event.activeyn}}</td>
      </tr>
    </tbody>
    </table>
  </div>
  </div>
  <app-event-footer></app-event-footer>
  <ngx-spinner>
    <div class="custom-loader"></div>
  </ngx-spinner>
