<div class="container-fluid">
  <form (ngSubmit)="onSubmit()" [formGroup]="epIncidentCharges" id="epIncidentCharges">
    <div class="col-md-1 mt-1"></div>
    <div class="col-md-11">
      <h4>Incident Charges</h4>
    </div>
    <div class="alert alert-light" role="alert">
      Note:<span style="color:red;">*</span> Screen to enter all the required details about your Incident Charges.
    </div>
    <div class="row">
      <label class="col-3  col-form-label"><strong>Incident Charges Details Required</strong></label>
      <div class="form-check mt-1">
        <input class="form-check-input" type="radio" formControlName="incidentchargesrequired" value="yes"
          (change)="incidentchargesdisable($event)">
        <label class="form-check-label"><b>Yes</b></label>
      </div>
      <div class="form-check mt-1">
        <input class="form-check-input ml-1" type="radio" formControlName="incidentchargesrequired" value="no"
          (change)="incidentchargesdisable($event)">
        <label class="form-check-label ml-4"><b>No</b></label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label for="epid" class="form-label">Event Place<span style="color:red;">*</span></label>
            <select class="form-control " id="epid" formControlName="epid" (change)="onShoweventsub($event)">
              <option *ngFor="let parms of eventDropdown" value="{{parms}}">{{parms}}</option>
              <option value="" disabled selected hidden>Select Event place</option>
            </select>
            <span *ngIf="epIncidentChargesErrors.epid" class="error-text">{{epIncidentChargesErrors.epid}}</span>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label for="epsubid" class="form-label">Event Sub Place<span style="color:red;">*</span></label>
            <select class="form-control " id="epsubid" formControlName="epsubid" disabled="false"
              (change)="onShowIncidentCharges($event)">
              <option value="" selected>Select Event Sub Place</option>
              <option *ngFor="let parms of eventSubDropDown" value="{{parms}}">{{parms}}</option>
            </select>
            <span *ngIf="epIncidentChargesErrors.epsubid" class="error-text">{{epIncidentChargesErrors.epsubid}}</span>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label for="epincidentalid" class="form-label">Incidental Charge</label>
            <select class="form-control " id="epincidentalid" formControlName="epincidentalid" disabled="false"
              (change)="onShowIncidentChargesDetails($event)">
              <option value="" selected>Select Incidental</option>
              <option *ngFor="let parms of eventIncidentChargesDropDown" value="{{parms}}">{{parms}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label>Incidental Charges Name<span style="color:red;">*</span></label>
            <input type="text" class="form-control text-capitalize" id="epincidentalname"
              formControlName="epincidentalname" placeholder="Incidental Charges Name">
            <span *ngIf="epIncidentChargesErrors.epincidentalname"
              class="error-text">{{epIncidentChargesErrors.epincidentalname}}</span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label>Incidental Unit<span style="color:red;">*</span></label>
            <input type="text" (keypress)="OnlyNumbersAllowed($event)" class="form-control " id="epincidentalunit"
              formControlName="epincidentalunit" placeholder="Incidental Unit" maxlength="5">
            <span *ngIf="epIncidentChargesErrors.epincidentalunit"
              class="error-text">{{epIncidentChargesErrors.epincidentalunit}}</span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label>Incidental Charges<span style="color:red;">*</span></label>
            <input type="text" (keypress)="OnlyNumbersAllowed($event)" class="form-control"
              id="epincidentalchargecharges" formControlName="epincidentalchargecharges"
              placeholder="Incidental Charges" maxlength="5">
            <span *ngIf="epIncidentChargesErrors.epincidentalchargecharges"
              class="error-text">{{epIncidentChargesErrors.epincidentalchargecharges}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label>Incidental Description</label>
            <input class="form-control text-capitalize" id="epincidentaldesc" formControlName="epincidentaldesc"
              placeholder="Incidental Description ">
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label class="mr-3">Incidental Chargeable<span style="color:red;">*</span></label>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" value="Yes" name="epincidentalchargeable"
                id="epincidentalchargeable" formControlName="epincidentalchargeable">
              <label class="form-check-label" for="epincidentalchargeable"><b>Yes</b></label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" value="No" name="epincidentalchargeable"
                id="epincidentalchargeable" formControlName="epincidentalchargeable">
              <label class="form-check-label" for="epincidentalchargeable"><b>No</b></label>
            </div><br>
            <span *ngIf="epIncidentChargesErrors.epincidentalchargeable"
              class="error-text">{{epIncidentChargesErrors.epincidentalchargeable}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center">
      <button type="button" class="btn btn-primary" (click)="draftButton('Draft')" *ngIf="isdisabledraft">Save as Draft
      </button>
      <button type="submit" class="btn btn-success m-3" *ngIf="isdisablesave">{{this.isUpdate == true ? "Update" :
        "Save"}}</button>
      <button type="submit" class="btn btn-primary m-3" (click)="nextButton()" *ngIf="isdisablenext">Next</button>
    </div>
    <div class="row mt-5"></div>
  </form>
</div>
<ngx-spinner>
  <div class="custom-loader"></div>
</ngx-spinner>