<app-event-header></app-event-header>
<div class="container-fluid">
  <div class="row mt-4">
    <div class="col-md-2">
      <div class="col-md-1"></div>
      <div class="col-md-11">
        <div class="form-group">
          <label for="epid" class="form-label">Event Place</label>
          <select class="form-control " id="epid" (change)="onShoweventplace($event)">
            <option *ngFor="let parms of eventDropdown" value="{{parms}}">{{parms}}</option>
            <option value="" disabled selected hidden>Select Event place</option>
          </select>
        </div>
      </div>
    </div>

    <div class="col-md-2">
      <div class="col-md-1"></div>
      <div class="col-md-11">
        <div class="form-group">
          <label for="epsubid" class="form-label">Event Sub Place</label>
          <select class="form-control " id="epsubid" (change)="onShowsubplace($event)" [disabled]="!subplacesdetails">
            <option value="" selected>Select Event Sub-Place</option>
            <option *ngFor="let parms of eventSubDropDown" value="{{parms}}">{{parms}}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="col-md-2">
      <div class="col-md-1"></div>
      <div class="col-md-11">
        <div class="form-group">
          <label class="form-label">Vendor Name</label>
          <select class="form-control " (change)="onShowvendordetails($event)" [disabled]="!vendordetails">
            <option value="" selected>Select Vendor</option>
            <option *ngFor="let parms of eventVendorDropDown" value="{{parms}}">{{parms}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-md-2">
      <div class="col-md-1"></div>
      <div class="col-md-11">
        <div class="form-group">
          <label class="form-label">Activity Name</label>
          <select class="form-control " (change)="onActivitydetails($event)" [disabled]="!activitydetails">
            <option value="Select Activity" selected>Select Activity</option>
            <option *ngFor="let parms of eventactivityDropDown" value="{{parms}}">{{parms}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-md-2">
      <div class="col-md-1"></div>
      <div class="col-md-11">
        <div class="form-group">
          <label class="form-label">Amenities Name</label>
          <select class="form-control" (change)="onShowAmenitiesdetails($event)" [disabled]="!amenitiesdetails">
            <option value="Select Amenities" selected>Select Amenities</option>
            <option *ngFor="let parms of eventAmenitiesDropDown" value="{{parms}}">{{parms}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-md-2">
      <div class="col-md-1"></div>
      <div class="col-md-11 mt-4">
        <button class="btn btn-danger" style="margin: 5px" type="button" *ngIf="mapbutton"
                (click)="UpdateAlert()"> {{this.isUpdate == true ? "Map" : "Map"}}</button>
      </div>
    </div>
  </div>
  <div class="container" *ngIf="tableactivitydisable">
    <table class="table table-bordered">
      <thead class="sticky-top">
      <tr>
        <th scope="col">Event Place</th>
        <th scope="col">Event Sub Place</th>
        <th scope="col">Vendor Name</th>
        <th scope="col">Activity Name</th>
      </tr>
      </thead>
      <tbody>
      <tr class="text-center">
        <td>{{eventName}}</td>
        <td>{{eventSubName}}</td>
        <td>{{vendorName}}</td>
        <td>{{activityName}}</td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="container" *ngIf="tableamenitiesdisable">
    <table class="table table-bordered">
      <thead class="sticky-top">
      <tr>
        <th scope="col">Event Place</th>
        <th scope="col">Event Sub Place</th>
        <th scope="col">Vendor Name</th>
        <th scope="col">Amenity Name</th>
      </tr>
      </thead>
      <tbody>
      <tr class="text-center">
        <td>{{eventName}}</td>
        <td>{{eventSubName}}</td>
        <td>{{vendorName}}</td>
        <td>{{amenityName}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
<app-event-footer></app-event-footer>
<ngx-spinner>
  <div class="custom-loader"></div>
</ngx-spinner>