<div class="row col-md-12">
  <div class="col-md-1" style="text-align-last: center">
    <ul class="navbar-nav">
      <pf-image
        alt="AdminLTE Logo"
        class="brand-image"
        height="45"
        rounded
        src="assets/img/logo1.png"
        style="opacity: 0.8"
        width="45"
      ></pf-image>
    </ul>
  </div>
  <div class="col-md-5">
    <h3 class="mt-2">Event Place Management</h3>
  </div>
  <div class="col-md-6">
    <ul class="navbar-nav ml-auto">
      <app-user></app-user>
    </ul>
  </div>
</div>

