import { Component, OnInit, HostListener } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { EventPlace } from "@modules/event-place-management/model/eventplace"
import { AppService } from "@services/app.service";
import { EventSubPlace } from "../model/eventsub-place";
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { EPMiscCharges } from '@modules/event-place-management/model/ep-misccharges';
import { pipe, Subject, takeUntil } from "rxjs";
import Swal from "sweetalert2";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-misc-charges',
  templateUrl: './misc-charges.component.html',
  styleUrls: ['./misc-charges.component.scss']
})

export class MiscChargesComponent implements OnInit {

  eventPlace: EventPlace[] = [];
  eventDropdown: string[] = [];
  eventSubPlace: EventSubPlace[] = [];
  eventSubDropDown: string[] = [];
  eventMiscChargePlace: EPMiscCharges[] = [];
  MiscChargeDetails: EPMiscCharges[] = [];
  eventMiscChargeDropDown: string[] = [];
  epIdDropDown: string | undefined;
  epSubDropDown: string | undefined;
  epMiscChargeDropDown: string | undefined;
  destroy$: Subject<boolean> = new Subject<boolean>();
  public EPID: String;
  isdisabledraft = false;
  isdisablesave = false;
  isdisablenext = false;
  isUpdate = false;
  isDisabled: boolean = false;

  constructor(private router: Router, private appService: AppService, private toastr: ToastrService,
    private spinner : NgxSpinnerService) {

  }


  epmisccharge = new UntypedFormGroup({
    epid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epsubid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epmiscellaneouschargeid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epmiscellaneouschargename: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epmiscellaneouschargeshow: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epmiscellaneouschargeum: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epmiscellaneouschargecharges: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epmiscellaneouschargeunit: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epmiscellaneouschargedesc: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epmiscellaneouschargeunitlistprice: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    chargeactivestatus: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epmiscellaneouschargechargeable: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    activeyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    deleteyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    createdby: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    miscchargesrequired: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
  })



  public epmiscchargeErrors = {
    epid: '',
    epsubid: '',
    epmiscellaneouschargename: '',
    epmiscellaneouschargeshow: '',
    epmiscellaneouschargeum: '',
    epmiscellaneouschargecharges: '',
    epmiscellaneouschargeunit: '',
    epmiscellaneouschargedesc: '',
    epmiscellaneouschargeunitlistprice: '',
    chargeactivestatus: '',
    epmiscellaneouschargechargeable: '',
  }

  onSubmit() {
    this.formValidation("Active");
  }

  // misc-charges formvalidation function
  formValidation(status: string) {
    this.epmiscchargeErrors.epid = "";
    this.epmiscchargeErrors.epsubid = "";
    this.epmiscchargeErrors.epmiscellaneouschargename = "";
    this.epmiscchargeErrors.epmiscellaneouschargecharges = "";
    this.epmiscchargeErrors.epmiscellaneouschargeunit = "";
    this.epmiscchargeErrors.chargeactivestatus = "";
    this.epmiscchargeErrors.epmiscellaneouschargechargeable = "";


    let hasError = false;

    let inputValue = this.epmisccharge.controls['epmiscellaneouschargename'].value;
    let outputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
    this.epmisccharge.controls['epmiscellaneouschargename'].setValue(outputValue)



    if (this.epmisccharge.get('epid')?.invalid) {
      this.epmiscchargeErrors.epid = "*Event Place is required";
      hasError = true;
    }

    if (this.epmisccharge.get('epsubid')?.invalid) {
      this.epmiscchargeErrors.epsubid = "*Event Sub Place is required";
      hasError = true;
    }

    if (this.epmisccharge.get('epmiscellaneouschargename')?.invalid) {
      this.epmiscchargeErrors.epmiscellaneouschargename = "*Miscellaneous Charges Name is required";
      hasError = true;
    }

    if (this.epmisccharge.get('epmiscellaneouschargecharges')?.invalid) {
      this.epmiscchargeErrors.epmiscellaneouschargecharges = "*Miscellaneous Charges is required";
      hasError = true;
    }

    if (this.epmisccharge.get('epmiscellaneouschargeunit')?.invalid) {
      this.epmiscchargeErrors.epmiscellaneouschargeunit = "*Miscellaneous Unit is required";
      hasError = true;
    }


    // if (this.epmisccharge.get('epmiscellaneouschargeunitlistprice')?.invalid) {
    //   this.epmiscchargeErrors.epmiscellaneouschargeunitlistprice = "*charge Unit List Price is required";
    //   hasError = true;
    // }

    if (this.epmisccharge.get('epmiscellaneouschargechargeable')?.invalid) {
      this.epmiscchargeErrors.epmiscellaneouschargechargeable = "*Miscellaneous Chargeable is required";
      hasError = true;
    }
    if (!hasError) {
      this.saveMiscCharge(status);
    }
  }

  ngOnInit() {
    this.getEventPlaceByuserId();
    this.epmisccharge.controls['epid'].disable();
    Object.keys(this.epmisccharge.controls).forEach((controlName) => {
      if (controlName !== 'miscchargesrequired') {
        this.epmisccharge.get(controlName).disable();
      }
    })
    // this.isdisablenext = true;

  }

  /*Misc-charges all details Autofetch API function*/
  getMiscChargeDetails() {
    this.spinner.show()
    this.appService
      .getMiscChargeDetails(this.epIdDropDown, this.epSubDropDown, this.epMiscChargeDropDown)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide()
        console.log("GetMiscCharge Details::", data);
        // @ts-ignore
        this.MiscChargeDetails = data;
        for (var MiscCharge of this.MiscChargeDetails) {
          this.isUpdate = true;
          this.epmisccharge.controls["epmiscellaneouschargename"].setValue(MiscCharge.epmiscellaneouschargename);
          this.epmisccharge.controls["epmiscellaneouschargeshow"].setValue(MiscCharge.epmiscellaneouschargeshow);
          this.epmisccharge.controls["epmiscellaneouschargeum"].setValue(MiscCharge.epmiscellaneouschargeum);
          this.epmisccharge.controls["epmiscellaneouschargecharges"].setValue(MiscCharge.epmiscellaneouschargecharges);
          this.epmisccharge.controls["epmiscellaneouschargeunit"].setValue(MiscCharge.epmiscellaneouschargeunit);
          this.epmisccharge.controls["epmiscellaneouschargedesc"].setValue(MiscCharge.epmiscellaneouschargedesc);
          this.epmisccharge.controls["epmiscellaneouschargeunitlistprice"].setValue(MiscCharge.epmiscellaneouschargeunitlistprice);
          this.epmisccharge.controls["chargeactivestatus"].setValue(MiscCharge.chargeactivestatus);
          this.epmisccharge.controls["epmiscellaneouschargechargeable"].setValue(MiscCharge.epmiscellaneouschargechargeable);

        }
      },(error: any) => {
        this.spinner.hide()
        console.log("Something went wrong");
      })
  }

  /*EventPlace dropdown details API function*/
  getEventPlaceByuserId() {
    this.appService
      .getEventPlaceByuserId(localStorage.getItem('token'))
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("Event place Dropdwon::", data);
        // @ts-ignore
        this.eventPlace = data;
        var localEventPlace: string[] = [];
        for (var ep of this.eventPlace) {
          localEventPlace.push(ep.epname)
        }
        this.eventDropdown = localEventPlace;
      })
  }

  /*EventSubPlace dropdown details API function*/
  getEventSubPlace() {
    this.appService
      //@ts-ignore
      .getEventSubPlace(this.epIdDropDown)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("sub dropdown::", data);
        // @ts-ignore
        this.eventSubPlace = data;
        var localEventSubPlace: string[] = [];
        for (var ep of this.eventSubPlace) {
          if (ep.epmainplace == "Yes") {
            localEventSubPlace.push(ep.epsubname + " - Main Hall")
            console.log("main hall", this.eventSubDropDown)
          } else {
            localEventSubPlace.push(ep.epsubname)
          }
          this.eventSubDropDown = localEventSubPlace;
        }
      })
  }
  /*Misc-charges dropdown details API function*/
  getMiscChargeDropDown() {
    this.appService
      .getMiscChargeDropDown(this.epIdDropDown, this.epSubDropDown)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("Test data::", data);
        // @ts-ignore
        this.eventMiscChargePlace = data;
        if (this.eventMiscChargePlace.length > 0) {
          this.epmisccharge.controls['epmiscellaneouschargeid'].enable();
        } else {
          this.epmisccharge.controls['epmiscellaneouschargeid'].disable();
        }
        var localMisc: string[] = [];
        for (var ep of this.eventMiscChargePlace) {
          localMisc.push(ep.epmiscellaneouschargename)
        }
        this.eventMiscChargeDropDown = localMisc;
      })
  }


  /*EventPlace Dropdown Autofetch function*/
  onShowevent(event) {
    const value = event.target.value;
    console.log("Event Name::", value)
    for (var EPid of this.eventPlace) {
      if (value == EPid.epname) {
        this.epIdDropDown = EPid.epid;
        console.log("ep id::", this.epIdDropDown)
        this.getEventSubPlace();
        this.isDisabled = true;
        this.disable();
      } else {
        this.isDisabled = false;
        console.log("ep id:: error")
      }
    }
  }

  disable() {
    if (this.isDisabled) {
      this.epmisccharge.controls['epsubid'].enable();
    } else {
      this.epmisccharge.controls['epsubid'].disable();
    }
  }

  /*EventSubPlace Dropdown Autofetch function*/
  onShowsubplace(event) {
    const value = event.target.value.replace(" - Main Hall", "");
    console.log("Packing type::", value)
    for (var EPid of this.eventSubPlace) {
      if (value == EPid.epsubname) {
        this.epSubDropDown = EPid.epsubid;
        console.log("ep id::", this.epSubDropDown)
        this.getMiscChargeDropDown();
        this.isDisabled = true;
        this.disable1();
      } else {
        this.isDisabled = false;
        console.log("ep id:: error")
      }
    }
  }

  disable1() {
    if (this.isDisabled) {
      this.epmisccharge.controls['epmiscellaneouschargeid'].enable();
    } else {
      this.epmisccharge.controls['epmiscellaneouschargeid'].disable();
    }
  }
  /*Misc-charges Dropdown Autofetch function*/
  onShowMiscChargedetails(event) {
    const value = event.target.value;
    console.log("Packing type::", value)
    for (var EPid of this.eventMiscChargePlace) {
      if (value == EPid.epmiscellaneouschargename) {
        this.epMiscChargeDropDown = EPid.epmiscellaneouschargeid;
        console.log("ep id::", this.epSubDropDown)
        this.getMiscChargeDetails();
      }
    }
  }

  /* save and update Misc-charges API call function */
  saveMiscCharge(status: string) {
    this.spinner.show()
    console.log("is update::", this.isUpdate)
    if (this.isUpdate) {
      this.epmisccharge.controls["chargeactivestatus"].setValue(status);
      this.epmisccharge.controls["activeyn"].setValue("Yes");
      this.epmisccharge.controls["deleteyn"].setValue("No");
      this.epmisccharge.controls["createdby"].setValue(localStorage.getItem("token"));
      this.epmisccharge.controls["epid"].setValue(this.epIdDropDown);
      this.epmisccharge.controls["epsubid"].setValue(this.epSubDropDown);
      this.epmisccharge.controls["epmiscellaneouschargeid"].setValue(this.epMiscChargeDropDown);
      console.log("MiscCharge details::", this.epmisccharge.value);
      this.appService.updateMiscCharge(this.epmisccharge.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          this.spinner.hide()
          console.log("updateMiscCharge details ::", data);
          if(data.chargeactivestatus == "Draft"){
            this.successAlertDarft()
          }else{
          this.UpdateAlert();
          }
        }, (err: any) => {
          this.spinner.hide()
          this.errorAlert()
        },
          () => console.log('HTTP request completed.'));
      // this.isdisablenext = true;


    } else if (!this.isUpdate) {
      console.log("is update::", this.isUpdate)
      this.epmisccharge.controls["chargeactivestatus"].setValue(status);
      this.epmisccharge.controls["activeyn"].setValue("Yes");
      this.epmisccharge.controls["deleteyn"].setValue("No");
      this.epmisccharge.controls["createdby"].setValue(localStorage.getItem("token"));
      this.epmisccharge.controls["epid"].setValue(this.epIdDropDown);
      this.epmisccharge.controls["epsubid"].setValue(this.epSubDropDown);
      console.log("MiscCharge details::", this.epmisccharge.value);
      this.appService.saveMiscCharge(this.epmisccharge.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          this.spinner.hide()
          console.log("MiscChargeDetails ::", data);
          if(data.chargeactivestatus == "Draft"){
            this.successAlertDarft()
          }else{
          this.successAlert();
          }
        }, (err: any) => {
          this.spinner.hide()
          this.errorAlert();
        },
          () => console.log('HTTP request completed.'));
      // this.isdisablenext =true;
    }


  }
  //misccharges required functionality
  miscchargesdisable(event) {
    const value = event.target.value;
    console.log("'miscellaneouscharge'::", value);
    if (value == "no") {
      this.epmisccharge.disable();
      this.epmisccharge.controls['miscchargesrequired'].enable();
      this.isdisablesave = false;
      this.isdisabledraft = false;
      this.isdisablenext = true;
    } else {
      if (value == "yes") {
        this.epmisccharge.enable();
        this.isdisablesave = true;
        this.isdisabledraft = true;
        this.isdisablenext = false;
        this.disable1();
        this.disable();
      }
    }
  }
  draftButton(status: string) {
    let hasError = false;

    if (this.epmisccharge.get('epid')?.invalid) {
      this.epmiscchargeErrors.epid = "*Event Place is required";
      hasError = true;
    }

    if (this.epmisccharge.get('epsubid')?.invalid) {
      this.epmiscchargeErrors.epsubid = "*Event Sub Place is required";
      hasError = true;
    }

    let inputValue = this.epmisccharge.controls['epmiscellaneouschargename'].value;
    let outputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
    this.epmisccharge.controls['epmiscellaneouschargename'].setValue(outputValue)

    if (this.epmisccharge.get('epmiscellaneouschargename')?.invalid) {
      this.epmiscchargeErrors.epmiscellaneouschargename = "*Miscellaneous Charges Name is required";
      hasError = true;
    }
    if (!hasError) {
      this.saveMiscCharge(status)
    }
  }
  nextButton() {
    this.router.navigate(['/main/vendor-registration'])
  }

  /* only allowed numeric value function */
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  /* Misc-charges successalert popup message function */
  successAlert() {
    Swal.fire({
      title: "Your Registration Sucessfully Completed, Do you want to register another Miscellaneous charges ?",
      icon: "success",
      showDenyButton: true,
      confirmButtonColor: "#4fab69",
      denyButtonColor: "#c73636",
      confirmButtonText: "Yes",
      denyButtonText: "No",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.epmisccharge.controls["epid"].setValue("");
        this.epmisccharge.controls["epsubid"].setValue("");
        this.epmisccharge.controls["epmiscellaneouschargename"].setValue("");
        this.epmisccharge.controls["epmiscellaneouschargeshow"].setValue("");
        this.epmisccharge.controls["epmiscellaneouschargeum"].setValue("");
        this.epmisccharge.controls["epmiscellaneouschargecharges"].setValue("");
        this.epmisccharge.controls["epmiscellaneouschargeunit"].setValue("");
        this.epmisccharge.controls["epmiscellaneouschargedesc"].setValue("");
        this.epmisccharge.controls["epmiscellaneouschargeunitlistprice"].setValue("");
        this.epmisccharge.controls["chargeactivestatus"].setValue("");
        this.epmisccharge.controls["epmiscellaneouschargechargeable"].setValue("");
      } else {
        this.router.navigate(['/dash'])
      }
    });
  }
   //Misc-charges successAlertDarft popup message
successAlertDarft() {
  Swal.fire({
    title: "Your Registration successfully saved as draft, Do you want to register another Miscellaneous charges ?",
    icon: "success",
    showDenyButton: true,
    confirmButtonColor: "#4fab69",
    denyButtonColor: "#c73636",
    confirmButtonText: "Yes",
    denyButtonText: "No",
    showClass: {
      popup: "animate_animated animate_fadeInDown",
    },
    hideClass: {
      popup: "animate_animated animate_fadeOutUp",
    },
  }).then((result) => {
    if (result.isConfirmed) {
      this.epmisccharge.controls["epid"].setValue("");
      this.epmisccharge.controls["epsubid"].setValue("");
      this.epmisccharge.controls["epmiscellaneouschargename"].setValue("");
      this.epmisccharge.controls["epmiscellaneouschargeshow"].setValue("");
      this.epmisccharge.controls["epmiscellaneouschargeum"].setValue("");
      this.epmisccharge.controls["epmiscellaneouschargecharges"].setValue("");
      this.epmisccharge.controls["epmiscellaneouschargeunit"].setValue("");
      this.epmisccharge.controls["epmiscellaneouschargedesc"].setValue("");
      this.epmisccharge.controls["epmiscellaneouschargeunitlistprice"].setValue("");
      this.epmisccharge.controls["chargeactivestatus"].setValue("");
      this.epmisccharge.controls["epmiscellaneouschargechargeable"].setValue("");
    } else {
      this.router.navigate(['/dash'])
    }
  });
}

  /* Misc-charges UpdateAlert popup message function */
  UpdateAlert() {
    Swal.fire({
      title: "Your Registration Updated Sucessfully.",
      icon: "success",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.reload();
      }
    });
  }
 
  errorAlert() {
    Swal.fire({
      title: "Sorry, Please Try Again Your Registration Process",
      icon: "error",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    });
  }
}


