<div class="container-fluid">
	<form (ngSubmit)="onSubmit()" [formGroup]="epvendorregister">
		<div class="col-md-2 mt-1"></div>
		<div class="col-md-11">
			<h4 class="">Vendor Registration</h4>
		</div>
		<div class="alert alert-light" role="alert">
			Note:<span style="color:red;">*</span> Screen to enter all the required details about your vendor
			Registration.
		</div>
		<div class="row mt-4">
			<div class="col-md-4">
				<div class="col-md-1"></div>
				<div class="col-md-11">
					<div class="form-group">
						<label for="epid" class="form-label">Event Place<span style="color:red;">*</span></label>
						<select class="form-control" id="epid" formControlName="epid" (change)="onShowevent($event)">
							<option *ngFor="let parms of eventDropdown" value="{{parms}}">{{parms}}</option>
							<option value="" disabled selected hidden>Select Event place</option>
						</select>
						<span *ngIf="epvendorErrors.epid" class="error-text">{{epvendorErrors.epid}}</span>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="col-md-1"></div>
				<div class="col-md-11">
					<div class="form-group">
						<label for="epsubid" class="form-label">Event Sub Place<span style="color:red;">*</span></label>
						<select class="form-control" id="epsubid" formControlName="epsubid" disabled="false"
							(change)="onShowsubplace($event)">
							<option value="" selected>Select Event Sub place</option>
							<option *ngFor="let parms of eventSubDropDown" value="{{parms}}">{{parms}}</option>
						</select>
						<span *ngIf="epvendorErrors.epsubid" class="error-text">{{epvendorErrors.epsubid}}</span>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="col-md-1"></div>
				<div class="col-md-11">
					<label for="ep3rdpartyvendorid" class="form-label">Vendor</label>
					<select class="form-control" id="ep3rdpartyvendorid" formControlName="ep3rdpartyvendorid"
						disabled="false" (change)="onShowvendordetails($event)">
						<option value="" selected>Select Vendor</option>
						<option *ngFor="let parms of eventVendorDropDown" value="{{parms}}">{{parms}}</option>
					</select>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-4">
				<div class="col-md-1"></div>
				<div class="col-md-11">
					<div class="form-group">
						<label for="ep3rdpartyvendorname">Vendor Name<span style="color:red;">*</span></label>
						<input class="form-control text-capitalize" type="text" placeholder="Vendor Name"
							id="ep3rdpartyvendorname" formControlName="ep3rdpartyvendorname">
						<span *ngIf="epvendorErrors.ep3rdpartyvendorname"
							class="error-text">{{epvendorErrors.ep3rdpartyvendorname}}</span>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="col-md-1"></div>
				<div class="col-md-11">
					<div class="form-group">
						<label for="ep3rdpartyvendoraddress1">Vendor Address 1<span style="color:red;">*</span></label>
						<textarea class="form-control text-capitalize" placeholder="Vendor Address 1"
							id="ep3rdpartyvendoraddress1" formControlName="ep3rdpartyvendoraddress1"></textarea>
						<span *ngIf="epvendorErrors.ep3rdpartyvendoraddress1"
							class="error-text">{{epvendorErrors.ep3rdpartyvendoraddress1}}</span>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="col-md-1"></div>
				<div class="col-md-11">
					<div class="form-group">
						<label for="ep3rdpartyvendoraddress2">Vendor Address 2<span style="color:red;">*</span></label>
						<textarea class="form-control text-capitalize" placeholder="Vendor Address 2"
							id="ep3rdpartyvendoraddress2" formControlName="ep3rdpartyvendoraddress2"></textarea>
						<span *ngIf="epvendorErrors.ep3rdpartyvendoraddress2"
							class="error-text">{{epvendorErrors.ep3rdpartyvendoraddress2}}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-4">
				<div class="col-md-1"></div>
				<div class="col-md-11">
					<div class="form-group">
						<label for="ep3rdpartyvendorcity">Vendor City<span style="color:red;">*</span></label>
						<input class="form-control text-capitalize" type="text" formControlName="ep3rdpartyvendorcity"
							id="ep3rdpartyvendorcity" placeholder="Vendor City">
						<span *ngIf="epvendorErrors.ep3rdpartyvendorcity"
							class="error-text">{{epvendorErrors.ep3rdpartyvendorcity}}</span>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="form-group">
					<div class="col-md-1"></div>
					<div class="col-md-11">
						<label for="ep3rdpartyvendorstate">Vendor State<span style="color:red;">*</span></label>
						<input class="form-control text-capitalize" type="text" formControlName="ep3rdpartyvendorstate"
							id="ep3rdpartyvendorstate" placeholder="Vendor State">
						<span *ngIf="epvendorErrors.ep3rdpartyvendorstate"
							class="error-text">{{epvendorErrors.ep3rdpartyvendorstate}}</span>
					</div>
				</div>
			</div>
			<div class="col-md-4 mt-2">
				<div class="form-group">
					<div class="col-md-1"></div>
					<div class="col-md-11">
						<label for="ep3rdpartyvendorcountry">Vendor Country<span style="color:red;">*</span></label>
						<input class="form-control" type="text" formControlName="ep3rdpartyvendorcountry"
							id="ep3rdpartyvendorcountry">
						<span *ngIf="epvendorErrors.ep3rdpartyvendorcountry"
							class="error-text">{{epvendorErrors.ep3rdpartyvendorcountry}}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-4">
				<div class="col-md-1"></div>
				<div class="col-md-11">
					<div class="form-group">
						<label for="ep3rdpartyvendoraadharid">Vendor Aadhaar ID<span style="color:red;">*</span></label>
						<input class="form-control" type="text" (keypress)="numberOnly($event)"
							formControlName="ep3rdpartyvendoraadharid" id="ep3rdpartyvendoraadharid"
							placeholder="Vendor Aadhaar ID" maxlength="16">
						<span *ngIf="epvendorErrors.ep3rdpartyvendoraadharid"
							class="error-text">{{epvendorErrors.ep3rdpartyvendoraadharid}}</span>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="form-group">
					<div class="col-md-1"></div>
					<div class="col-md-11">
						<label for="ep3rdpartyvendorbankacno">Vendor Bank Account Number<span
								style="color:red;">*</span></label>
						<input class="form-control" type="text" (keypress)="numberOnly($event)"
							id="ep3rdpartyvendorbankacno" formControlName="ep3rdpartyvendorbankacno"
							placeholder="Vendor Bank Account Number" maxlength="20">
						<span *ngIf="epvendorErrors.ep3rdpartyvendorbankacno"
							class="error-text">{{epvendorErrors.ep3rdpartyvendorbankacno}}</span>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="form-group">
					<div class="col-md-1"></div>
					<div class="col-md-11">
						<label for="ep3rdpartyvendorbankifsc">Vendor Bank IFSC Code<span
								style="color:red;">*</span></label>
						<input class="form-control" type="text" id="ep3rdpartyvendorbankifsc"
							formControlName="ep3rdpartyvendorbankifsc" placeholder="Vendor Bank IFSC Code">
						<span *ngIf="epvendorErrors.ep3rdpartyvendorbankifsc"
							class="error-text">{{epvendorErrors.ep3rdpartyvendorbankifsc}}</span>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-md-4">
				<div class="col-md-1"></div>
				<div class="col-md-11">
					<div class="form-group">
						<label for="ep3rdpartyvendorsupplydesc">Vendor Description</label>
						<input class="form-control text-capitalize" id="ep3rdpartyvendorsupplydesc"
							formControlName="ep3rdpartyvendorsupplydesc" placeholder="Vendor Description">
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="form-group">
					<div class="col-md-1"></div>
					<div class="col-md-11">
						<label class="mr-3" for="ep3rdpartyvendoruom">Event Acceptance<span
								style="color:red;">*</span>:</label>
						<div class="form-check form-check-inline">
							<input class="form-check-input" type="radio" value="Yes"
								name="ep3rdpartyvendoreventacceptance" id="ep3rdpartyvendoreventacceptance"
								formControlName="ep3rdpartyvendoreventacceptance">
							<label class="form-check-label" for="ep3rdpartyvendoreventacceptance">Yes</label>
						</div>
						<div class="form-check form-check-inline">
							<input class="form-check-input" type="radio" value="No"
								name="ep3rdpartyvendoreventacceptance" id="ep3rdpartyvendoreventacceptance"
								formControlName="ep3rdpartyvendoreventacceptance">
							<label class="form-check-label" for="ep3rdpartyvendoreventacceptance">No</label>
						</div><br>
						<span *ngIf="epvendorErrors.ep3rdpartyvendoreventacceptance"
							class="error-text">{{epvendorErrors.ep3rdpartyvendoreventacceptance}}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="card-body text-center">
			<button type="button" class="btn btn-primary" (click)="draftButton('Draft')">Save as Draft </button>
			<button type="submit" class="btn btn-success m-3">{{this.isUpdate == true ? "Update" : "Save"}}</button>
		</div>
		<div class="row mt-4"></div>
	</form>
</div>
<ngx-spinner>
	<div class="custom-loader"></div>
</ngx-spinner>