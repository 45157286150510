<!-- Brand Logo -->
<a  class="brand-link">
  <pf-image
    alt="AdminLTE Logo"
    class="brand-image"
    height="45"
    rounded
    src="assets/img/logo1.png"
    style="opacity: 0.8"
    width="45"
  ></pf-image>
  <span class="brand-text font-weight-light">Event Place <br> Management</span>
</a>

<!-- Sidebar -->
<div class="sidebar">
  <ul class="label-text" nz-menu nzMode="inline" style="margin-left: -30px">

    <ng-container>
      <!-- Sidebar Menu -->
      <nav class="mt-2" style="overflow-y: hidden">
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
        >
          <app-menu-item
            *ngFor="let item of menu"
            [menuItem]="item" [title]="item.tooltip"
          ></app-menu-item>
        </ul>
      </nav>
    </ng-container>
  </ul>

</div>
