<div class="container-fluid">
<form (ngSubmit)="onSubmit()" [formGroup]="epkitchendetails" id="epkitchendetails">
  <div class="col-md-1 mt-1"></div>
  <div class="col-md-11 ">
    <h4>Kitchen</h4>
  </div>
<!-- <div class="row mt-2">
  <h6 class="m-2"><p>Note:This Screen Your Want To Save The Kitchen Details,Please Insert Your Kitchen Details.</p></h6>
<hr>  
</div> -->
<div class="alert alert-light" role="alert">
  Note:<span style="color:red;">*</span>   Screen to enter all the required details about your Kitchen.
</div>
      <div class="row">
        <label class="col-3  col-form-label"><strong>Kitchen Details Required</strong></label>
        <div class="form-check mt-1">
          <input class="form-check-input" type="radio" formControlName="Kitchenrequired" value="yes"
           (change)="kitchendisable($event)" >
          <label class="form-check-label"><b>Yes</b></label>
        </div>
        <div class="form-check mt-1">
          <input class="form-check-input ml-1" type="radio" formControlName="Kitchenrequired" value="no"
          (change)="kitchendisable($event)" >
          <label class="form-check-label ml-4"><b>No</b></label>
        </div>
      </div>
  <div class="row mt-1" >
    <div class="col-md-4">
      <div class="col-md-1"></div>
					<div class="col-md-11">
        <label for="epid" class="form-label">Event Place<span style="color:red;">*</span></label>
  <select class="form-control "  formControlName="epid" (change)="onShoweventsub($event)" >
       <option *ngFor="let parms of eventDropdown" value="{{parms}}">{{parms}}</option>
         <option value="" disabled selected hidden >Select Event place</option>
  </select>
  <span *ngIf="epkitchenErrors.epid"
        class="error-text">{{epkitchenErrors.epid}}</span>
        </div>
      </div>
        <div class="col-md-4" >
          <div class="col-md-1"></div>
					<div class="col-md-11">
            <div class="form-group">
            <label for="epsubid" class="form-label">Event Sub Place<span style="color:red;">*</span></label>
          <select class="form-control  slct shadow-none "  id="epsubid" formControlName="epsubid"  disabled="false" (change)="onShowkitchen($event)" >
            <option value="" selected  >Select Event Sub Place</option>
            <option *ngFor="let parms of eventSubDropDown" value="{{parms}}">{{parms}}</option>
          </select>
               <span *ngIf="epkitchenErrors.epsubid"
        class="error-text">{{epkitchenErrors.epsubid}}</span>
               </div>
               </div>
               </div>
        <div class="col-md-4" >
          <div class="col-md-1"></div>
					<div class="col-md-11">
            <div class="form-group">
            <label for="epkitchenid" class="form-label">Kitchen</label>
          <select class="form-control "  id="epkitchenid" formControlName="epkitchenid"  disabled="false"  (change)="onShowkitchendetails($event)">
            <option value="" selected>Select Kitchen</option>
            <option *ngFor="let parms of eventKitchenDropDown" value="{{parms}}">{{parms}}</option>
          </select>
          </div>
          </div>
          </div>
        </div>
          <div class="row">
          <div class="col-md-4">
            <div class="col-md-1"></div>
					<div class="col-md-11">
            <div class="form-group">
            <label>Name<span style="color:red;">*</span></label>
          <input type="text"  class="form-control text-capitalize" id="epkitchenname" formControlName="epkitchenname" placeholder="Kitchen Name">
          <span *ngIf="epkitchenErrors.epkitchenname"
          class="error-text">{{epkitchenErrors.epkitchenname}}</span>
          </div>
          </div>
          </div>
          
          <div class="col-md-4">
            <div class="col-md-1"></div>
					<div class="col-md-11">
            <div class="form-group">
            <label>Capacity<span style="color:red;">*</span></label>
          <input type="text"  class="form-control" id="epkitchencapacity" formControlName="epkitchencapacity" placeholder="Kitchen Capacity" (keypress)="numberOnly($event)" maxlength="2">
          <span *ngIf="epkitchenErrors.epkitchencapacity"
          class="error-text">{{epkitchenErrors.epkitchencapacity}}</span>
        </div>
        </div>
        </div>

          <div class="col-md-4">
            <div class="col-md-1"></div>
					<div class="col-md-11">
            <div class="form-group">
            <label>Kitchen Size<span style="color:red;">*</span></label>
          <input type="text"  class="form-control" id="epkitchensize" formControlName="epkitchensize" placeholder="Kitchen Size 21 * 21" >
          <span *ngIf="epkitchenErrors.epkitchensize"
          class="error-text">{{epkitchenErrors.epkitchensize}}</span>
        </div>
        </div>
        </div>
        </div>

          <div class="row">
            <div class="col-md-4">
              <div class="col-md-1"></div>
					<div class="col-md-11">
            <div class="form-group">
              <label>Stoves Count<span style="color:red;">*</span></label>
            <input type="text"  class="form-control" (keypress)="numberOnly($event)" id="epkitchennoofstoves" formControlName="epkitchennoofstoves" placeholder="Kitchen Stoves Count" maxlength="2" >
            <span *ngIf="epkitchenErrors.epkitchennoofstoves"
            class="error-text">{{epkitchenErrors.epkitchennoofstoves}}</span>
          </div>
          </div>
          </div>


          <div class="col-md-4">
            <div class="col-md-1"></div>
					<div class="col-md-11">
            <div class="form-group">
              <label>Grinders Count<span style="color:red;">*</span></label>
            <input type="text"  class="form-control" (keypress)="numberOnly($event)" id="epkitchennoofgrinders" formControlName="epkitchennoofgrinders" placeholder="Kitchen Grinders Count" maxlength="2">
            <span *ngIf="epkitchenErrors.epkitchennoofgrinders"
            class="error-text">{{epkitchenErrors.epkitchennoofgrinders}}</span>
          </div>
          </div>
          </div>


          <div class="col-md-4">
            <div class="col-md-1"></div>
					<div class="col-md-11">
            <div class="form-group">
              <label>Fridge Count<span style="color:red;">*</span></label>
            <input  type="text"  class="form-control" (keypress)="numberOnly($event)" id="epkitchennooffridge" formControlName="epkitchennooffridge" placeholder="Kitchen Fridge Count" maxlength="2" >
            <span *ngIf="epkitchenErrors.epkitchennooffridge"
            class="error-text">{{epkitchenErrors.epkitchennooffridge}}</span>
          </div>
        </div>
        </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="col-md-1"></div>
					<div class="col-md-11">
            <div class="form-group">
              <label>Mixie Count<span style="color:red;">*</span></label>
            <input type="text"  class="form-control" (keypress)="numberOnly($event)" id="epkitchennoofmixie" formControlName="epkitchennoofmixie" placeholder="Kitchen Mixie Count" maxlength="2">
            <span *ngIf="epkitchenErrors.epkitchennoofmixie"
            class="error-text">{{epkitchenErrors.epkitchennoofmixie}}</span>
          </div>
          </div>
          </div>

        <div class="col-md-4">
          <div class="col-md-1"></div>
					<div class="col-md-11">
            <div class="form-group">
          <label>Charges<span style="color:red;">*</span></label><br>
          <input type="text"  class="form-control" id="epkitchencharges" formControlName="epkitchencharges" (keypress)="numberOnly($event)" placeholder="Kitchen charges" maxlength="6">
          <span *ngIf="epkitchenErrors.epkitchencharges"
                class="error-text">{{epkitchenErrors.epkitchencharges}}</span>
            </div>
            </div>
            </div>

            <div class="col-md-4">
              <div class="col-md-1"></div>
					<div class="col-md-11">
            <div class="form-group">
              <label>Description</label>
              <!-- <input type="text"  class="form-control" id="epkitchendesc" formControlName="epkitchendesc" placeholder="Kitchen Description"> -->
              <input class="form-control text-capitalize"  rows="3" id="epkitchendesc" formControlName="epkitchendesc" placeholder="Kitchen Description">
            </div>
            </div>
          </div>
          </div>

            <div class="row">
              <!-- <div class="col-md-3 mt-3">
                  <label class="mr-2">Show<span style="color:red;">*</span></label>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" value="Yes" name="epkitchenshow" id="epkitchenshow" formControlName="epkitchenshow">
                  <label class="form-check-label" for="epkitchenshow">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" value="No" name="epkitchenshow" id="epkitchenshow" formControlName="epkitchenshow" >
                  <label class="form-check-label" for="epkitchenshow">No</label>
                </div>
                  <div><span *ngIf="epkitchenErrors.epkitchenshow"
                  class="error-text">{{epkitchenErrors.epkitchenshow}}</span></div>
                </div>
                <div class="col-md-1"></div>
              <div class="col-md-3 mt-3">
                <label class="mr-2">Active Status<span style="color:red;">*</span></label>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" value="Active" name="kitchenactivestatus" id="kitchenactivestatus" formControlName="kitchenactivestatus">
                  <label class="form-check-label" for="epkitchenchargable">Active</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" value="Inactive" name="kitchenactivestatus" id="kitchenactivestatus" formControlName="kitchenactivestatus" >
                  <label class="form-check-label" for="epkitchenchargable">Inactive</label>
                </div><br>
                <span *ngIf="epkitchenErrors.kitchenactivestatus"
                      class="error-text">{{epkitchenErrors.kitchenactivestatus}}</span>
              </div> -->
              <div class="col-md-4">
                <div class="col-md-1"></div>
					<div class="col-md-11">
            <div class="form-group">
                <label class="mr-2">Chargeable<span style="color:red;">*</span></label>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" value="Yes" name="epkitchenchargable" id="epkitchenchargable" formControlName="epkitchenchargable">
                  <label class="form-check-label" for="epkitchenchargable"><b>Yes</b></label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" value="No" name="epkitchenchargable" id="epkitchenchargable" formControlName="epkitchenchargable" >
                  <label class="form-check-label" for="epkitchenchargable"><b>No</b></label>
                </div><br>
                <span *ngIf="epkitchenErrors.epkitchenchargable"
                      class="error-text">{{epkitchenErrors.epkitchenchargable}}</span>
            </div>
            </div>
            </div>
          </div>
            <div class="text-center">
              <button type="button" class="btn btn-primary" (click)="draftButton('Draft')" *ngIf="isdisabledraft" >Save as Draft </button>
              <button type="submit" class="btn btn-success m-3" *ngIf="isdisablesave">{{this.isUpdate == true ? "Update" : "Save"}}</button>
              <button type="submit" class="btn btn-primary m-3" (click)="nextButton()" *ngIf="isdisablenext">Next</button>
              <div class="row mt-5"></div>
            </div> 
      </form>
    </div>
    <ngx-spinner>
      <div class="custom-loader"></div>
    </ngx-spinner>