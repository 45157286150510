import {AppState} from '@/store/state';
import {UiState} from '@/store/ui/state';
import {Component, HostBinding, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppService} from '@services/app.service';
import {Observable} from 'rxjs';
import {Router} from "@angular/router";

const BASE_CLASSES = 'main-sidebar elevation-4';

@Component({
  selector: 'app-menu-sidebar',
  templateUrl: './menu-sidebar.component.html',
  styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
  @HostBinding('class') classes: string = BASE_CLASSES;
  public ui: Observable<UiState>;
  public user;
  public menu = MENU;

  constructor(
    public appService: AppService,
    private store: Store<AppState>, private router: Router
  ) {
  }

  ngOnInit() {
    this.ui = this.store.select('ui');
    this.ui.subscribe((state: UiState) => {
      this.classes = `${BASE_CLASSES} ${state.sidebarSkin}`;
    });
    this.user = this.appService.user;
  }

  onNavigation(url: string) {
    if (url && url !== '') {
      this.router.navigate([url]);
    }
  }

  isCurrentPage(viewLocation: string) {
    if (viewLocation == "/main")
      return false

    return this.router.url.includes(viewLocation)
  }

}

export const MENU = [
  /*{
    name: 'Dashboard',
    iconClasses: 'fas fa-tachometer-alt',
    path: ['/main/dash']
  },*/
  {
    name: 'Event Place Registration',
    iconClasses: 'fas fa-place-of-worship',
    path: ['/main/event-place-registration'],
    tooltip: 'Screen to enter all the required details about your Event Place'
  },
  {
    name: 'Sub Place Registration',
    iconClasses: 'fa fa-university',
    path: ['/main/sub-place'],
    tooltip: 'Screen to enter all the required details about your Sub Place'
  },
  {
    name: 'Rooms',
    iconClasses: 'fa fa-home',
    path: ['/main/rooms'],
    tooltip: 'Screen to enter all the required details about your Rooms'
  },
  {
    name: 'Kitchen',
    iconClasses: 'fas fa-pizza-slice',
    path: ['/main/kitchen'],
    tooltip: 'Screen to enter all the required details about your Kitchen'
  },
  {
    name: 'Open Space',
    iconClasses: 'fas fa-icons',
    path: ['/main/open-space'],
    tooltip: 'Screen to enter all the required details about your Open Space'
  },
  {
    name: 'Parking Area',
    iconClasses: 'fas fa-car',
    path: ['/main/parking-area'],
    tooltip: 'Screen to enter all the required details about your Parking Area'
  },{
    name: 'General Amenities',
    iconClasses: 'fas fa-layer-group',
    path: ['/main/general-amenities'],
    tooltip: 'Screen to enter all the required details about your General Amenities'
  },
  {
    name: 'Activities',
    iconClasses: 'fas fa-diagnoses',
    path: ['/main/activities'],
    tooltip: 'Screen to enter all the required details about your Activites'
  },{
    name: 'Incident Charges',
    iconClasses: 'ion ion-cash',
    path: ['/main/incident-charges'],
    tooltip: 'Screen to enter all the required details about your Incident Charges'
  },{
    name: 'Miscellaneous Charges',
    iconClasses: 'ion ion-cash',
    path: ['/main/misc-charges'],
    tooltip: 'Screen to enter all the required details about your Miscellaneous Charges'
  },
  /*{
    name: 'Vendor Registration',
    iconClasses: 'fas fa-chalkboard-teacher',
    path: ['/main/vendor-registration']
  },
  {
    name: 'User Mapping',
    iconClasses: 'fa fa-users',
    path: ['/main/user-mapping']
  },
  {
    name: 'Amenities Mapping',
    iconClasses: 'fa fa-users',
    path: ['/main/amenities-mapping']
  },
  {
    name: 'Activities Mapping',
    iconClasses: 'fa fa-users',
    path: ['/main/activities-mapping']
  },*/
];
