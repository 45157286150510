import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {UiKitComponent} from "@/ui-kit/ui-kit.component";
import {AppRoutingModule} from '@/app-routing.module';
import {AppComponent} from './app.component';
import { CalendarOptions } from '@fullcalendar/core';
import { FullCalendarModule } from '@fullcalendar/angular';
import {MainComponent} from '@modules/main/main.component';
import {LoginComponent} from '@modules/login/login.component';
import {HeaderComponent} from '@modules/main/header/header.component';
import {EventHeaderComponent} from '@modules/main/header/event-header/event-header.component'
import {FooterComponent} from '@modules/main/footer/footer.component';
import {EventFooterComponent} from '@modules/main/eventfooter/event-footer.component'
import {MenuSidebarComponent} from '@modules/main/menu-sidebar/menu-sidebar.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {ReactiveFormsModule} from '@angular/forms';
import {ProfileComponent} from '@pages/profile/profile.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from "@modules/event-place-management/dashboard/dashboard.component";
import {ToastrModule} from 'ngx-toastr';
import {MessagesComponent} from '@modules/main/header/messages/messages.component';
import {NotificationsComponent} from '@modules/main/header/notifications/notifications.component';
import {DatePipe} from '@angular/common';
import localeEn from '@angular/common/locales/en';
import {UserComponent} from '@modules/main/header/user/user.component';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {LanguageComponent} from '@modules/main/header/language/language.component';
import {MainMenuComponent} from './pages/main-menu/main-menu.component';
import {SubMenuComponent} from './pages/main-menu/sub-menu/sub-menu.component';
import {MenuItemComponent} from './components/menu-item/menu-item.component';
import {ControlSidebarComponent} from './modules/main/control-sidebar/control-sidebar.component';
import {StoreModule} from '@ngrx/store';
import {authReducer} from './store/auth/reducer';
import {uiReducer} from './store/ui/reducer';
import {ProfabricComponentsModule} from '@profabric/angular-components';
import {defineCustomElements} from '@profabric/web-components/loader';
import {SidebarSearchComponent} from './components/sidebar-search/sidebar-search.component';
import {EventPlaceRegistrationComponent} from "@modules/event-place-management/event-place-registration/event-place-registration.component";
import {RoomsComponent} from "@modules/event-place-management/rooms/rooms.component";
import {SubPlaceComponent} from "@modules/event-place-management/sub-place-registration/sub-place.component";
import {KitchenComponent} from "@modules/event-place-management/kitchen/kitchen.component";
import {OpenSpaceComponent} from "@modules/event-place-management/open-space/open-space.component";
import {ParkingAreaComponent} from "@modules/event-place-management/parking-area/parking-area.component";
import {GeneralAmenitiesComponent} from "@modules/event-place-management/general-amenities/general-amenities.component";
import {ActivitiesComponent} from "@modules/event-place-management/activities/activities.component";
import {IncidentChargesComponent} from "@modules/event-place-management/incident-charges/incident-charges.component";
import {MiscChargesComponent} from "@modules/event-place-management/misc-charges/misc-charges.component";
import {VendorRegistrationComponent} from "@modules/event-place-management/vendor-registration/vendor-registration.component";
import {UserMappingComponent} from "@modules/event-place-management/user-mapping/user-mapping.component";
import {AmenitiesMappingComponent} from "@modules/event-place-management/amenities-mapping/amenities-mapping.component";
import {ActivitiesMappingComponent} from "@modules/event-place-management/activities-mapping/activities-mapping.component";
import { EventBookingAreaComponent } from "@modules/ep-booking-management/event-bookingarea/event-bookingarea.component";
import { EventPlaceBookingComponent } from "@modules/ep-booking-management/eventplace-booking/eventplace-booking.component";
import {EventBookingPaymentComponent} from "@modules/ep-booking-management/event-bookingpayment/event-bookingpayment.component";
import {EventBookingPaymentPrintComponent} from "@modules/ep-booking-management/event-booking-payment-print/event-booking-payment-print.component";
import {HashLocationStrategy, LocationStrategy, registerLocaleData} from '@angular/common';
import {NgxSpinnerModule} from "ngx-spinner";
import {UserMappingManagementComponent} from "@modules/event-place-management/user-mapping-management/user-mapping-management.component";
import {ActivityAmenityMappingComponent} from "@modules/event-place-management/activity-amenity-mapping/activity-amenity-mapping.component";
import {EventBookingListComponent} from "@modules/ep-booking-management/event-bookinglist/event-bookinglist.component";
import { UserStatusComponent } from '@modules/event-place-management/user-status/user-status.component';
import {BookingareaDataService} from "@modules/ep-booking-management/model/bookingareaDataService";
import {Ng2SearchPipeModule} from "ng2-search-filter";
import {WebsiteComponent} from "@modules/website/website.component";
import {VenueViewComponent} from "@modules/venue-view/venue-view.component";
import {CustomerBookingComponent} from "@modules/customer-booking/customer-booking.component";
import { SwiperModule } from 'swiper/angular';
import {PrivacyPolicyComponent} from "@modules/privacy-policy/privacy-policy.component";
import {TermsComponent} from "@modules/terms-and-conditions/terms.component";



defineCustomElements();
registerLocaleData(localeEn, 'en-EN');

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LoginComponent,
    UiKitComponent,
    HeaderComponent,
    EventHeaderComponent,
    FooterComponent,
    EventFooterComponent,
    MenuSidebarComponent,
    BlankComponent,
    ProfileComponent,
    RegisterComponent,
    DashboardComponent,
    MessagesComponent,
    NotificationsComponent,
    UserComponent,
    ForgotPasswordComponent,
    RecoverPasswordComponent,
    LanguageComponent,
    MainMenuComponent,
    SubMenuComponent,
    MenuItemComponent,
    ControlSidebarComponent,
    SidebarSearchComponent,
    EventPlaceRegistrationComponent,
    RoomsComponent,
    SubPlaceComponent,
    KitchenComponent,
    OpenSpaceComponent,
    ParkingAreaComponent,
    GeneralAmenitiesComponent,
    ActivitiesComponent,
    IncidentChargesComponent,
    MiscChargesComponent,
    VendorRegistrationComponent,
    UserMappingComponent,
    AmenitiesMappingComponent,
    ActivitiesMappingComponent,
    EventBookingAreaComponent,
    EventPlaceBookingComponent,
    EventBookingPaymentComponent,
    EventBookingPaymentPrintComponent,
    UserMappingManagementComponent,
    ActivityAmenityMappingComponent,
    EventBookingListComponent,
    UserStatusComponent,
    WebsiteComponent,
    VenueViewComponent,
    CustomerBookingComponent,
    PrivacyPolicyComponent,
    TermsComponent,
  ],
    imports: [
        BrowserModule,
        StoreModule.forRoot({auth: authReducer, ui: uiReducer}),
        HttpClientModule,
        AppRoutingModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        FullCalendarModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-top-right',
            preventDuplicates: true
        }),
        ProfabricComponentsModule,
        NgxSpinnerModule.forRoot(),
        FormsModule,
        Ng2SearchPipeModule,
        SwiperModule,
        SwiperModule
    ],
  providers: [DatePipe, BookingareaDataService,
    {provide: LocationStrategy, useClass: HashLocationStrategy},],
  bootstrap: [AppComponent]
})
export class AppModule {
}
