<div class="container">
  <i class="fa fa-rupee"></i>
  <div class="" *ngFor="let event of this.eventAutoDetails">
  <div class="text-center mt-5 row">
    <div class="col-md-2">
      <div class="card" id="imageUrl"></div>
    </div>
    <div class="col-md-9">
    <div class="text-center">
      <label class="Mahal">{{event.epname}}</label>
    </div>
    <h6><p>{{event.epaddress1}}</p></h6>
    <h6><label>Phone Number : {{event.phonenumber}}</label></h6>
    <h6><label>{{event.emailid}}</label></h6>
    <h5><label>Invoice</label></h5>
    </div>
  </div>
</div>
<div class="row" *ngFor="let customer of this.getbookingDetails">
  <div class="col-md-6 ml-2">
    <h6 class="mb-1"><label>Bill To:</label></h6>
    <p class="mb-1">Bill No : {{BillNo}}</p>
    <p class="mb-1">{{customer.contactperson}}</p>
    <p class="mb-1">{{customer.address}}</p>
    <p>Phone Number : {{customer.phonenumber2}}</p>
  </div>
</div>
<div class="row mt-3">
  <div class="col-md-12">
    <div class="table-container ">
      <table class="table table-bordered">
        <thead class="width:50%">
          <tr class="text-center">
            <th scope="col" class="w-50">Description</th>
            <th scope="col" class="w-50">Amount</th>
          </tr>
        </thead>
        <tbody class="text-center">
          <tr class="table-borderless">
            <td>Event Hall Payment</td>
            <td formControlName="eventhallpayment" class=""><span class="WebRupee">&#x20B9;</span>{{eventhallpayment}}</td>
          </tr>
          <tr class="table-borderless" *ngIf="roomdisable">
            <td>Room Charges</td>
            <td class=""><span class="WebRupee">&#x20B9;</span>{{roomcharges}}</td>
          </tr>
          <tr class="table-borderless" *ngIf="kitchendisable">
            <td>Kitchen Charges</td>
            <td class=""><span class="WebRupee">&#x20B9;</span>{{kitchencharges}}</td>
          </tr>
          <tr class="table-borderless" *ngIf="openspacedisable">
            <td>Open Space charges</td>
            <td class=""><span class="WebRupee">&#x20B9;</span>{{openspacecharges}}</td>
          </tr>
          <tr class="table-borderless" *ngIf="parkingdisable">
            <td>Parking Charges</td>
            <td class=""><span class="WebRupee">&#x20B9;</span>{{parkingcharges}}</td>
          </tr>
          <tr class="table-borderless" *ngIf="activitydisable">
            <td>Activity Charges</td>
            <td class=""><span class="WebRupee">&#x20B9;</span>{{activitycharges}}</td>
          </tr>
          <tr class="table-borderless" *ngIf="amenitiesdisable">
            <td>Amenities Charges</td>
            <td class=""><span class="WebRupee">&#x20B9;</span>{{amenitiescharges}}</td>
          </tr>
          <tr class="table-borderless" *ngIf="incidentaldisable">
            <td>Incidental Charges</td>
            <td class=""><span class="WebRupee">&#x20B9;</span>{{incidentalcharges}}</td>
          </tr>
          <tr class="table-borderless" *ngIf="miscdisable">
            <td >Misc Charges</td>
            <td class=""><span class="WebRupee">&#x20B9;</span>{{misccharges}}</td>
          </tr>
          <tr class="table-borderless" *ngIf="othersdisable">
            <td >Others</td>
            <td class=""><span class="WebRupee">&#x20B9;</span>{{othercharges}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <hr class="hrline">
    <div class="row mt-2">
      <div class="col-md-5"></div>
      <div class="col-md-7">
      <table class="table text-left">
      <tbody>
        <!-- <tr class="table-borderless ">
          <td>Total Capacity :</td>
          <td>{{totalcapacity}}</td>
        </tr> -->
        <tr class="table-borderless  mb-1">
          <label></label>
          <th >Total Amount to Pay :</th>
          <td><span class="WebRupee">&#x20B9;</span>{{totalamountpay}}</td>
        </tr>
        <tr class="table-borderless  mb-1">
          <label></label>
          <th>CGST% :</th>
          <td><span class="WebRupee">&#x20B9;</span>{{cgst}}</td>
        </tr>
        <tr class="table-borderless  mb-1">
          <label></label>
          <th>SGST% :</th>
          <td><span class="WebRupee">&#x20B9;</span>{{sgst}}</td>
        </tr>
        <tr class="table-borderless mb-1">
          <label></label>
          <th>Total Inclusive of Tax :</th>
          <td><span class="WebRupee">&#x20B9;</span>{{totalinclusiveoftax}}</td>
        </tr>
        <tr class="table-borderless">
          <td class="mt-3" *ngIf="advancepaidDisable">Bill No : {{BillNo}} Adv &nbsp; <b>Date</b> : ({{currentDate}})</td>
          <td *ngIf="emptydisable"></td>
          <th class="">Advanced Paid :</th>
          <td class=""><span class="WebRupee">&#x20B9;</span>{{advancepaid}}</td>
        </tr>
        <tr class="table-borderless  mb-1">
          <label></label>
          <th>Net Value to Pay :</th>
          <td><span class="WebRupee">&#x20B9;</span>{{netvaluetopay}}</td>
        </tr>
          <!-- <tr class="table-borderless ">
            <td class="card">Total : {{totalinclusiveoftax}}</td>
          </tr> -->
      </tbody>
    </table>
  </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-1">
        <label>Signature:</label>
      </div>
        <hr class="col-md-4 hrline">
        <div class="col-md-2"></div>
        <div class="col-md-1">
          <label>Date:</label>
        </div>
        <hr class="col-md-3 hrline">
    </div>
</div>
</div>
  <div class="row mt-4">
    <div class="col-md-12">
      <h5 class="text-center font-weight-bold ">Thank You!</h5>
    </div>
  </div>
</div>
