<app-event-header></app-event-header>
<div class="container-fluid">
  <div class="mt-4">
  </div>
      <div class="row justify-content-center" *ngIf="allBoxes">
        <div class="col-md-3">
          <!-- small box -->
          <div class="small-box bg-info">
            <a routerLink="/main/event-place-registration" class="small-box-footer">
            <div class="inner">
              <h4 class="mb-4 m-3">Event Place <br> Management</h4>
            <div class="icon">
              <i class="ion ion-location"></i>
            </div>
          </div>
            </a>
          </div>
        </div>
        <div class="col-md-3">
          <!-- small box -->
          <div class="small-box bg-success">
            <a routerLink="/user-mapping-management" class="small-box bg-success" class="small-box-footer">
            <div class="inner ">
              <h4 class="mb-4 m-3">User <br>Management</h4>
            <div class="icon">
              <i class="ion ion-person-add"></i>
            </div>
            </div>
            </a>
          </div>
        </div>
        <div class="col-md-3">
          <!-- small box -->
          <div class="small-box bg-gradient-olive" >
            <a routerLink="/main/vendor-registration" class="small-box-footer">
            <div class="inner">
              <h4 class="mb-4 m-3">Vendor <br>Management</h4>
            </div>
            <div class="icon">
              <i class="ion ion-man"></i>
            </div>
            </a>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <!-- ./col -->
        <div class="col-md-3" *ngIf="allBoxes">
          <!-- small box -->
          <div class="small-box bg-gradient-purple">
            <a class="small-box-footer" routerLink="/activity-amenity-mapping">
            <div class="inner">
              <h4 class="mb-4 m-3">Activity/Amenity <br>Management</h4>
            <div class="icon">
              <i class="ion ion-pie-graph"></i>
            </div>
          </div>
        </a>
          </div>
        </div>
       <div class="col-md-3" *ngIf="bookingBox">
        <div class="small-box bg-gradient-fuchsia">
          <a class="small-box-footer" routerLink="/eventplace-booking">
          <div class="inner">
            <h4 class="mb-4 m-3">Booking <br>Management</h4>
          <div class="icon">
            <i class="ion ion-printer"></i>
          </div>
          </div>
          </a>
        </div>
      </div>
      </div>
</div>

<app-event-footer></app-event-footer>

