import { Component, OnInit, HostListener } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { AppService } from "@services/app.service";
import { EventPlace } from "@modules/event-place-management/model/eventplace"
import { EventSubPlace } from "../model/eventsub-place";
import { pipe, Subject, takeUntil } from "rxjs";
import { EPRoomsDetails } from "../model/epRooms-details";
import { EPMapRooms } from "../model/epmap-rooms";
import Swal from "sweetalert2";
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-rooms',
  templateUrl: './rooms.component.html',
  styleUrls: ['./rooms.component.scss']
})

export class RoomsComponent implements OnInit {
  private readonly FIXED_SCROLL_POSITION = 0;

  eventPlace: EventPlace[] = [];
  eventDropdown: string[] = [];
  eventSubPlace: EventSubPlace[] = [];
  eventRoomPlace: EPMapRooms[] = [];
  roomsDetails: EPMapRooms[] = [];
  eventSubDropDown: string[] = [];
  eventRoomDropDown: string[] = [];
  epIdDropDown: string | undefined;
  epSubDropDown: string | undefined;
  epRoomDropDown: string | undefined;
  destroy$: Subject<boolean> = new Subject<boolean>();
  public EPID: String;
  isUpdate = false;
  isdisabledraft = false;
  isdisablesave = false;
  isdisablenext = false;
  isDisabled: boolean = false;

  constructor(private router: Router, private appService: AppService, private toastr: ToastrService,
    private spinner: NgxSpinnerService) {
 }

  eproomsdetails = new UntypedFormGroup({

    epid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epsubid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    eproomid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    eproomname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    eproomcapacity: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    eproomsize: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    eproomlocation: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    eproomdesc: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    roomsactivestatus: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    eproomaircond: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    eproomshow: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    eproomchargable: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    eproomscharges: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    activeyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    deleteyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    createdby: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    createddate: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    roomsrequired: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),

  })

  public eproomsErrors = {
    epid: '',
    epsubid: '',
    eproomid: '',
    eproomname: '',
    eproomcapacity: '',
    eproomsize: '',
    eproomlocation: '',
    eproomdesc: '',
    roomsactivestatus: '',
    eproomaircond: '',
    eproomshow: '',
    eproomchargable: '',
    eproomscharges: '',
  }

  ngOnInit() {
    this.getEventPlaceByuserId();
    this.eproomsdetails.controls['epid'].disable();
    Object.keys(this.eproomsdetails.controls).forEach((controlName) => {
      if (controlName !== 'roomsrequired') {
        this.eproomsdetails.get(controlName).disable();
      }
    })
    // this.isdisablenext = true;

  }
  ngOnDestroy() {
    document.body.style.overflow = 'auto';
  }


  onSubmit() {
    this.formValidation("Active");

  }

  // form validation funtion call

  formValidation(status: string) {
    this.eproomsErrors.epid = "";
    this.eproomsErrors.epsubid = "";
    this.eproomsErrors.eproomid = "";
    this.eproomsErrors.eproomname = "";
    this.eproomsErrors.eproomcapacity = "";
    this.eproomsErrors.eproomsize = "";
    this.eproomsErrors.eproomaircond = "";
    this.eproomsErrors.eproomchargable = "";
    this.eproomsErrors.eproomscharges = ""


    let hasError = false;

    if (this.eproomsdetails.get('epid')?.invalid) {
      this.eproomsErrors.epid
        = "*Event place is required";
      hasError = true;
    }
    if (this.eproomsdetails.get('epsubid')?.invalid) {
      this.eproomsErrors.epsubid
        = "*Event Sub place is required";
      hasError = true;
    }
    let inputValue = this.eproomsdetails.controls['eproomname'].value;
    let outputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
    this.eproomsdetails.controls['eproomname'].setValue(outputValue)
    if (this.eproomsdetails.get('eproomname')?.invalid) {
      this.eproomsErrors.eproomname = "*Room Name is required";
      hasError = true;
    }

    if (this.eproomsdetails.get('eproomcapacity')?.invalid) {
      this.eproomsErrors.eproomcapacity = "*Room Capacity is required";
      hasError = true;
    }

    if (this.eproomsdetails.get('eproomsize')?.invalid) {
      this.eproomsErrors.eproomsize = "*Room Size is required";
      hasError = true;
    }
    let inputValue1 = this.eproomsdetails.controls['eproomlocation'].value;
    let outputValue1 = inputValue1.charAt(0).toUpperCase() + inputValue1.slice(1);
    this.eproomsdetails.controls['eproomlocation'].setValue(outputValue1)

    let inputValue2 = this.eproomsdetails.controls['eproomdesc'].value;
    let outputValue2 = inputValue2.charAt(0).toUpperCase() + inputValue2.slice(1);
    this.eproomsdetails.controls['eproomdesc'].setValue(outputValue2)

    if (this.eproomsdetails.get('eproomaircond')?.value == "") {
      this.eproomsErrors.eproomaircond = "*Room Air Condtioned is required";
      hasError = true;
    }

    if (this.eproomsdetails.get('eproomchargable')?.invalid) {
      this.eproomsErrors.eproomchargable = "*Room Chargable is required";
      hasError = true;
    }
    // if (this.eproomsdetails.get('eproomscharges')?.invalid) {
    //   this.eproomsErrors.eproomscharges = "*Room Charges is required";
    //   hasError = true;
    // }
    if (this.eproomsdetails.get('eproomscharges')?.invalid) {
      this.eproomsErrors.eproomscharges = "*Room Charges is required";
      hasError = true;
    }
    if (!hasError) {
      this.saveRooms(status);
    }
  }

  // Eventplace dropdown API function

  getEventPlaceByuserId() {
    this.appService
      .getEventPlaceByuserId(localStorage.getItem('token'))
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("Event place Dropdwon::", data);
        // @ts-ignore
        this.eventPlace = data;
        var localEventPlace: string[] = [];
        for (var ep of this.eventPlace) {
          localEventPlace.push(ep.epname)
        }
        this.eventDropdown = localEventPlace;

      })
  }

  //Subplace dropdown API function 

  getEventSubPlace() {
    this.appService
      //@ts-ignore
      .getEventSubPlace(this.epIdDropDown)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("sub dropdown::", data);
        // @ts-ignore
        this.eventSubPlace = data;
        var localEventSubPlace: string[] = [];
        for (var ep of this.eventSubPlace) {
          if (ep.epmainplace == "Yes") {
            localEventSubPlace.push(ep.epsubname + " - Main Hall")
            console.log("main hall", this.eventSubDropDown)
          } else {
            localEventSubPlace.push(ep.epsubname)
          }
          this.eventSubDropDown = localEventSubPlace;
        }
      })
  }

  // Rooms dropdown get API Function

  getRoomsDropDown() {
    this.appService
      .getRoomsDropDown(this.epIdDropDown, this.epSubDropDown)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("Test data::", data);
        // @ts-ignore
        this.eventRoomPlace = data;
        if (this.eventRoomPlace.length > 0) {
          this.eproomsdetails.controls['eproomid'].enable();
        } else {
          this.eproomsdetails.controls['eproomid'].disable();
        }
        var localRooms: string[] = [];
        for (var ep of this.eventRoomPlace) {
          localRooms.push(ep.eproomname)
        }
        this.eventRoomDropDown = localRooms;
      })
  }

  //Rooms Details autofetch API function

  getRoomDetails() {
    this.spinner.show()
    this.appService
      .getRoomDetails(this.epIdDropDown, this.epSubDropDown, this.epRoomDropDown)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("GetRooms Details::", data);
        this.spinner.hide()
        // @ts-ignore
        this.roomsDetails = data;
        for (var rooms of this.roomsDetails) {
          this.isUpdate = true;
          this.eproomsdetails.controls["eproomname"].setValue(rooms.eproomname);
          this.eproomsdetails.controls["eproomcapacity"].setValue(rooms.eproomcapacity);
          this.eproomsdetails.controls["eproomsize"].setValue(rooms.eproomsize);
          this.eproomsdetails.controls["eproomlocation"].setValue(rooms.eproomlocation);
          this.eproomsdetails.controls["eproomdesc"].setValue(rooms.eproomdesc);
          this.eproomsdetails.controls["roomsactivestatus"].setValue(rooms.roomsactivestatus);
          this.eproomsdetails.controls["eproomaircond"].setValue(rooms.eproomaircond);
          this.eproomsdetails.controls["eproomshow"].setValue(rooms.eproomshow);
          this.eproomsdetails.controls["eproomchargable"].setValue(rooms.eproomchargable);
          this.eproomsdetails.controls["eproomscharges"].setValue(rooms.eproomscharges);
        }
      }, (error: any) => {
        this.spinner.hide()
        console.log("Something went wrong");
      })
  }

  //Save & update function in Rooms

  saveRooms(status: string) {
    this.spinner.show()
    console.log("is update::", this.isUpdate)
    if (this.isUpdate) {
      this.eproomsdetails.controls["roomsactivestatus"].setValue(status);
      this.eproomsdetails.controls["activeyn"].setValue("Yes");
      this.eproomsdetails.controls["deleteyn"].setValue("No");
      this.eproomsdetails.controls["createdby"].setValue(localStorage.getItem("token"));
      this.eproomsdetails.controls["epid"].setValue(this.epIdDropDown);
      this.eproomsdetails.controls["epsubid"].setValue(this.epSubDropDown);
      this.eproomsdetails.controls["eproomid"].setValue(this.epRoomDropDown);
      console.log("Romms details::", this.eproomsdetails.value);
      this.appService.updateRooms(this.eproomsdetails.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          this.spinner.hide()
          console.log("updateRooms details ::", data);
          // @ts-ignore
          if (data.roomsactivestatus == "Draft") {
            this.successAlertDarft()
          } else {
            this.UpdateAlert();
          }
        }, (err: any) => {
          this.spinner.hide()
          this.errorAlert();
        },
          () => console.log('HTTP request completed.'));
    }

    else {
      this.eproomsdetails.controls["roomsactivestatus"].setValue(status);
      this.eproomsdetails.controls["activeyn"].setValue("Yes");
      this.eproomsdetails.controls["deleteyn"].setValue("No");
      this.eproomsdetails.controls["createdby"].setValue(localStorage.getItem("token"));
      this.eproomsdetails.controls["epid"].setValue(this.epIdDropDown);
      this.eproomsdetails.controls["epsubid"].setValue(this.epSubDropDown);
      console.log("kitchen details::", this.eproomsdetails.value);
      this.appService.saveRooms(this.eproomsdetails.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          console.log("saveRooms ::", data);
          this.spinner.hide()
          // @ts-ignore
          if (data.roomsactivestatus == "Draft") {
            this.successAlertDarft()
          } else {
            this.successAlert();
          }
        }, (err: any) => {
          this.spinner.hide()
          this.errorAlert();
        },
          () => console.log('HTTP request completed.'));
      // this.isdisablenext =true;
    }
  }
  roomsdisable(event) {
    const value = event.target.value;
    console.log("'rooms'::", value);
    if (value == "no") {
      this.eproomsdetails.disable();
      this.eproomsdetails.controls['roomsrequired'].enable();
      this.isdisablesave = false;
      this.isdisabledraft = false;
      this.isdisablenext = true;
    } else {
      if (value == "yes") {
        this.eproomsdetails.enable();
        this.isdisablesave = true;
        this.isdisabledraft = true;
        this.isdisablenext = false;
        this.disable1();
        this.disable();
      }
    }
  }

  draftButton(status: string) {
    let hasError = false;

    if (this.eproomsdetails.get('epid')?.invalid) {
      this.eproomsErrors.epid
        = "*Event place is required";
      hasError = true;
    }

    if (this.eproomsdetails.get('epsubid')?.invalid) {
      this.eproomsErrors.epsubid
        = "*Event Sub place is required";
      hasError = true;
    }
    let inputValue = this.eproomsdetails.controls['eproomname'].value;
    let outputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
    this.eproomsdetails.controls['eproomname'].setValue(outputValue)
    if (this.eproomsdetails.get('eproomname')?.invalid) {
      this.eproomsErrors.eproomname = "*Room Name is required";
      hasError = true;
    }
    if (!hasError) {
      this.saveRooms(status)
    }
  }
  nextButton() {
    this.router.navigate(['/main/kitchen'])
  }


  //EventPLace Dropdown AutoFetch function

  onShoweventsub(event) {
    const value = event.target.value;
    console.log("Event Name::", value)
    for (var EPid of this.eventPlace) {
      if (value == EPid.epname) {
        this.epIdDropDown = EPid.epid;
        console.log("ep id::", this.epIdDropDown)
        this.getEventSubPlace();
        this.isDisabled = true;
        this.disable();
      } else {
        this.isDisabled = false;
        console.log("ep id:: error")
      }
    }
  }

  disable() {
    if (this.isDisabled) {
      this.eproomsdetails.controls['epsubid'].enable();
    } else {
      this.eproomsdetails.controls['epsubid'].disable();
    }
  }

  //SubPlace Details Dropdown AutoFetch function

  onShowkitchen(event) {
    const value = event.target.value.replace(" - Main Hall", "");
    console.log("Rooms type::", value)
    for (var EPid of this.eventSubPlace) {
      if (value == EPid.epsubname) {
        this.epSubDropDown = EPid.epsubid;
        console.log("ep id::", this.epSubDropDown)
        this.isDisabled = true;
        this.disable1();
        this.getRoomsDropDown();
      } else {
        this.isDisabled = false;
        console.log("ep id:: error")
      }
    }
  }

  disable1() {
    if (this.isDisabled) {
      this.eproomsdetails.controls['eproomid'].enable();
    } else {
      this.eproomsdetails.controls['eproomid'].disable();
    }
  }

  //Rooms Details autofetch funtion

  onShowkitchendetails(event) {
    const value = event.target.value;
    console.log("Rooms details type::", value)
    for (var EPid of this.eventRoomPlace) {
      if (value == EPid.eproomname) {
        this.epRoomDropDown = EPid.eproomid;
        console.log("ep id::", this.epSubDropDown)
        this.getRoomDetails();
      }
    }
  }

  // Only allow numeric values

  OnlyNumbers($event) {
    let regex: RegExp = new RegExp(/^[0-9]{1,}$/g);
    let specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowRight', 'ArrowLeft'];
    if (specialKeys.indexOf($event.key) !== -1) {
      return;
    } else {
      if (regex.test($event.key)) {
        return true;
      } else {
        return false;
      }
    }
  }

  //Rooms successAlert popup message

  successAlert() {
    Swal.fire({
      title: "Your Registration successfully Completed, Do you want to register another Room ?",
      icon: "success",
      showDenyButton: true,
      confirmButtonColor: "#4fab69",
      denyButtonColor: "#c73636",
      confirmButtonText: "Yes",
      denyButtonText: "No",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.eproomsdetails.controls["epid"].setValue("");
        this.eproomsdetails.controls["epsubid"].setValue("");
        this.eproomsdetails.controls["eproomname"].setValue("");
        this.eproomsdetails.controls["eproomcapacity"].setValue("");
        this.eproomsdetails.controls["eproomsize"].setValue("");
        this.eproomsdetails.controls["eproomlocation"].setValue("");
        this.eproomsdetails.controls["eproomdesc"].setValue("");
        this.eproomsdetails.controls["roomsactivestatus"].setValue("");
        this.eproomsdetails.controls["eproomaircond"].setValue("");
        this.eproomsdetails.controls["eproomshow"].setValue("");
        this.eproomsdetails.controls["eproomchargable"].setValue("");
        this.eproomsdetails.controls["eproomscharges"].setValue("");
      } else {
        this.router.navigate(['/main/kitchen'])
      }
    });
  }

  //Rooms UpdateAlert popup message

  UpdateAlert() {
    Swal.fire({
      title: "Your Registration Updated Sucessfully",
      icon: "success",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.reload();
      }
    });
  }
  //Rooms successAlertDarft popup message
  successAlertDarft() {
    Swal.fire({
      title: "Your Registration successfully saved as draft, Do you want to register another Room ?",
      icon: "success",
      showDenyButton: true,
      confirmButtonColor: "#4fab69",
      denyButtonColor: "#c73636",
      confirmButtonText: "Yes",
      denyButtonText: "No",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.eproomsdetails.controls["epid"].setValue("");
        this.eproomsdetails.controls["epsubid"].setValue("");
        this.eproomsdetails.controls["eproomname"].setValue("");
        this.eproomsdetails.controls["eproomcapacity"].setValue("");
        this.eproomsdetails.controls["eproomsize"].setValue("");
        this.eproomsdetails.controls["eproomlocation"].setValue("");
        this.eproomsdetails.controls["eproomdesc"].setValue("");
        this.eproomsdetails.controls["roomsactivestatus"].setValue("");
        this.eproomsdetails.controls["eproomaircond"].setValue("");
        this.eproomsdetails.controls["eproomshow"].setValue("");
        this.eproomsdetails.controls["eproomchargable"].setValue("");
        this.eproomsdetails.controls["eproomscharges"].setValue("");
      } else {
        this.router.navigate(['/main/kitchen'])
      }
    });
  }
  //Rooms errorAlert popup message
  errorAlert() {
    Swal.fire({
      title: "Sorry, Please Try Again Your Registration Process",
      icon: "error",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    });
  }



}

