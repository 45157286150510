import {Component, HostBinding, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {FormControl, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AppService} from '@services/app.service';
import {Router} from "@angular/router";
import {ToastrService} from 'ngx-toastr';
import {pipe, Subject, takeUntil} from "rxjs";
import Swal from 'sweetalert2';
import {OtpResponse} from "@modules/event-place-management/model/otpResponse";
import { EPUSerDetails } from '@modules/event-place-management/model/epuser-details';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {
  epuserdetails: EPUSerDetails [] = [];
  getEPUserDetails: EPUSerDetails [] = [];
  getEmailId : string [] = []
  passwordShown = false;
  isVisible = false;
  openOTP = false;
  otpResponse: OtpResponse | undefined;
  verifiedOTP = false;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private apiService: AppService, private router: Router, private toastr: ToastrService,
    private spinner : NgxSpinnerService) {

  }

  epUser = new UntypedFormGroup({
    epuserid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epuserfirstname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epuserlastname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epuseremailidpy: new UntypedFormControl('', [Validators.required, Validators.nullValidator, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
    epuseremailidsy: new UntypedFormControl('', [Validators.required, Validators.nullValidator, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
    epuserloginpassword: new UntypedFormControl('', [Validators.required, Validators.nullValidator, Validators.minLength(8)]),
    epusername: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epusertype: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epuseraddress1: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epuseraddress2: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epusercity: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epuserstate: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epuserpincode: new UntypedFormControl('', [Validators.required, Validators.nullValidator, Validators.pattern("^((\\+91-?)|0)?[0-9]{6}$")]),
    userCountry: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    activeYn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    deleteYn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    phonenumber: new UntypedFormControl('', [Validators.required, Validators.nullValidator, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
    role: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    acknowledgement: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
  })

  otpInput = new UntypedFormGroup({
    otp: new UntypedFormControl('', [Validators.required, Validators.nullValidator])
  })

  public epUserErrors = {
    epuserfirstname: '',
    epuserlastname: '',
    epuseremailidpy: '',
    // epuseremailidsy: '',
    epuserloginpassword: '',
    epusername: '',
    epusertype: '',
    epuseraddress1: '',
    epuseraddress2: '',
    epusercity: '',
    epuserstate: '',
    phonenumber: '',
    epuserpincode: '',
    userCountry: '',
    acknowledgement: '',
    role: ''
  }

  ngOnInit() {
    this.getEPUser()
    this.epUser.controls['userCountry'].setValue("India")
    const reloadFlag = 'reloadFlag';
    const hasReloaded = sessionStorage.getItem(reloadFlag);

    if (!hasReloaded) {
      sessionStorage.setItem(reloadFlag, 'true');
      location.reload();
    } else {
      sessionStorage.removeItem(reloadFlag);
    }
  }


  ngOnDestroy() {
  }

  onSubmit() {
    this.formValidation();
  }

  formValidation() {
    this.epUserErrors.epuserfirstname = "";
    this.epUserErrors.epuserlastname = "";
    this.epUserErrors.epuseremailidpy = "";
    // this.epUserErrors.epuseremailidsy = "";
    this.epUserErrors.epuserloginpassword = "";
    this.epUserErrors.epusertype = "";
    this.epUserErrors.epusername = "";
    this.epUserErrors.epuseraddress1 = "";
    this.epUserErrors.epuseraddress2 = "";
    this.epUserErrors.epusercity = "";
    this.epUserErrors.epuserstate = "";
    this.epUserErrors.phonenumber = "";
    this.epUserErrors.epuserpincode = "",
      this.epUserErrors.userCountry = "";
    this.epUserErrors.acknowledgement = "";
    this.epUserErrors.role = "";
    let hasError = false;

    if (this.epUser.get('epuserfirstname')?.invalid) {
      this.epUserErrors.epuserfirstname
        = "*First Name is required";
      hasError = true;
    }

    if (this.epUser.get('epuserlastname')?.invalid) {
      this.epUserErrors.epuserlastname = "*Last Name is required";
      hasError = true;
    }

    if (this.epUser.get('epuseremailidpy')?.invalid) {
      this.epUserErrors.epuseremailidpy = "*First Email Id is required";
      hasError = true;
    }
    if (this.epUser.get('epuseremailidpy')?.invalid) {
      if (this.epUser.get('epuseremailidpy').errors.required) {
        this.epUserErrors.epuseremailidpy = "* Email id is required";
      }
      if (this.epUser.get('epuseremailidpy').errors.pattern) {
        this.epUserErrors.epuseremailidpy = "*Enter Valid Email.(Ex:example@gmail.com)";
      }
      hasError = true;
    }

    if (this.epUser.get('epuserloginpassword')?.invalid) {
      if (this.epUser.get('epuserloginpassword').errors.required) {
        this.epUserErrors.epuserloginpassword = "*Password is required";
        hasError = true;
      }
      if (this.epUser.get('epuserloginpassword').errors.minlength) {
        this.epUserErrors.epuserloginpassword = "*Must be at least 8 characters";
      }
    }
    if (this.epUser.get('epusername')?.invalid) {
      this.epUserErrors.epusername = "*Username is required";
      hasError = true;
    }

    if (this.epUser.get('role')?.invalid) {
      this.epUserErrors.role = "*User Type is required";
      hasError = true;
    }
    if (this.epUser.get('epuseraddress1')?.invalid) {
      this.epUserErrors.epuseraddress1 = "*Address 1 is required";
      hasError = true;
    }
    /*if (this.epUser.get('epuseraddress2')?.invalid) {
      this.epUserErrors.epuseraddress2 = "*Address 2 is required";
      hasError = true;
    }*/
    if (this.epUser.get('epusercity')?.value == "") {
      this.epUserErrors.epusercity = "*City is required";
      hasError = true;
    }
    if (this.epUser.get('epuserstate')?.invalid) {
      this.epUserErrors.epuserstate = "*State is required";
      hasError = true;
    }
    if (this.epUser.get('phonenumber')?.invalid) {
      if (this.epUser.get('phonenumber').errors.required) {
        this.epUserErrors.phonenumber = "*Phone Number is required";
      }
      if (this.epUser.get('phonenumber').errors.pattern) {
        this.epUserErrors.phonenumber = "*Enter the 10 digits of the Phone Number";
      }
      hasError = true;
    }
    if (this.epUser.get('epuserpincode')?.invalid) {
      if (this.epUser.get('epuserpincode').errors.required) {
        this.epUserErrors.epuserpincode = "*Pincode is required";
      }
      if (this.epUser.get('epuserpincode').errors.pattern) {
        this.epUserErrors.epuserpincode = "*Please, Enter 6 digit Pincode number.";
      }
      hasError = true;
    }
    if (this.epUser.get('userCountry')?.invalid) {
      this.epUserErrors.userCountry = "*Country is required";
      hasError = true;
    }
    if (this.epUser.get('acknowledgement')?.invalid) {
      this.epUserErrors.acknowledgement = "*Terms&Conditions is required";
      hasError = true;
    }
    if (!hasError) {
      this.saveEPUser();
    }

  }

  sendOtp() {
    this.epUserErrors.epuseremailidpy = "";

    let hasError = false;
    var emailId = this.epUser.get('epuseremailidpy').value
    console.log("emaildid::",emailId)
    if(!this.epUser.get('epuseremailidpy')?.invalid){
      console.log("emailid2::",emailId)
    if(this.getEmailId.includes(emailId)){
      console.log("emailid3::",emailId)
      this.epUserErrors.epuseremailidpy ="*Email id is already existing";
        hasError = true;
    }
  }
    if (this.epUser.get('epuseremailidpy')?.invalid) {
      if (this.epUser.get('epuseremailidpy').errors.required) {
        this.epUserErrors.epuseremailidpy = "* Email id is required";
      }
      if (this.epUser.get('epuseremailidpy').errors.pattern) {
        this.epUserErrors.epuseremailidpy = "*Enter Valid Email.(Ex:example@gmail.com)";
      }
      hasError = true;
    }

    if (!hasError) {
      this.openOTP = true;
      this.apiService.sendEmailOtp(this.epUser.get('epuseremailidpy').value)
        .pipe(takeUntil(this.destroy$))
        .subscribe(data => {
          console.log("Email OTP Sent Status:", data);
          // @ts-ignore
          this.otpResponse = data
          console.log("this.otpResponse::", this.otpResponse)
        }, (error: any) => {
          console.log("Something went wrong");
        });
    }
  }


  saveEPUser() {
    this.spinner.show()
    if (this.verifiedOTP) {
      this.epUser.controls["activeYn"].setValue('Yes');
      this.epUser.controls["deleteYn"].setValue('No');
      this.epUser.controls["acknowledgement"].setValue('Enable');
      this.apiService.saveEPUser(this.epUser.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          this.spinner.hide()
            console.log("EPUSerDetails ::", data);
            // @ts-ignore
            this.successAlert();
          }, (err: any) => {
            this.spinner.hide()
            this.errorAlert()
          },
          () => console.log('HTTP request completed.'));
    } else {
      this.toastr.error("Email is Not Verified");
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  terms() {
    this.isVisible = true;
  }

  successAlert() {
    Swal.fire({
      title: "Your Registration Completed",
      icon: "success",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate([""])
      }
    });
  }

  errorAlert() {
    Swal.fire({
      title: "Sorry, Please Try Again Your Registration Process",
      icon: "error",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    });
  }

  getOtp() {
    this.openOTP = true;
  }

  submitOtp() {
    console.log('Submitted OTP:', this.otpInput.get('otp').value);
    const otp = this.otpInput.get('otp').value
    if (otp != null || otp != undefined || otp != "") {
      this.apiService.verifyEmailOtp(this.epUser.get('epuseremailidpy').value, otp)
        .pipe(takeUntil(this.destroy$))
        .subscribe(data => {
          console.log("OTP Verify status:", data)

          // @ts-ignore
          this.otpResponse = data;
          console.log("this.otpResponse:", this.otpResponse)
          if (this.otpResponse.success) {
            this.verifiedOTP = true;
            this.openOTP = false;
            this.toastr.success("OTP Verified");
          } else {
            this.toastr.error("OTP is Incorrect");
          }
        }, (error: any) => {
          console.log("Something went wrong");
        })

    } else {
      this.toastr.error("Please Enter the OTP");
    }
  }

  close() {
    this.openOTP = false;
  }

  getEPUser(){
    this.apiService.getUserRegDetails()
    .pipe(takeUntil(this.destroy$))
    .subscribe((data) => {
      console.log("EPUserDetails::",data)
      //@ts-ignore
      this.getEPUserDetails = data
      var localEmailid : string[] = []
      for(let ep of this.getEPUserDetails){
      localEmailid.push(ep.epuseremailidpy)
      }
      this.getEmailId = localEmailid
      console.log("getEmailId::",this.getEmailId)
    },(err: any) =>{
    },() => console.log("HTTP request completed"))
  }
}
