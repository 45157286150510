<div class="row">
    <div class="col-md-11 path-name">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li aria-current="page" class="breadcrumb-item active">UI-kit</li>
            </ol>
        </nav>
    </div>
</div>
<div class="row page-request sticky_action_bar">
    <div class="col-md-12 ">
        <div class="page-title justify-content-between"><h3 class="admin-page-heading">Title </h3>
            <div class="btn-request">
                <button class="btn btn-primary btn-ml6" type="submit">New Record</button>
            </div>
        </div>
    </div>
</div>
<div class="mrg-b25"><h4 class="admin-page-heading col-ptb">Buttons</h4>
    <button class="btn btn-secondary" type="button">Back</button>
    <button class="btn btn-primary btn-ml6" type="submit">Submit</button>
    <button class="btn btn-white btn-ml6" type="button">Save</button>
</div>
<div class="d-flex mrg-b25">
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 form-group"><h4 class="admin-page-heading">Radio Buttons</h4>
            <div class="form-check-inline col-mrb"><label class="customradio"><span class="radiotextsty">Yes</span>
                <input checked="checked" name="radio" type="radio"> <span class="checkmark"></span> </label>        
                <label class="customradio"><span class="radiotextsty">No</span> <input name="radio" type="radio"> <span
                        class="checkmark"></span> </label></div>
        </div>
    </div>
</div>
<div class="mrg-b25"><h4 class="admin-page-heading">Input Field</h4>
    <div class="row col-mrb">
        <div class="col-md-6">
            <div class="row"><label class="col-md-4 col-form-label text-md-left" for="email_address">User Name</label>
                <div class="col-md-8"><input autofocus class="form-control" name="User Name" required type="text"></div>
            </div>
        </div>
        <!-- <div class="col-md-6">      <div class="form-group row">        <label class="col-md-4 col-form-label text-md-left">Subject</label>        <div class="col-md-8">          <div class="minimal" id="arrow" (click)="test($event)" (focusout)="selectFocusOut($event)">            <select class="form-control w-100" id="arrow-1" #arrow (click)="ChangeScreen($event)">              <option selected>Model</option>              <option>Accord</option>              <option>Camry</option>              <option>Civic</option>              <option>Rapid</option>              <option>Octavia</option>            </select>          </div>        </div>      </div>    </div> -->
        <div class="col-md-6">
            <div class="form-group row"><label class="col-md-4 col-form-label text-md-left">Subject</label>
                <div class="col-md-8">
                    <div (click)="test($event)" (focusout)="selectFocusOut($event)" class="" id="make1"><select
                            class="form-control w-100 minimal">
                        <option selected>Model</option>
                        <option>Accord</option>
                        <option>Camry</option>
                        <option>Civic</option>
                        <option>Rapid</option>
                        <option>Octavia</option>
                    </select></div>
                </div>
            </div>
        </div>
    </div>
    <div><h4 class="admin-page-heading">Textarea</h4>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group row col-mrb"><label class="col-md-2 col-form-label text-md-left">Notice
                    Heading</label>
                    <div class="col-md-10"><textarea class="form-control" height="175px" name="description"
                                                     type="text"></textarea></div>
                </div>
            </div>
        </div>
    </div>
    <div class="mrg-b25"><h4 class="admin-page-heading">Search Box</h4>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group row col-mrb"><label
                        class="search col-md-2 col-form-label text-md-left">Search</label>
                    <div class="search-box col-md-10"><input class="search-input" id="search"
                                                             placeholder="What are you looking for?"
                                                             type="text"/>
                        <button class="search-button"><i class="fa fa-search search-icon"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mrg-b25">
        <div class="col-lg-12"><h4 class="admin-page-heading">Table</h4></div>
    </div>
    <div class="table-responsive">
        <table class="table table-all">
            <thead>
            <tr>
                <th scope="col">Seral No.</th>
                <th scope="col">First</th>
                <th scope="col">Last</th>
                <th scope="col">Handing1</th>
                <th scope="col">Handing2</th>
                <th scope="col">Handing3</th>
                <th scope="col">Handing4</th>
                <th scope="col">Handing5</th>
                <th scope="col">Handing6</th>
                <th scope="col">Handing7</th>
                <th scope="col">Handing8</th>
                <th scope="col">Handing9</th>
                <th scope="col">Handing10</th>
                <th scope="col">Handing11</th>
                <th scope="col">Handing12</th>
                <th scope="col">Handing13</th>
                <th scope="col">Handing14</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>1</td>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
                <td>cell</td>
                <td>cell</td>
                <td>cell</td>
                <td>cell</td>
                <td>cell</td>
                <td>cell</td>
                <td>cell</td>
                <td>cell</td>
                <td>cell</td>
                <td>cell</td>
                <td>cell</td>
                <td>cell</td>
                <td>cell</td>
            </tr>
            <tr>
                <td>2</td>
                <td>Jacob</td>
                <td>Thorn</td>
                <td>@fat</td>
                <td>cell</td>
                <td>cell</td>
                <td>cell</td>
                <td>cell</td>
                <td>cell</td>
                <td>cell</td>
                <td>cell</td>
                <td>cell</td>
                <td>cell</td>
                <td>cell</td>
                <td>cell</td>
                <td>cell</td>
                <td>cell</td>
            </tr>
            <tr>
                <td>3</td>
                <td>Larry</td>
                <td>the Bird</td>
                <td>@twitter</td>
                <td>cell</td>
                <td>cell</td>
                <td>cell</td>
                <td>cell</td>
                <td>cell</td>
                <td>cell</td>
                <td>cell</td>
                <td>cell</td>
                <td>cell</td>
                <td>cell</td>
                <td>cell</td>
                <td>cell</td>
                <td>cell</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="d-flex justify-content-end w-100">
        <nav aria-label="Page navigation example">
            <ul class="pagination">
                <li class="page-item"><a aria-label="Previous" class="page-link" href="#"> <i aria-hidden="true"
                                                                                              class="fa fa-chevron-left"></i>
                </a></li>
                <li class="page-item"><a class="page-link" href="#">1</a></li>
                <li class="page-item"><a class="page-link" href="#">2</a></li>
                <li class="page-item"><a class="page-link" href="#">3</a></li>
                <li class="page-item"><a aria-label="Next" class="page-link" href="#"> <i aria-hidden="true"
                                                                                          class="fa fa-chevron-right"></i> </a>
                </li>
            </ul>
        </nav>
    </div>
    <div>
        <div class="row mrg-b25">
            <div class="col-lg-12"><h4 class="admin-page-heading">Table fixed header</h4></div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="table-responsive">
                    <table class="table table-fixed">
                        <thead>
                        <tr>
                            <th class="col-3" scope="col">#</th>
                            <th class="col-3" scope="col">First</th>
                            <th class="col-3" scope="col">Last</th>
                            <th class="col-3" scope="col">Handle</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td class="col-3" scope="row">1</td>
                            <td class="col-3">Mark</td>
                            <td class="col-3">Otto</td>
                            <td class="col-3">@mdo</td>
                        </tr>
                        <tr>
                            <td class="col-3" scope="row">2</td>
                            <td class="col-3">Jacob</td>
                            <td class="col-3">Thornton</td>
                            <td class="col-3">@fat</td>
                        </tr>
                        <tr>
                            <td class="col-3" scope="row">3</td>
                            <td class="col-6" colspan="2">Larry the Bird</td>
                            <td class="col-3">@twitter</td>
                        </tr>
                        <tr>
                            <td class="col-3" scope="row">4</td>
                            <td class="col-3">Martin</td>
                            <td class="col-3">Williams</td>
                            <td class="col-3">@Marty</td>
                        </tr>
                        <tr>
                            <td class="col-3" scope="row">5</td>
                            <td class="col-3" colspan="2">Sam</td>
                            <td class="col-3" colspan="2">Pascal</td>
                            <td class="col-3">@sam</td>
                        </tr>
                        <tr>
                            <td class="col-3" scope="row">6</td>
                            <td class="col-3">John</td>
                            <td class="col-3">Green</td>
                            <td class="col-3">@john</td>
                        </tr>
                        <tr>
                            <td class="col-3" scope="row">7</td>
                            <td class="col-3" colspan="2">David</td>
                            <td class="col-3" colspan="2">Bowie</td>
                            <td class="col-3">@david</td>
                        </tr>
                        <tr>
                            <td class="col-3" scope="row">8</td>
                            <td class="col-3">Pedro</td>
                            <td class="col-3">Rodriguez</td>
                            <td class="col-3">@rod</td>
                        </tr>
                        <tr>
                            <td class="col-3" scope="row">5</td>
                            <td class="col-3" colspan="2">Sam</td>
                            <td class="col-3" colspan="2">Pascal</td>
                            <td class="col-3">@sam</td>
                        </tr>
                        <tr>
                            <td class="col-3" scope="row">10</td>
                            <td class="col-3">Jacob</td>
                            <td class="col-3">Thornton</td>
                            <td class="col-3">@fat</td>
                        </tr>
                        <tr>
                            <td class="col-3" scope="row">11</td>
                            <td class="col-6" colspan="2">Larry the Bird</td>
                            <td class="col-3">@twitter</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div class="row mrg-b25">
            <div class="col-lg-12"><h4 class="admin-page-heading">Accordion Tabs </h4></div>
        </div>
        <div class="accordion" id="accordionExample">
            <div class="item">
                <div class="item-header" id="headingOne">
                    <h2 class="mb-0">
                        <button aria-controls="collapseOne" aria-expanded="false" class="btn btn-link collapsed"
                                data-target="#collapseOne" data-toggle="collapse" type="button">
                            Collapsible Item #1 <i aria-hidden="true" class="fa fa-angle-down"></i></button>
                    </h2>
                </div>
                <div aria-labelledby="headingOne" class="collapse" data-parent="#accordionExample" id="collapseOne">
                    <div class="t-p"> It is a long established fact that a reader will be distracted by the readable
                        content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a
                        more-or-less normal distribution of letters, as opposed to using 'Content here, content here',
                        making it look like readable English. Many desktop publishing packages and web page editors now
                        use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many
                        web sites still in their infancy. Various versions have evolved over the years, sometimes by
                        accident, sometimes on purpose (injected humour and the like).
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="item-header" id="headingTwo">
                    <h2 class="mb-0">
                        <button aria-controls="collapseTwo" aria-expanded="false" class="btn btn-link collapsed"
                                data-target="#collapseTwo" data-toggle="collapse" type="button">
                            Collapsible Item #2 <i aria-hidden="true" class="fa fa-angle-down"></i></button>
                    </h2>
                </div>
                <div aria-labelledby="headingTwo" class="collapse" data-parent="#accordionExample" id="collapseTwo">
                    <div class="t-p"> It is a long established fact that a reader will be distracted by the readable
                        content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a
                        more-or-less normal distribution of letters, as opposed to using 'Content here, content here',
                        making it look like readable English. Many desktop publishing packages and web page editors now
                        use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many
                        web sites still in their infancy. Various versions have evolved over the years, sometimes by
                        accident, sometimes on purpose (injected humour and the like).
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="item-header" id="headingThree">
                    <h2 class="mb-0">
                        <button aria-controls="collapseThree" aria-expanded="false" class="btn btn-link collapsed"
                                data-target="#collapseThree" data-toggle="collapse" type="button">
                            Collapsible Item #3 <i aria-hidden="true" class="fa fa-angle-down"></i></button>
                    </h2>
                </div>
                <div aria-labelledby="headingThree" class="collapse" data-parent="#accordionExample" id="collapseThree">
                    <div class="t-p"> It is a long established fact that a reader will be distracted by the readable
                        content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a
                        more-or-less normal distribution of letters, as opposed to using 'Content here, content here',
                        making it look like readable English. Many desktop publishing packages and web page editors now
                        use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many
                        web sites still in their infancy. Various versions have evolved over the years, sometimes by
                        accident, sometimes on purpose (injected humour and the like).
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="item-header" id="headingFour">
                    <h2 class="mb-0">
                        <button aria-controls="collapseFour" aria-expanded="false" class="btn btn-link collapsed"
                                data-target="#collapseFour" data-toggle="collapse" type="button">
                            Collapsible Item #4 <i aria-hidden="true" class="fa fa-angle-down"></i></button>
                    </h2>
                </div>
                <div aria-labelledby="headingFour" class="collapse" data-parent="#accordionExample" id="collapseFour">
                    <div class="t-p"> It is a long established fact that a reader will be distracted by the readable
                        content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a
                        more-or-less normal distribution of letters, as opposed to using 'Content here, content here',
                        making it look like readable English. Many desktop publishing packages and web page editors now
                        use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many
                        web sites still in their infancy. Various versions have evolved over the years, sometimes by
                        accident, sometimes on purpose (injected humour and the like).
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div class="row">
            <div class="col-md-12 tab"><h4 class="admin-page-heading mrg-b25">Tab</h4>
                <div class="tab-horizontal-panel">
                    <div class="tab-horizontal-line">
                        <ul class="nav nav-tabs ">
                            <li (click)="onClick(1)" [ngClass]="{'active': tab==='tab1'}"><a data-toggle="tab"
                                                                                             href="#tab_default_1"> Company
                                or Individual </a></li>
                            <li (click)="onClick(2)" [ngClass]="{'active': tab==='tab2'}"><a data-toggle="tab"
                                                                                             href="#tab_default_2"> Report
                                title & Category </a></li>
                            <li (click)="onClick(3)" [ngClass]="{'active': tab==='tab3'}"><a data-toggle="tab"
                                                                                             href="#tab_default_3"> Your
                                Reports </a></li>
                            <li (click)="onClick(4)" [ngClass]="{'active': tab==='tab4'}"><a data-toggle="tab"
                                                                                             href="#tab_default_4">
                                Documents </a></li>
                            <li (click)="onClick(5)" [ngClass]="{'active': tab==='tab5'}"><a data-toggle="tab"
                                                                                             href="#tab_default_5"> T&C </a>
                            </li>
                        </ul>
                        <div class="tab-content">
                            <div class="tab-pane active" id="tab_default_1"><p> Et et consectetur ipsum labore excepteur
                                est proident excepteur ad velit occaecat qui minim occaecat veniam. Fugiat veniam
                                incididunt anim aliqua enim pariatur veniam sunt est aute sit dolor anim. Velit non
                                irure adipisicing aliqua ullamco irure incididunt irure non esse consectetur nostrud
                                minim non minim occaecat. Amet duis do nisi duis veniam non est eiusmod tempor
                                incididunt tempor dolor ipsum in qui sit. Exercitation mollit sit culpa nisi culpa non
                                adipisicing reprehenderit do dolore. Duis reprehenderit occaecat anim ullamco ad duis
                                occaecat ex. </p></div>
                            <div class="tab-pane" id="tab_default_2"><p> Excepteur est proident excepteur ad velit
                                occaecat qui minim occaecat veniam. Fugiat veniam incididunt anim aliqua enim pariatur
                                veniam sunt est aute sit dolor anim. Velit non irure adipisicing aliqua ullamco irure
                                incididunt irure non esse consectetur nostrud minim non minim occaecat. Amet duis do
                                nisi duis veniam non est eiusmod tempor incididunt tempor dolor ipsum in qui sit.
                                Exercitation mollit sit culpa nisi culpa non adipisicing reprehenderit do dolore. Duis
                                reprehenderit occaecat anim ullamco ad duis occaecat ex. </p></div>
                            <div class="tab-pane" id="tab_default_3"><p> Labore excepteur est proident excepteur ad
                                velit occaecat qui minim occaecat veniam. Fugiat veniam incididunt anim aliqua enim
                                pariatur veniam sunt est aute sit dolor anim. Velit non irure adipisicing aliqua ullamco
                                irure incididunt irure non esse consectetur nostrud minim non minim occaecat. Amet duis
                                do nisi duis veniam non est eiusmod tempor incididunt tempor dolor ipsum in qui sit.
                                Exercitation mollit sit culpa nisi culpa non adipisicing reprehenderit do dolore. Duis
                                reprehenderit occaecat anim ullamco ad duis occaecat ex. </p></div>
                            <div class="tab-pane" id="tab_default_4"><p> Proident excepteur ad velit occaecat qui minim
                                occaecat veniam. Fugiat veniam incididunt anim aliqua enim pariatur veniam sunt est aute
                                sit dolor anim. Velit non irure adipisicing aliqua ullamco irure incididunt irure non
                                esse consectetur nostrud minim non minim occaecat. Amet duis do nisi duis veniam non est
                                eiusmod tempor incididunt tempor dolor ipsum in qui sit. Exercitation mollit sit culpa
                                nisi culpa non adipisicing reprehenderit do dolore. Duis reprehenderit occaecat anim
                                ullamco ad duis occaecat ex. </p></div>
                            <div class="tab-pane" id="tab_default_5"><p> Fugiat veniam incididunt anim aliqua enim
                                pariatur veniam sunt est aute sit dolor anim. Velit non irure adipisicing aliqua ullamco
                                irure incididunt irure non esse consectetur nostrud minim non minim occaecat. Amet duis
                                do nisi duis veniam non est eiusmod tempor incididunt tempor dolor ipsum in qui sit.
                                Exercitation mollit sit culpa nisi culpa non adipisicing reprehenderit do dolore. Duis
                                reprehenderit occaecat anim ullamco ad duis occaecat ex. </p></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

