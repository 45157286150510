import {EventPlaceBooking} from "@modules/ep-booking-management/model/eventplace-booking";
import {EventAreaIncidental} from "@modules/ep-booking-management/model/eventarea-incidental";
import { EventPaymentDetails } from "./event-payment-details";

export class BookingareaDataService{
    public bookingData : EventPlaceBooking | undefined;
    public updateInc: EventAreaIncidental | undefined;
    public savepaymentdata : EventPaymentDetails | undefined;
    public paymentdata : EventPaymentDetails | undefined;
}
