import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { AppService } from "@services/app.service";
import { EPVendorRegister } from "@modules/event-place-management/model/ep-vendorregister";
import { EventPlace } from "@modules/event-place-management/model/eventplace";
import { EventSubPlace } from "../model/eventsub-place";
import { pipe, Subject, takeUntil } from "rxjs";
import Swal from "sweetalert2";
import { NgxSpinnerService } from "ngx-spinner";


@Component({
	selector: 'app-vendor-registration',
	templateUrl: './vendor-registration.component.html',
	styleUrls: ['./vendor-registration.component.scss']
})

export class VendorRegistrationComponent implements OnInit {

	eventPlace: EventPlace[] = [];
	epIdDropDown: string | undefined;
	eventSubPlace: EventSubPlace[] = [];
	eventSubDropDown: string[] = [];
	eventDropdown: string[] = [];
	epSubDropDown: string | undefined;
	eventVendorPlace: EPVendorRegister[] = [];
	eventVendorDropDown: string[] = [];
	epVendorDropDown: string | undefined;
	vendorDetails: EPVendorRegister[] = [];
	isDisabled: boolean = false;
	destroy$: Subject<boolean> = new Subject<boolean>();
	public EPID: String;
	isUpdate = false;
	constructor(private router: Router, private appService: AppService, private toastr: ToastrService,
		private spinner : NgxSpinnerService) {

	}
	epvendorregister = new UntypedFormGroup({
		epid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
		epsubid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
		ep3rdpartyvendorid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
		ep3rdpartyvendorname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
		ep3rdpartyvendoraddress1: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
		ep3rdpartyvendoraddress2: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
		ep3rdpartyvendorcity: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
		ep3rdpartyvendorstate: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
		ep3rdpartyvendorcountry: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
		ep3rdpartyvendoraadharid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
		ep3rdpartyvendorbankacno: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
		ep3rdpartyvendorbankifsc: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
		ep3rdpartyvendoreventacceptance: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
		vendoractivestatus: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
		// ep3rdpartyvendorshow: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
		ep3rdpartyvendorsupplydesc: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
		activeyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
		deleteyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
		createdby: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
		// vendorrequired: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
	})

	public epvendorErrors = {
		epid: '',
		epsubid: '',
		ep3rdpartyvendorid: '',
		ep3rdpartyvendorname: '',
		ep3rdpartyvendoraddress1: '',
		ep3rdpartyvendoraddress2: '',
		ep3rdpartyvendorcity: '',
		ep3rdpartyvendorstate: '',
		ep3rdpartyvendorcountry: '',
		ep3rdpartyvendoraadharid: '',
		ep3rdpartyvendorbankacno: '',
		ep3rdpartyvendorbankifsc: '',
		ep3rdpartyvendoreventacceptance: '',
		vendoractivestatus: '',
		// ep3rdpartyvendorshow: '',
		ep3rdpartyvendorsupplydesc: '',
	}

	ngOnInit() {
		this.getEventPlaceByuserId();
		this.epvendorregister.controls['ep3rdpartyvendorcountry'].setValue("India")
	}


	ngOnDestroy() {
		document.body.style.overflow = 'auto';
	}

	onSubmit() {
		this.formValidation("Active");
	}
	/* Vendor Registration formvalidation function */
	formValidation(status: string) {
		this.epvendorErrors.epid = "";
		this.epvendorErrors.epsubid = "";
		this.epvendorErrors.ep3rdpartyvendorname = "";
		this.epvendorErrors.ep3rdpartyvendoraddress1 = "";
		this.epvendorErrors.ep3rdpartyvendoraddress2 = "";
		this.epvendorErrors.ep3rdpartyvendorcity = "";
		this.epvendorErrors.ep3rdpartyvendorstate = "";
		this.epvendorErrors.ep3rdpartyvendorcountry = "";
		this.epvendorErrors.ep3rdpartyvendoraadharid = "";
		this.epvendorErrors.ep3rdpartyvendorbankacno = "";
		this.epvendorErrors.ep3rdpartyvendorbankifsc = "";
		this.epvendorErrors.ep3rdpartyvendoreventacceptance = "";
		this.epvendorErrors.vendoractivestatus = "";
		let hasError = false;

		if (this.epvendorregister.get('epid')?.invalid) {
			this.epvendorErrors.epid = "*Event Place is required";
			hasError = true;
		}
		if (this.epvendorregister.get('epsubid')?.invalid) {
			this.epvendorErrors.epsubid = "*Event Sub Place is required";
			hasError = true;
		}

		let inputValue = this.epvendorregister.controls['ep3rdpartyvendorname'].value;
		let outputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
		this.epvendorregister.controls['ep3rdpartyvendorname'].setValue(outputValue)

		if (this.epvendorregister.get('ep3rdpartyvendorname')?.invalid) {
			this.epvendorErrors.ep3rdpartyvendorname = "*Vendor Name is required";
			hasError = true;
		}
		if (this.epvendorregister.get('ep3rdpartyvendoraddress1')?.invalid) {
			this.epvendorErrors.ep3rdpartyvendoraddress1 = "*Vendor Address 1 is required";
			hasError = true;
		}
		if (this.epvendorregister.get('ep3rdpartyvendoraddress2')?.invalid) {
			this.epvendorErrors.ep3rdpartyvendoraddress2 = "*Vendor Address 2 is required";
			hasError = true;
		}
		if (this.epvendorregister.get('ep3rdpartyvendorcity')?.invalid) {
			this.epvendorErrors.ep3rdpartyvendorcity = "*Vendor City is required";
			hasError = true;
		}
		if (this.epvendorregister.get('ep3rdpartyvendorstate')?.invalid) {
			this.epvendorErrors.ep3rdpartyvendorstate = "*Vendor State is required";
			hasError = true;
		}
		if (this.epvendorregister.get('ep3rdpartyvendorcountry')?.invalid) {
			this.epvendorErrors.ep3rdpartyvendorcountry = "*Vendor Country is required";
			hasError = true;
		}
		if (this.epvendorregister.get('ep3rdpartyvendoraadharid')?.invalid) {
			this.epvendorErrors.ep3rdpartyvendoraadharid = "*Vendor Aadhaar ID is required";
			hasError = true;
		}
		if (this.epvendorregister.get('ep3rdpartyvendorbankacno')?.invalid) {
			this.epvendorErrors.ep3rdpartyvendorbankacno = "*Vendor Bank Account Number is required";
			hasError = true;
		}
		if (this.epvendorregister.get('ep3rdpartyvendorbankifsc')?.invalid) {
			this.epvendorErrors.ep3rdpartyvendorbankifsc = "*Vendor Bank IFSC Code is required";
			hasError = true;
		}
		if (this.epvendorregister.get('ep3rdpartyvendoreventacceptance')?.invalid) {
			this.epvendorErrors.ep3rdpartyvendoreventacceptance = "*Event Acceptance is required";
			hasError = true;
		}
		// let inputValue1 = this.epvendorregister.controls['ep3rdpartyvendorsupplydesc'].value;
		// let outputValue1 = inputValue.charAt(0).toUpperCase() + inputValue1.slice(1);
		// this.epvendorregister.controls['ep3rdpartyvendorsupplydesc'].setValue(outputValue1)

		if (!hasError) {
			this.saveVendor(status);
		}
	}


	/*Vendor Registration all details Autofetch API function*/
	getVendorDetails() {
		this.spinner.show()
		this.appService
			.getVendorDetails(this.epIdDropDown, this.epSubDropDown, this.epVendorDropDown)
			.pipe(takeUntil(this.destroy$))
			.subscribe((data) => {
				this.spinner.hide()
				console.log("GetVendor Details::", data);
				// @ts-ignore
				this.vendorDetails = data;
				for (var vendor of this.vendorDetails) {
					this.isUpdate = true;
					this.epvendorregister.controls["ep3rdpartyvendorname"].setValue(vendor.ep3rdpartyvendorname);
					this.epvendorregister.controls["ep3rdpartyvendoraddress1"].setValue(vendor.ep3rdpartyvendoraddress1);
					this.epvendorregister.controls["ep3rdpartyvendoraddress2"].setValue(vendor.ep3rdpartyvendoraddress2);
					this.epvendorregister.controls["ep3rdpartyvendorcity"].setValue(vendor.ep3rdpartyvendorcity);
					this.epvendorregister.controls["ep3rdpartyvendorstate"].setValue(vendor.ep3rdpartyvendorstate);
					this.epvendorregister.controls["ep3rdpartyvendorcountry"].setValue(vendor.ep3rdpartyvendorcountry);
					this.epvendorregister.controls["ep3rdpartyvendoraadharid"].setValue(vendor.ep3rdpartyvendoraadharid);
					this.epvendorregister.controls["ep3rdpartyvendorbankacno"].setValue(vendor.ep3rdpartyvendorbankacno);
					this.epvendorregister.controls["ep3rdpartyvendorbankifsc"].setValue(vendor.ep3rdpartyvendorbankifsc);
					this.epvendorregister.controls["ep3rdpartyvendoreventacceptance"].setValue(vendor.ep3rdpartyvendoreventacceptance);
					this.epvendorregister.controls["vendoractivestatus"].setValue(vendor.vendoractivestatus);
					this.epvendorregister.controls["ep3rdpartyvendorsupplydesc"].setValue(vendor.ep3rdpartyvendorsupplydesc);
				}
			},(error: any) => {
				this.spinner.hide()
				console.log("Something went wrong");
			  })
	}

	// Eventplace dropdown API function

	getEventPlaceByuserId() {
		this.appService
			.getEventPlaceByuserId(localStorage.getItem('token'))
			.pipe(takeUntil(this.destroy$))
			.subscribe((data) => {
				console.log("Event place Dropdwon::", data);
				// @ts-ignore
				this.eventPlace = data;
				var localEventPlace: string[] = [];
				for (var ep of this.eventPlace) {
					localEventPlace.push(ep.epname)
				}
				this.eventDropdown = localEventPlace;

			})
	}

	//Subplace dropdown API function

	getEventSubPlace() {
		this.appService
			//@ts-ignore
			.getEventSubPlace(this.epIdDropDown)
			.pipe(takeUntil(this.destroy$))
			.subscribe((data) => {
				console.log("sub dropdown::", data);
				// @ts-ignore
				this.eventSubPlace = data;
				var localEventSubPlace: string[] = [];
				for (var ep of this.eventSubPlace) {
					if (ep.epmainplace == "Yes") {
						localEventSubPlace.push(ep.epsubname + " - Main Hall")
						console.log("main hall", this.eventSubDropDown)
					} else {
						localEventSubPlace.push(ep.epsubname)
					}
					this.eventSubDropDown = localEventSubPlace;
				}
			})
	}

	/*Vendor Registration dropdown details API function*/
	getVendorDropDown() {
		this.appService
			.getVendorDropDown(this.epIdDropDown, this.epSubDropDown)
			.pipe(takeUntil(this.destroy$))
			.subscribe((data) => {
				console.log("Test data::", data);
				// @ts-ignore
				this.eventVendorPlace = data;
				if (this.eventVendorPlace.length > 0) {
					this.epvendorregister.controls['ep3rdpartyvendorid'].enable();
				} else {
					this.epvendorregister.controls['ep3rdpartyvendorid'].disable();
				}
				var localVendor: string[] = [];
				for (var ep of this.eventVendorPlace) {
					localVendor.push(ep.ep3rdpartyvendorname)
				}
				this.eventVendorDropDown = localVendor;
			})
	}


	/*EventPlace Dropdown Autofetch function*/
	onShowevent(event) {
		const value = event.target.value;
		console.log("Event Name::", value)
		for (var EPid of this.eventPlace) {
			if (value == EPid.epname) {
				this.epIdDropDown = EPid.epid;
				console.log("ep id::", this.epIdDropDown)
				this.getEventSubPlace();
				this.isDisabled = true;
				this.disable();
			} else {
				this.isDisabled = false;
				console.log("ep id:: error")
			}
		}
	}
	disable() {
		if (this.isDisabled) {
			this.epvendorregister.controls['epsubid'].enable();
		} else {
			this.epvendorregister.controls['epsubid'].disable();
		}
	}

	/*EventSubPlace Dropdown Autofetch function*/
	onShowsubplace(event) {
		const value = event.target.value.replace(" - Main Hall", "");
		console.log("Packing type::", value)
		for (var EPid of this.eventSubPlace) {
			if (value == EPid.epsubname) {
				this.epSubDropDown = EPid.epsubid;
				console.log("ep id::", this.epSubDropDown)
				this.getVendorDropDown();
				this.isDisabled = true;
				this.disable1();
			} else {
				this.isDisabled = false;
				console.log("ep id:: error")
			}
		}
	}
	disable1() {
		if (this.isDisabled) {
			this.epvendorregister.controls['ep3rdpartyvendorid'].enable();
		} else {
			this.epvendorregister.controls['ep3rdpartyvendorid'].disable();
		}
	}


	/*Vendor Registration Dropdown Autofetch function*/
	onShowvendordetails(event) {
		const value = event.target.value;
		console.log("Packing type::", value)
		for (var EPid of this.eventVendorPlace) {
			if (value == EPid.ep3rdpartyvendorname) {
				this.epVendorDropDown = EPid.ep3rdpartyvendorid;
				console.log("ep id::", this.epSubDropDown)
				this.getVendorDetails();
			}
		}
	}


	/* Vendor Registration save and update api functionality */
	saveVendor(status: string) {
		this.spinner.show()
		console.log("is update::", this.isUpdate)
		if (this.isUpdate) {
			this.epvendorregister.controls["vendoractivestatus"].setValue(status);
			this.epvendorregister.controls["activeyn"].setValue("Yes");
			this.epvendorregister.controls["deleteyn"].setValue("No");
			this.epvendorregister.controls["createdby"].setValue(localStorage.getItem("token"));
			this.epvendorregister.controls["epid"].setValue(this.epIdDropDown);
			this.epvendorregister.controls["epsubid"].setValue(this.epSubDropDown);
			this.epvendorregister.controls["ep3rdpartyvendorid"].setValue(this.epVendorDropDown);
			console.log("vendor details::", this.epvendorregister.value);
			this.appService.updatevendor(this.epvendorregister.value)
				.pipe(takeUntil(this.destroy$))
				.subscribe((data) => {
					this.spinner.hide()
					console.log("vendor details ::", data);
					// @ts-ignore
					// this.protected = data;
					if(data.vendoractivestatus == "Draft"){
						this.successAlertDarft();
					}else{
					this.UpdateAlert();
					}
				}, (err: any) => {
					this.spinner.hide()
					this.errorAlert();
				},
					() => console.log('HTTP request completed.'));
		}
		else if (!this.isUpdate) {
			console.log("is update::", this.isUpdate)
			this.epvendorregister.controls["vendoractivestatus"].setValue(status);
			this.epvendorregister.controls["activeyn"].setValue("Yes");
			this.epvendorregister.controls["deleteyn"].setValue("No");
			this.epvendorregister.controls["createdby"].setValue(localStorage.getItem("token"));
			this.epvendorregister.controls["epid"].setValue(this.epIdDropDown);
			this.epvendorregister.controls["epsubid"].setValue(this.epSubDropDown);
			console.log("vendor details::", this.epvendorregister.value);
			this.appService.savevendor(this.epvendorregister.value)
				.pipe(takeUntil(this.destroy$))
				.subscribe((data) => {
					this.spinner.hide()
					console.log("vendor details ::", data);
					// @ts-ignore
					if(data.vendoractivestatus == "Draft"){
						this.successAlertDarft();
					}else{
					this.successAlert();
					}
				}, (err: any) => {
					this.spinner.hide()
					this.errorAlert();
				},
					() => console.log('HTTP request completed.'));
		}

	}

	draftButton(status: string) {
		let hasError = false;

		if (this.epvendorregister.get('epid')?.invalid) {
			this.epvendorErrors.epid = "*Event Place is required";
			hasError = true;
		}

		if (this.epvendorregister.get('epsubid')?.invalid) {
			this.epvendorErrors.epsubid = "*Event Sub Place is required";
			hasError = true;
		}
		let inputValue = this.epvendorregister.controls['ep3rdpartyvendorname'].value;
		let outputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
		this.epvendorregister.controls['ep3rdpartyvendorname'].setValue(outputValue)

		if (this.epvendorregister.get('ep3rdpartyvendorname')?.invalid) {
			this.epvendorErrors.ep3rdpartyvendorname = "*Vendor Name is required";
			hasError = true;
		}
		if (!hasError) {
			this.saveVendor(status);
		}
	}


	/* Vendor Registration only allowed numeric value function */
	numberOnly(event): boolean {
		const charCode = (event.which) ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;

	}

	/* Vendor Registration successAlert popup message function */
	successAlert() {
		Swal.fire({
			title: "Your Registration Sucessfully Completed, Do you want to register another Vendor?",
			icon: "success",
			showDenyButton: true,
			confirmButtonColor: "#4fab69",
			denyButtonColor: "#c73636",
			confirmButtonText: "Yes",
			denyButtonText: "No",
			showClass: {
				popup: "animate_animated animate_fadeInDown",
			},
			hideClass: {
				popup: "animate_animated animate_fadeOutUp",
			},
		}).then((result) => {
			if (result.isConfirmed) {
				this.epvendorregister.controls["epid"].setValue("");
				this.epvendorregister.controls["epsubid"].setValue("");
				this.epvendorregister.controls["ep3rdpartyvendorname"].setValue("");
				this.epvendorregister.controls["ep3rdpartyvendoraddress1"].setValue("");
				this.epvendorregister.controls["ep3rdpartyvendoraddress2"].setValue("");
				this.epvendorregister.controls["ep3rdpartyvendorcity"].setValue("");
				this.epvendorregister.controls["ep3rdpartyvendorstate"].setValue("");
				this.epvendorregister.controls["ep3rdpartyvendorcountry"].setValue("");
				this.epvendorregister.controls["ep3rdpartyvendoraadharid"].setValue("");
				this.epvendorregister.controls["ep3rdpartyvendorbankacno"].setValue("");
				this.epvendorregister.controls["ep3rdpartyvendorbankifsc"].setValue("");
				this.epvendorregister.controls["ep3rdpartyvendoreventacceptance"].setValue("");
				this.epvendorregister.controls["vendoractivestatus"].setValue("");
				this.epvendorregister.controls["ep3rdpartyvendorsupplydesc"].setValue("");
			} else {
				this.router.navigate(['/dash'])
			}
		});
	}

	// Vendor Registration successAlertDarft popup message
successAlertDarft() {
	Swal.fire({
	  title: "Your Registration successfully saved as draft, Do you want to register another register another Vendor ?",
	  icon: "success",
	  showDenyButton: true,
	  confirmButtonColor: "#4fab69",
	  denyButtonColor: "#c73636",
	  confirmButtonText: "Yes",
	  denyButtonText: "No",
	  showClass: {
		popup: "animate_animated animate_fadeInDown",
	  },
	  hideClass: {
		popup: "animate_animated animate_fadeOutUp",
	  },
	}).then((result) => {
		if (result.isConfirmed) {
			this.epvendorregister.controls["epid"].setValue("");
			this.epvendorregister.controls["epsubid"].setValue("");
			this.epvendorregister.controls["ep3rdpartyvendorname"].setValue("");
			this.epvendorregister.controls["ep3rdpartyvendoraddress1"].setValue("");
			this.epvendorregister.controls["ep3rdpartyvendoraddress2"].setValue("");
			this.epvendorregister.controls["ep3rdpartyvendorcity"].setValue("");
			this.epvendorregister.controls["ep3rdpartyvendorstate"].setValue("");
			this.epvendorregister.controls["ep3rdpartyvendorcountry"].setValue("");
			this.epvendorregister.controls["ep3rdpartyvendoraadharid"].setValue("");
			this.epvendorregister.controls["ep3rdpartyvendorbankacno"].setValue("");
			this.epvendorregister.controls["ep3rdpartyvendorbankifsc"].setValue("");
			this.epvendorregister.controls["ep3rdpartyvendoreventacceptance"].setValue("");
			this.epvendorregister.controls["vendoractivestatus"].setValue("");
			this.epvendorregister.controls["ep3rdpartyvendorsupplydesc"].setValue("");
		} else {
			this.router.navigate(['/dash'])
		}
	});
  }

	/* Vendor Registration UpdateAlert popup message function */
	UpdateAlert() {
		Swal.fire({
			title: "Your Registration Updated Sucessfully",
			icon: "success",
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "OK",
			showClass: {
				popup: "animate_animated animate_fadeInDown",
			},
			hideClass: {
				popup: "animate_animated animate_fadeOutUp",
			},
		}).then((result) => {
			if (result.isConfirmed) {
				window.location.reload();
			}
		});
	}

	errorAlert() {
		Swal.fire({
			title: "Sorry, Please Try Again Your Registration Process",
			icon: "error",
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "OK",
			showClass: {
				popup: "animate_animated animate_fadeInDown",
			},
			hideClass: {
				popup: "animate_animated animate_fadeOutUp",
			},
		});
	}

}
