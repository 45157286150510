<div class="container-fluid">
  <form (ngSubmit)="onSubmit()" [formGroup]="eproomsdetails" id="eproomsdetails">
    <div class="col-md-2 mt-1"></div>
    <div class="col-md-11">
      <h4 class="">Room Areas</h4>
    </div>
    <div class="alert alert-light" role="alert">
      Note:<span style="color:red;">*</span> Screen to enter all the required details about your Rooms.
    </div>
    <div class="row">
      <label class="col-3  col-form-label"><strong>Rooms Details Required</strong></label>
      <div class="form-check mt-1">
        <input class="form-check-input" type="radio" formControlName="roomsrequired" value="yes"
          (change)="roomsdisable($event)">
        <label class="form-check-label"><b>Yes</b></label>
      </div>
      <div class="form-check mt-1">
        <input class="form-check-input ml-1" type="radio" formControlName="roomsrequired" value="no"
          (change)="roomsdisable($event)">
        <label class="form-check-label ml-4"><b>No</b></label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label for="epid" class="form-label">Event Place<span style="color:red;">*</span></label>
            <select class="form-control" formControlName="epid" (change)="onShoweventsub($event)">
              <option *ngFor="let parms of eventDropdown" value="{{parms}}">{{parms}}</option>
              <option value="" disabled selected hidden>Select Event place</option>
            </select>
            <span *ngIf="eproomsErrors.epid" class="error-text">{{eproomsErrors.epid}}</span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label for="epsubid" class="form-label">Event Sub Place<span style="color:red;">*</span></label>
            <select class="form-control " id="epsubid" formControlName="epsubid" disabled="false"
              (change)="onShowkitchen($event)">
              <option value="" selected>Select Event Sub Place</option>
              <option *ngFor="let parms of eventSubDropDown" value="{{parms}}">{{parms}}</option>
            </select>
            <span *ngIf="eproomsErrors.epsubid" class="error-text">{{eproomsErrors.epsubid}}</span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label for="eproomid" class="form-label">Rooms</label>
            <select class="form-control " id="eproomid" formControlName="eproomid" disabled="false"
              (change)="onShowkitchendetails($event)">
              <option value="" selected>Select Room</option>
              <option *ngFor="let parms of eventRoomDropDown" value="{{parms}}">{{parms}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label>Name<span style="color:red;">*</span></label>
            <input type="text" class="form-control text-capitalize" id="eproomname" formControlName="eproomname"
              placeholder="Room Name">
            <span *ngIf="eproomsErrors.eproomname" class="error-text">{{eproomsErrors.eproomname}}</span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label>Capacity<span style="color:red;">*</span></label>
            <input type="text" class="form-control text-capitalize" id="eproomcapacity" formControlName="eproomcapacity"
              placeholder="Room Capacity" (keypress)="OnlyNumbers($event)" maxlength="2">
            <span *ngIf="eproomsErrors.eproomcapacity" class="error-text">{{eproomsErrors.eproomcapacity}}</span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label>Size<span style="color:red;">*</span></label>
            <input type="text" class="form-control" id="eproomsize" formControlName="eproomsize"
              placeholder="Room Size 21 * 21" maxlength="10">
            <span *ngIf="eproomsErrors.eproomsize" class="error-text">{{eproomsErrors.eproomsize}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label>Location</label>
            <input type="text" class="form-control text-capitalize" id="eproomlocation" formControlName="eproomlocation"
              placeholder="Room Location">
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label>Charges<span style="color:red;">*</span></label>
            <input type="text" class="form-control" id="eproomscharges" formControlName="eproomscharges"
              (keypress)="OnlyNumbers($event)" maxlength="6" placeholder="Room Charges">
            <span *ngIf="eproomsErrors.eproomscharges" class="error-text">{{eproomsErrors.eproomscharges}}</span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label>Description</label>
            <input class="form-control text-capitalize" rows="3" id="eproomdesc" formControlName="eproomdesc"
              placeholder="Room Description">
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label class="mr-3">Air Condtioned<span style="color:red;">*</span>:</label>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" value="Yes" name="eproomaircond" id="eproomaircond"
                formControlName="eproomaircond">
              <label class="form-check-label" for="eproomaircond"><b>Yes</b></label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" value="No" name="eproomaircond" id="eproomaircond"
                formControlName="eproomaircond">
              <label class="form-check-label" for="eproomaircond"><b>No</b></label>
            </div>
            <div>
              <span *ngIf="eproomsErrors.eproomaircond" class="error-text">{{eproomsErrors.eproomaircond}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label class="mr-4">Chargeable<span style="color:red;">*</span>:</label>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" value="Yes" name="eproomchargable" id="eproomchargable"
                formControlName="eproomchargable">
              <label class="form-check-label" for="eproomchargable"><b>Yes</b></label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" value="No" name="eproomchargable" id="eproomchargable"
                formControlName="eproomchargable">
              <label class="form-check-label" for="eproomchargable"><b>No</b></label>
            </div>
            <div>
              <span *ngIf="eproomsErrors.eproomchargable" class="error-text">{{eproomsErrors.eproomchargable}}</span>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="text-center">
      <button type="button" class="btn btn-primary" (click)="draftButton('Draft')" *ngIf="isdisabledraft">Save as Draft
      </button>
      <button type="submit" class="btn btn-success m-3" *ngIf="isdisablesave">{{this.isUpdate == true ? "Update" :
        "Save"}}</button>
      <button type="submit" class="btn btn-primary m-3" (click)="nextButton()" *ngIf="isdisablenext">Next</button>
    </div>
    <div class="row mt-5"></div>
  </form>
</div>
<ngx-spinner>
  <div class="custom-loader"></div>
</ngx-spinner>