<div class="container-fluid">
  <form (ngSubmit)="onSubmit()" [formGroup]="openSpace" id="openSpace">
    <div class="col-md-2 mt-1"></div>
    <div class="col-md-11">
      <h4 class="">Open Space</h4> </div>
    
    <div class="alert alert-light" role="alert">
      Note:<span style="color:red;">*</span> Screen to enter all the required details about your Open Space.
    </div>
    <div class="row">
      <label class="col-3  col-form-label"><strong>Open Space Details Required</strong></label>
      <div class="form-check mt-1">
        <input class="form-check-input" type="radio" formControlName="openspacerequired" value="yes"(change)="openspacedisable($event)" >
        <label class="form-check-label"><b>Yes</b></label>
      </div>
      <div class="form-check mt-1">
        <input class="form-check-input ml-1" type="radio" formControlName="openspacerequired" value="no" (change)="openspacedisable($event)" >
        <label class="form-check-label ml-4"><b>No</b></label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label for="epid" class="form-label">Event Place<span style="color:red;">*</span></label>
            <select class="form-control "  formControlName="epid" (change)="onShoweventplace($event)" >
              <option *ngFor="let parms of eventDropDown" value="{{parms}}">{{parms}}</option>
              <option value="" disabled selected hidden >Select Event place</option>
            </select>
            <span *ngIf="EPOpenSpaceErrors.epid" class="error-text">{{EPOpenSpaceErrors.epid}}</span>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label for="epsubid" class="form-label">Event Sub Place<span style="color:red;">*</span></label>
            <select class="form-control" disabled="false" formControlName="epsubid" (change)="onShowSubPlace($event)">
              <option value="" selected >Select Event Sub Place</option>
              <option *ngFor="let parms of eventSubDropDown" value="{{parms}}">{{parms}}</option>
            </select> <span *ngIf="EPOpenSpaceErrors.epsubid" class="error-text">{{EPOpenSpaceErrors.epsubid}}</span> </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <label for="epopenspaceid" class="form-label">Open Space</label>
          <select class="form-control " formControlName="epopenspaceid" disabled="false" (change)="onShowOpenspacedetails($event)">
            <option value="" selected>Select Open Space</option>
            <option *ngFor="let parms of eventOpenspaceDropDown " value="{{parms}}">{{parms}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label>Name<span style="color:red;">*</span></label>
            <input type="text" class="form-control text-capitalize"  placeholder="Open Space Name" formControlName="epopenspacename">
            <span *ngIf="EPOpenSpaceErrors.epopenspacename" class="error-text">{{EPOpenSpaceErrors.epopenspacename}}</span> </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label>Capacity<span style="color:red;">*</span></label>
            <input type="text" class="form-control" maxlength="4" placeholder="Open Space Capacity"  (keypress)="numberOnly($event)" formControlName="epopnspacecapacity">
            <span *ngIf="EPOpenSpaceErrors.epopnspacecapacity" class="error-text">{{EPOpenSpaceErrors.epopnspacecapacity}}</span> </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label>Size<span style="color:red;">*</span></label>
            <input type="text" maxlength="10" class="form-control" placeholder="Open Space Size 21 * 21" formControlName="epopenspacesize">
            <span *ngIf="EPOpenSpaceErrors.epopenspacesize" class="error-text">{{EPOpenSpaceErrors.epopenspacesize}}</span> </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label>Charges<span style="color:red;">*</span></label>
            <input type="text" class="form-control" placeholder="Open Space Charges" maxlength="5" (keypress)="numberOnly($event)" formControlName="epopenspacecharges"> <span *ngIf="EPOpenSpaceErrors.epopenspacecharges" class="error-text">{{EPOpenSpaceErrors.epopenspacecharges}}</span> </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <div class="col-md-1"></div>
          <div class="col-md-11">
            <label>Description</label>
            <input type="text" row="4" cols="20" class="form-control text-capitalize" placeholder="Open Space Description" formControlName="epopenspacedesc"><!-- <span *ngIf="EPOpenSpaceErrors.epopenspacedesc" class="error-text">{{EPOpenSpaceErrors.epopenspacedesc}}</span>  -->
          </div>
        </div>
      </div>

     <div class="col-md-4">
       <div class="form-group">
         <div class="col-md-1"></div>
         <div class="col-md-11">
                      <label class="mr-3">Chargeable<span style="color:red;">*</span>:</label> 
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" value="Yes" name="epopenspacechargable" id="epopenspacechargable" formControlName="epopenspacechargable">
            <label class="form-check-label" for="epopenspacechargable"><b>Yes</b></label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" value="No" name="epopenspacechargable" id="epopenspacechargable" formControlName="epopenspacechargable" >
            <label class="form-check-label" for="epopenspacechargable"><b>No</b></label>
          </div><br>
          <span *ngIf="EPOpenSpaceErrors.epopenspacechargable" class="error-text">{{EPOpenSpaceErrors.epopenspacechargable}}</span> </div>
       </div>
     </div>
    </div>
    <div class="text-center">
      <button type="button" class="btn btn-primary" (click)="draftButton('Draft')" *ngIf="isdisabledraft" >Save as Draft </button>
      <button type="submit" class="btn btn-success m-3" *ngIf="isdisablesave">{{this.isUpdate == true ? "Update" : "Save"}}</button>
      <button type="submit" class="btn btn-primary m-3" (click)="nextButton()" *ngIf="isdisablenext">Next</button>
    </div>
    <div class="row mt-5"></div>
  </form>
</div>
<ngx-spinner>
  <div class="custom-loader"></div>
</ngx-spinner>
