import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MainComponent} from '@modules/main/main.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {LoginComponent} from '@modules/login/login.component';
import {ProfileComponent} from '@pages/profile/profile.component';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from "@modules/event-place-management/dashboard/dashboard.component";
import {AuthGuard} from '@guards/auth.guard';
import {NonAuthGuard} from '@guards/non-auth.guard';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {SubMenuComponent} from '@pages/main-menu/sub-menu/sub-menu.component';
import {EventPlaceRegistrationComponent} from "@modules/event-place-management/event-place-registration/event-place-registration.component";
import {RoomsComponent} from "@modules/event-place-management/rooms/rooms.component";
import {SubPlaceComponent} from "@modules/event-place-management/sub-place-registration/sub-place.component";
import {KitchenComponent} from "@modules/event-place-management/kitchen/kitchen.component";
import {OpenSpaceComponent} from "@modules/event-place-management/open-space/open-space.component";
import {ParkingAreaComponent} from "@modules/event-place-management/parking-area/parking-area.component";
import {GeneralAmenitiesComponent} from "@modules/event-place-management/general-amenities/general-amenities.component";
import {ActivitiesComponent} from "@modules/event-place-management/activities/activities.component";
import {IncidentChargesComponent} from "@modules/event-place-management/incident-charges/incident-charges.component";
import {MiscChargesComponent} from "@modules/event-place-management/misc-charges/misc-charges.component";
import {VendorRegistrationComponent} from "@modules/event-place-management/vendor-registration/vendor-registration.component";
import {UserMappingComponent} from "@modules/event-place-management/user-mapping/user-mapping.component";
import {AmenitiesMappingComponent} from "@modules/event-place-management/amenities-mapping/amenities-mapping.component";
import {ActivitiesMappingComponent} from "@modules/event-place-management/activities-mapping/activities-mapping.component";
import { EventBookingAreaComponent } from '@modules/ep-booking-management/event-bookingarea/event-bookingarea.component';
import { EventPlaceBookingComponent } from '@modules/ep-booking-management/eventplace-booking/eventplace-booking.component';
import {EventBookingPaymentComponent} from '@modules/ep-booking-management/event-bookingpayment/event-bookingpayment.component';
import {EventBookingPaymentPrintComponent} from "@modules/ep-booking-management/event-booking-payment-print/event-booking-payment-print.component";
import {UserMappingManagementComponent} from "@modules/event-place-management/user-mapping-management/user-mapping-management.component";
import { UserStatusComponent } from '@modules/event-place-management/user-status/user-status.component';
import {ActivityAmenityMappingComponent} from "@modules/event-place-management/activity-amenity-mapping/activity-amenity-mapping.component";
import { EventBookingListComponent } from "@modules/ep-booking-management/event-bookinglist/event-bookinglist.component";
import {WebsiteComponent} from "@modules/website/website.component";
import {VenueViewComponent} from "@modules/venue-view/venue-view.component";
import {CustomerBookingComponent} from "@modules/customer-booking/customer-booking.component";
import {PrivacyPolicyComponent} from "@modules/privacy-policy/privacy-policy.component";
import {TermsComponent} from "@modules/terms-and-conditions/terms.component";

const routes: Routes = [
  /*{
    path: '',
    component: LoginComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'profile',
        component: ProfileComponent
      },
      {
        path: 'blank',
        component: BlankComponent
      },
      {
        path: 'sub-menu-1',
        component: SubMenuComponent
      },
      {
        path: 'sub-menu-2',
        component: BlankComponent
      },
      {
        path: 'dash',
        component: DashboardComponent
      },
      {
        path: 'event-place-registration',
        component: EventPlaceRegistrationComponent
      },
      {
        path: 'rooms',
        component: RoomsComponent
      },
      {
        path: 'sub-place',
        component: SubPlaceComponent
      },
      {
        path: 'kitchen',
        component: KitchenComponent
      },
      {
        path: 'open-space',
        component: OpenSpaceComponent
      },
      {
        path: 'parking-area',
        component: ParkingAreaComponent
      },
      {
        path: 'general-amenities',
        component: GeneralAmenitiesComponent
      },
      {
        path: 'activities',
        component: ActivitiesComponent
      },
      {
        path: 'incident-charges',
        component: IncidentChargesComponent
      },
      {
        path: 'misc-charges',
        component: MiscChargesComponent
      },
      {
        path: 'vendor-registration',
        component: VendorRegistrationComponent
      },
      {
        path: 'user-mapping',
        component: ActivityAmenityMappingComponent
      },
      {
        path: 'amenities-mapping',
        component: AmenitiesMappingComponent
      },
      {
        path: 'activities-mapping',
        component: ActivitiesMappingComponent
      }
    ]
  },*/
  {
    path: 'login',
    component: LoginComponent,
    // canActivate: [NonAuthGuard]
  },
  {
    path: '',
    component: WebsiteComponent,
  },
  {
    path: 'venue-view',
    component: VenueViewComponent,
  },
  {
    path: 'customer-booking',
    component: CustomerBookingComponent,
  },
  {
    path: 'privacy',
    component: PrivacyPolicyComponent,
  },
  {
    path:'terms',
    component: TermsComponent,
  },
  /*{
    path: 'main',
    component: MainComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'profile',
        component: ProfileComponent
      },
      {
        path: 'blank',
        component: BlankComponent
      },
      {
        path: 'sub-menu-1',
        component: SubMenuComponent
      },
      {
        path: 'sub-menu-2',
        component: BlankComponent
      },
      /!*{
        path: 'dash',
        component: DashboardComponent
      },*!/
      {
        path: 'event-place-registration',
        component: EventPlaceRegistrationComponent
      },
      {
        path: 'rooms',
        component: RoomsComponent
      },
      {
        path: 'sub-place',
        component: SubPlaceComponent
      },
      {
        path: 'kitchen',
        component: KitchenComponent
      },
      {
        path: 'open-space',
        component: OpenSpaceComponent
      },
      {
        path: 'parking-area',
        component: ParkingAreaComponent
      },
      {
        path: 'general-amenities',
        component: GeneralAmenitiesComponent
      },
      {
        path: 'activities',
        component: ActivitiesComponent
      },
      {
        path: 'incident-charges',
        component: IncidentChargesComponent
      },
      {
        path: 'misc-charges',
        component: MiscChargesComponent
      },
      {
        path: 'vendor-registration',
        component: VendorRegistrationComponent
      },
      {
        path: 'user-mapping',
        component: UserMappingComponent
      },
      {
        path: 'amenities-mapping',
        component: AmenitiesMappingComponent
      },
      {
        path: 'activities-mapping',
        component: ActivitiesMappingComponent
      },

    ]
  },
  {
    path: 'dash',
    component: DashboardComponent
  },
  {
    path: 'register',
    component: RegisterComponent,
    // canActivate: [NonAuthGuard]
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'recover-password',
    component: RecoverPasswordComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'user-mapping-management',
    component: UserMappingManagementComponent
  },
  {
    path: 'user-status',
    component: UserStatusComponent,
  },
  {
    path: 'activity-amenity-mapping',
    component: ActivityAmenityMappingComponent
  },
  {
    path: 'event-bookingarea',
    component: EventBookingAreaComponent
  },
  {
    path: 'eventplace-booking',
    component: EventPlaceBookingComponent
  },
  {
    path: 'event-bookingpayment',
    component: EventBookingPaymentComponent
  },
  {
    path: 'event-booking-payment-print',
    component: EventBookingPaymentPrintComponent
  },
  {
    path : 'event-bookinglist',
    component: EventBookingListComponent
  },*/

  {path: '**', redirectTo: ''},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
