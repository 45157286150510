import {Component, HostBinding, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import { Router } from "@angular/router";
import {AppService} from '@services/app.service';
/*import { Login } from '@modules/event-place-management/model/Login'; */
import { EPUSerDetails } from '@modules/event-place-management/model/epuser-details';
import {pipe, Subject, takeUntil} from "rxjs";
import { EventPlace } from '@modules/event-place-management/model/eventplace';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  @HostBinding('class') class = 'login-box';
  destroy$: Subject<boolean> = new Subject<boolean>();
  loginDetails : EPUSerDetails[] = [];
  eventPlace : EventPlace[] = [];
  eventDropdown: string [] = [];
  eventId : string | undefined;
  testType : string | undefined;
  eventownerId : string | undefined;
  isVisible = false;
  isPasswordVisible: boolean = false;

  constructor(
    private renderer: Renderer2,
    private toastr: ToastrService,
    private appService: AppService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {
  }
  LoginFrom = new UntypedFormGroup({

    epuseremailidpy : new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epuserloginpassword:new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
  })
  public LoginErrors ={
    epuseremailidpy:  '',
    epuserloginpassword: '',
  }

  ngOnInit() {
    this.renderer.addClass(
      document.querySelector('app-root'),
      'login-page'
    );
  }

  // async loginByAuth() {
    //if (this.loginForm.valid) {
  //     this.isAuthLoading = true;
  //     await this.appService.loginByAuth(this.loginForm.value);
  //     this.isAuthLoading = false;
  //   } else {
  //     this.toastr.error('Form is not valid!');
  //   }
  // }

  // async loginByGoogle() {
  //   this.isGoogleLoading = true;
  //   await this.appService.loginByGoogle();
  //   this.isGoogleLoading = false;
  // }

  // async loginByFacebook() {
  //   this.isFacebookLoading = true;
  //   await this.appService.loginByFacebook();
  //   this.isFacebookLoading = false;
  // }
  onSubmit() {
    this.formValidation();

}
formValidation() {
  this.LoginErrors.epuseremailidpy = "";
  this.LoginErrors.epuserloginpassword = "";


  let hasError = false;

  if (this.LoginFrom.controls['epuseremailidpy']?.invalid) {
      this.LoginErrors.epuseremailidpy
          = "*epuseremailid is required";
      hasError = true;
  }

  if (this.LoginFrom.get('epuserloginpassword')?.invalid) {
      this.LoginErrors.epuserloginpassword = "*epuserloginpassword is required";
      hasError = true;
  }

  if (!hasError) {
    this.getLogin();
}
}
  ngOnDestroy() {

  }

  getLogin() {
    this.spinner.show()
    this.appService
      .getLogin(this.LoginFrom.get("epuseremailidpy").value,this.LoginFrom.get("epuserloginpassword").value)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide()
        console.log("Test data::", data);
        // @ts-ignore
        this.loginDetails = data;
        var epUserId: string[] = [];
        var EPUserName: string[] = [];
        var EPUserRole: string[] = [];
        var EPCreatedBy: string[] = [];
        for (var login of this.loginDetails) {
          if(login.role == "OWNER" || login.role == "VSSPL ADMIN"){
          epUserId.push(login.epuserid)
          EPUserName.push(login.epuserfirstname)
          EPUserRole.push(login.role)
          EPCreatedBy.push(login.createdby)
          }
          else(login.role == 'ADMIN');{
            epUserId.push(login.createdby)
            EPUserName.push(login.epuserfirstname)
            EPUserRole.push(login.role)
          }
        }
        console.log("user id value::", epUserId)
        console.log("user id value::", EPUserRole)
        if (epUserId[0] != null && EPUserName[0] !=null && EPUserRole[0] != null) {
          this.router.navigate(['/dash'])
          this.toastr.success("Login successfully")
          localStorage.setItem("token", epUserId[0]);
          localStorage.setItem("username", EPUserName[0]);
          localStorage.setItem("userRole", EPUserRole[0]);
        }
         else {
          this.toastr.error("Login Credentisls is incorrect")
          this.LoginFrom.reset()
        }
      },
      (err: any) => {
        this.spinner.hide()
        this.errorAlert()
         },
       () => console.log('HTTP request completed.'))
    }
    getAllEventPlace(){
      this.appService
      .getAllEventPlace()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data)=>{
        console.log("Test data::", data);
        //@ts-ignore
        this.eventPlace = data;
        for (var ep of this.eventPlace) {
          this.eventDropdown.push(ep.epname)
        }
      })
    }
    onShoweventplace(event) {
      const value = event.target.value;
      console.log("Event Name::", value)
      for (var EPid of this.eventPlace) {
        if (value == EPid.epname) {
          this.eventId = EPid.epid;
          this.eventownerId = EPid.createdby
          this.testType = EPid.epid;
          localStorage.setItem("customerEventId", this.eventId);
          localStorage.setItem("ownerId", this.eventownerId);
          console.log("ep id::", this.eventId)
          console.log("epowner id::", this.eventownerId)
        }
      }
    }
    saveType() {
      for(var ep of this.eventPlace){
      if (this.testType == ep.epid)
       {
        this.isVisible = false;
        this.router.navigate(['/eventplace-booking'])
      }
    }
    }
    saveType1() {
        this.isVisible = false;
      }

      errorAlert() {
        Swal.fire({
          title: "Sorry, Please Try Again Your login Process",
          icon: "error",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
          showClass: {
            popup: "animate_animated animate_fadeInDown",
          },
          hideClass: {
            popup: "animate_animated animate_fadeOutUp",
          },
        }).then((result) => {
          if (result.isConfirmed) {
           this.LoginFrom.reset()
          }
        });
      }

  togglePasswordVisibility() {
    const passwordShow = document.getElementById('epuserloginpassword') as HTMLInputElement;
    if (passwordShow.type == 'password') {
      passwordShow.type = 'text';
    } else {
      passwordShow.type = 'password';
    }
    this.isPasswordVisible = !this.isPasswordVisible;
  }
  registarion(){
    this.router.navigate(['/register'])
  }
}
