import {Component, OnInit} from "@angular/core";
import {AppService} from "@services/app.service";
import {Subject, takeUntil} from "rxjs";
import {EventPlace} from "../model/eventplace";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {EventSubPlace} from "../model/eventsub-place";
import {EPVendorRegister} from "../model/ep-vendorregister";
import {EPActivity} from "../model/ep-activity";
import {EPGeneralAmenities} from "../model/ep-generalamenities";
import Swal from "sweetalert2";
import {ToastrService} from "ngx-toastr";
import {ActivitiesMapping} from "../model/activities-mapping";
import {AmenitiesMapping} from "../model/amenities-mapping";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-activity-amenity-mapping',
  templateUrl: './activity-amenity-mapping.component.html',
  styleUrls: ['./activity-amenity-mapping.component.scss']
})
export class ActivityAmenityMappingComponent implements OnInit {

  destroy$: Subject<boolean> = new Subject<boolean>();
  eventPlace: EventPlace [] = [];
  eventDropdown: string [] = [];
  epIdDropDown: string | undefined;
  epeventName: string | undefined;
  eventSubPlace: EventSubPlace [] = [];
  eventSubDropDown: string [] = [];
  epSubDropDown: string | undefined;
  epeventsubPlace: string | undefined;
  eventVendorPlace: EPVendorRegister [] = [];
  eventVendorDropDown: string [] = [];
  epVendorDropDown: string | undefined;
  epVendorName: string | undefined;
  activityDetails: EPActivity [] = [];
  eventactivityDropDown: string [] = [];
  epactivityDropDown: string | undefined;
  epactivityName: string | undefined;
  generalAmenitiesDetails: EPGeneralAmenities [] = [];
  eventAmenitiesDropDown: string [] = [];
  epAmenitiesDropDown: string | undefined;
  epAmenitiesName: string | undefined;
  activityMapping: ActivitiesMapping | undefined;
  amenitiesmapdetails: AmenitiesMapping | undefined;
  isDisabled: boolean = false;
  subplacesdetails = false
  vendordetails = false
  activitydetails = false
  amenitiesdetails = false
  mapbutton = false
  isUpdate = false
  tableamenitiesdisable = false
  tableactivitydisable = false
  eventName: string = ""
  eventSubName: string = ""
  vendorName: string = ""
  activityName: string = ""
  amenityName: string = ""

  constructor(private appService: AppService, private toastr: ToastrService,
    private spinner : NgxSpinnerService) {

  }

  activitiesMapping = new UntypedFormGroup({
    epid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epsubid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epsubname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epactivityid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epactivityname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epvendorid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epvendorname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    activeyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    deleteyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    createdby: new UntypedFormControl('', [Validators.required, Validators.nullValidator])
  })

  AmenitiesMapping = new UntypedFormGroup({
    epid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epsubid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epsubname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epgenamenitiesid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epgenamenitiesname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epvendorid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epvendorname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    activeyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    deleteyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    createdby: new UntypedFormControl('', [Validators.required, Validators.nullValidator])
  })

  ngOnInit() {
    this.getEventPlaceByuserId();
  }

  getEventPlaceByuserId() {
    this.appService
      .getEventPlaceByuserId(localStorage.getItem('token'))
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("Event place Dropdwon::", data);
        // @ts-ignore
        this.eventPlace = data;
        var localEventPlace: string [] = [];
        for (var ep of this.eventPlace) {
          localEventPlace.push(ep.epname)
        }
        this.eventDropdown = localEventPlace
      },(error: any) => {
        console.log("Something went wrong");
      })
  }

//eventplace autofetch function
  onShoweventplace(event) {
    const value = event.target.value;
    console.log("Event Name::", value)
    for (var EPid of this.eventPlace) {
      if (value == EPid.epname) {
        this.epIdDropDown = EPid.epid;
        this.epeventName = EPid.epname;
        console.log("ep id::", this.epIdDropDown)
        this.getEventSubPlace();
        this.subplacesdetails = true
      }
    }
  }

//EventSubPlace dropdown autofetch function
  getEventSubPlace() {
    this.appService
      //@ts-ignore
      .getEventSubPlace(this.epIdDropDown)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("sub dropdown::", data);
        // @ts-ignore
        this.eventSubPlace = data;
        var localEventSubPlace: string [] = [];
        for (var ep of this.eventSubPlace) {
          if (ep.epmainplace == "Yes") {
            localEventSubPlace.push(ep.epsubname + " - Main Hall")
            console.log("main hall", this.eventSubDropDown)
          } else {
            localEventSubPlace.push(ep.epsubname)
          }
          this.eventSubDropDown = localEventSubPlace
        }
      },(error: any) => {
        console.log("Something went wrong");
      })
  }

  onShowsubplace(event) {
    const value = event.target.value.replace(" - Main Hall", "");
    console.log("Packing type::", value)
    for (var EPid of this.eventSubPlace) {
      if (value == EPid.epsubname) {
        this.epSubDropDown = EPid.epsubid;
        this.epeventsubPlace = EPid.epsubname
        console.log("ep id::", this.epSubDropDown)
        this.getVendorDropDown();
        this.vendordetails = true
      }
    }
  }

  /*Vendor Registration dropdown details API function*/
  getVendorDropDown() {
    this.appService
      .getVendorDropDown(this.epIdDropDown, this.epSubDropDown)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("Test data::", data);
        // @ts-ignore
        this.eventVendorPlace = data;
        var localVendor: string[] = [];
        for (var ep of this.eventVendorPlace) {
          localVendor.push(ep.ep3rdpartyvendorname)
        }
        this.eventVendorDropDown = localVendor;
      },(error: any) => {
        console.log("Something went wrong");
      })
  }

  /*Vendor Registration Dropdown Autofetch function*/
  onShowvendordetails(event) {
    const value = event.target.value;
    console.log("Packing type::", value)
    for (var EPid of this.eventVendorPlace) {
      if (value == EPid.ep3rdpartyvendorname) {
        this.epVendorDropDown = EPid.ep3rdpartyvendorid;
        this.epVendorName = EPid.ep3rdpartyvendorname;
        console.log("ep id::", this.epSubDropDown)
        this.activitydetails = true
        this.amenitiesdetails = true
        this.getActivityDropDown()
        this.getAmenitiesDropDown()
      }
    }
  }

  getActivityDropDown() {
    this.appService
      .getActivityDropDown(this.epIdDropDown, this.epSubDropDown)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("Test data::", data);
        // @ts-ignore
        this.activityDetails = data;
        var localActivies: string[] = [];
        for (var ep of this.activityDetails) {
          localActivies.push(ep.epactivityname)
        }
        this.eventactivityDropDown = localActivies;
      },(error: any) => {
        console.log("Something went wrong");
      })
  }

  onActivitydetails(event) {
    const value = event.target.value;
    console.log("Packing type::", value)
    for (var EPid of this.activityDetails) {
      if (value == EPid.epactivityname) {
        this.epactivityDropDown = EPid.epactivityid;
        this.epactivityName = EPid.epactivityname;
        console.log("ep id::", this.epactivityDropDown)
        this.amenitiesdetails = false
        this.mapbutton = true
        this.isUpdate = false
      } else if (value == "Select Activity") {
        this.amenitiesdetails = true
        this.mapbutton = false
      }
    }
  }

  //Amenities Mapping DropDown get API function

  getAmenitiesDropDown() {
    this.appService
      .getAmenitiesDropDown(this.epIdDropDown, this.epSubDropDown)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("Test data::", data);
        // @ts-ignore
        this.generalAmenitiesDetails = data;
        var localAmenities: string[] = [];
        for (var ep of this.generalAmenitiesDetails) {
          localAmenities.push(ep.epgenamenitiesname)
        }
        this.eventAmenitiesDropDown = localAmenities;
      },(error: any) => {
        console.log("Something went wrong");
      })
  }

  //AMenities Mapping Details Dropdown AutoFetch function
  onShowAmenitiesdetails(event) {
    const value = event.target.value;
    console.log("Packing type::", value)
    for (var EPid of this.generalAmenitiesDetails) {
      if (value == EPid.epgenamenitiesname) {
        this.epAmenitiesDropDown = EPid.epgenamenitiesid;
        this.epAmenitiesName = EPid.epgenamenitiesname;
        console.log("ep id::", this.epAmenitiesDropDown);
        this.activitydetails = false
        this.mapbutton = true
        this.isUpdate = true
      } else if (value == "Select Amenities") {
        this.activitydetails = true
        this.mapbutton = false
      }
    }
  }

//save Activity Mapping

  saveActivityMapping() {
    this.spinner.show()
    this.activitiesMapping.controls["createdby"].setValue(localStorage.getItem("token"));
    this.activitiesMapping.controls["epid"].setValue(this.epIdDropDown);
    this.activitiesMapping.controls["epname"].setValue(this.epeventName);
    this.activitiesMapping.controls["epsubid"].setValue(this.epSubDropDown);
    this.activitiesMapping.controls["epsubname"].setValue(this.epeventsubPlace);
    this.activitiesMapping.controls["epvendorid"].setValue(this.epVendorDropDown);
    this.activitiesMapping.controls["epvendorname"].setValue(this.epVendorName);
    this.activitiesMapping.controls["epactivityid"].setValue(this.epactivityDropDown);
    this.activitiesMapping.controls["epactivityname"].setValue(this.epactivityName);
    this.activitiesMapping.controls["activeyn"].setValue("Yes");
    this.activitiesMapping.controls["deleteyn"].setValue("No");
    console.log("activtity mapping ::", this.activitiesMapping.value)
    this.appService.saveActivitiesMapping(this.activitiesMapping.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.spinner.hide()
          console.log("saveRooms ::", data);
          this.activityMapping = data;
          this.eventName = this.activityMapping.epname
          this.eventSubName = this.activityMapping.epsubname
          this.vendorName = this.activityMapping.epvendorname
          this.activityName = this.activityMapping.epactivityname
          this.tableactivitydisable = true
          console.log("tableacticitymapping::", this.activityMapping)
          this.toastr.success('Details are saved successfully');
        }, (err: any) => {
          this.spinner.hide()
          this.toastr.error('Something went wrong, Please try again later');
        },
        () => console.log('HTTP request completed.'));
  }


// Save Amenities Mapping

  saveAmenitiesMapping() {
    this.spinner.show()
    this.AmenitiesMapping.controls["createdby"].setValue(localStorage.getItem("token"));
    this.AmenitiesMapping.controls["epid"].setValue(this.epIdDropDown);
    this.AmenitiesMapping.controls["epname"].setValue(this.epeventName);
    this.AmenitiesMapping.controls["epsubid"].setValue(this.epSubDropDown);
    this.AmenitiesMapping.controls["epsubname"].setValue(this.epeventsubPlace);
    this.AmenitiesMapping.controls["epvendorid"].setValue(this.epVendorDropDown);
    this.AmenitiesMapping.controls["epvendorname"].setValue(this.epVendorName);
    this.AmenitiesMapping.controls["epgenamenitiesid"].setValue(this.epAmenitiesDropDown);
    this.AmenitiesMapping.controls["epgenamenitiesname"].setValue(this.epAmenitiesName);
    this.AmenitiesMapping.controls["activeyn"].setValue("Yes");
    this.AmenitiesMapping.controls["deleteyn"].setValue("No");
    console.log("AmenitiesMapping::", this.AmenitiesMapping.value)
    this.appService.saveAmenitiesMapping(this.AmenitiesMapping.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide()
          this.amenitiesmapdetails = data;
          this.eventName = this.amenitiesmapdetails.epname
          this.eventSubName = this.amenitiesmapdetails.epsubname
          this.vendorName = this.amenitiesmapdetails.epvendorname
          this.amenityName = this.amenitiesmapdetails.epgenamenitiesname
          console.log("AmenitiesMappingdetails::", data);
          this.tableamenitiesdisable = true
          this.toastr.success('Details are saved successfully');
        }, (err: any) => {
          this.spinner.hide()
          this.toastr.error('Something went wrong, Please try again later');
        },
        () => console.log('HTTP request completed.'));
  }


  UpdateAlert() {
    if (!this.isUpdate) {
      Swal.fire({
        title: "Do you want to Map this Activity",
        icon: "question",
        showDenyButton: true,
        confirmButtonColor: "#4fab69",
        denyButtonColor: "#c73636",
        confirmButtonText: "Yes",
        denyButtonText: "No",
        showClass: {
          popup: "animate_animated animate_fadeInDown",
        },
        hideClass: {
          popup: "animate_animated animate_fadeOutUp",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.saveActivityMapping()
        }
      });
    } else {
      Swal.fire({
        title: "Do you want to Map this Amenity",
        icon: "question",
        showDenyButton: true,
        confirmButtonColor: "#4fab69",
        denyButtonColor: "#c73636",
        confirmButtonText: "Yes",
        denyButtonText: "No",
        showClass: {
          popup: "animate_animated animate_fadeInDown",
        },
        hideClass: {
          popup: "animate_animated animate_fadeOutUp",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.saveAmenitiesMapping()
        }
      });
    }
  }
}
