import {Component, OnInit} from "@angular/core";
import {UntypedFormGroup, UntypedFormControl, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {AppService} from "@services/app.service";
import {pipe, Subject, takeUntil} from "rxjs";
import {EPUSerDetails} from "../model/epuser-details";
import Swal from "sweetalert2";
import {ToastrService} from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-user-status',
  templateUrl: './user-status.component.html',
  styleUrls: ['./user-status.component.scss']
})
export class UserStatusComponent implements OnInit {

  userid: string = "";
  firstname: string = "";
  username: string = "";
  emailid: string = "";
  phoneno: string = "";
  status: string = "";
  users: EPUSerDetails [] = [];
  eventUserid: string | undefined;
  getUserDetails: EPUSerDetails [] = [];
  UserRegistrationDetails: EPUSerDetails [] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  public EPID: String;
  selectedCheckbox: any;
  buttondisable = false;

  constructor(private appService: AppService, private router: Router, private toaster: ToastrService,
    private spinner : NgxSpinnerService) {

  }

  events = new UntypedFormGroup({
    epuserid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    activeYn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
  })

  // Place/getUserRegistrationDetails

  ngOnInit() {
    this.getUserRegistrationDetails();
  }

  getUserRegistrationDetails() {
    this.spinner.show()
    this.appService
      .getUserRegDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide()
        console.log("EP id::", data);
        // @ts-ignore
        this.UserRegistrationDetails = data;
        var localUser: EPUSerDetails [] = [];
        console.log("UserRegistration::", this.UserRegistrationDetails)
        for (var user of this.UserRegistrationDetails) {
            localUser.push(user)
        }
        this.users = localUser.sort((a,b) => {
          const idA = parseInt(a.epuserid.split('-')[1]);
          const idB = parseInt(b.epuserid.split('-')[1]);

          // Compare the numeric IDs in descending order
          return idB - idA;
        })

      },(error: any) => {
        this.spinner.hide()
        console.log("Something went wrong");
      })
  }

  selectCheckbox(id: string, isChecked: boolean) {
    console.log("useridgeeting::", id)
    console.log("checked::", isChecked)
    if (id != null) {
      if (isChecked) {
        this.users.forEach((event) => {
          if (event.epuserid != id) {
            event.isChecked = false;
          }else{
            event.isChecked = true;
          }
        });
        this.selectedCheckbox = id;
        console.log("user id getting::", this.selectedCheckbox)
        this.buttondisable = true;
      } else {
        this.buttondisable = false;
      }
    }
  }

  onSubmit() {
  }

  usermapping() {
    this.router.navigate(['user-mapping-management']);
  }

  userstatus() {
    this.router.navigate(['user-status']);
  }

  updateDetails(status) {
    this.spinner.show()
    this.events.controls['epuserid'].setValue(this.selectedCheckbox);
    this.events.controls['activeYn'].setValue(status)
    console.log("gettingepuserid::", this.selectedCheckbox)
    console.log("getting status::", this.events.value)
    this.appService.updateEPUser(this.events.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide()
          console.log("updateepuserdetails ::", data);
          this.getUserRegistrationDetails();
          this.buttondisable = false;
          this.toaster.success("User Details Update Successfully")
        }, (err: any) => {
          this.spinner.hide()
          this.toaster.error("Something went wrong. Try Again later")
        },
        () => console.log('HTTP request completed.'));
  }

  UpdateAlert(status) {
    Swal.fire({
      title: "Do you want to Activate this User",
      icon: "question",
      showDenyButton: true,
      confirmButtonColor: "#4fab69",
      denyButtonColor: "#c73636",
      confirmButtonText: "Yes",
      denyButtonText: "No",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.updateDetails(status)
      }
    });
  }

  UpdateDeActiveAlert(status) {
    Swal.fire({
      title: "Do you want to De-Activate this User",
      icon: "question",
      showDenyButton: true,
      confirmButtonColor: "#4fab69",
      denyButtonColor: "#c73636",
      confirmButtonText: "Yes",
      denyButtonText: "No",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.updateDetails(status)
      }
    });
  }

}
