import { Component, OnInit, HostListener } from "@angular/core";
import { EPOpenSpace } from "../model/epopen-space";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { AppService } from "@services/app.service";
import { pipe, Subject, takeUntil } from "rxjs";
import { EventPlace } from "../model/eventplace";
import { EventSubPlace } from "../model/eventsub-place";
import { EPMapOpenSpace } from "../model/epmap-openspace";
import Swal from "sweetalert2";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-open-space',
  templateUrl: './open-space.component.html',
  styleUrls: ['./open-space.component.scss']
})

export class OpenSpaceComponent implements OnInit {
  eventPlace: EventPlace[] = [];
  eventDropDown: string[] = [];
  eventSubPlace: EventSubPlace[] = [];
  eventSubDropDown: string[] = [];
  openSpaceDetails: EPMapOpenSpace[] = [];
  OpenSpacegetDetails: EPOpenSpace[] = [];
  eventOpenspaceDropDown: string[] = [];
  epIdDropDown: string | undefined;
  epSubIdDropDown: string | undefined;
  epOpenIdDropDown: string | undefined;
  isdisabledraft = false;
  isdisablesave = false;
  isdisablenext = false;
  isUpdate = false;
  isDisabled: boolean = false;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private router: Router, private appService: AppService, private toastr: ToastrService,
    private spinner : NgxSpinnerService) {

  }

  openSpace = new UntypedFormGroup({
    epid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epsubid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epopenspaceid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epopenspacename: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epopnspacecapacity: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    openspaceactivestatus: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epopenspaceshow: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epopenspacechargable: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epopenspacecharges: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epopenspacedesc: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epopenspacesize: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    activeyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    deleteyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    createdby: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    openspacerequired: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),

  })


  public EPOpenSpaceErrors = {
    epid: '',
    epsubid: '',
    epopenspaceid: '',
    epopenspacename: '',
    epopnspacecapacity: '',
    openspaceactivestatus: '',
    epopenspaceshow: '',
    epopenspacechargable: '',
    epopenspacecharges: '',
    activeyn: '',
    // epopenspacedesc: '',
    epopenspacesize: '',

  }


  ngOnInit() {
    this.getEventPlaceByuserId();
    this.openSpace.controls['epid'].disable();
    Object.keys(this.openSpace.controls).forEach((controlName) => {
      if (controlName !== 'openspacerequired') {
        this.openSpace.get(controlName).disable();
      }
    })
    // this.isdisablenext = true;

  }

  ngOnDestroy() {
    document.body.style.overflow = 'auto';
  }

  onSubmit() {
    this.formValidation("Active");
  }

  /* Openspace formvalidation function */
  formValidation(status: string) {
    this.EPOpenSpaceErrors.epid="";
    this.EPOpenSpaceErrors.epsubid="";
    this.EPOpenSpaceErrors.epopenspacename = "";
    this.EPOpenSpaceErrors.epopnspacecapacity = "";
    this.EPOpenSpaceErrors.openspaceactivestatus = "";
    // this.EPOpenSpaceErrors.epopenspaceshow = "";
    this.EPOpenSpaceErrors.epopenspacechargable = "";
    this.EPOpenSpaceErrors.epopenspacecharges = "";
    // this.EPOpenSpaceErrors.activeyn = "";
    // this.EPOpenSpaceErrors.epopenspacedesc = "";
    this.EPOpenSpaceErrors.epopenspacesize = "";



    let hasError = false;

    let inputValue = this.openSpace.controls['epopenspacename'].value;
    let outputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
    this.openSpace.controls['epopenspacename'].setValue(outputValue)

    if (this.openSpace.get('epid')?.invalid) {
      this.EPOpenSpaceErrors.epid = "*Event place is required";
      hasError = true;
    }

    if (this.openSpace.get('epsubid')?.invalid) {
      this.EPOpenSpaceErrors.epsubid = "*Event sub Place is required";
      hasError = true;
    }
    if (this.openSpace.get('epopenspacename')?.invalid) {
      this.EPOpenSpaceErrors.epopenspacename = "*Open Space Name is required";
      hasError = true;
    }
    if (this.openSpace.get('epopnspacecapacity')?.invalid) {
      this.EPOpenSpaceErrors.epopnspacecapacity = "*Open Space Capacity is required";
      hasError = true;
    }
    if (this.openSpace.get('epopenspacesize')?.invalid) {
      this.EPOpenSpaceErrors.epopenspacesize = "*Open Space Size is required";
      hasError = true;
    }
    // if (this.openSpace.get('openspaceactivestatus')?.invalid) {
    //   this.EPOpenSpaceErrors.openspaceactivestatus = "*Active status is required";
    //   hasError = true;
    // }

    // if (this.openSpace.get('epopenspaceshow')?.invalid) {
    //   this.EPOpenSpaceErrors.epopenspaceshow = "*Show is required";
    //   hasError = true;
    // }

    if (this.openSpace.get('epopenspacechargable')?.invalid) {
      this.EPOpenSpaceErrors.epopenspacechargable = "*Open Space Chargeable is required";
      hasError = true;
    }

    if (this.openSpace.get('epopenspacecharges')?.invalid) {
      this.EPOpenSpaceErrors.epopenspacecharges = "*Open Space Charge is required";
      hasError = true;
    }

    // if (this.openSpace.get('epopenspacedesc')?.invalid) {
    //   this.EPOpenSpaceErrors.epopenspacedesc = "*Description is required";
    //   hasError = true;
    // }

    if (!hasError) {
      this.saveEventPlace(status);
    }
  }

  /*EventPlace dropdown details API function*/
  getEventPlaceByuserId() {
    this.appService
      .getEventPlaceByuserId(localStorage.getItem('token'))
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("Event place Dropdwon::", data);
        //@ts-ignore
        this.eventPlace = data;
        var localEventPlace: string[] = [];
        for (var ep of this.eventPlace) {
          localEventPlace.push(ep.epname)
        }
        this.eventDropDown = localEventPlace;
      })
  }

  /*EventSubPlace dropdown details API function*/
  getEventSubPlace() {
    this.appService
      //@ts-ignore
      .getEventSubPlace(this.epIdDropDown)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("sub dropdown::", data);
        // @ts-ignore
        this.eventSubPlace = data;
        var localEventSubPlace: string[] = [];
        for (var ep of this.eventSubPlace) {
          if (ep.epmainplace == "Yes") {
            localEventSubPlace.push(ep.epsubname + " - Main Hall")
            console.log("main hall", this.eventSubDropDown)
          } else {
            localEventSubPlace.push(ep.epsubname)
          }
          this.eventSubDropDown = localEventSubPlace
        }
      })
  }

  /*Openspace dropdown details API function*/
  getOpenSpaceDropDown() {
    this.appService
      .getOpenSpaceDropDown(this.epIdDropDown, this.epSubIdDropDown)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("Test data::", data);
        // @ts-ignore
        this.OpenSpacegetDetails = data;
        if (this.OpenSpacegetDetails.length > 0) {
          this.openSpace.controls['epopenspaceid'].enable();
        } else{
          this.openSpace.controls['epopenspaceid'].disable();
        }
        var localOpenSpace: string[] = [];
        for (var ep of this.OpenSpacegetDetails) {
          localOpenSpace.push(ep.epopenspacename)
        }
        this.eventOpenspaceDropDown = localOpenSpace;
      })
  }

  /*Openspace all details Autofetch API function*/
  getOpenSpaceDetails() {
    this.spinner.show()
    this.appService
      .getOpenSpaceDetails(this.epIdDropDown, this.epSubIdDropDown, this.epOpenIdDropDown)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("GetKitchen Details::", data);
        this.spinner.hide()
        // @ts-ignore
        this.openSpaceDetails = data;
        for (var epOpen of this.openSpaceDetails) {
          this.isUpdate = true;
          this.openSpace.controls["epopenspacename"].setValue(epOpen.epopenspacename);
          this.openSpace.controls["epopnspacecapacity"].setValue(epOpen.epopnspacecapacity);
          this.openSpace.controls["epopenspacesize"].setValue(epOpen.epopenspacesize);
          this.openSpace.controls["epopenspacechargable"].setValue(epOpen.epopenspacechargable);
          // this.openSpace.controls["epopenspaceshow"].setValue(epOpen.epopenspaceshow);
          this.openSpace.controls["epopenspacedesc"].setValue(epOpen.epopenspacedesc);
          this.openSpace.controls["openspaceactivestatus"].setValue(epOpen.openspaceactivestatus)
          this.openSpace.controls["epopenspacecharges"].setValue(epOpen.epopenspacecharges);
        }
      },(error: any) => {
        console.log("Something went wrong");
        this.spinner.hide()
      })
  }

  /*EventPlace Dropdown Autofetch function*/
  onShoweventplace(event) {
    const value = event.target.value;
    console.log("Packing type::", value)
    for (var EPid of this.eventPlace) {
      if (value == EPid.epname) {
        this.epIdDropDown = EPid.epid;
        console.log("ep id::", this.epIdDropDown)
        this.getEventSubPlace();
        this.isDisabled = true;
        this.disable();
      } else {
        this.isDisabled = false;
        console.log("ep id:: error")
      }
    }
  }
  disable() {
    if (this.isDisabled) {
      this.openSpace.controls['epsubid'].enable();
    } else {
      this.openSpace.controls['epsubid'].disable();
    }
  }


  /*EventSubPlace Dropdown Autofetch function*/
  onShowSubPlace(event) {
    const value = event.target.value.replace(" - Main Hall", "");
    console.log("eventSub type::", value)
    for (var EPid of this.eventSubPlace) {
      if (value == EPid.epsubname) {
        this.epSubIdDropDown = EPid.epsubid;
        console.log("ep id::", this.epSubIdDropDown)
        this.getOpenSpaceDropDown();
        this.isDisabled = true;
        this.disable1();
      } else {
        this.isDisabled = false;
        console.log("epsub id:: error")
      }
    }
  }

  disable1() {
    if (this.isDisabled) {
      this.openSpace.controls['epopenspaceid'].enable();
    } else{
      this.openSpace.controls['epopenspaceid'].disable();
    }
  }


  /*Openspace Dropdown Autofetch function*/
  onShowOpenspacedetails(event) {
    const value = event.target.value;
    console.log("Packing type::", value)
    for (var EPid of this.OpenSpacegetDetails) {
      if (value == EPid.epopenspacename) {
        this.epOpenIdDropDown = EPid.epopenspaceid;
        console.log("ep id::", this.epOpenIdDropDown)
        this.getOpenSpaceDetails();
      }
    }
  }

  /* save and update Openspace API call function */
  saveEventPlace(status: string) {
    this.spinner.show()
    console.log("op ::",)
    if (this.isUpdate) {
      this.openSpace.controls["openspaceactivestatus"].setValue(status);
      this.openSpace.controls["createdby"].setValue(localStorage.getItem("token"));
      this.openSpace.controls["activeyn"].setValue("Yes");
      this.openSpace.controls["deleteyn"].setValue("No");
      this.openSpace.controls["epid"].setValue(this.epIdDropDown);
      this.openSpace.controls["epsubid"].setValue(this.epSubIdDropDown);
      this.openSpace.controls["epopenspaceid"].setValue(this.epOpenIdDropDown);
      console.log("updateopen space details::", this.openSpace.value);
      this.appService.updateopenSpace(this.openSpace.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          this.spinner.hide()
          console.log("Open space ::", data);
          // @ts-ignore
          if(data.openspaceactivestatus == "Draft"){
            this.successAlertDarft();
          }else{
          this.UpdateAlert();
          }
        }, (err: any) => {
          this.spinner.hide()
          this.errorAlert();
        },
          () => console.log('HTTP request completed.'));

    }
    else if (!this.isUpdate) {
      this.openSpace.controls["openspaceactivestatus"].setValue(status);
      this.openSpace.controls["createdby"].setValue(localStorage.getItem("token"));
      this.openSpace.controls["epid"].setValue(this.epIdDropDown);
      this.openSpace.controls["epsubid"].setValue(this.epSubIdDropDown);
      this.openSpace.controls["activeyn"].setValue("Yes");
      this.openSpace.controls["deleteyn"].setValue("No");
      console.log("open space details::", this.openSpace.value);
      this.appService.saveopenSpace(this.openSpace.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          this.spinner.hide()
          console.log("eventPlace ::", data);
          // @ts-ignore
          if(data.openspaceactivestatus == "Draft"){
            this.successAlertDarft();
          }else{
          this.successAlert();
          }
        }, (err: any) => {
          this.spinner.hide()
          this.errorAlert();
        },
          () => console.log('HTTP request completed.'));
          // this.isdisablenext =true;
    }
  }


  openspacedisable(event) {
    const value = event.target.value;
    console.log("'rooms'::", value);
    if (value == "no") {
      this.openSpace.disable();
      this.openSpace.controls['openspacerequired'].enable();
      this.isdisablesave = false;
      this.isdisabledraft = false;
      this.isdisablenext = true;
    } else {
      if (value == "yes") {
        this.openSpace.enable();
        this.isdisablesave = true;
        this.isdisabledraft = true;
        this.isdisablenext = false;
        this.disable1();
        this.disable();
      }
    }
  }

  draftButton(status: string){
    let hasError = false;

    if (this.openSpace.get('epid')?.invalid) {
      this.EPOpenSpaceErrors.epid
        = "*Event place is required";
      hasError = true;
    }

    if (this.openSpace.get('epsubid')?.invalid) {
      this.EPOpenSpaceErrors.epsubid = "*Event sub Place is required";
      hasError = true;
    }
    let inputValue = this.openSpace.controls['epopenspacename'].value;
    let outputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
    this.openSpace.controls['epopenspacename'].setValue(outputValue)
    if (this.openSpace.get('epopenspacename')?.invalid) {
      this.EPOpenSpaceErrors.epopenspacename = "*Open Space Name is required";
      hasError = true;
    }
      if (!hasError) {
        this.saveEventPlace(status)
   }
  }
  nextButton(){
    this.router.navigate(['/main/parking-area'])
    }

  /* only allowed numeric value function */
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  /* Openspace successalert popup message function */
  successAlert() {
    Swal.fire({
      title: "Your Registration Sucessfully Completed, Do you want to register another Open Space ?",
      icon: "success",
      showDenyButton: true,
      confirmButtonColor: "#4fab69",
      denyButtonColor: "#c73636",
      confirmButtonText: "Yes",
      denyButtonText: "No",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
          this.openSpace.controls["epid"].setValue("");
          this.openSpace.controls["epsubid"].setValue("");
          this.openSpace.controls["epopenspacename"].setValue("");
          this.openSpace.controls["epopnspacecapacity"].setValue("");
          this.openSpace.controls["epopenspacesize"].setValue("");
          this.openSpace.controls["epopenspacechargable"].setValue("");
          this.openSpace.controls["epopenspacedesc"].setValue("");
          this.openSpace.controls["openspaceactivestatus"].setValue("")
          this.openSpace.controls["epopenspacecharges"].setValue("");
      }else{
        this.router.navigate(['/main/parking-area'])
      }
    });
  }

  /* Openspace UpdateAlert popup message function */
  UpdateAlert() {
    Swal.fire({
      title: "Your Registration Updated Sucessfully",
      icon: "success",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.reload();
      }
    });
  }

  //OpenSpace successAlertDarft popup message
  successAlertDarft() {
    Swal.fire({
      title: "Your Registration successfully saved as draft, Do you want to register another Open Space ?",
      icon: "success",
      showDenyButton: true,
      confirmButtonColor: "#4fab69",
      denyButtonColor: "#c73636",
      confirmButtonText: "Yes",
      denyButtonText: "No",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.openSpace.controls["epid"].setValue("");
        this.openSpace.controls["epsubid"].setValue("");
        this.openSpace.controls["epopenspacename"].setValue("");
        this.openSpace.controls["epopnspacecapacity"].setValue("");
        this.openSpace.controls["epopenspacesize"].setValue("");
        this.openSpace.controls["epopenspacechargable"].setValue("");
        this.openSpace.controls["epopenspacedesc"].setValue("");
        this.openSpace.controls["openspaceactivestatus"].setValue("")
        this.openSpace.controls["epopenspacecharges"].setValue("");
    }else{
      this.router.navigate(['/main/parking-area'])
    }
    });
  }
  // openspace errorAlert popup message function
  errorAlert() {
    Swal.fire({
      title: "Sorry, Please Try Again Your Registration Process",
      icon: "error",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    });
  }
}
