<div class="container-fluid">
  <form [formGroup]="eventSubPlace" (ngSubmit)="onSubmit()" id="eventSubPlace">
    <div class="col-md-2 mt-1"></div>
    <div class="col-md-11">
      <h4 class="">Sub Place Registration</h4>
    </div>
    <div class="alert alert-light" role="alert">
      Note:<span style="color:red;">*</span> Screen to enter all the required details about your Sub Place.
    </div>
    <div class="row">
      <label class="col-3  col-form-label"><strong>Sub-Place Details Required</strong></label>
      <div class="form-check mt-1">
        <input class="form-check-input" type="radio" formControlName="subplacerequired" value="yes"
          (change)="subplacedisable($event)">
        <label class="form-check-label"><b>Yes</b></label>
      </div>
      <div class="form-check mt-1">
        <input class="form-check-input ml-1" type="radio" formControlName="subplacerequired" value="no"
          (change)="subplacedisable($event)">
        <label class="form-check-label ml-4"><b>No</b></label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label for="epsubname" class="form-label">Event Place<span style="color:red;">*</span></label>
            <select class="form-control slct shadow-none " formControlName="epid" formControlName="epid"
              (change)="onShowevent($event)">
              <option *ngFor="let parms of eventDropdown" value="{{parms}}">{{parms}}</option>
              <option value="" disabled selected hidden>Select Event place</option>
            </select>
            <span *ngIf="epsubplaceErrors.epid" class="error-text">{{epsubplaceErrors.epid}}</span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label for="epsubname" class="form-label">Event Sub Place</label>
            <select class="form-control  slct shadow-none " formControlName="epsubid" disabled="true"
              (change)="onShowSubPlaceDetails($event)">
              <option value="" selected>Select Event Sub Place</option>
              <option *ngFor="let parms of eventSubDropDown" value="{{parms}}">{{parms}}</option>
            </select>
          </div>
        </div>
      </div>
      <!-- <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label>If you register in the Main Hall select Yes or No.</label>
            <select class="form-control" id="epmainplace" formControlName="epmainplace">
              <option value="" disabled selected hidden>Select Menu</option>
              <option>Yes</option>
              <option>No</option>
            </select>
          </div>
        </div>
      </div> -->
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label>Sub Place Name<span style="color:red;">*</span></label>
            <input type="text" class="form-control text-capitalize" id="epsubname" placeholder="Sub Place Name"
              formControlName="epsubname">
            <span *ngIf="epsubplaceErrors.epsubname" class="error-text">{{epsubplaceErrors.epsubname}}</span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label>Sub Place Nick Name<span style="color:red;">*</span></label>
            <input type="text" class="form-control text-capitalize" id="epsubnickname" placeholder="Sub Place Nick Name"
              formControlName="epsubnickname">
            <span *ngIf="epsubplaceErrors.epsubnickname" class="error-text">{{epsubplaceErrors.epsubnickname}}</span>
          </div>
        </div>
      </div> 
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label>Logo<span style="color:red;">*</span></label>
            <div class="form-check">
              <label>
                <input type="radio" class="form-check-input" formControlName="logotype" id="square" value="square"
                       checked="checked" (change)="onShapeChanged('square')">Square(100*100px)
              </label>
            </div>
            <div class="form-check">
              <label>
                <input type="radio" class="form-check-input" formControlName="logotype" id="rectangle" value="rectangle"
                       (change)="onShapeChanged('rectangle')">Rectangle(200*100px)
              </label>
            </div>
            <div class="form-check">
              <label>
                <input type="radio" class="form-check-input" formControlName="logotype" id="circle" value="circle"
                 (change)="onShapeChanged('circle')">Circle(100*100px)
              </label>
              <div class="pt-3 d-flex justify-content-center">
                <div *ngIf="isSquare" id="imagesUrl" class="card" style="width: 100px; height: 100px; margin-top: -120px; margin-left: 100px;">
                  <div *ngIf="images" style="width: 100px; height: 100px;">
                    <img class="image-preview" [src]="images" width="100" height="100">
                  </div>
                </div>
                <div *ngIf="isRectangle" id="imagesUrl" class="card" style="width: 200px; height: 100px; margin-top: -120px; margin-left: 100px;">
                  <div *ngIf="images" style="width: 200px; height: 100px;">
                    <img class="image-preview" [src]="images" width="200" height="100">
                  </div>
                </div>
                <div *ngIf="isCircle" id="imagesUrl" class="card" style="width: 100px; height: 100px; margin-top: -120px; margin-left: 100px; border-radius: 50%">
                  <div *ngIf="images" style="width: 100px; height: 100px; border-radius: 50%">
                    <img class="image-preview circle" [src]="images" width="100" height="100">
                  </div>
                </div>
              </div>
            </div>

            <div class="custom-file">
              <input type="file" class="custom-file-input" id="epsubimage" (change)="onFileChange($event)" formControlName="epsubimage">
              <div *ngIf="epsubplaceErrors.epsubimage" class="error-text">
                {{epsubplaceErrors.epsubimage}}
              </div>
              <label class="custom-file-label" for="epsubimage">Choose file</label>
            </div>
          </div>
        </div>
      </div>    
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label>Phone Number<span style="color:red;">*</span></label>
            <input type="text" (keypress)="OnlyNumbersAllowed($event)" class="form-control" id="phonenumber"
              placeholder="Phone Number" formControlName="phonenumber" maxLength="10">
            <span *ngIf="epsubplaceErrors.phonenumber" class="error-text">{{epsubplaceErrors.phonenumber}}</span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label>Email Address<span style="color:red;">*</span></label>
            <input type="text" class="form-control" id="emailid" placeholder="Email Address" formControlName="emailid">
            <span *ngIf="epsubplaceErrors.emailid" class="error-text">{{epsubplaceErrors.emailid}}</span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <div class="col-md-1"></div>
          <div class="col-md-11">
            <label>Description</label>
            <input class="form-control text-capitalize" id="epsubdesc" placeholder="Description"
              formControlName="epsubdesc">
          </div>
        </div>
      </div>
   </div>
    <div class="row">
      <div class="col-md-4">
        <div class="col-md-1"></div>
        <div class="col-md-11">
          <div class="form-group">
            <label class="mr-3">Air Conditioned<span style="color:red;">*</span>:</label>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" value="Yes" name="epsubaircond" id="epsubaircond"
                formControlName="epsubaircond">
              <label class="form-check-label" for="epsubaircond"><b>Yes</b></label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" value="No" name="epsubaircond" id="epsubaircond"
                formControlName="epsubaircond">
              <label class="form-check-label" for="epsubaircond"><b>No</b></label>
            </div><br>
            <span *ngIf="epsubplaceErrors.epsubaircond" class="error-text">{{epsubplaceErrors.epsubaircond}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center">
      <button type="button" class="btn btn-primary" (click)="draftButton('Draft')" *ngIf="isdisabledraft">Save as Draft
      </button>
      <button type="submit" class="btn btn-success m-3" *ngIf="isdisablesave">{{this.isUpdate == true ? "Update" :
        "Save"}}</button>
      <button type="submit" class="btn btn-primary m-3" (click)="nextButton()" *ngIf="isdisablenext">Next</button>
    </div>
    <div class="row mt-5"></div>
  </form>
</div>
<ngx-spinner>
  <div class="custom-loader"></div>
</ngx-spinner>