<app-event-header  class="sticky-top"></app-event-header>
<nav class="navbar  navbar-light bg-light"  style="height: 45px;">
        <div class="col-md-2">
      <a class="nav-link" routerLink="/user-mapping-management" data-toggle="tooltip"
      data-placement="top" title="User Mapping Id Creates to Event Place">User Mapping</a>
    </div>
    <div class="col-md-2" *ngIf="userStatusShow">
      <a class="nav-link" routerLink="/user-status" data-toggle="tooltip"
      data-placement="top" title="User Status Approval Screen">User Status</a>
      </div>
      <div class="col-md-8"></div>
  </nav>
<div class="row mt-1"></div>
<div class="container">
  <form (ngSubmit)="onSubmit()" [formGroup]="epUser" id="events">
    <div class="row mt-3"></div>
      <div style="text-align: center" *ngIf="buttondisable">
        <button class="btn btn-danger" style="margin: 5px" type="button" (click)="mappingButton()"> Map </button>
      </div>
   
  <!--<div class="row mt-3"></div>-->
  <div class="row mt-2"></div>
  <div class="card table-container">
    <table class="table table-hover mb-0">
       <thead class="sticky-top">
  <tr class="fixed-row">
    <th scope="col"></th>
    <th scope="col">Event Place</th>
    <th scope="col">Address</th>
    <th scope="col">Status</th>
    <th scope="col">User Name</th>
  </tr>
  </thead>
  <tbody class="table-bordered">
  <tr *ngFor="let event of eventPlace" class="text-center">
    <td>
      <input type="checkbox" [checked]="event.isChecked" (change)="selectCheckbox(event.epname, $event.target.checked)" />
    </td>
    <td>{{ event.epname }}</td>
    <!--<td>{{ eventSubPlace }}</td>-->
    <td>{{ event.epaddress1 }}</td>
    <td>{{ event.activeyn }}</td>
    <td>
      <select class="form-control" (change)="adminselect($event)" formControlName="epuserfirstname">
        <option *ngFor="let parms of users" value="{{parms.epuserfirstname}}">{{parms.epuserfirstname}}</option>
        <option value="" disabled selected hidden>Select User Name</option>
      </select>
    </td>
    <!--<td>{{ firstName }}</td>-->
  </tr>
  </tbody>
</table>
  </div>
</form>
   </div>
<div class="row mt-1"></div>

<app-event-footer></app-event-footer>
<ngx-spinner>
  <div class="custom-loader"></div>
</ngx-spinner>