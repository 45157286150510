import { Component, OnInit, HostListener } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { AppService } from "@services/app.service";
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { pipe, Subject, takeUntil } from "rxjs";
import { EventPlace } from "@modules/event-place-management/model/eventplace";
import { EventSubPlace } from "@modules/event-place-management/model/eventsub-place";
import { EPIncidentalCharges } from '@modules/event-place-management/model/ep-incidentalcharges';
import Swal from "sweetalert2";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-incident-charges',
  templateUrl: './incident-charges.component.html',
  styleUrls: ['./incident-charges.component.scss']
})

export class IncidentChargesComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  incidentChargesDetails: EPIncidentalCharges[] = [];
  eventPlace: EventPlace[] = [];
  eventSubPlace: EventSubPlace[] = [];
  eventDropdown: string[] = [];
  eventSubDropDown: string[] = [];
  epIdDropDown: string | undefined;
  epSubDropDown: string | undefined;
  epIncidentChargesDropDown: string | undefined;
  eventIncidentCharges: EPIncidentalCharges[] = [];
  eventIncidentChargesDropDown: string[] = [];
  public EPID: String;
  isdisabledraft = false;
  isdisablesave = false;
  isdisablenext = false;
  isDisabled: boolean = false;
  isUpdate = false;

  constructor(private router: Router, private appService: AppService, private toastr: ToastrService,
    private spinner : NgxSpinnerService) { }
  epIncidentCharges = new UntypedFormGroup({
    epid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epsubid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epincidentalid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epincidentalname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epincidentalunit: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    chargeactivestatus: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epincidentalchargeable: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epincidentalchargecharges: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epincidentaldesc: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    activeyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    deleteyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    createdby: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    incidentchargesrequired: new UntypedFormControl('', [Validators.required, Validators.nullValidator])
  })

  public epIncidentChargesErrors = {
    epid: '',
    epsubid: '',
    epincidentalid: '',
    epincidentalname: '',
    epincidentalunit: '',
    epincidentalchargeable: '',
    epincidentalchargecharges: '',
  }


  ngOnInit() {
    this.getEventPlaceByUserId();
    this.epIncidentCharges.controls['epid'].disable();
    Object.keys(this.epIncidentCharges.controls).forEach((controlName) => {
      if (controlName !== 'incidentchargesrequired') {
        this.epIncidentCharges.get(controlName).disable();
      }
    })
    // this.isdisablenext = true;

  }
  ngOnDestroy() {
    document.body.style.overflow = 'auto';
  }
  onSubmit() {
    this.formValidation("Active");
  }

  //Form validation call funtion

  formValidation(status: string) {
    this.epIncidentChargesErrors.epid = "";
    this.epIncidentChargesErrors.epsubid = "";
    this.epIncidentChargesErrors.epincidentalid = "";
    this.epIncidentChargesErrors.epincidentalname = "";
    this.epIncidentChargesErrors.epincidentalunit = "";
    this.epIncidentChargesErrors.epincidentalchargeable = "";
    this.epIncidentChargesErrors.epincidentalchargecharges = "";
    let hasError = false;

    let inputValue = this.epIncidentCharges.controls['epincidentalname'].value;
    let outputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
    this.epIncidentCharges.controls['epincidentalname'].setValue(outputValue)

    let inputValue1 = this.epIncidentCharges.controls['epincidentaldesc'].value;
    let outputValue1 = inputValue1.charAt(0).toUpperCase() + inputValue1.slice(1);
    this.epIncidentCharges.controls['epincidentaldesc'].setValue(outputValue1)

    if (this.epIncidentCharges.get('epid')?.invalid) {
      this.epIncidentChargesErrors.epid = "*Event Place is required";
      hasError = true;
    }
    if (this.epIncidentCharges.get('epsubid')?.invalid) {
      this.epIncidentChargesErrors.epsubid = "*Event Sub Place is required";
      hasError = true;
    }
    if (this.epIncidentCharges.get('epincidentalname')?.invalid) {
      this.epIncidentChargesErrors.epincidentalname = "*Incidental Charges Name is required";
      hasError = true;
    }
    if (this.epIncidentCharges.get('epincidentalunit')?.invalid) {
      this.epIncidentChargesErrors.epincidentalunit = "*Incidental Unit is required";
      hasError = true;
    }
    if (this.epIncidentCharges.get('epincidentalchargeable')?.invalid) {
      this.epIncidentChargesErrors.epincidentalchargeable = "*Incidental Chargeable is required";
      hasError = true;
    }
    if (this.epIncidentCharges.get('epincidentalchargecharges')?.invalid) {
      this.epIncidentChargesErrors.epincidentalchargecharges = "*Incidental Charges is required";
      hasError = true;
    }
    if (!hasError) {
      this.saveIncidentCharges(status);
    }
  }

  //EventPlace DropDown API Function

  getEventPlaceByUserId() {
    this.appService
      .getEventPlaceByuserId(localStorage.getItem('token'))
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("Event Place Dropdown::", data);
        // @ts-ignore
        this.eventPlace = data;
        var localEventPlace: string[] = [];
        for (var ep of this.eventPlace) {
          localEventPlace.push(ep.epname)
        }
        this.eventDropdown = localEventPlace;
      })
  }

  //SubPlace DropDown API Function

  getEventSubPlace() {
    this.appService
      //@ts-ignore
      .getEventSubPlace(this.epIdDropDown)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("Sub Dropdown::", data);
        // @ts-ignore
        this.eventSubPlace = data;
        var localEventSubPlace: string[] = [];
        for (var ep of this.eventSubPlace) {
          if (ep.epmainplace == "Yes") {
            localEventSubPlace.push(ep.epsubname + " - Main Hall")
            console.log("main hall", this.eventSubDropDown)
          } else {
            localEventSubPlace.push(ep.epsubname)
          }
          this.eventSubDropDown = localEventSubPlace
        }
      })
  }

  //Incident Charges DropDown get API function

  getIncidentChargesDropDown() {
    this.appService
      .getIncidentChargesDropDown(this.epIdDropDown, this.epSubDropDown)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("Test data::", data);
        // @ts-ignore
        this.eventIncidentCharges = data;
        if (this.eventIncidentCharges.length > 0) {
          this.epIncidentCharges.controls['epincidentalid'].enable();
        } else {
          this.epIncidentCharges.controls['epincidentalid'].disable();
        }
        var locaIncidental: string[] = [];
        for (var ep of this.eventIncidentCharges) {
          locaIncidental.push(ep.epincidentalname)
        }
        this.eventIncidentChargesDropDown = locaIncidental;
      })
  }

  //Incident Charges Details AutoFetch API Funtion

  getIncidentChargesDetails() {
    this.spinner.show()
    this.appService
      .getIncidentChargesDetails(this.epIdDropDown, this.epSubDropDown, this.epIncidentChargesDropDown)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide()
        console.log("Get Incident Charges Details::", data);
        // @ts-ignore
        this.incidentChargesDetails = data;
        for (var charges of this.incidentChargesDetails) {
          this.isUpdate = true
          this.epIncidentCharges.controls["epincidentalname"].setValue(charges.epincidentalname);
          // this.epIncidentCharges.controls["epincidentalum"].setValue(charges.epincidentalum);
          this.epIncidentCharges.controls["epincidentalunit"].setValue(charges.epincidentalunit);
          // this.epIncidentCharges.controls["epincidentalunitlistprice"].setValue(charges.epincidentalunitlistprice);
          this.epIncidentCharges.controls["chargeactivestatus"].setValue(charges.chargeactivestatus);
          this.epIncidentCharges.controls["epincidentalchargeable"].setValue(charges.epincidentalchargeable);
          // this.epIncidentCharges.controls["epincidentalshow"].setValue(charges.epincidentalshow);
          this.epIncidentCharges.controls["epincidentalchargecharges"].setValue(charges.epincidentalchargecharges);
          this.epIncidentCharges.controls["epincidentaldesc"].setValue(charges.epincidentaldesc);
        }
      },(error: any) => {
        this.spinner.hide()
        console.log("Something went wrong");
      })
  }

  // Save and Update Funtion in Incident Charges

  saveIncidentCharges(status: string) {
    this.spinner.show()
    console.log("is update::", this.isUpdate)
    if (this.isUpdate) {
      this.epIncidentCharges.controls["chargeactivestatus"].setValue(status);
      this.epIncidentCharges.controls["activeyn"].setValue("Yes");
      this.epIncidentCharges.controls["deleteyn"].setValue("No");
      this.epIncidentCharges.controls["createdby"].setValue(localStorage.getItem("token"));
      this.epIncidentCharges.controls["epid"].setValue(this.epIdDropDown);
      this.epIncidentCharges.controls["epsubid"].setValue(this.epSubDropDown);
      this.epIncidentCharges.controls["epincidentalid"].setValue(this.epIncidentChargesDropDown);
      console.log("epIncidentCharges details::", this.epIncidentCharges.value);
      this.appService.updateincidentcharges(this.epIncidentCharges.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          this.spinner.hide()
          console.log("updateincidentcharges details ::", data);
          // @ts-ignore
          if(data.chargeactivestatus == "Draft"){
            this.successAlertDarft()
          }else{
          this.UpdateAlert();
          }
        }, (err: any) => {
          this.spinner.hide()
          this.errorAlert();
        },
          () => console.log('HTTP request completed.'));
      // this.isdisablenext =true;
    }
    else if (!this.isUpdate) {
      console.log("is update::", this.isUpdate)
      this.epIncidentCharges.controls["chargeactivestatus"].setValue(status);
      this.epIncidentCharges.controls["activeyn"].setValue("Yes");
      this.epIncidentCharges.controls["deleteyn"].setValue("No");
      this.epIncidentCharges.controls["createdby"].setValue(localStorage.getItem("token"));
      this.epIncidentCharges.controls["epid"].setValue(this.epIdDropDown);
      this.epIncidentCharges.controls["epsubid"].setValue(this.epSubDropDown);
      console.log("Incident Charges details::", this.epIncidentCharges.value);
      this.appService.saveCharges(this.epIncidentCharges.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          this.spinner.hide()
          console.log("IncidentCharges Details ::", data);
          // @ts-ignore
          if(data.chargeactivestatus == "Draft"){
            this.successAlertDarft()
          }else{
          this.successAlert();
          }
        }, (err: any) => {
          this.spinner.hide()
          this.errorAlert();
        },
          () => console.log('HTTP request completed.'));
      // this.isdisablenext =true;
    }
  }
  //incidentcharges required functionality
  incidentchargesdisable(event) {
    const value = event.target.value;
    console.log("'incident charges'::", value);
    if (value == "no") {
      this.epIncidentCharges.disable();
      this.epIncidentCharges.controls['incidentchargesrequired'].enable();
      this.isdisablesave = false;
      this.isdisabledraft = false;
      this.isdisablenext = true;
    } else {
      if (value == "yes") {
        this.epIncidentCharges.enable();
        this.isdisablesave = true;
        this.isdisabledraft = true;
        this.isdisablenext = false;
        this.disable1();
        this.disable();
      }
    }
  }
  draftButton(status: string) {
    let hasError = false;

    if (this.epIncidentCharges.get('epid')?.invalid) {
      this.epIncidentChargesErrors.epid = "*Event place is required";
      hasError = true;
    }
    if (this.epIncidentCharges.get('epsubid')?.invalid) {
      this.epIncidentChargesErrors.epsubid = "*Event Sub Place is required";
      hasError = true;
    }
    let inputValue = this.epIncidentCharges.controls['epincidentalname'].value;
    let outputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
    this.epIncidentCharges.controls['epincidentalname'].setValue(outputValue)

    if (this.epIncidentCharges.get('epincidentalname')?.invalid) {
      this.epIncidentChargesErrors.epincidentalname = "*Incidental Charges Name is required";
      hasError = true;
    }
    if (!hasError) {
      this.saveIncidentCharges(status)
    }
  }
  nextButton() {
    this.router.navigate(['/main/misc-charges'])
  }



  //EventPlace DropDown AutoFetch Funtion

  onShoweventsub(event) {
    const value = event.target.value;
    console.log("Event Name::", value)
    for (var EPid of this.eventPlace) {
      if (value == EPid.epname) {
        this.epIdDropDown = EPid.epid;
        console.log("ep id::", this.epIdDropDown)
        this.getEventSubPlace();
        this.isDisabled = true;
        this.disable();
      } else {
        this.isDisabled = false;
        console.log("ep id:: error")
      }
    }
  }

  disable() {
    if (this.isDisabled) {
      this.epIncidentCharges.controls['epsubid'].enable();
    } else {
      this.epIncidentCharges.controls['epsubid'].disable();
    }
  }

  //SubPlace Dropdown autofetch function

  onShowIncidentCharges(event) {
    const value = event.target.value.replace(" - Main Hall", "");
    console.log("Packing type::", value)
    for (var EPid of this.eventSubPlace) {
      if (value == EPid.epsubname) {
        this.epSubDropDown = EPid.epsubid;
        console.log("ep id::", this.epSubDropDown)
        this.getIncidentChargesDropDown();
        this.isDisabled = true;
        this.disable1();
      } else {
        this.isDisabled = false;
        console.log("ep id:: error")
      }
    }
  }

  disable1() {
    if (this.isDisabled) {
      this.epIncidentCharges.controls['epincidentalid'].enable();
    } else {
      this.epIncidentCharges.controls['epincidentalid'].disable();
    }
  }

  //Incident Charges Details Dropdown AutoFetch function

  onShowIncidentChargesDetails(event) {
    const value = event.target.value;
    console.log("Packing type::", value)
    for (var EPid of this.eventIncidentCharges) {
      if (value == EPid.epincidentalname) {
        this.epIncidentChargesDropDown = EPid.epincidentalid;
        console.log("ep id::", this.epSubDropDown);
        this.getIncidentChargesDetails();
      }
    }
  }

  // Only Allow Numeric Values

  OnlyNumbersAllowed(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  //Incident Charges Success Alert Popup Message

  successAlert() {
    Swal.fire({
      title: "Your Registration Sucessfully Completed, Do you want to register another Incidental Charge?",
      icon: "success",
      showDenyButton: true,
      confirmButtonColor: "#4fab69",
      denyButtonColor: "#c73636",
      confirmButtonText: "Yes",
      denyButtonText: "No",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.epIncidentCharges.controls["epid"].setValue("");
        this.epIncidentCharges.controls["epsubid"].setValue("");
        this.epIncidentCharges.controls["epincidentalname"].setValue("");
        this.epIncidentCharges.controls["epincidentalunit"].setValue("");
        this.epIncidentCharges.controls["chargeactivestatus"].setValue("");
        this.epIncidentCharges.controls["epincidentalchargeable"].setValue("");
        this.epIncidentCharges.controls["epincidentalchargecharges"].setValue("");
        this.epIncidentCharges.controls["epincidentaldesc"].setValue("");
      }else{
        this.router.navigate(['/main/misc-charges'])
      }
    });
  }

  //Incident Charges successAlertDarft popup message
successAlertDarft() {
  Swal.fire({
    title: "Your Registration successfully saved as draft, Do you want to register another Incidental Charge?",
    icon: "success",
    showDenyButton: true,
    confirmButtonColor: "#4fab69",
    denyButtonColor: "#c73636",
    confirmButtonText: "Yes",
    denyButtonText: "No",
    showClass: {
      popup: "animate_animated animate_fadeInDown",
    },
    hideClass: {
      popup: "animate_animated animate_fadeOutUp",
    },
  }).then((result) => {
    if (result.isConfirmed) {
      this.epIncidentCharges.controls["epid"].setValue("");
      this.epIncidentCharges.controls["epsubid"].setValue("");
      this.epIncidentCharges.controls["epincidentalname"].setValue("");
      this.epIncidentCharges.controls["epincidentalunit"].setValue("");
      this.epIncidentCharges.controls["chargeactivestatus"].setValue("");
      this.epIncidentCharges.controls["epincidentalchargeable"].setValue("");
      this.epIncidentCharges.controls["epincidentalchargecharges"].setValue("");
      this.epIncidentCharges.controls["epincidentaldesc"].setValue("");
    }else{
      this.router.navigate(['/main/misc-charges'])
    }
  });
}

  //Incident Charges Update Alert Popup Message

  UpdateAlert() {
    Swal.fire({
      title: "Your Registration Updated Sucessfully.",
      icon: "success",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.reload();
      }
    });
  }

  errorAlert() {
    Swal.fire({
      title: "Sorry, Please Try Again Your Registration Process",
      icon: "error",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    });
  }
}
