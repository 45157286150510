<head>
  <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css" rel="stylesheet"
        integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN" crossorigin="anonymous">
  <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-C6RzsynM9kWDrMNeT87bh95OGNyZPhcTNXj1NW7RuBCsyN/o0jlpcV8Qyq46cDfL" crossorigin="anonymous"></script>
</head>
<nav class="navbar navbar-white bg-white fixed-top p-0">
  <a class="navbar-brand" href="#">
    <img src="../../../assets/img/logo1.png" height="60" width="60" alt="EPM" class="img-fluid bg-light">
    <span class="text-bold mt-5 h4" style="color:#f71d83;"><span style="color:#0aa2e5">Panthal.</span>com</span>
  </a>

  <!--<button class="btn btn-success" [routerLink]="['/vh-website']">Website</button>-->
</nav>
<section class="slider-main w-100 h-50"
         style="background-image: url(../../../assets/img/wedding-slider.png);opacity: 60%">
</section>
<div class="container-fluid" style="font-family: 'prompt';background-color: rgba(237,252,250,0.78)">
  <div class="container col-lg-12">
    <div class="row">
      <div class="col-lg-12">
        <div class="card bg-white" style="margin-top: -40px">
          <div class="row mt-2 mb-3 ml-3 mr-3">
            <div class="col-lg col-12 mt-2">
              <div class="form-floating">
                <select class="form-select" aria-label="Floating label select example" [formControl]="country">
                  <option>Select your Event Country</option>
                  <option [value]="'India'" selected>India</option>
                  <!-- <option *ngFor="let country of countrySortList" [value]="country">{{country}}</option>-->

                </select>
                <label><img src="../../../assets/icons/city%20marker.png" class="img-fluid mr-2"> Wedding
                  Country</label>
              </div>
            </div>

            <div class="col-lg col-12 mt-2">
              <div class="form-floating">
                <select class="form-select" aria-label="Floating label select example" [formControl]="state">
                  <option selected>Select your State</option>
                  <!--<option *ngFor="let state of stateSortList" [value]="state">{{state}}</option>-->
                  <option [value]="'Tamil Nadu'" selected>Tamil Nadu</option>
                </select>
                <label><img src="../../../assets/icons/local.png" class="img-fluid mr-2"> State</label>
              </div>
            </div>

            <div class="col-lg col-12 mt-2">
              <div class="form-floating">
                <select class="form-select" aria-label="Floating label select example" [formControl]="city"
                        (change)="selectCity($event)">
                  <option selected>Select your City</option>
                  <option *ngFor="let city of citySortList" [value]="city">{{city}}</option>
                </select>
                <label><img src="../../../assets/icons/local.png" class="img-fluid mr-2"> City</label>
              </div>
            </div>

            <!--<div class="col-lg col-12 mt-2">
              <div class="form-floating">
                <select class="form-select"  aria-label="Floating label select example" [formControl]="area" (change)="selectArea($event)">
                  <option selected>Select your City</option>
                  <option *ngFor="let city of citySortList" [value]="city">{{city}}</option>
                </select>
                <label ><img src="../../../assets/icons/local.png" class="img-fluid mr-2"> City</label>
              </div>
            </div>-->

            <div class="col-lg col-12 mt-2">
              <button class="btn btn-primary btn-block p-3" (click)="findVenue(cityName)">Find Venues <img
                src="../../../assets/icons/search-i.png" class="img-fluid"></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="container-fluid  row mt-2">
    <h4 class="text-bold col-12 ml-5 mt-3 mb-3">Event Venues at Top Cities</h4>
  </div>
  <div class="">
    <div class="col-lg-12 col-12 swiper-wrapper">
      <swiper
        [config]="configCity"
        [spaceBetween]="10"
        [navigation]="true"
      >
        <ng-container *ngFor="let image of images">
          <ng-template swiperSlide class="text-center" type="button">
            <img [src]="image.src" class="img-fluid" [attr.value]="image.value" class="img-fluid"
                 (click)="topCityVenues(image.value)" type="button">
            <h6 class="text-center mt-2 text-bold">{{ image.value }}</h6>
            <!-- <p class="text-center mt-2 text-primary">{{maduraiVenueLength}} Venues</p>-->
          </ng-template>
        </ng-container>
      </swiper>
    </div>
  </div>

  <div class="container-fluid" *ngIf="showSubPlace">
    <div class="row mt-3">
      <div class="row mt-2">
        <h5 class="text-muted mt-2 mb-3 col-lg-11 col-8 col-md-9">{{eventMainName}} Venues</h5>
        <h5 class="col-lg-1 col-4 col-md-3 mt-2" type="button">
          <button class="btn btn-light" (click)="backCity()">
            <img src="../../../assets/icons/arrow.png" class="img-fluid" style="height: 20px"><span class=" ml-2 mt-3">Back</span>
          </button>
        </h5>
      </div>
      <div class="col-lg-12 col-md-12 col-12">
        <swiper
          [config]="config"
          [spaceBetween]="20"
          [navigation]="true"

        >
          <ng-template swiperSlide *ngFor="let showSubVenue of getEventSubIdDetails">
            <div class="card card-container" type="button"
                 (click)="subIdBasedDetailsShowInViewScreen(showSubVenue.epsubid)">
              <img class="card-img-top img-fluid" style="height: 300px;"
                   src="https://tomcat3.varnik.cloud:8443/epm-ws/Place/EventsubPlaceLogo/{{showSubVenue.epid}}/{{showSubVenue.epsubid}}"
                   alt="{{showSubVenue.epsubname}}">
              <div class="card-body">
                <h5 class="text-bold">{{showSubVenue.epsubname}}</h5>
                <p class="text-muted" style="font-size: 14px">{{eventCity}},{{eventState}}</p>
              </div>
            </div>
          </ng-template>
        </swiper>
      </div>
    </div>
  </div>


  <div class="container-fluid col-lg-11  col-12 mb-5 mt-3" *ngIf="showCity">
    <div class="">
      <div class="row mt-2">
        <div class="col-lg-12">
          <div class="row">
            <h4 class="text-bold">Top Event Venues in {{cityName}}<span class="text-muted col mt-1 font-weight-lighter"
                                                                        style="font-size: 16px">({{searchByCity.length}}
              found)</span></h4>
          </div>
        </div>

        <div class="container text-center" *ngIf="searchByCity.length == 0 && noResult">
          <div class="row mt-2">
            <h3 class="text-danger">No Result Found, Please Search Nearest City Form You</h3>
          </div>
        </div>
        <div class="row ml-2">
          <div class="col-lg-2 col-md-6 col-12 mt-4" *ngFor="let filterCity of searchByCity">
            <div class="card card-container" type="button" (click)="venueDetails(filterCity.epid)">
              <img class="card-img-top img-fluid" style="height: 300px;"
                   src="https://tomcat3.varnik.cloud:8443/epm-ws/Place/EventPlaceLogo/{{filterCity.epid}}"
                   alt="{{filterCity.epname}}">
              <div class="card-body">
                <h5 class="text-bold">{{filterCity.epname}}</h5>
                <p class="text-muted" style="font-size: 14px">{{filterCity.epcity}},{{filterCity.epstate}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid col-lg-11  col-12 mb-5 mt-3" *ngIf="showTopCities">
    <div class="">
      <div class="row mt-2">
        <div class="col-lg-12">
          <div class="row">
            <h4 class="text-bold">Top Event Venues in {{topCityNames}}<span
              class="text-muted col mt-1 font-weight-lighter" style="font-size: 16px">({{searchByTopCities.length}}
              found)</span></h4>
          </div>
        </div>

        <div class="container text-center" *ngIf="searchByTopCities.length == 0 && noResultForTopCities">
          <div class="row mt-2">
            <h3 class="text-danger">No Result Found, Please Search Nearest City Form You</h3>
          </div>
        </div>
        <div class="row ml-2">
          <div class="col-lg-3 col-12 mt-4" *ngFor="let filterTopCities of searchByTopCities">
            <div class="card card-container" type="button" (click)="venueDetails(filterTopCities.epid)">
              <img class="card-img-top img-fluid" style="height: 300px;"
                   src="https://tomcat3.varnik.cloud:8443/epm-ws/Place/EventPlaceLogo/{{filterTopCities.epid}}"
                   alt="{{filterTopCities.epname}}">
              <div class="card-body">
                <h5 class="text-bold">{{filterTopCities.epname}}</h5>
                <p class="text-muted" style="font-size: 14px">{{filterTopCities.epcity}},{{filterTopCities.epstate}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="container-fluid col-lg-12" *ngIf="showAll">
    <div class="row mt-5"></div>
    <div class="row p-0">
      <div class="col-lg-12 col-12">
        <h5 class="text-bold ml-5">Event Venues By City</h5>
      </div>
    </div>
    <swiper
      [config]="config"
      [spaceBetween]="20"
      [navigation]="true"

    >
      <ng-template swiperSlide>
        <div class="card card-container" type="button" (click)="venueDetails('EPI-000008')">
          <img class="card-img-top img-fluid w-100" style="height: 275px;"
               src="https://tomcat3.varnik.cloud:8443/epm-ws/Place/EventPlaceLogo/EPI-000008"
               alt="Agro Food Auditorium">
          <div class="card-body">
            <h6 class="text-bold">Agro Food Auditorium</h6>
            <p class="text-muted" style="font-size: 14px">Madurai, Tamilnadu</p>
          </div>
        </div>
      </ng-template>
      <ng-template swiperSlide *ngFor="let showVenue of firstFiveVenues">
        <div class="card card-container" type="button" (click)="venueDetails(showVenue.epid)" *ngIf="showVenue.epid !== 'EPI-000008'">
          <img class="card-img-top img-fluid w-100" style="height: 275px;"
               [src]="generateImageUrl(showVenue.epid)"
               [alt]="generateAltText(showVenue.epname)">
          <div class="card-body">
            <h6 class="text-bold">{{showVenue.epname}}</h6>
            <p class="text-muted" style="font-size: 14px">{{showVenue.epcity}},{{showVenue.epstate}}</p>
          </div>
        </div>
      </ng-template>
    </swiper>
  </div>

  <section class="container-fluid p-0 mb-5" style="background-color: rgba(173,216,230,0.32)" *ngIf="showContent"
           data-aos="zoom-in-down" data-aos-duration="3000">
    <div class="container col-10">
      <div class="row">
        <div class="col-lg-12 col-12">
          <h3 class="text-bold mt-5">Welcome to<span class="text-bold" style="color:#f71d83;"><span
            style="color:#0aa2e5"> Panthal.</span>com</span> - Simplifying Your Event Planning Journey</h3>
          <h6>At Panthal.com, we understand that planning an event is no small feat. That's why we've designed an
            innovative event booking system that takes the hassle out of the process, leaving you more time to focus on
            creating memorable experiences.</h6>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-lg-4 col-12 mb-5">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <img src="../../../assets/icons/verify-profile.png" class="img-fluid h-75">
            </div>
            <div class="col-12 mt-1 text-center">
              <h5 class="text-bold">100% Verified Vendors</h5>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-12 mb-5">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <img src="../../../assets/icons/location%20(1).png" class="img-fluid h-75">
            </div>
            <div class="col-12 mt-1 text-center">
              <h5 class="text-bold">100+ Venues in Major Cities</h5>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-12 mb-5">
          <div class="row mt-3">
            <div class="col-12 text-center">
              <img src="../../../assets/icons/call-center.png" class="img-fluid h-75">
            </div>
            <div class="col-12 mt-1 text-center">
              <h5 class="text-bold">Free assistance to find your venue</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="mt-5">
    <div class="container">
      <div class="row">
        <div *ngIf="showContent" data-aos="flip-left" data-aos-duration="1200">
          <div class="col-12 text-center">
            <h3 class="text-bold mt-3" style="color:#f71d83;"><span style="color:#0aa2e5">Why Choose</span> Panthal.com?
            </h3>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12">
          <div *ngIf="showContent" data-aos="zoom-in-right" data-aos-duration="1200">
            <div class="row">
              <div class="col-lg-4 col-xs-12 col-sm-6 col-md-4 text-center">
                <!--<img src="./assets/img/create-account-2.png" alt="image" class="img-fluid mt-3">-->
                <h4 class="text-bold mt-5" style="color:#5f5f5f;">Effortless Event Management</h4>
                <p class="text-muted mt-3">Gone are the days of complex spreadsheets and endless email chains. With
                  Panthal.com, managing your event is as simple as a few clicks. Our user-friendly platform streamlines
                  the entire process, from initial planning to the day-of coordination.</p>
              </div>
              <div class="col-lg-4 col-xs-12 col-sm-6 col-md-4 text-center">
                <!-- <img src="./assets/img/customize.png" alt="image" class="img-fluid mt-3">-->
                <h4 class="text-bold mt-5" style="color:#5f5f5f;">Seamless Booking Experience</h4>
                <p class="text-muted mt-3">For event attendees, registering has never been easier. Our intuitive booking
                  system ensures a seamless experience, allowing guests to secure their spot with ease. From ticket
                  selection to payment, Panthal.com provides a smooth and secure transaction process.</p>
              </div>
              <div class="col-lg-4 col-xs-12 col-sm-6 col-md-4 text-center">
                <!--<img src="./assets/img/share.png" alt="image" class="img-fluid">-->
                <h4 class="text-bold mt-5" style="color:#5f5f5f;">Customizable Solutions</h4>
                <p class="text-muted mt-3">We understand that every event is unique. Panthal.com offers a range of
                  customizable features, allowing you to tailor the system to meet the specific needs of your event.
                  Whether it's ticket types, seating arrangements, or promotional discounts, we've got you covered.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>


  <section class="mt-5" style="background-color: rgba(173,216,230,0.32)">
    <div class="container mt-3" data-aos="flip-right" data-aos-duration="1000">
      <div class="row text-center">
        <h3 class="text-bold mt-5" style="color:#f71d83;"><span style="color:#0aa2e5">Awesome</span> Features</h3>
        <p class="text-muted">Panthal.com Offering all Venue at One Place</p>
      </div>
    </div>
    <div class="container mt-3">
      <div data-aos="zoom-in-down" data-aos-duration="1000">
        <div class="row">
          <div class="col-lg-3 col-xs-12 col-md-3 col-sm-2">
            <div class="text-center">
              <!-- <img src="./assets/img/custom%20design.png" alt="image" class="img-fluid mt-3">-->
              <h5 class="mt-3 mb-3 text-bold">User-Friendly Interface</h5>
              <p class="text-muted p-3">Our clean and intuitive interface makes event planning a breeze for organizers
                and booking a joy for attendees.</p>
            </div>
          </div>
          <div class="col-lg-3 col-xs-12 col-md-3 col-sm-2">
            <div class="text-center">
              <!-- <img src="./assets/img/fully%20response.png" alt="image" class="img-fluid mt-3">-->
              <h5 class="mt-3 mb-3 text-bold">Real-Time Analytics</h5>
              <p class="text-muted p-3">Stay on top of your event's performance with real-time analytics. Track
                registrations, ticket sales, and attendee engagement effortlessly.</p>
            </div>
          </div>
          <div class="col-lg-3 col-xs-12 col-md-3 col-sm-2">
            <div class=" text-center">
              <!-- <img src="./assets/img/social%20share.png" alt="image" class="img-fluid mt-3">-->
              <h5 class="mt-3 mb-3 text-bold">Secure Payment Processing</h5>
              <p class="text-muted p-3">Rest easy knowing that our secure payment gateway ensures smooth and secure
                transactions for both organizers and attendees.</p>
            </div>
          </div>
          <div class="col-lg-3 col-xs-12 col-md-3 col-sm-2">
            <div class="text-center">
              <!--<img src="./assets/img/click%20to%20connect.png" alt="image" class="img-fluid mt-3">-->
              <h5 class="mt-3 mb-3 text-bold">Mobile Accessibility</h5>
              <p class="text-muted p-3"> Manage your event on the go with our mobile-responsive platform. Attendees can
                easily book tickets from their smartphones, enhancing accessibility.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="mt-5">
    <div class="container">
      <div class="row">
        <div *ngIf="showContent" data-aos="flip-left" data-aos-duration="1200">
          <div class="col-12 text-center">
            <h3 class="text-bold" style="color:#f71d83;"><span style="color:#0aa2e5">How It</span> Works</h3>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12">
          <div *ngIf="showContent" data-aos="zoom-in-right" data-aos-duration="1200">
            <div class="row">
              <div class="col-lg-4 col-xs-12 col-sm-6 col-md-4 text-center">
                <!--<img src="./assets/img/create-account-2.png" alt="image" class="img-fluid mt-3">-->
                <h4 class="text-bold mt-4" style="color:#5f5f5f;">Discover Event Place</h4>
                <p class="text-muted mt-3">Browse through a curated selection of events or search for specific ones that
                  catch your eye.</p>
              </div>
              <div class="col-lg-4 col-xs-12 col-sm-6 col-md-4 text-center">
                <!-- <img src="./assets/img/customize.png" alt="image" class="img-fluid mt-3">-->
                <h4 class="text-bold mt-4" style="color:#5f5f5f;">Book Your Event</h4>
                <p class="text-muted mt-3">Secure your spot with a quick and easy booking process. Choose your preferred
                  venue and any additional perks to enhance your experience.</p>
              </div>
              <div class="col-lg-4 col-xs-12 col-sm-6 col-md-4 text-center">
                <!--<img src="./assets/img/share.png" alt="image" class="img-fluid">-->
                <h4 class="text-bold mt-4" style="color:#5f5f5f;">Enjoy the Event</h4>
                <p class="text-muted mt-3">With your booking in hand, all that's left to do is immerse yourself in the
                  moment and create lasting memories.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="mt-5 m-0" style="background-color: rgba(173,216,230,0.32)">
    <div class="mt-3 mb-3 p-5" data-aos="flip-right" data-aos-duration="1000">
      <div class="row ml-3">
        <h3 class="text-bold mt-3" style="color:#f71d83;"><span style="color:#0aa2e5">Get Started with </span>
          Panthal.com Today!</h3>
        <p class="text-muted mt-3">Ready to simplify your event planning journey? Panthal.com and experience the
          convenience of our comprehensive event booking system. From small gatherings to large conferences, we're here
          to make your event a success.</p>
      </div>
    </div>
  </section>


  <section class="">
    <div class="container col-lg-10 mt-5">
      <div *ngIf="showContentThree" data-aos="flip-left"
           data-aos-easing="ease-out-cubic" data-aos-duration="3000">
        <div class="row mt-5">
          <h1 class="text-bold text-center mt-5" style="color:#f71d83;"><span style="color:#0aa2e5">Frequently</span>
            Asked Questions
          </h1>
        </div>
        <div class="row mt-5">
          <div class="col-lg-6 col-xs-12 col-sm-12 col-md-6">
            <div class="row mt-5">
              <div class="accordion" id="accordionExample">
                <div class="accordion-item mb-3">
                  <h2 class="accordion-header bg-white accordion-button collapsed" id="headingOne" type="button"
                      data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false"
                      aria-controls="collapseOne">
                    What types of venues can I book through your website?
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                       data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p class="text-muted">
                        Our website offers a diverse range of venues, including event halls, meeting rooms, conference
                        centers, outdoor spaces, and more. You can find venues suitable for various occasions such as
                        weddings, corporate events, parties, and conferences..
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item mb-3">
                  <h2 class="accordion-header bg-white accordion-button collapsed" id="headingTwo" type="button"
                      data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false"
                      aria-controls="collapseTwo">
                    How do I search for venues on your website?
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                       data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p class="text-muted">Use our search bar to enter specific criteria such as location, event type,
                        capacity, and date. You can also browse through different categories and filters to find the
                        perfect venue for your needs.</p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item mb-3">
                  <h2 class="accordion-header bg-white accordion-button collapsed" id="headingThree" type="button"
                      data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false"
                      aria-controls="collapseThree">
                    Is there a fee for using your venue booking service?
                  </h2>
                  <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                       data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p class="text-muted">
                        No, our service is free for customers. You can search for venues, compare prices, and contact
                        venue owners without any additional charges.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item mb-3">
                  <h2 class="accordion-header bg-white accordion-button collapsed" type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour"
                      id="headingFour">
                    Can I see the venues before making a booking?
                  </h2>
                  <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                       data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p class="text-muted">
                        Many venues on our platform provide photos, virtual tours, and detailed descriptions. Some
                        venues may also offer the option to schedule a visit. Contact the venue owner directly to
                        inquire about viewing arrangements.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item mb-3">
                  <h2 class="accordion-header bg-white accordion-button collapsed" type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix" id="headingSix">
                    How do I book a venue?
                  </h2>
                  <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix"
                       data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p class="text-muted">
                        Once you find a suitable venue, you can inquire about availability through the website. The
                        venue owner will respond to your request, and you can finalize the booking details directly with
                        them.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item mb-3">
                  <h2 class="accordion-header bg-white accordion-button collapsed" type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive"
                      id="headingFive">
                    What payment methods are accepted for venue bookings?
                  </h2>
                  <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                       data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p class="text-muted">
                        Payment methods vary by venue. Most venues accept credit/debit cards, bank transfers, or other
                        online payment options. Check with the venue owner for specific payment details.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item mb-3">
                  <h2 class="accordion-header bg-white accordion-button collapsed" type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven"
                      id="headingSeven">
                    Is there a cancellation policy for venue bookings?
                  </h2>
                  <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven"
                       data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p class="text-muted">Cancellation policies differ from venue to venue. Be sure to review the
                        terms and conditions provided by the venue owner before finalizing your booking.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="accordion-item mb-3">
                  <h2 class="accordion-header bg-white accordion-button collapsed" type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight"
                      id="headingEight">
                    Can I make changes to my booking after it's confirmed?
                  </h2>
                  <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight"
                       data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <p class="text-muted">Any changes to your booking, such as date or guest count adjustments, should
                        be discussed directly with the venue owner. They will provide guidance on the process and any
                        associated fees.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-12"></div>
          <div class="col-lg-4 col-xs-12 col-sm-12 col-md-4 mt-5">
            <div class="section mt-5" *ngIf="showContentThree" data-aos="fade-right" data-aos-duration="2000">
              <img src="../../../assets/img/faq.png" alt="image" class="img-fluid animated">
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<!-- Remove the container if you want to extend the Footer to full width. -->
<div class="container-fluid p-0">

  <footer class="text-center" style="background-color: rgba(199,233,250,0.73);">
    <!-- Grid container -->
    <!-- <div class="container">
       &lt;!&ndash; Section: Social media &ndash;&gt;
       <section class="mb-4">
         &lt;!&ndash; Facebook &ndash;&gt;
         <a
           class="btn btn-link btn-floating btn-lg text-dark m-1"
           href="#!"
           role="button"
           data-mdb-ripple-color="dark"
         ><i class="fab fa-facebook-f"></i
         ></a>
         <a
           class="btn btn-link btn-floating btn-lg text-dark m-1"
           href="#!"
           role="button"
           data-mdb-ripple-color="dark"
         ><i class="fab fa-linkedin"></i
         ></a>
       </section>
     </div>-->
    <div class="text-center text-bold text-dark p-3" style="background-color: rgba(181,215,234,0.2);">
      <span>Copyright &copy; {{ currentYear }}  </span>
      <a href="https://varnikcloud.com/"
         style="margin: 0; text-decoration: none"
         target="_blank"
      ><span class="text-dark">  VarNik Systems Services Pvt. Ltd</span></a>
      <span>. </span>
      <span>  All rights reserved.</span>
    </div>
    <div class="text-center text-bold" style="background-color: rgba(181,215,234,0.2);">
      <span class="text-black mr-2 text-end" type="button" [routerLink]="['/terms']">Terms & Conditions <span>|</span>
        <span class="text-black ml-1 text-end" type="button" [routerLink]="['/privacy']">Privacy Policy</span></span>
    </div>
  </footer>

</div>


<ngx-spinner>
  <div class="custom-loader"></div>
</ngx-spinner>
