<div class="container-fluid" class="sticky-top">
<app-event-header ></app-event-header>
  <nav class="navbar  navbar-light bg-light" style="height: 45px;">
        <div class="col-md-2">
      <a class="nav-link active"  routerLink="/eventplace-booking" data-toggle="tooltip"
      data-placement="top" title="Event Booking screen">Event Booking</a>
    </div>
    <div class="col-md-2">
      <a class="nav-link" routerLink="/event-bookinglist" data-toggle="tooltip"
      data-placement="top" title="Event Booking All Details List Screen">Event Booking Details</a>
      </div>
      <div class="col-md-8"></div>
 </nav>
</div>
 <div class="row mt-1"></div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-4">
        <div class="form-group mt-2">
          <label for="epid" class="form-label">Event Place<span style="color:red;">*</span></label>
          <select class="form-control" (change)="onShowevent($event)" >
            <option value="" disabled selected>Select Event place</option>
            <option *ngFor="let parms of eventDropdown" value="{{parms}}">{{parms}}</option>
          </select>
        </div>
      </div>
      <div class="col-4">
        <div class="form-group mt-2">
          <label for="epsubid" class="form-label">Event Sub Place<span style="color:red;">*</span></label>
          <select class="form-control"  (change)="getEventPlace($event)" [disabled]="isSelectDisabled"
          >
            <option value="" selected>Select Event Sub Place</option>
            <option *ngFor="let parms of eventSubDropDown" value="{{parms}}">{{parms}}</option>
          </select>

        </div>
      </div>     
      <div class="col-3 mt-4 p-2 ml-5">
        <div class="input-group mt-2" >
          <input type="" id="myInput" class="form-control rounded" placeholder="Search"  [(ngModel)]="searchText">
          <span class="input-group-text border-0" id="search-addon">
            <i class="fas fa-search"></i>
          </span>
        </div>
      </div>
   </div>
   <div class="row mt-3"></div>
   <div class="card table-container">
    <table class="table table-hover mb-0">
      <thead class="sticky-top">
        <tr class="">
          <th scope="col">Booking Id</th>
          <th scope="col">Booking Type</th>
          <th scope="col">Customer Name</th>
          <th scope="col">Customer Address</th>
          <th scope="col">Customer Phone No</th>
          <th scope="col">Type of Function</th>
          <th scope="col">Start Date</th>
          <th scope="col">End Date</th>
          <th scope="col">For Update</th>
          <th scope="col">For Cencel</th>
        </tr>
      </thead>
      <tbody class="table-bordered">
        <tr *ngFor="let list of filteredEventPlaceDetails | filter: searchText;let i = index;" class="text-center">
          <td>{{list.eventid}}</td>
          <td>{{list.eventbookingtype}}</td>
          <td>{{list.contactperson}}</td>
          <td>{{list.address}}</td>
          <td>{{list.phonenumber2}}</td>
          <td>{{list.typeoffunction}}</td>
          <td>{{list.startdate | date: 'dd-MM-yyyy'}}/{{list.starthalf1 ? 'FH' : 'SH'}}</td>
          <td>{{list.enddate | date: 'dd-MM-yyyy'}} /{{list.endhalf1 ? 'FH':'SH'}}</td>
          <td><div class="text-center">
           <button type="button" class="btn btn-primary" (click)="getEvent(list.eventid)">Click Here</button>
         </div></td>
         <td><div class="text-center">
          <button type="button" class="btn btn-danger" (click)="getEventCancel(list.eventid)">Cencel</button>
        </div></td>
        </tr>
      </tbody>
    </table>
   </div>

</div>
<div class="row mt-1"></div>

<ngx-spinner>
  <div class="custom-loader"></div>
</ngx-spinner>


   <app-event-footer></app-event-footer>



