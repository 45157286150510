import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { AppService } from "@services/app.service";
import { Router } from "@angular/router";
import { EventSubPlace } from '@modules/event-place-management/model/eventsub-place';
import { ToastrService } from 'ngx-toastr';
import { pipe, Subject, takeUntil } from "rxjs";
import { EventPlace } from "../model/eventplace";
import Swal from "sweetalert2";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-sub-place',
  templateUrl: './sub-place.component.html',
  styleUrls: ['./sub-place.component.scss']
})


export class SubPlaceComponent implements OnInit {
  private readonly FIXED_SCROLL_POSITION = 0;
  destroy$: Subject<boolean> = new Subject<boolean>();
  eventPlace: EventPlace[] = [];
  eventSubplaceDetails: EventSubPlace[] = [];
  eventSubplaceAuto: EventSubPlace[] = [];
  eventsubplace: EventSubPlace | undefined;
  eventDropdown: string[] = [];
  eventSubDropDown: string[] = [];
  eventId: string | undefined;
  eventSubId: string | undefined;
  isDisabled: boolean = false;
  isUpdate = false;
  isdisabledraft = false;
  isdisablesave = false;
  isdisablenext = false;
  isSquare = true;
  isRectangle = false;
  isCircle = false;
  images: string = "";
  fileList: File[] = [];
  listOfFiles: any[] = [];
  fileUploadStatus: string = "Exist";
  formData = new FormData();


  constructor(private router: Router, private appService: AppService, private toastr: ToastrService,
    private spinner: NgxSpinnerService) {
  }

  eventSubPlace = new UntypedFormGroup({
    epid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epsubid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epsubname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epsubnickname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epsubdesc: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epsubaircond: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    emailid: new UntypedFormControl('', [Validators.required, Validators.nullValidator, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
    phonenumber: new UntypedFormControl('', [Validators.required, Validators.nullValidator, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
    activestatus: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    activeyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    deleteyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    createdby: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epmainplace: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    subplacerequired: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    logotype: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epsubimage: new UntypedFormControl('', [Validators.required, Validators.nullValidator])
  })

  public epsubplaceErrors = {
    epid: '',
    epsubid: '',
    epsubname: '',
    epsubnickname: '',
    epsubdesc: '',
    epsubaircond: '',
    emailid: '',
    phonenumber: '',
    activestatus: '',
    epsubimage:'',
  }

  // Only allow numeric values

  OnlyNumbersAllowed(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    //only numbers 0-9
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      console.log('charCode restricted is' + charCode);
      this.epsubplaceErrors.phonenumber = "Please enter numbers only.";
      return false;
    } else {
      this.epsubplaceErrors.phonenumber = "";
    }
    return true;
  }

  ngOnInit() {
    this.getEventPlaceByUserId();
    this.eventSubPlace.controls['epid'].disable();
    Object.keys(this.eventSubPlace.controls).forEach((controlName) => {
      if (controlName !== 'subplacerequired') {
        this.eventSubPlace.get(controlName).disable();
      }
    })
    // this.isdisablenext = true;
  }

  ngOnDestroy() {
    document.body.style.overflow = 'auto';
  }


  onSubmit() {
    this.formValidation("Active");
  }

  // form validation funtion call

  formValidation(status: string) {
    this.epsubplaceErrors.epid = "";
    this.epsubplaceErrors.epsubname = "";
    this.epsubplaceErrors.epsubnickname = "";
    this.epsubplaceErrors.epsubaircond = "";
    this.epsubplaceErrors.emailid = "";
    this.epsubplaceErrors.phonenumber = "";
    this.epsubplaceErrors.epsubimage= "";

    let hasError = false;

    let inputValue = this.eventSubPlace.controls['epsubname'].value;
    let outputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
    this.eventSubPlace.controls['epsubname'].setValue(outputValue)

    let inputValue1 = this.eventSubPlace.controls['epsubnickname'].value;
    let outputValue1 = inputValue1.charAt(0).toUpperCase() + inputValue1.slice(1);
    this.eventSubPlace.controls['epsubnickname'].setValue(outputValue1)

    let inputValue2 = this.eventSubPlace.controls['epsubdesc'].value;
    let outputValue2 = inputValue2.charAt(0).toUpperCase() + inputValue2.slice(1);
    this.eventSubPlace.controls['epsubdesc'].setValue(outputValue2)

    if (this.eventSubPlace.get('epid')?.invalid) {
      this.epsubplaceErrors.epid = "*Event place is required";
      hasError = true;
    }


    if (this.eventSubPlace.get('epsubname')?.invalid) {
      this.epsubplaceErrors.epsubname = "*Sub Place Name is required";
      hasError = true;
    }

    if (this.eventSubPlace.get('epsubnickname')?.invalid) {
      this.epsubplaceErrors.epsubnickname = "*Sub Place Nick Name is required";
      hasError = true;
    }
    if (!this.isUpdate) {
      if (this.eventSubPlace.get('epsubimage')?.invalid) {
        this.epsubplaceErrors.epsubimage = "*Logo is required";
        hasError = true;
      }
    }

    if (this.eventSubPlace.get('phonenumber')?.invalid) {
      if (this.eventSubPlace.get('phonenumber').errors.required) {
        this.epsubplaceErrors.phonenumber = "*Phone Number is required";
      }
      if (this.eventSubPlace.get('phonenumber').errors.pattern) {
        this.epsubplaceErrors.phonenumber = "*Please, Enter 10 digit Phone number.";
      }
      hasError = true;
    }

    if (this.eventSubPlace.get('emailid')?.invalid) {
      if (this.eventSubPlace.get('emailid').errors.required) {
        this.epsubplaceErrors.emailid = "*Email Address is required";
      }
      if (this.eventSubPlace.get('emailid').errors.pattern) {
        this.epsubplaceErrors.emailid = "*Please, Enter a Valid Email Address.";
      }
      hasError = true;
    }

    if (this.eventSubPlace.get('epsubaircond')?.value == "") {
      this.epsubplaceErrors.epsubaircond = "*Air Conditioned is required";
      hasError = true;
    }
    if (!hasError) {
      this.saveSubPlace(status);
    }

  }

  // Event place dropdown API function

  getEventPlaceByUserId() {
    this.appService
      .getEventPlaceByuserId(localStorage.getItem('token'))
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("Event sub place Dropdown::", data);
        // @ts-ignore
        this.eventPlace = data;
        var localEventPlace: string[] = [];
        for (var ep of this.eventPlace) {
          localEventPlace.push(ep.epname);
        }
        this.eventDropdown = localEventPlace;
      })
  }

  //Sub place dropdown API function

  getEventSubPlace() {
    this.appService
      //@ts-ignore
      .getEventSubPlace(this.eventId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("sub dropdown::", data);
        // @ts-ignore
        this.eventSubplaceDetails = data;
        if (this.eventSubplaceDetails.length > 0) {
          this.eventSubPlace.controls['epsubid'].enable();
        } else {
          this.eventSubPlace.controls['epsubid'].disable();
        }
        var localEventSub: string[] = [];
        for (var ep of this.eventSubplaceDetails) {
          if (ep.epmainplace == "Yes") {
            localEventSub.push(ep.epsubname + " - Main Hall")
            console.log("main hall", this.eventSubDropDown)
          } else {
            localEventSub.push(ep.epsubname)
          }

          if (ep.epmainplace == "Yes") {
            this.eventSubPlace.controls["epmainplace"].disable();
          }
          this.eventSubDropDown = localEventSub;
        }
      })
  }

  // subplace Details dropdown get API Function

  getSubPlaceDetails() {
    this.spinner.show()
    this.appService
      .getEventSubPlaceDropDown(this.eventId, this.eventSubId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("Get Sub Place Details::", data);
        this.spinner.hide()
        // @ts-ignore
        this.eventSubplaceAuto = data;
        this.isUpdate = true;
        for (var subplace of this.eventSubplaceAuto) {
          this.eventSubPlace.controls["epsubname"].setValue(subplace.epsubname);
          this.eventSubPlace.controls["epsubnickname"].setValue(subplace.epsubnickname);
          this.eventSubPlace.controls["phonenumber"].setValue(subplace.phonenumber);
          this.eventSubPlace.controls["emailid"].setValue(subplace.emailid);
          this.eventSubPlace.controls["epsubdesc"].setValue(subplace.epsubdesc);
          this.eventSubPlace.controls["activestatus"].setValue(subplace.activestatus);
          this.eventSubPlace.controls["epsubaircond"].setValue(subplace.epsubaircond);
          this.eventSubPlace.controls["epmainplace"].setValue(subplace.epmainplace);
          this.eventSubPlace.controls["logotype"].setValue(subplace.logotype);
          this.onShapeChanged(subplace.logotype)
        }
      }, (error: any) => {
        this.spinner.hide()
        console.log("Something went wrong");
      })
  }

  //Save & update function in subplace-Event

  saveSubPlace(status: string) {
    this.spinner.show()
    console.log("is update::", this.isUpdate)
    for (const file of this.fileList) {
      this.formData.append('file', file, file.name);
    }
    if (this.isUpdate) {
      this.eventSubPlace.controls["activestatus"].setValue(status);
      this.eventSubPlace.controls["createdby"].setValue(localStorage.getItem("token"));
      this.eventSubPlace.controls["epid"].setValue(this.eventId);
      this.eventSubPlace.controls["epsubid"].setValue(this.eventSubId);
      this.eventSubPlace.controls["activeyn"].setValue("Yes");
      this.eventSubPlace.controls["deleteyn"].setValue("No");
      if (this.eventSubPlace.controls['epmainplace'].value == "") {
        this.eventSubPlace.controls['epmainplace'].setValue("No")
      }
      console.log("esubplace details::", this.eventSubPlace.value);
      this.appService.updateEventSubPlace(this.eventSubPlace.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          console.log("update eventPlace ::", data);
          this.spinner.hide()
          // @ts-ignore
          if (data.activestatus == "Draft") {
            this.draftAlert();
          } else {
            this.UpdateAlert();
          }
          console.log("file list Value::", this.fileList.length)
          if (this.fileList.length > 0) {
            this.appService.logoSubPlaceFileUpload(this.formData, this.eventId, this.eventSubId)
              .pipe(takeUntil(this.destroy$))
              .subscribe(data => {
                console.log("File Upload Successfully")
              }, (error: any) => {
                console.log("Something went wrong. File not upload")
              })
          }
        }, (err: any) => {
          this.errorAlert();
          this.spinner.hide()
        },
          () => console.log('HTTP request completed.'));
    } else if (!this.isUpdate) {
      this.eventSubPlace.controls["activestatus"].setValue(status);
      this.eventSubPlace.controls["createdby"].setValue(localStorage.getItem("token"));
      this.eventSubPlace.controls["epid"].setValue(this.eventId);
      this.eventSubPlace.controls["activeyn"].setValue("Yes");
      this.eventSubPlace.controls["deleteyn"].setValue("No");
      if (this.eventSubPlace.controls['epmainplace'].value == "") {
        this.eventSubPlace.controls['epmainplace'].setValue("No")
      }
      this.appService.saveSubPlace(this.eventSubPlace.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          console.log("eventSubPlace details::", data);
          this.spinner.hide()
          // @ts-ignore
          if (data.activestatus == "Draft") {
            this.draftAlert();
          } else {
            this.successAlert();
          }
          this.appService.logoFileUpload(this.formData,this.eventSubId)
          .pipe(takeUntil(this.destroy$))
          .subscribe(data => {
            console.log("File Upload Successfully")
          }, (error: any) => {
            console.log("Something went wrong. File not upload")
          })
        // @ts-ignore
        }, (err: any) => {
          this.errorAlert();
          this.spinner.hide()
        },
          () => console.log('HTTP request completed.'));
      // this.isdisablenext =true;
    }

  }


  subplacedisable(event) {
    const value = event.target.value;
    console.log("'Subplace'::", value);
    if (value == "no") {
      this.eventSubPlace.disable();
      this.eventSubPlace.controls['subplacerequired'].enable();
      this.isdisablesave = false;
      this.isdisabledraft = false;
      this.isdisablenext = true;
    } else {
      if (value == "yes") {
        this.eventSubPlace.enable();
        this.isdisablesave = true;
        this.isdisabledraft = true;
        this.isdisablenext = false;
        this.disable();
      }
    }
  }

  draftButton(status: string) {
    let hasError = false;

    if (this.eventSubPlace.get('epid')?.invalid) {
      this.epsubplaceErrors.epid
        = "*Event place is required";
      hasError = true;
    }

    // if (this.eventSubPlace.get('epsubid')?.invalid) {
    //   this.epsubplaceErrors.epsubid
    //     = "*Event Sub place is required";
    //   hasError = true;
    // }
    let inputValue = this.eventSubPlace.controls['epsubname'].value;
    let outputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
    this.eventSubPlace.controls['epsubname'].setValue(outputValue)
    if (this.eventSubPlace.get('epsubname')?.invalid) {
      this.epsubplaceErrors.epsubname = "*Sub Place Name is required";
      hasError = true;
    }
    if (!hasError) {
      this.saveSubPlace(status)
    }
  }

  //navigation funtion

  nextButton() {
    this.router.navigate(['/main/rooms'])
  }

  //SubPlace Dropdown Autofetch Function

  onShowevent(event) {
    const value = event.target.value;
    console.log("Event Name::", value)
    for (var EPid of this.eventPlace) {
      if (value == EPid.epname) {
        this.eventId = EPid.epid;
        console.log("ep id::", this.eventId)
        this.isDisabled = true;
        this.getEventSubPlace();
        this.disable();
      } else {
        this.isDisabled = false;
        console.log("ep id:: error")
      }
    }
  }

  disable() {
    if (this.isDisabled) {
      this.eventSubPlace.controls['epsubid'].enable();
    } else {
      this.eventSubPlace.controls['epsubid'].disable();
    }
  }

  //SubPlace Details Dropdown Autofetch Function

  onShowSubPlaceDetails(event) {
    const value = event.target.value.replace(" - Main Hall", "");
    console.log("Packing type::", value);
    for (var EPid of this.eventSubplaceDetails) {
      if (value == EPid.epsubname) {
        this.eventSubId = EPid.epsubid;
        console.log("ep id::", this.eventSubDropDown)
        this.getSubPlaceDetails();
        this. getEventSubPlaceLogo();
      }
      var localSubValue: EventSubPlace[] = [];
      if (value == EPid.epsubname) {
        localSubValue.push(EPid);
        for (let local of localSubValue) {
          if (local.epmainplace == 'Yes') {
            this.eventSubPlace.controls["epmainplace"].enable();
          } else {
            this.eventSubPlace.controls["epmainplace"].disable();
          }
        }
      }
    }
  }

  onShapeChanged(shape: string) {
    if (shape == "square") {
      this.isSquare = true;
      this.isCircle = false;
      this.isRectangle = false;
    } else if (shape == "rectangle") {
      this.isSquare = false;
      this.isCircle = false;
      this.isRectangle = true;
    } else if (shape == "circle") {
      this.isSquare = false;
      this.isCircle = true;
      this.isRectangle = false;
    }
  }

  onFileChange(event: any) {
    this.fileList = [];
    for (var i = 0; i <= event.target.files.length - 1; i++) {
      var selectedFile = event.target.files[i];
      console.log("select file size ::" + selectedFile);
      var fileSizeInMb = Math.round((selectedFile.size * 100) / (1024 * 1024)) / 100;
      console.log("select file convert size ::" + fileSizeInMb);
      if (fileSizeInMb <= 10) {
        if (event.target.files && event.target.files[0]) {
          var reader = new FileReader();
          reader.onload = (event: any) => {
            this.images = event.target.result;
            this.eventSubPlace.patchValue({});
          };
          reader.readAsDataURL(event.target.files[0]);
        }
        this.fileList.push(selectedFile);
        this.listOfFiles.push(selectedFile.name);
        this.fileUploadStatus = "Uploaded";
        console.log("File list::", this.fileList)
      } else {
        this.toastr.error("File size is large. Please upload below 10MP")
      }
    }
  }

 getEventSubPlaceLogo() {
  this.appService.getEventSubPlaceLogo(this.eventId,this.eventSubId)
    .pipe(takeUntil(this.destroy$))
    .subscribe((data: Blob) => {
      const imageBlob: Blob = data;
      const imageUrl = URL.createObjectURL(imageBlob);
      this.displayImage(imageUrl);
      console.log("Logo Get", imageBlob);
    }, (error: any) => {
      console.log("Something went wrong", error);
    });
}


  displayImage(imageUrl: string) {
    const imgElement = document.createElement('img');
    imgElement.src = imageUrl;
    console.log("imagesss::", imgElement)
    // this.images = imageUrl;
    const imagePlace = document.getElementById("imagesUrl")
    const existingImg = imagePlace.querySelector('img');
    if (existingImg) {
      imagePlace.removeChild(existingImg);
    }
    // Add the new img element
    imagePlace.appendChild(imgElement);
    }
  //Subplace successAlert popup message

  successAlert() {
    Swal.fire({
      title: "Your Registration Sucessfully Completed, Do you want to register another Sub Place?",
      icon: "success",
      showDenyButton: true,
      confirmButtonColor: "#4fab69",
      denyButtonColor: "#c73636",
      confirmButtonText: "Yes",
      denyButtonText: "No",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.eventSubPlace.controls["epid"].setValue("");
        this.eventSubPlace.controls["epsubid"].setValue("");
        this.eventSubPlace.controls["epsubname"].setValue("");
        this.eventSubPlace.controls["epsubnickname"].setValue("");
        this.eventSubPlace.controls["phonenumber"].setValue("");
        this.eventSubPlace.controls["emailid"].setValue("");
        this.eventSubPlace.controls["epsubdesc"].setValue("");
        this.eventSubPlace.controls["activestatus"].setValue("");
        this.eventSubPlace.controls["epsubaircond"].setValue("");
        this.eventSubPlace.controls["epmainplace"].setValue("");
      } else {
        this.router.navigate(['/main/rooms'])
      }
    });
  }
  draftAlert() {
    Swal.fire({
      title: "Your Registration successfully saved as draft, Do you want to register another Sub Place?",
      icon: "success",
      showDenyButton: true,
      confirmButtonColor: "#4fab69",
      denyButtonColor: "#c73636",
      confirmButtonText: "Yes",
      denyButtonText: "No",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.eventSubPlace.controls["epid"].setValue("");
        this.eventSubPlace.controls["epsubid"].setValue("");
        this.eventSubPlace.controls["epsubname"].setValue("");
        this.eventSubPlace.controls["epsubnickname"].setValue("");
        this.eventSubPlace.controls["phonenumber"].setValue("");
        this.eventSubPlace.controls["emailid"].setValue("");
        this.eventSubPlace.controls["epsubdesc"].setValue("");
        this.eventSubPlace.controls["activestatus"].setValue("");
        this.eventSubPlace.controls["epsubaircond"].setValue("");
        this.eventSubPlace.controls["epmainplace"].setValue("");
      } else {
        this.router.navigate(['/main/rooms'])
      }
    });
  }

  // Subplace successAlert popup message

  UpdateAlert() {
    Swal.fire({
      title: "Your Registration Updated Sucessfully.",
      icon: "success",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.reload();
      }
    });
  }
  //Sub-place errorAlert popup message
  errorAlert() {
    Swal.fire({
      title: "Sorry, Please Try Again Your Registration Process",
      icon: "error",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    });
  }

}









