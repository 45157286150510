export class ActivityClass {
  forEach(arg0: (value: any, index: any) => void) {
    throw new Error('Method not implemented.');
  }
  splice(index: any, arg1: number) {
    throw new Error('Method not implemented.');
  }
  reqid: string;
  activity:  number;
  actid: string;
  activityrequired: string;
  selectvendor: string;
  selectactivity: string;
  qtyrequired : string;
  activeyn: string;
  deleteyn: string;
  createdtime: string;
  modifiedtime: string;
  createdby: string;
  modifiedby: string;
  epid: string;
  epsubid: string;
  eventid: string;
  selectedvendorValue : string;
  selecteactivityValue : string;
}
