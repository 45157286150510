import {Component, OnInit} from "@angular/core";
import { Subject } from "rxjs/internal/Subject";
import { EventPlace } from "../model/eventplace";
import { EventSubPlace } from "../model/eventsub-place";
import { AppService } from "@services/app.service";
import { Router } from "@angular/router";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { takeUntil } from "rxjs";
import { EPActivity } from "../model/ep-activity";
import Swal from "sweetalert2";
import { ActivitiesMapping } from "../model/activities-mapping";

@Component({
  selector: 'app-activities-mapping',
  templateUrl: './activities-mapping.component.html',
  styleUrls: ['./activities-mapping.component.scss']
})

export class ActivitiesMappingComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  eventPlaceDetails : EventPlace [] = [];
  eventPlaceShow : EventPlace [] = [];
  eventSubplaceDetails: EventSubPlace [] = [];
  eventSubplaceShow: EventSubPlace [] = [];
  eventsubplace: EventSubPlace | undefined;
  activityDetails: EPActivity[] = [];
  activityDetailsShow: EPActivity[] = [];
  getActivitiesMappingId : ActivitiesMapping [] = [];
  eventactivityDropDown:string[]=[];
    eventDropdown: string [] = [];
    eventSubDropDown: string[] = [];
    eventId : string | undefined;
    eventSubId : string | undefined;
    epactivityDropDown: string | undefined;
    isDisabled : boolean = false;

    constructor (private appService : AppService,private router : Router){

    }
    activitiesMapping = new UntypedFormGroup ({
      epid : new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
      epname : new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
      epnickname : new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
      epimage : new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
      phonenumber: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
      emailid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
      epdescription: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
      epaddress1: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
      epaddress2: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
      epnearbylandmark: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
      epcity: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
      epstate: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
      eppincode: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
      epcountry: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
      activestatus: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
      epaircond: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
      epsubid:new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
      epsubname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
      epsubnickname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
      epsubdesc:new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
      epsubemailid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
      epsubaircond: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
      subactivestatus: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
      epactivityid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
      epactivityname : new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
      epactivitiescharges : new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
      activitiesactivestatus : new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
      epactivity3rdparty : new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
      epactivitychargable : new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
      epactivityshow : new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
      epactivitydesc : new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
      activeyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
      deleteyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
      createdby: new UntypedFormControl('', [Validators.required, Validators.nullValidator])
    })

    public EPActivitiesMappinErrors ={
      epid : '',
      epsubid: '',
      epactivityid: '',

    }

      ngOnInit() {
        this. getEventPlaceByuserId();
      }


      onSubmit(){
        this.formValidation();
      }


      formValidation(){
        this.EPActivitiesMappinErrors.epid = "";
        this.EPActivitiesMappinErrors.epsubid = "";
        this.EPActivitiesMappinErrors.epactivityid = "";
        let hasError = false;

        if (this.activitiesMapping.get('epid')?.invalid) {
             this.EPActivitiesMappinErrors.epid = "*EPId is required";
             hasError = true;
        }
        if (this.activitiesMapping.get('epsubid')?.invalid) {
          this.EPActivitiesMappinErrors.epsubid = "*EPSubId is required";
          hasError = true;
     }
       if (this.activitiesMapping.get('epactivityid')?.invalid) {
          this.EPActivitiesMappinErrors.epactivityid = "*EPActiviesName is required";
         hasError = true;
    }
    if (!hasError) {
      this.saveactivitiesMapping();
    }
      }

      getEventPlaceByuserId() {
        this.appService
          .getEventPlaceByuserId(localStorage.getItem('token'))
          .pipe(takeUntil(this.destroy$))
          .subscribe((data) => {
            console.log("Event place Dropdwon::", data);
            // @ts-ignore
            this.eventPlaceDetails = data;
            var localEventPlace : string [] = [];
            for (var ep of this.eventPlaceDetails) {
              localEventPlace.push(ep.epname)
            }
            this.eventDropdown = localEventPlace
          })
      }
      getEventPlaceDetails() {
        console.log("getid::",this.eventId);
        this.appService
          .getEventPlaceDetails(this.eventId)
          .pipe(takeUntil(this.destroy$))
          .subscribe((data) => {
            console.log("GetKitchen Details::", data);
            // @ts-ignore
            this.eventPlaceShow = data;
            for(var event of this.eventPlaceShow) {
              this.activitiesMapping.controls["epname"].setValue(event.epname);
              this.activitiesMapping.controls["epnickname"].setValue(event.epnickname);
              this.activitiesMapping.controls["epimage"].setValue(event.epimage);
              this.activitiesMapping.controls["phonenumber"].setValue(event.phonenumber);
              this.activitiesMapping.controls["emailid"].setValue(event.emailid);
              this.activitiesMapping.controls["epdescription"].setValue(event.epdescription);
              this.activitiesMapping.controls["epaddress1"].setValue(event.epaddress1);
              this.activitiesMapping.controls["epaddress2"].setValue(event.epaddress2);
              this.activitiesMapping.controls["epnearbylandmark"].setValue(event.epnearbylandmark);
              this.activitiesMapping.controls["epcity"].setValue(event.epcity);
              this.activitiesMapping.controls["epstate"].setValue(event.epstate);
              this.activitiesMapping.controls["eppincode"].setValue(event.eppincode);
              this.activitiesMapping.controls["epcountry"].setValue(event.epcountry);
              this.activitiesMapping.controls["activestatus"].setValue(event.activestatus);
              this.activitiesMapping.controls["epaircond"].setValue(event.epaircond);
            }
          })
      }
      getEventSubPlace() {
        this.appService
        //@ts-ignore
          .getEventSubPlace(this.eventId)
          .pipe(takeUntil(this.destroy$))
          .subscribe((data) => {
            console.log("sub dropdown::", data);
            // @ts-ignore
            this.eventSubplaceDetails = data;
            var localEventSubPlace : string [] = [];
            for (var ep of this.eventSubplaceDetails) {
              if (ep.epmainplace == "Yes") {
                localEventSubPlace.push(ep.epsubname + " - Main Hall")
                console.log("main hall", this.eventSubDropDown)
              } else {
               localEventSubPlace.push(ep.epsubname)
              }
              this.eventSubDropDown =  localEventSubPlace;
            }
          })
      }

      getSubPlaceDetails() {
        this.appService
          .getEventSubPlaceDropDown(this.eventId, this.eventSubId)
          .pipe(takeUntil(this.destroy$))
          .subscribe((data) => {
            console.log("Get Sub Place Details::", data);
            // @ts-ignore
            this.eventSubplaceShow = data;
            // this.isUpdate = true;
            for(var subplace of this.eventSubplaceShow) {
              this.activitiesMapping.controls["epsubname"].setValue(subplace.epsubname);
              this.activitiesMapping.controls["epsubnickname"].setValue(subplace.epsubnickname);
              this.activitiesMapping.controls["phonenumber"].setValue(subplace.phonenumber);
              this.activitiesMapping.controls["epsubemailid"].setValue(subplace.emailid);
              this.activitiesMapping.controls["epsubdesc"].setValue(subplace.epsubdesc);
              this.activitiesMapping.controls["subactivestatus"].setValue(subplace.activestatus);
              this.activitiesMapping.controls["epsubaircond"].setValue(subplace.epsubaircond);
              this.activitiesMapping.controls["epmainplace"].setValue(subplace.epmainplace);
            }
          })

      }
      getActivityDropDown() {
        this.appService
          .getActivityDropDown(this.eventId,this.eventSubId)
          .pipe(takeUntil(this.destroy$))
          .subscribe((data) => {
          console.log("Test data::", data);
          // @ts-ignore
          this.activityDetails = data;
          var localActivies: string[] = [];
          for (var ep of this.activityDetails) {
            localActivies.push(ep.epactivityname)
          }
          this.eventactivityDropDown = localActivies;
        })
        }
        getActivityDetails() {
        this.appService
          .getActivityDetails(this.eventId, this.eventSubId, this.epactivityDropDown)
          .pipe(takeUntil(this.destroy$))
          .subscribe((data) => {
          console.log("GetActivity Details::", data);
          // @ts-ignore
          this.activityDetailsShow = data;
          for(var activity of this.activityDetailsShow) {
            this.activitiesMapping.controls["epactivityname"].setValue(activity.epactivityname);
            this.activitiesMapping.controls["epactivitiescharges"].setValue(activity.epactivitiescharges);
            this.activitiesMapping.controls["activitiesactivestatus"].setValue(activity.activitiesactivestatus);
            this.activitiesMapping.controls["epactivity3rdparty"].setValue(activity.epactivity3rdparty);
            this.activitiesMapping.controls["epactivitychargable"].setValue(activity.epactivitychargable);
            this.activitiesMapping.controls["epactivityshow"].setValue(activity.epactivityshow);
            this.activitiesMapping.controls["epactivitydesc"].setValue(activity.epactivitydesc);
          }
          })
        }

        getactivitiesMapping() {
          this.appService
            .getActivitiesMapping(this.eventId, this.eventSubId,this.epactivityDropDown)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
              console.log("EP Userid Dropdwon::", data);
              // @ts-ignore
              this.getActivitiesMappingId = data;
            //   for (var ep of this.getactivitiesMappingId) {
            // }
            })
        }

  saveactivitiesMapping(){

        this.activitiesMapping.controls["createdby"].setValue(localStorage.getItem("token"));
        this.activitiesMapping.controls["epid"].setValue(this.eventId);
        this.activitiesMapping.controls["epsubid"].setValue(this.eventSubId);
        this.activitiesMapping.controls["epactivityid"].setValue(this.epactivityDropDown);
        this.activitiesMapping.controls["activeyn"].setValue("Yes");
        this.activitiesMapping.controls["deleteyn"].setValue("No");
        this.appService.saveActivitiesMapping(this.activitiesMapping.value)
                              .pipe(takeUntil(this.destroy$))
                              .subscribe((data) => {
                                      console.log("saveRooms ::", data);
                                      // @ts-ignore
                                      // this.protected = data;
                                  }, (err: any) => {
                                  },
                                  () => console.log('HTTP request completed.'));
                                  // this.toastr.success('Details are saved success');
                                  this.successAlert();
                                }

      onShowevent(event) {
        const value = event.target.value;
        console.log("Event Name::", value)
        for (var EPid of this.eventPlaceDetails) {
          if (value == EPid.epname) {
            this.eventId = EPid.epid;
            console.log("ep id::", this.eventId)
            this.isDisabled = true;
            this.getEventSubPlace();
            this. getEventPlaceDetails();
            this.disable();
          } else {
            this.isDisabled = false;
            console.log("ep id:: error")
          }
        }
      }

      disable() {
        if (this.isDisabled) {
            this.activitiesMapping.controls['epsubid'].enable();
        } else {
            this.activitiesMapping.controls['epsubid'].disable();
        }
      }
      onShowSubPlaceDetails(event) {
        const value = event.target.value.replace(" - Main Hall","");
        console.log("Packing type::", value);
        for (var EPid of this.eventSubplaceDetails) {
          if (value == EPid.epsubname) {
            this.eventSubId = EPid.epsubid;
            console.log("ep id::", this.eventSubDropDown)
            this.isDisabled = true;
            this.getSubPlaceDetails();
            this.getActivityDropDown()
            this.disable1();
          }
          else {
            this.isDisabled = false;
            console.log("ep id:: error")
          }
}
      }

      disable1() {
        if (this.isDisabled) {
            this.activitiesMapping.controls['epactivityid'].enable();
        } else {
            this.activitiesMapping.controls['epactivityid'].disable();
        }
      }
      onActivitydetails(event) {
        const value = event.target.value;
        console.log("Packing type::", value)
        for (var EPid of this.activityDetails) {
          if (value == EPid.epactivityname) {
          this.epactivityDropDown = EPid.epactivityid;
          console.log("ep id::", this.epactivityDropDown)
          this.getActivityDetails();
          this. getactivitiesMapping();
          }
        }
        }

        successAlert() {
          Swal.fire({
            title: "Your Registration Sucessfully Completed.",
            icon: "success",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
            showClass: {
              popup: "animate_animated animate_fadeInDown",
            },
            hideClass: {
              popup: "animate_animated animate_fadeOutUp",
            },
          }).then((result) =>{
            if(result.isConfirmed) {
              window.location.reload();
            }
          });
        }
}
