<div class="container-fluid">
	<form (ngSubmit)="onSubmit()" [formGroup]="epactivity" id="epactivity">
		<div class="col-md-2 mt-1"></div>
		<div class="col-md-11">
			<h4 class="">Activites</h4>
		</div>
		<div class="alert alert-light" role="alert">
			Note:<span style="color:red;">*</span> Screen to enter all the required details about your Activites.
		</div>
		<div class="form-group row">
			<label class="col-3  col-form-label"><strong>Activites Details Required</strong></label>
			<div class="form-check mt-1">
				<input class="form-check-input" type="radio" formControlName="Activitesrequired" value="yes"
					(change)="Activitesdisable($event)">
				<label class="form-check-label"><b>Yes</b></label>
			</div>
			<div class="form-check mt-1">
				<input class="form-check-input ml-1" type="radio" formControlName="Activitesrequired" value="no"
					(change)="Activitesdisable($event)">
				<label class="form-check-label ml-4"><b>No</b></label>
			</div>
		</div>
		<div class="row">
			<div class="col-md-4">
				<div class="col-md-1"></div>
				<div class="col-md-11">
					<div class="form-group">
						<label for="epid" class="form-label">Event Place<span style="color:red;">*</span></label>
						<select class="form-control" id="epid" formControlName="epid" (change)="onShoweventsub($event)">
							<option *ngFor="let parms of eventDropdown" value="{{parms}}">{{parms}}</option>
							<option value="" disabled selected hidden>Select Event place</option>
						</select>
						<span *ngIf="epactivityErrors.epid" class="error-text">{{epactivityErrors.epid}}</span>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="col-md-1"></div>
				<div class="col-md-11">
					<div class="form-group">
						<label for="epsubid" class="form-label">Event Sub Place<span style="color:red;">*</span></label>
						<select class="form-control" id="epsubid" disabled="false" formControlName="epsubid"
							(change)="onShowActivity($event)">
							<option value="" selected>Select Event Sub Place</option>
							<option *ngFor="let parms of eventSubDropDown" value="{{parms}}">{{parms}}</option>
						</select>
						<span *ngIf="epactivityErrors.epsubid" class="error-text">{{epactivityErrors.epsubid}}</span>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="col-md-1"></div>
				<div class="col-md-11">
					<label for="epactivityid" class="form-label">Activity</label>
					<select class="form-control" id="epactivityid" disabled="false" formControlName="epactivityid"
						(change)="onActivitydetails($event)">
						<option value="" selected>Select Activity</option>
						<option *ngFor="let parms of eventactivityDropDown" value="{{parms}}">{{parms}}</option>
					</select>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-4">
				<div class="col-md-1"></div>
				<div class="col-md-11">
					<div class="form-group">
						<label>Activity Name<span style="color:red;">*</span></label>
						<input type="text" class="form-control  text-capitalize" id="epactivityname"
							formControlName="epactivityname" placeholder="Activity Name">
						<span *ngIf="epactivityErrors.epactivityname"
							class="error-text">{{epactivityErrors.epactivityname}}</span>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="col-md-1"></div>
				<div class="col-md-11">
					<div class="form-group">
						<label>Activity Charges<span style="color:red;">*</span></label>
						<input type="text" class="form-control" id="epactivitiescharges" maxlength="6"
							(keypress)="numberOnly($event)" formControlName="epactivitiescharges"
							placeholder="Activity Charges">
						<span *ngIf="epactivityErrors.epactivitiescharges"
							class="error-text">{{epactivityErrors.epactivitiescharges}}</span>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="form-group">
					<div class="col-md-1"></div>
					<div class="col-md-11">
						<label>Activity Description</label>
						<input class="form-control  text-capitalize" rows="3" id="epactivitydesc"
							formControlName="epactivitydesc" placeholder="Activity Description">
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-4">
				<div class="col-md-1"></div>
				<div class="col-md-11">
					<div class="form-group">
						<label class="mr-3">Activity 3rd Party<span style="color:red;">*</span>:</label>
						<div class="form-check form-check-inline">
							<input class="form-check-input" type="radio" value="Yes" name="epactivity3rdparty"
								id="epactivity3rdparty" formControlName="epactivity3rdparty">
							<label class="form-check-label" for="epactivity3rdparty"><b>Yes</b></label>
						</div>
						<div class="form-check form-check-inline">
							<input class="form-check-input" type="radio" value="No" name="epactivity3rdparty"
								id="epactivity3rdparty" formControlName="epactivity3rdparty">
							<label class="form-check-label" for="epactivity3rdparty"><b>No</b></label>
						</div><br>
						<span *ngIf="epactivityErrors.epactivity3rdparty"
							class="error-text">{{epactivityErrors.epactivity3rdparty}}</span>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="form-group">
					<div class="col-md-1"></div>
					<div class="col-md-11">
						<label class="mr-3">Activity Chargeable<span style="color:red;">*</span>:</label>
						<div class="form-check form-check-inline">
							<input class="form-check-input" type="radio" value="Yes" name="epactivitychargable"
								id="epactivitychargable" formControlName="epactivitychargable">
							<label class="form-check-label" for="epactivitychargable"><b>Yes</b></label>
						</div>
						<div class="form-check form-check-inline">
							<input class="form-check-input" type="radio" value="No" name="epactivitychargable"
								id="epactivitychargable" formControlName="epactivitychargable">
							<label class="form-check-label" for="epactivitychargable"><b>No</b></label>
						</div><br>
						<span *ngIf="epactivityErrors.epactivitychargable" class="error-text">
							{{epactivityErrors.epactivitychargable}}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="text-center">
			<button type="button" class="btn btn-primary" (click)="draftButton('Draft')" *ngIf="isdisabledraft">Save as
				Draft </button>
			<button type="submit" class="btn btn-success m-3" *ngIf="isdisablesave">{{this.isUpdate == true ? "Update" :
				"Save"}}</button>
			<button type="submit" class="btn btn-primary m-3" (click)="nextButton()" *ngIf="isdisablenext">Next</button>
		</div>
		<div class="row mt-5"></div>
	</form>
</div>
<ngx-spinner>
	<div class="custom-loader"></div>
</ngx-spinner>