
<div class="container-fuild sticky-top">
<app-event-header></app-event-header>
<nav class="p-2 bg-primary  ">
  <div class="row">
    <div class="col-md-2">
  <label class="text-dark">Function Booking Date:</label>
</div>
<div class="col-md-10">
    <label class="col-md-2">Start Date - {{startDate}}</label>
    <label class="col-md-2">First Half - {{startDateFirstHF}}</label>
    <label class="col-md-2">Second Half - {{startDateSecondHF}}</label>
    <label class="col-md-2">End Date - {{endDate}}</label>
    <label class="col-md-2" *ngIf="endFirstHalf">First Half - {{endDateFirstHF}}</label>
    <label class="col-md-2" *ngIf="endsecondHalf">Second Half - {{endDateSecondHF}}</label>

  </div>
  </div>
</nav>
</div>
<div class="background">
<div class="container-fuild">
<form  (ngSubmit)="onSubmit()" [formGroup]="eventHallPaymentDetails" id="eventHallPaymentDetails">
<div class="row">
  <div class="col-md-1"></div>
  <div class="col-md-11 mt-4">
    <h3 class="mt-3">Event Hall Payment Details</h3>
  </div>
</div><br>
  <div class="container">
    <div class="borader m-2">
    <div class="row m-3">
      <div class="col-md-6">
          <div class="form-group row">
            <label class="col-md-5 col-form-label">Event Hall Payment </label>
            <div class="col-md-1 mt-1" style="font-size: 18px;">: <span class="WebRupee">&#x20B9;</span></div>
            <div class="col-md-5">
              <input type="text" class="form-control"  (input)="totalAmount($event)" (keypress)="numberOnly($event)" formControlName="eventhallpayment" >
            </div>
          </div>
          <div class="form-group row"  *ngIf="roomdisable">
            <label class="col-md-5 col-form-label">Room Charges </label>
            <div class="col-md-1 mt-1" style="font-size: 18px;">: <span class="WebRupee">&#x20B9;</span></div>
            <div class="col-md-5">
              <input type="text" class="form-control"  (input)="totalAmount($event)" (keypress)="numberOnly($event)"  formControlName="roomcharges">
            </div>
          </div>
          <div class="form-group row"  *ngIf="kitchendisable">
            <label class="col-md-5 col-form-label">Kitchen Charges </label>
            <div class="col-md-1 mt-1" style="font-size: 18px;">: <span class="WebRupee">&#x20B9;</span></div>
            <div class="col-md-5">
              <input type="text" class="form-control"  (input)="totalAmount($event)" (keypress)="numberOnly($event)"  formControlName="kitchencharges">
            </div>
          </div>
          <div class="form-group row" *ngIf="openspacedisable">
            <label class="col-md-5 col-form-label">Open Space Charges </label>
            <div class="col-md-1 mt-1" style="font-size: 18px;">: <span class="WebRupee">&#x20B9;</span></div>
            <div class="col-md-5">
              <input type="text" class="form-control"  (input)="totalAmount($event)" (keypress)="numberOnly($event)"  formControlName="openspacecharges">
            </div>
          </div>
          <div class="form-group row" *ngIf="parkingdisable">
            <label class="col-md-5 col-form-label">Parking Charges </label>
            <div class="col-md-1 mt-1" style="font-size: 18px;">: <span class="WebRupee">&#x20B9;</span></div>
            <div class="col-md-5">
              <input type="text" class="form-control"  (input)="totalAmount($event)" (keypress)="numberOnly($event)"  formControlName="parkingcharges">
            </div>
          </div>
          <div class="form-group row" *ngIf="acitvitydisable">
            <label class="col-md-5 col-form-label">Activity Charges </label>
            <div class="col-md-1 mt-1" style="font-size: 18px;">: <span class="WebRupee">&#x20B9;</span></div>
            <div class="col-md-5">
              <div class="input-group">
                <input type="text" class="form-control"  (input)="totalAmount($event)" (keypress)="numberOnly($event)"  formControlName="activitycharges">
                <div class="input-group-append">
                    <div class="input-group-text" (click)="openActivity()">
                        <span class="fa fa-list" data-toggle="modal" data-target="#myModal"></span>
                    </div>
                </div>
            </div>
            </div>
          </div>
          <div class="form-group row" *ngIf="amenitydisable">
            <label class="col-md-5 col-form-label">Amenities Charges </label>
            <div class="col-md-1 mt-1" style="font-size: 18px;">: <span class="WebRupee">&#x20B9;</span></div>
            <div class="col-md-5">
              <div class="input-group">
                <input type="text" class="form-control"  (input)="totalAmount($event)" (keypress)="numberOnly($event)"  formControlName="amenitiescharges">
                <div class="input-group-append">
                    <div class="input-group-text" (click)="openAmenitiesCharge()">
                        <span class="fa fa-list" data-toggle="modal" data-target="#myModal"></span>
                    </div>
                </div>
            </div>
            </div>
          </div>
          <div class="form-group row" *ngIf="incidentaldisable">
            <label class="col-md-5 col-form-label">Incidental Charges </label>
            <div class="col-md-1 mt-1" style="font-size: 18px;">: <span class="WebRupee">&#x20B9;</span></div>
            <div class="col-md-5">
              <div class="input-group">
                <input type="text" class="form-control"  (input)="totalAmount($event)" (keypress)="numberOnly($event)"  formControlName="incidentalcharges">
                <div class="input-group-append">
                    <div class="input-group-text" (click)="openIncCharge()">
                        <span class="fa fa-list" data-toggle="modal" data-target="#myModal"></span>
                    </div>
                </div>
            </div>
            </div>
          </div>
          <div class="form-group row" *ngIf="miscdisable">
            <label class="col-md-5 col-form-label">Misc Charges </label>
            <div class="col-md-1 mt-1" style="font-size: 18px;">: <span class="WebRupee">&#x20B9;</span></div>
            <div class="col-md-5">
              <div class="input-group">
              <input type="text" class="form-control"  (input)="totalAmount($event)" (keypress)="numberOnly($event)"  formControlName="misccharges">
              <div class="input-group-append">
                <div class="input-group-text" (click)="openMiscCharge()">
                    <span class="fa fa-list" data-toggle="modal" data-target="#myModal"></span>
                </div>
              </div>
            </div>
            </div>
          </div>
          <div class="form-group row" *ngIf="otherdisable">
            <label class="col-md-5 col-form-label">Others </label>
            <div class="col-md-1 mt-1" style="font-size: 18px;">: <span class="WebRupee">&#x20B9;</span></div>
            <div class="col-md-5">
              <div class="input-group">
              <input type="text" class="form-control"  (input)="totalAmount($event)" (keypress)="numberOnly($event)"   formControlName="othercharges">
              <!-- <div class="input-group-append">
                <div class="input-group-text" (click)="openMiscCharge()">
                    <span class="fa fa-list" data-toggle="modal" data-target="#myModal"></span>
                </div>
              </div> -->
            </div>
            </div>
          </div>
      </div>
      <div class="col-md-6">
        <div class="form-group row">
          <label class="col-md-6 col-form-label">Total Capacity</label>
          <div class="col-md-5">
            <input type="text" class="form-control"  (input)="totalAmount($event)" (keypress)="numberOnly($event)"  formControlName="totalcapacity">
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="form-group row">
              <label class="col-md-5 col-form-label">Total Amount to Pay </label>  
              <div class="col-md-1 mt-1" style="font-size: 18px;">: <span class="WebRupee">&#x20B9;</span></div>
              <div class="col-md-5">
                <input type="text" class="form-control"   formControlName="totalamountpay" (keypress)="numberOnly($event)" readonly>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-5 col-form-label">CGST % </label>
              <div class="col-md-1 mt-1" style="font-size: 18px;">: <span class="WebRupee">&#x20B9;</span></div>
              <div class="col-md-5">
                <input type="text" class="form-control"  (input)="totalAmount($event)" (keypress)="numberOnly($event)"  formControlName="cgst" readonly>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-5 col-form-label">SGST % </label>
              <div class="col-md-1 mt-1" style="font-size: 18px;">: <span class="WebRupee">&#x20B9;</span></div>
              <div class="col-md-5">
                <input type="text" class="form-control"  (input)="totalAmount($event)" (keypress)="numberOnly($event)"  formControlName="sgst" readonly>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-5 col-form-label">Total Tax Included in Total Payment</label>
              <div class="col-md-1 mt-1" style="font-size: 18px;">: <span class="WebRupee">&#x20B9;</span></div>
              <div class="col-md-5">
                <input type="text" class="form-control"  (input)="totalAmount($event)" (keypress)="numberOnly($event)"  formControlName="totalinclusiveoftax">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-5 col-form-label">Advance Paid </label>
              <div class="col-md-1 mt-1" style="font-size: 18px;">: <span class="WebRupee">&#x20B9;</span></div>
              <div class="col-md-5">
                <input type="text" class="form-control" (input)="advancAmount($event)"  (keypress)="numberOnly($event)" formControlName="advancepaid">
                <span *ngIf="eventHallPaymentDetailsError.advancepaid" class="error-text">{{eventHallPaymentDetailsError.advancepaid}}</span>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-5 col-form-label">Net Value to Pay </label>
              <div class="col-md-1 mt-1" style="font-size: 18px;">: <span class="WebRupee">&#x20B9;</span></div>
              <div class="col-md-5">
                <input type="text" class="form-control"  formControlName="netvaluetopay" (keypress)="numberOnly($event)" readonly>
                <span *ngIf="eventHallPaymentDetailsError.netvaluetopay" class="error-text">{{eventHallPaymentDetailsError.netvaluetopay}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="card-body text-center">
        <button type="submit" class="btn btn-success mr-3">
          <span style="margin:center;">{{isUpdate ? 'Update Payment' : 'Save Payment'}}</span></button>
        <!-- <button type="button" class="btn btn-success"><span style="margin:center;">Print Details</span></button> -->
      </div>
    </div>
  </div>
    <div class="form-group row col-md-12 mt-3">
      <div class="col-md-4"></div>
      <div class="col-md-3 text-center">
        </div>
    </div>
    <div class="form-group row col-md-12 mt-3">
      <div class="col-md-4"></div>
      <div class="col-md-3 text-center">
        </div>
    </div>
  </div>
</form>
</div>
</div>
<app-event-footer></app-event-footer>
<ngx-spinner>
  <div class="custom-loader"></div>
</ngx-spinner>

<!-- Activity Charges Deatils get Popup -->

<div class="modal" id="myModal" [style.display]="isActivity ?  'block' : 'none'">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Amenities Charges</h4>
        <button type="button" class="close" data-dismiss="modal" (click)="closeActivity()">&times;</button>
      </div>
      <div class="modal-body">
          <table class="table table-striped">
    <thead class="text-center">
        <th>#</th>
        <th>Activity Name</th>
        <th>Charges</th>
    </thead>
    <tbody class="text-center" *ngFor="let act of this.activityDetails let i = index">
        <td>{{i+1}}</td>
        <td>{{act.selectactivity}}</td>
        <td>{{act.qtyrequired}}</td>
    </tbody>
  </table>
  </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="closeActivity()">Close</button>
      </div>

    </div>
  </div>
</div>

<!--AmenityCharges Get Popup -->

<div class="modal" id="myModal" [style.display]="isAmenities ?  'block' : 'none'">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Amenities Charges</h4>
        <button type="button" class="close" data-dismiss="modal" (click)="closeAmenities()">&times;</button>
      </div>
      <div class="modal-body">
          <table class="table table-striped">
    <thead class="text-center">
        <th>#</th>
        <th>Amenities Name</th>
        <th>Charges</th>
    </thead>
    <tbody class="text-center" *ngFor="let amt of this.amenitiedDetails let i = index">
        <td>{{i+1}}</td>
        <td>{{amt.selectamenities}}</td>
        <td>{{amt.qtyrequired}}</td>
    </tbody>
  </table>
  </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="closeAmenities()">Close</button>
      </div>

    </div>
  </div>
</div>

<!--Incidental Charges Get Popup -->

<div class="modal" id="myModal" [style.display]="isIncidental ?  'block' : 'none'">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Incidental Charges</h4>
        <button type="button" class="close" data-dismiss="modal" (click)="closeIncCharges()">&times;</button>
      </div>
      <div class="modal-body">
          <table class="table table-striped">
    <thead class="text-center">
        <th>#</th>
        <th>Incidental Name</th>
        <th>Charges</th>
    </thead>
    <tbody class="text-center" *ngFor="let inc of this.getIncdetails let i = index">
        <td>{{i+1}}</td>
        <td>{{inc.selectinccharges}}</td>
        <td>{{inc.qtyrequired}}</td>
    </tbody>
  </table>
  </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="closeIncCharges()">Close</button>
      </div>

    </div>
  </div>
</div>
<!-- MiscChargs Get Popup -->

<div class="modal" id="myModal" [style.display]="isMisc ?  'block' : 'none'">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Miscellaneous Charges</h4>
        <button type="button" class="close" data-dismiss="modal" (click)="closeMisc()">&times;</button>
      </div>
      <div class="modal-body">
          <table class="table table-striped">
    <thead class="text-center">
        <th>#</th>
        <th>Miscellaneous Name</th>
        <th>Charges</th>
    </thead>
    <tbody class="text-center" *ngFor="let misc of this.miscDetails let i = index">
        <td>{{i+1}}</td>
        <td>{{misc.selectmischarges}}</td>
        <td>{{misc.qtyrequired}}</td>
    </tbody>
  </table>
  </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="closeMisc()">Close</button>
      </div>

    </div>
  </div>
</div>