import {Component, OnInit, HostListener} from "@angular/core";
import {Router} from "@angular/router";
import {AppService} from "@services/app.service";
import {ToastrService} from "ngx-toastr";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {EventPlaceBooking} from '@modules/ep-booking-management/model/eventplace-booking';
import {pipe, Subject, takeUntil} from "rxjs";
import {EventPlace} from "@modules/event-place-management/model/eventplace";
import {EventSubPlace} from "@modules/event-place-management/model/eventsub-place";
import {styles} from "ansi-colors";
import Swal from "sweetalert2";
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";
import { OtpResponse } from "@modules/event-place-management/model/otpResponse";
import { BookingareaDataService } from "../model/bookingareaDataService";


@Component({
  selector: 'app-eventplace-booking',
  templateUrl: './eventplace-booking.component.html',
  styleUrls: ['./eventplace-booking.component.scss']
})

export class EventPlaceBookingComponent implements OnInit {
moveFocus(arg0: string) {
throw new Error('Method not implemented.');
}
  eventBooking: EventPlaceBooking [] = [];
  currentEventBooking: EventPlaceBooking | undefined;
  eventPlace: EventPlace [] = [];
  eventdetails : EventPlaceBooking [] = []
  eventDropdown: string [] = [];
  alreadyDate: string [] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  epIdDropDown: string | undefined;
  eventPlaceName: string | undefined;
  eventSubPlace: EventSubPlace[] = [];
  eventSubDropDown: string[] = [];
  epSubDropDown: string | undefined;
  eventSubId: string | undefined;
  eventSubPlaceName : string | undefined;
  isDisabled = false;
  isSubDisabled = false;
  currentDate: string;
  getData: string;
  startDate : string[] =[]
  endDate : string[]=[]
  startfh : string[]=[]
  starteh : string[]=[]
  endfh : string[]=[]
  endeh : string[]=[]
  currentStartDate : string
  currentEndDate : string
  openOTP = false;
  verifiedOTP = false;
  isVisible = true;
  isUpdateEvent = false;
  isSaveEvent = false;
  otpResponse: OtpResponse | undefined;
  getEventData : EventPlaceBooking [] = [];
  eventId : string = ""
  isOtp = false;
  eventEmail : string = ""
  firstValue: string = '';
  secondValue: string = '';
  thirdValue: string = '';
  fourthValue: string = '';
  firstValue1: string = '';
  secondValue2: string = '';
  thirdValue3: string = '';
  fourthValue4: string = '';
  email :string = ''

  constructor(private router: Router, private appService: AppService,
              private toastr: ToastrService,private datePipe: DatePipe,
              private spinner : NgxSpinnerService,private dataService : BookingareaDataService) {
    this.currentDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  }

  EventPlaceBooking = new UntypedFormGroup({
    eventid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epsubid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    eventname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    eventsubname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    startdate: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    starthalf1: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    starthalf2: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    enddate: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    endhalf1: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    endhalf2: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    typeoffunction: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    noofmembers: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    contactperson: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    phonenumber1: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    phonenumber2: new UntypedFormControl('', [Validators.required, Validators.nullValidator, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
    customerid : new UntypedFormControl('',[Validators.required, Validators.nullValidator]),
    emailid: new UntypedFormControl('', [Validators.required, Validators.nullValidator, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
    address: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    activeyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    deleteyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    createdby: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    createddate: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    otherOption: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    eventemailid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    eventbookingtype: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),

  })

  otpInput = new UntypedFormGroup({
    otp: new UntypedFormControl('', [Validators.required, Validators.nullValidator])
  })

  currentEmail = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.nullValidator])
})
  
  public EventPlaceBookingErrors = {
    epid: '',
    epsubid: '',
    startdate: '',
    starthalf1: '',
    starthalf2: '',
    enddate: '',
    endhalf1: '',
    endhalf2: '',
    typeoffunction: '',
    otherOption: '',
    noofmembers: '',
    contactperson: '',
    phonenumber2: '',
    emailid: '',
    address: '',
    customerid: '',
  }


  ngOnInit() { 
    if(this.dataService.bookingData != undefined){
      this.isUpdateEvent = true
     this.isVisible = false;
    console.log("Event data::", this.dataService.bookingData )
    // @ts-ignore
    this.getEventData = this.dataService.bookingData;
    console.log("Event data::", this.getEventData )
    for (let event of this.getEventData ){
      // this.EventPlaceBooking.controls['eventid'].setValue(event.eventid)
      this.EventPlaceBooking.controls['epid'].setValue(event.epid)
      this.EventPlaceBooking.controls['epsubid'].setValue(event.epsubid)
      this.EventPlaceBooking.controls['eventname'].setValue(event.eventname)
      this.EventPlaceBooking.controls['eventsubname'].setValue(event.eventsubname)
      this.EventPlaceBooking.controls['startdate'].setValue(event.startdate)
      if(event.starthalf1 == "true" || event.starthalf1 == ""){
        this.EventPlaceBooking.controls['starthalf1'].setValue(event.starthalf1)
      }
      if(event.starthalf2 == "true" || event.starthalf2 == ""){
      this.EventPlaceBooking.controls['starthalf2'].setValue(event.starthalf2)
      }
      this.EventPlaceBooking.controls['enddate'].setValue(event.enddate)
      if(event.endhalf1 == "true" || event.endhalf1 == ""){
      this.EventPlaceBooking.controls['endhalf1'].setValue(event.endhalf1)
      } if(event.endhalf2 == "true" || event.endhalf2 == ""){
      this.EventPlaceBooking.controls['endhalf2'].setValue(event.endhalf2)  
      }
      this.EventPlaceBooking.controls['contactperson'].setValue(event.contactperson)
      this.EventPlaceBooking.controls['typeoffunction'].setValue(event.typeoffunction)
      this.EventPlaceBooking.controls['noofmembers'].setValue(event.noofmembers)
      this.EventPlaceBooking.controls['phonenumber1'].setValue(event.phonenumber1)
      this.EventPlaceBooking.controls['phonenumber2'].setValue(event.phonenumber2)
      this.EventPlaceBooking.controls['emailid'].setValue(event.emailid)
      this.EventPlaceBooking.controls['address'].setValue(event.address)
      this.EventPlaceBooking.controls['eventemailid'].setValue(event.eventemailid)
      this.EventPlaceBooking.controls['createdby'].setValue(event.createdby)
      this.EventPlaceBooking.controls['createddate'].setValue(event.createdtime)
      this.EventPlaceBooking.controls['customerid'].setValue(event.customerid)
      this.EventPlaceBooking.controls["activeyn"].setValue("Yes");
      this.EventPlaceBooking.controls["deleteyn"].setValue("No");
      this.eventSubDropDown.push(event.eventsubname)
      this.eventDropdown.push(event.eventname)
      this.eventId = (event.eventid)
      // this.alreadyDate.push(event.startdate,event.enddate)
      console.log("getDate::",this.alreadyDate)
      this.epIdDropDown = event.epid
      this.eventSubId = event.epsubid
      console.log("getdate::",this.epIdDropDown)
      console.log("evensubid::",this.eventSubId)
      // this.calendar();
this.updatefunction()
    }
  }else{
    this.calendar();
    this.getEventPlaceByuserId();
    this.EventPlaceBooking.controls['epsubid'].disable();
    this.EventPlaceBooking.controls["phonenumber1"].setValue("+91");
    this.isSaveEvent = true
    this.isOtp = true;
    this.EventPlaceBooking.controls['epid'].enable();
    Object.keys(this.EventPlaceBooking.controls).forEach((controlName) => {
      if (controlName !== 'epid') {
        this.EventPlaceBooking.get(controlName).disable();
      }
    })
  }
}

updatefunction(){
  this.getRegisterDate()
  this.getEventPlaceByuserId()
  for(let ep of this.getEventData){
    this.currentStartDate = ep.startdate
    this.currentEndDate = ep.enddate
  }
  this.updatestartDateFun()
  this.updateEndDateFun()
}
  onSubmit() {
    this.formValidation();

  }

  OnlyNumbersAllowed(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    //only numbers 0-9
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      console.log('charCode restricted is' + charCode);
      this.EventPlaceBookingErrors.phonenumber2 = "Please enter numbers only.";
      return false;
    } else {
      this.EventPlaceBookingErrors.phonenumber2 = "";
    }
    return true;
  }


  formValidation() {
    this.EventPlaceBookingErrors.epid = "";
    this.EventPlaceBookingErrors.epsubid = "";
    this.EventPlaceBookingErrors.startdate = "";
    this.EventPlaceBookingErrors.starthalf1 = "";
    this.EventPlaceBookingErrors.starthalf2 = "";
    this.EventPlaceBookingErrors.enddate = "";
    this.EventPlaceBookingErrors.endhalf1 = "";
    this.EventPlaceBookingErrors.endhalf2 = "";
    this.EventPlaceBookingErrors.typeoffunction = "";
    this.EventPlaceBookingErrors.otherOption = "";
    this.EventPlaceBookingErrors.noofmembers = "";
    this.EventPlaceBookingErrors.contactperson = "";
    this.EventPlaceBookingErrors.phonenumber2 = "";
    this.EventPlaceBookingErrors.emailid = "";
    this.EventPlaceBookingErrors.address = "";
    this.EventPlaceBookingErrors.customerid = "";


    let hasError = false;

    let inputValue = this.EventPlaceBooking.controls['contactperson'].value;
    let outputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
    this.EventPlaceBooking.controls['contactperson'].setValue(outputValue)


    if (this.EventPlaceBooking.get('epid')?.invalid) {
      this.EventPlaceBookingErrors.epid = "*Event Place is required";
      hasError = true;
    }

    if (this.EventPlaceBooking.get('epsubid')?.invalid) {
      this.EventPlaceBookingErrors.epsubid = "*Event Sub Place is required";
      hasError = true;
    }
    if (this.EventPlaceBooking.get('startdate')?.invalid) {
      this.EventPlaceBookingErrors.startdate = "*Start Date is required";
      hasError = true;
    }
    if (this.EventPlaceBooking.get('starthalf1')?.invalid && this.EventPlaceBooking.get('starthalf2')?.invalid) {
      this.EventPlaceBookingErrors.starthalf1 = "*Select a time period is required";
      hasError = true;
    }
    /*if (this.EventPlaceBooking.get('startdate').value == this.alreadyDate) {
      this.EventPlaceBookingErrors.startdate = "*Start Date is required";
    }*/
    // else{
    //   this.EventPlaceBookingErrors.starthalf2 = "*Select a time period is required";
    //   hasError = true;
    //   }

    if (this.EventPlaceBooking.get('enddate')?.invalid) {
      this.EventPlaceBookingErrors.enddate = "*End Date is required";
      hasError = true;
    }

    if (this.EventPlaceBooking.get('endhalf1')?.invalid && this.EventPlaceBooking.get('endhalf2')?.invalid) {
      this.EventPlaceBookingErrors.endhalf1 = "*Select a time period is required";
      hasError = true;
    }
    /*if (this.EventPlaceBooking.controls['startdate'].value != this.EventPlaceBooking.controls['enddate'].value) {
      if (this.EventPlaceBooking.get('endhalf1')?.invalid && this.EventPlaceBooking.get('endhalf2')?.invalid) {
        this.EventPlaceBookingErrors.endhalf1 = "*Select a time period is required";
        hasError = true;
      }
      // else{
      //     this.EventPlaceBookingErrors.endhalf2 = "*Select a time period is required";
      //         hasError = true;
      //   }

    }*/

    if (this.EventPlaceBooking.get('typeoffunction')?.invalid) {
      this.EventPlaceBookingErrors.typeoffunction = "*Type of Function is required";
      hasError = true;
    }
    if (this.EventPlaceBooking.controls['typeoffunction'].value == "Others") {
      if (this.EventPlaceBooking.get('otherOption')?.invalid) {
        this.EventPlaceBookingErrors.otherOption = "*Other Type of Function is required";
        hasError = true;
      }
    }

    if (this.EventPlaceBooking.get('noofmembers')?.invalid) {
      this.EventPlaceBookingErrors.noofmembers = "*No of Members is required";
      hasError = true;
    }

    if (this.EventPlaceBooking.get('contactperson')?.invalid) {
      this.EventPlaceBookingErrors.contactperson = "*Contact Person Name is required";
      hasError = true;
    }
    if (this.EventPlaceBooking.get('phonenumber2')?.invalid) {
      if (this.EventPlaceBooking.get('phonenumber2').errors.required) {
        this.EventPlaceBookingErrors.phonenumber2 = "*Phone Number is required";
      }
      if (this.EventPlaceBooking.get('phonenumber2').errors.pattern) {
        this.EventPlaceBookingErrors.phonenumber2 = "*Please, Enter 10 digit Phone Number.";
      }
      hasError = true;
    }
    if(this.EventPlaceBooking.get('customerid').invalid){
      this.EventPlaceBookingErrors.customerid = "*Aadhar Number is required"
    }
    if (this.EventPlaceBooking.get('emailid')?.invalid) {
      if (this.EventPlaceBooking.get('emailid').errors.required) {
        this.EventPlaceBookingErrors.emailid = "*Email-ID is required";
      }
      if (this.EventPlaceBooking.get('emailid').errors.pattern) {
        this.EventPlaceBookingErrors.emailid = "*Please,Enter a Valid Email Address.";
      }
      hasError = true;
    }

    if (this.EventPlaceBooking.get('address')?.invalid) {
      this.EventPlaceBookingErrors.address = "*Address is required";
      hasError = true;
    }
    if (!hasError) {
      // const startDate = this.EventPlaceBooking.controls['startdate'].value;
      // const endDate = this.EventPlaceBooking.controls['enddate'].value;
      // const startFH = this.EventPlaceBooking.controls['starthalf1'].value;
      // const startSH = this.EventPlaceBooking.controls['starthalf2'].value;
      // const endFH = this.EventPlaceBooking.controls['endhalf1'].value;
      // const endSH = this.EventPlaceBooking.controls['endhalf2'].value;
      // console.log("sttsatst::", startDate)
      // console.log("stacey::", this.startDate)
      // console.log("first half value:", startFH)
      // console.log("second half value:", startSH)
      // console.log("End 1st half value:", endFH)
      // console.log("End 2nd half value:", endSH)

      // let existDate = false;
      // let existLoopDate = false;
      // let bothBookStart = false;
      // let bothBookEnd = false;

      // for (var ep of this.eventBooking) {
      //   if (ep.epid == this.epIdDropDown && ep.epsubid == this.eventSubId) {
      //     if (startDate == ep.startdate) {
      //       console.log("start date values:", ep)
      //       if (startFH != "" && startSH != "") {
      //         console.log("checking")
      //         if (startFH.toString() == ep.starthalf1 && startSH.toString() == ep.starthalf2) {
      //           this.toastr.error("This Date is Already Booked")
      //           this.EventPlaceBookingErrors.startdate = "*Start Date is Already Booked";
      //           existDate = true;
      //         } else if (startFH.toString() == ep.starthalf1) {
      //           this.toastr.error("This First Half is Already Booked")
      //           this.EventPlaceBookingErrors.starthalf1 = "*First Half is Already Booked";
      //           existDate = true;
      //           bothBookStart = true;
      //         } else if (startSH.toString() == ep.starthalf2) {
      //           this.toastr.error("This Second Half is Already Booked")
      //           this.EventPlaceBookingErrors.starthalf2 = "*Second Half is Already Booked";
      //           existDate = true;
      //           bothBookStart = true;
      //         }
      //         existLoopDate = false;dataService
      //       }
      //       if (startFH != "" && startSH == "" || startSH == false) {
      //         if (startFH.toString() == ep.starthalf1) {
      //           console.log("if FH Match")
      //           console.log("current SFH::", startFH.toString())
      //           console.log("exit SFH::", ep.starthalf1)
      //           this.toastr.error("This First Half is Already Booked")
      //           this.EventPlaceBookingErrors.starthalf1 = "*First Half is Already Booked";
      //           existDate = true;
      //         } else {
      //           existLoopDate = false;
      //           console.log("if FH Not Match")
      //           console.log("current SFH::", startFH.toString())
      //           console.log("exit SFH::", ep.starthalf1)
      //         }
      //       }dataService

      //       if (startSH != "" && startFH == "" || startFH == false) {
      //         if (startSH.toString() == ep.starthalf2) {
      //           console.log("if SH Match")
      //           console.log("current SSH::", startSH.toString())
      //           console.log("exit SSH::", ep.starthalf2)
      //           this.toastr.error("This Second Half is Already Booked")
      //           this.EventPlaceBookingErrors.starthalf2 = "*Second Half is Already Booked";
      //           existDate = true;
      //         } else {
      //           existLoopDate = false;
      //           console.log("if SH Not Match")
      //           console.log("current SSH::", startSH.toString())
      //           console.log("exit SSH::", ep.starthalf2)
      //         }
      //       }
      //       console.log("exist start date::", ep)
      //     }

      //     if (endDate == ep.enddate) {
      //       console.log("start date values:", ep)
      //       if (endFH != "" && endSH != "") {
      //         if (endFH.toString() == ep.endhalf1 && endSH.toString() == ep.endhalf2) {
      //           this.toastr.error("This Date is Already Booked")
      //           this.EventPlaceBookingErrors.enddate = "*End Date is Already Booked";
      //           existDate = true;
      //         } else if (endFH.toString() == ep.endhalf1) {
      //           this.toastr.error("This First Half is Already Booked")
      //           this.EventPlaceBookingErrors.endhalf1 = "*First Half is Already Booked";
      //           existDate = true;
      //           bothBookEnd = true;
      //         } else if (endSH.toString() == ep.endhalf2) {
      //           this.toastr.error("This Second Half is Already Booked")
      //           this.EventPlaceBookingErrors.endhalf2 = "*Second Half is Already Booked";
      //           existDate = true;
      //           bothBookEnd = true;
      //         }
      //         existLoopDate = false;
      //       }
      //       if (endFH != "" && endSH == "" || endSH == false) {
      //         if (endFH.toString() == ep.endhalf1) {
      //           console.log("if FH Match")
      //           console.log("current SFH::", endFH.toString())
      //           console.log("exit SFH::", ep.endhalf1)
      //           this.toastr.error("This First Half is Already Booked")
      //           this.EventPlaceBookingErrors.endhalf1 = "*First Half is Already Booked";
      //           existDate = true;
      //         } else {
      //           existLoopDate = false;
      //           console.log("if FH Not Match")
      //           console.log("current SFH::", endFH.toString())
      //           console.log("exit SFH::", ep.endhalf1)
      //         }
      //       }

      //       if (endSH != "" && endFH == "" || endFH == false) {
      //         if (endSH.toString() == ep.endhalf2) {
      //           console.log("if SH Match")
      //           console.log("current SSH::", endSH.toString())
      //           console.log("exit SSH::", ep.endhalf2)
      //           this.toastr.error("This Second Half is Already Booked")
      //           this.EventPlaceBookingErrors.endhalf2 = "*Second Half is Already Booked";
      //           existDate = true;
      //         } else {
      //           existLoopDate = false;
      //           console.log("if SH Not Match")
      //           console.log("current SSH::", endSH.toString())
      //           console.log("exit SSH::", ep.endhalf2)
      //         }
      //       }
      //       console.log("exist start date::", ep)
      //     }

      //   }
      // }
      // if (bothBookStart) {
      //   this.EventPlaceBookingErrors.startdate = "*Start Date is Already Booked";
      // }
      // if (bothBookEnd) {
      //   this.EventPlaceBookingErrors.enddate = "*End Date is Already Booked";
      // }
      // if (existDate == false && existLoopDate == false) {
      //   this.saveEventBooking();
      // }

      this.saveEventBooking();
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }




  calendar() {
    let date = new Date();
    const coloredDates = this.alreadyDate.map(dateString => new Date(dateString));
    console.log("Colored Date:", coloredDates);
  
    const renderCalendar = () => {
      const monthDays = document.querySelector(".days");
  
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
      const prevLastDay = new Date(date.getFullYear(), date.getMonth(), 0).getDate();
      const firstDayIndex = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
  
      const nextDays = 7 - new Date(date.getFullYear(), date.getMonth() + 1, 0).getDay() - 1;
  
      const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];
  
      document.querySelector(".date h1").innerHTML = months[date.getMonth()];
      document.querySelector(".date p").innerHTML = date.toDateString();
  
      let days = "";
  
      for (let x = firstDayIndex; x > 0; x--) {
        days += `<div class="day prev-date">${prevLastDay - x + 1}</div>`;
      }
  
      for (let i = 1; i <= lastDay; i++) {
        let isColoredDate = coloredDates.some(coloredDate => (
          i === coloredDate.getDate() && date.getMonth() === coloredDate.getMonth()
        ));
  
        if (i === new Date().getDate() && date.getMonth() === new Date().getMonth()) {
          days += `<div class="day today${isColoredDate ? ' colored-date' : ''}"${isColoredDate ? ' style="background-color: #FF4F4B; color: black;"' : ''}>${i}</div>`;
        } else {
          days += `<div class="day${isColoredDate ? ' colored-date' : ''}"${isColoredDate ? ' style="background-color: #FF4F4B; color: black;"' : ''}>${i}</div>`;
        }
      }
  
      for (let j = 1; j <= nextDays; j++) {
        days += `<div class="day next-date">${j}</div>`;
      }
  
      monthDays.innerHTML = days;
    };
  
    document.querySelector(".prev").addEventListener("click", () => {
      date.setMonth(date.getMonth() - 1);
      renderCalendar();
    });
  
    document.querySelector(".next").addEventListener("click", () => {
      date.setMonth(date.getMonth() + 1);
      renderCalendar();
    });
  
    renderCalendar();
  }
  

  onNavigation(url: string) {
    if (url && url !== "") {
      this.router.navigate([url]);
    }
  }

  getEventPlaceByuserId() {
    if(localStorage.getItem('ownerId')){
      this.appService
        .getEventPlaceByuserId(localStorage.getItem('ownerId'))
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          console.log("Event place Owner Dropdwon::", data);
          // @ts-ignore
          this.eventPlace = data;
          for (var ep of this.eventPlace) {
            if(ep.epid == localStorage.getItem('customerEventId')){
              this.eventDropdown.push(ep.epname)
            }
          }
        })
    }else
      this.appService
        .getEventPlaceByuserId(localStorage.getItem('token'))
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          console.log("Event place Dropdwon::", data);
          // @ts-ignore
          this.eventPlace = data;
          for (var ep of this.eventPlace) {
            this.eventDropdown.push(ep.epname)
            this.eventEmail = ep.emailid
          }
        })
  }

  saveEventBooking() {
  
      if(this.isUpdateEvent){
        this.spinner.show()
        this.EventPlaceBooking.controls["eventemailid"].setValue(this.eventEmail)
        this.EventPlaceBooking.controls["eventbookingtype"].setValue("Admin");
        this.EventPlaceBooking.controls["eventid"].setValue(this.eventId);
        console.log("UpdateData::",this.EventPlaceBooking.value)
        this.appService.updateEventDetails(this.EventPlaceBooking.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          this.spinner.hide()
        console.log("UpdateData::",data)
         this.currentEventBooking = data;
        var localdata : EventPlaceBooking [] = []
        localdata.push(data)
        localStorage.setItem('epId', this.currentEventBooking.epid)
          localStorage.setItem('epSubId', this.currentEventBooking.epsubid)
          localStorage.setItem('epEventId',this.currentEventBooking.eventid)
          localStorage.setItem('eventEmailId',this.currentEventBooking.eventemailid)
          localStorage.setItem('capacity',this.currentEventBooking.noofmembers)
        console.log("UpdateData112::",localdata)
        this.updateAlert()
        },(err : any) =>{
        console.log("Something went Wrong")
        this.spinner.hide()
        this.errorAlert()
        })
      }else {
        if(this.verifiedOTP){
    this.spinner.show()
    this.EventPlaceBooking.controls["activeyn"].setValue("Yes");
    this.EventPlaceBooking.controls["deleteyn"].setValue("No");
    this.EventPlaceBooking.controls["createdby"].setValue(localStorage.getItem("token"));
    this.EventPlaceBooking.controls["epid"].setValue(this.epIdDropDown);
    this.EventPlaceBooking.controls["epsubid"].setValue(this.eventSubId);
    this.EventPlaceBooking.controls["eventname"].setValue(this.eventPlaceName);
    this.EventPlaceBooking.controls["eventsubname"].setValue(this.eventSubPlaceName);
    this.EventPlaceBooking.controls["eventemailid"].setValue(this.eventEmail);
    this.EventPlaceBooking.controls["eventbookingtype"].setValue("Admin");
    if(this.isDisabled){
      const noofotherfunction = this.EventPlaceBooking.controls["otherOption"].value;
      this.EventPlaceBooking.controls["typeoffunction"].setValue(noofotherfunction);
    }
    console.log("EventBooking details::", this.EventPlaceBooking.value);
    console.log("Event details::", this.eventPlaceName);
    this.appService.saveEventBooking(this.EventPlaceBooking.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide()
          console.log("EventBooking Details ::", data);
          // @ts-ignore
          this.currentEventBooking = data;
          localStorage.setItem('epId', this.currentEventBooking.epid)
          localStorage.setItem('epSubId', this.currentEventBooking.epsubid)
          localStorage.setItem('epStartDate',this.currentEventBooking.startdate)
          localStorage.setItem('epEndDate',this.currentEventBooking.enddate)
          localStorage.setItem('epEventId',this.currentEventBooking.eventid)
          localStorage.setItem('capacity',this.currentEventBooking.noofmembers)
          localStorage.setItem('startFirstHF',this.currentEventBooking.starthalf1)
          localStorage.setItem('startSecondHF',this.currentEventBooking.starthalf2)
          localStorage.setItem('endFirstHF',this.currentEventBooking.endhalf1)
          localStorage.setItem('endSecondHF',this.currentEventBooking.endhalf2)
          localStorage.setItem('eventEmailId',this.currentEventBooking.eventemailid)
          this.successAlert();
        }, (err: any) => {
          this.spinner.hide()
          this.errorAlert()
        },
        () => console.log('HTTP request completed.'));
  }else {
    this.toastr.error("Email is Not Verified");
  }
}

}


  onShowOthers(event) {
    const value = event.target.value;
    console.log("Packing type::", value);
    if (value == "Others") {
      this.isDisabled = true;
    }else{
      this.isDisabled = false;
    }
  }

  startDateFun(event){
    this.EventPlaceBooking.controls['starthalf1'].setValue("")
    this.EventPlaceBooking.controls['starthalf2'].setValue("")
    this.EventPlaceBooking.get('starthalf1').enable()
    this.EventPlaceBooking.get('starthalf2').enable()
    this.EventPlaceBooking.get('starthalf1').enable()
    this.EventPlaceBooking.get('starthalf2').enable()
    let value = event.target.value
    console.log("getstartdate::",value)
    this.currentStartDate = value
    if(this.currentEndDate !== value){
      this.EventPlaceBooking.get('endhalf1').enable()
      this.EventPlaceBooking.get('endhalf2').enable()
    }else{
      this.EventPlaceBooking.get('endhalf1').disable()
      this.EventPlaceBooking.get('endhalf2').disable()
    }
    var localfirst : EventPlaceBooking [] = []
    for(let ep of this.eventdetails){
      if(ep.startdate == value){
        if(ep.starthalf1 == "true" && ep.starthalf2 == "true"){
          this.EventPlaceBooking.get('starthalf1').disable()
          this.EventPlaceBooking.get('starthalf2').disable()
          this.toastr.error("Start Date is Already Booked")
        }else if(ep.starthalf1 == "true"){
          this.EventPlaceBooking.get('starthalf1').disable()
          // this.toastr.error("Start Date is Already Booked")
        }else if(ep.starthalf2 == 'true'){
          this.EventPlaceBooking.get('starthalf2').disable()
          // this.toastr.error("Start Date is Already Booked")
        }
      }else if(value == ep.enddate ){
        console.log("getne::",ep)
        if(ep.endhalf1 == "true" && ep.endhalf2 == "true"){
          this.EventPlaceBooking.get('starthalf1').disable()
          this.EventPlaceBooking.get('starthalf2').disable()
        }else if(ep.endhalf1 == "true"){
          this.EventPlaceBooking.get('starthalf1').disable()
        }else if(ep.starthalf2 == 'true'){
          this.EventPlaceBooking.get('starthalf2').disable()
        }else if( ep.endhalf1 == "" && ep.endhalf2 == ""){
          this.EventPlaceBooking.get('endhalf1').disable()
          this.EventPlaceBooking.get('endhalf2').disable()
        }
      }
    }
  }

  updatestartDateFun(){
    // this.EventPlaceBooking.controls['starthalf1'].setValue("")
    // this.EventPlaceBooking.controls['starthalf2'].setValue("")
    this.EventPlaceBooking.get('starthalf1').enable()
    this.EventPlaceBooking.get('starthalf2').enable()
    this.EventPlaceBooking.get('starthalf1').enable()
    this.EventPlaceBooking.get('starthalf2').enable()
    // let value = event.target.value
    console.log("getstartdate::",this.currentStartDate)
    // this.currentStartDate = value
    if(this.currentEndDate !== this.currentStartDate){
      this.EventPlaceBooking.get('endhalf1').enable()
      this.EventPlaceBooking.get('endhalf2').enable()
    }else{
      this.EventPlaceBooking.get('endhalf1').disable()
      this.EventPlaceBooking.get('endhalf2').disable()
    }
    var localfirst : EventPlaceBooking [] = []
    for(let ep of this.eventdetails){
      if(ep.startdate == this.currentStartDate){
        if(ep.starthalf1 == "true" && ep.starthalf2 == "true"){
          this.EventPlaceBooking.get('starthalf1').disable()
          this.EventPlaceBooking.get('starthalf2').disable()
          this.toastr.error("Start Date is Already Booked")
        }else if(ep.starthalf1 == "true"){
          this.EventPlaceBooking.get('starthalf1').disable()
          // this.toastr.error("Start Date is Already Booked")
        }else if(ep.starthalf2 == 'true'){
          this.EventPlaceBooking.get('starthalf2').disable()
          // this.toastr.error("Start Date is Already Booked")
        }
      }else if(this.currentStartDate == ep.enddate ){
        console.log("getne::",ep)
        if(ep.endhalf1 == "true" && ep.endhalf2 == "true"){
          this.EventPlaceBooking.get('starthalf1').disable()
          this.EventPlaceBooking.get('starthalf2').disable()
        }else if(ep.endhalf1 == "true"){
          this.EventPlaceBooking.get('starthalf1').disable()
        }else if(ep.starthalf2 == 'true'){
          this.EventPlaceBooking.get('starthalf2').disable()
        }
      }
    }
  }


  endDateFun(event : any){
    const value = event.target.value
    console.log("getenddate::",value)
    this.currentEndDate = value 
    this.EventPlaceBooking.controls['endhalf1'].setValue("")
    this.EventPlaceBooking.controls['endhalf2'].setValue("")
    if(this.currentStartDate !== value){
      this.EventPlaceBooking.get('endhalf1').enable()
      this.EventPlaceBooking.get('endhalf2').enable()
    }else{
      this.EventPlaceBooking.get('endhalf1').disable()
      this.EventPlaceBooking.get('endhalf2').disable()
    }
    for(let ep of this.eventdetails){
      if(ep.enddate == value){
        console.log("getnewdata::",ep)
        if(ep.endhalf1 == "true" && ep.endhalf2 == "true" ){
          this.EventPlaceBooking.get('endhalf1').disable()
          this.EventPlaceBooking.get('endhalf2').disable()
          this.toastr.error("End Date is Already Booked")
        }else if(ep.endhalf1 == "true"){
          this.EventPlaceBooking.get('endhalf1').disable()
        }else if(ep.endhalf2 == 'true'){
          this.EventPlaceBooking.get('endhalf2').disable()
        }else if( ep.endhalf1 == "" && ep.endhalf2 == ""){
          this.EventPlaceBooking.get('endhalf1').disable()
          this.EventPlaceBooking.get('endhalf2').disable()
        }
      }
    }
  }

  updateEndDateFun(){
    console.log("getenddate::",this.currentEndDate)
    // this.currentEndDate = value 
    // this.EventPlaceBooking.controls['endhalf1'].setValue("")
    // this.EventPlaceBooking.controls['endhalf2'].setValue("")
    if(this.currentStartDate !==this.currentEndDate){
      this.EventPlaceBooking.get('endhalf1').enable()
      this.EventPlaceBooking.get('endhalf2').enable()
    }else{
      this.EventPlaceBooking.get('endhalf1').disable()
      this.EventPlaceBooking.get('endhalf2').disable()
    }
    for(let ep of this.eventdetails){
      if(ep.enddate == this.currentEndDate){
        console.log("getnewdata::",ep)
        if(ep.endhalf1 == "true" && ep.endhalf2 == "true"){
          this.EventPlaceBooking.get('endhalf1').disable()
          this.EventPlaceBooking.get('endhalf2').disable()
          this.toastr.error("End Date is Already Booked")
        }else if(ep.endhalf1 == "true"){
          this.EventPlaceBooking.get('endhalf1').disable()
        }else if(ep.endhalf2 == 'true'){
          this.EventPlaceBooking.get('endhalf2').disable()
        }else if( ep.endhalf1 == "" && ep.endhalf2 == ""){
          this.EventPlaceBooking.get('endhalf1').disable()
          this.EventPlaceBooking.get('endhalf2').disable()
        }
      }
    }
  }

  updateStartFun(){

  }
  updateEventDetails(){
    // this.EventPlaceBooking.controls['activeyn'].setValue("No")
    // this.EventPlaceBooking.controls['deleteyn'].setValue("Yes")
 
  }

  getEventSubPlace() {
    this.appService
      .getEventSubPlace(this.epIdDropDown)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("sub dropdown::", data);
        // @ts-ignore
        this.eventSubPlace = data;
        var localeventsubplace : string [] = [];
        for (var ep of this.eventSubPlace) {
          if (ep.epmainplace == "Yes") {
            localeventsubplace.push(ep.epsubname + " - Main Hall")
            console.log("main hall", this.eventSubDropDown)
          } else {
            localeventsubplace.push(ep.epsubname)
          }
          this.eventSubDropDown = localeventsubplace
        }
      },(error: any) => {
				console.log("Something went wrong");
			  })
  }

  onShoweventsub(event) {
    const value = event.target.value;
    console.log("Event Name::", value)
    for (var EPid of this.eventPlace) {
      if (value === EPid.epname) {
        this.epIdDropDown = EPid.epid;
        this.eventPlaceName = EPid.epname;
        this.eventEmail = EPid.emailid
        this.EventPlaceBooking.controls["eventemailid"].setValue(this.eventEmail)
        console.log("ep id::", this.epIdDropDown)
        console.log("EventemailId::", this.eventEmail)
        this.getEventSubPlace();
        this.isSubDisabled = true;
        this.disable1();
        this.EventPlaceBooking.enable()
      } else {
        this.isSubDisabled = false;
        console.log("ep id:: error")
      }
    }
  }

  saveType(){
    this.isVisible = false
  }

  disable1() {
    if (this.isSubDisabled) {
      this.EventPlaceBooking.controls['epsubid'].enable();
    } else {
      this.EventPlaceBooking.controls['epsubid'].disable();
    }
  }

  onEventDate(event) {
    const value = event.target.value.replace(" - Main Hall","");
    console.log("Eventsubplace Name::", value)
    for (var EPid of this.eventSubPlace) {
      if (value == EPid.epsubname) {
        this.eventSubId = EPid.epsubid;
        this.eventSubPlaceName = EPid.epsubname;
        console.log("ep id::", this.eventSubId)
      }
    }
    this.getRegisterDate();
  }

  getRegisterDate() {
    this.appService
      .getRegisterDate()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("EP User Dropdwon::", data);
        // @ts-ignore
        this.eventBooking = data;
        var localUser: string [] = [];
        var localstartdate: string[] = [];
        var localenddate: string[] = [];
        var localstartfh: string[] = [];
        var localstartef: string[] = [];
        var localendfh: string[] = [];
        var localendef: string[] = [];
        var localgetData : EventPlaceBooking[] = [];
        for (var ep of this.eventBooking) {
          if (ep.epid == this.epIdDropDown && ep.epsubid == this.eventSubId ) {
            if(ep.activeyn == "Yes"){
            localUser.push(ep.startdate, ep.enddate)
            localstartdate.push(ep.startdate)
            localstartfh.push(ep.starthalf1)
            localstartef.push(ep.starthalf2)
            localendfh.push(ep.endhalf1)
            localendef.push(ep.endhalf2)
            localenddate.push(ep.enddate)
            localgetData.push(ep)
            console.log("booking get date::", localgetData)
            }
          }
        }
        this.eventdetails = localgetData
        console.log("booking get date::", this.eventdetails)
        this.alreadyDate = localUser;
        this.startDate = localstartdate;
        this.endDate = localenddate;
        this.startfh = localstartfh;
        this.starteh = localstartef;
        this.endfh = localendfh;
        this.endeh = localendef;
        console.log("eventplace date::", this.alreadyDate)
        this.calendar();
      })
      this.calendar()
  }
  booking(){
    this.router.navigate(['eventplace-booking'])
  }

  sendotp(){
    this.EventPlaceBookingErrors.emailid = "";

    let hasError = false;
    
    if (this.EventPlaceBooking.get('emailid')?.invalid) {
      if (this.EventPlaceBooking.get('emailid').errors.required) {
        this.EventPlaceBookingErrors.emailid = "* Email id is required";
      }
      if (this.EventPlaceBooking.get('emailid').errors.pattern) {
        this.EventPlaceBookingErrors.emailid = "*Enter Valid Email.(Ex:example@gmail.com)";
      }
      hasError = true;
    }

    if (!hasError) {
      this.openOTP = true;
      this.email = this.EventPlaceBooking.get('emailid')?.value
      this.currentEmail.controls['email'].setValue(this.EventPlaceBooking.get('emailid')?.value)
      this.appService.sendEmailOtp(this.EventPlaceBooking.get('emailid').value)
        .pipe(takeUntil(this.destroy$))
        .subscribe(data => {
          console.log("Email OTP Sent Status:", data);
          // @ts-ignore
          this.otpResponse = data
          this.toastr.success("Email OTP was sent successfully")
          console.log("this.otpResponse::", this.otpResponse)
        }, (error: any) => {
          console.log("Something went wrong");
        });
    }
  }
  
  close(){
    this.openOTP = false
  }

  submitOtp(){
    let hasError = false;

    if (this.firstValue == "") {
        this.toastr.error("Please enter OTP")
        hasError = true;
    }
    if (this.secondValue == "") {
        this.toastr.error("Please enter OTP")
        hasError = true;
    }
    if (this.thirdValue == "") {
        this.toastr.error("Please enter OTP")
        hasError = true;
    }
    if (this.fourthValue == "") {
        this.toastr.error("Please enter OTP")
        hasError = true;
    }
    if(!hasError){
    console.log('Submitted OTP:', this.otpInput.get('otp')?.value);
    console.log('Submitted OTP1:', this.firstValue);
    this.currentEmail.controls['email'].setValue(this.EventPlaceBooking.get('emailid')?.value)
const otp = this.firstValue + this.secondValue + this.thirdValue + this.fourthValue
console.log('Submitted OTP:', otp);
    if (otp != null || otp != undefined || otp != "") {
      this.appService.verifyEmailOtp(this.EventPlaceBooking.get('emailid').value, otp)
        .pipe(takeUntil(this.destroy$))
        .subscribe(data => {
          console.log("OTP Verify status:", data)

          // @ts-ignore
          this.otpResponse = data;
          console.log("this.otpResponse:", this.otpResponse)
          if (this.otpResponse.success) {
            this.verifiedOTP = true;
            this.openOTP = false;
            this.toastr.success("OTP Verified");
          } else {
            this.toastr.error("OTP is Incorrect");
          }
        }, (error: any) => {
          console.log("Something went wrong");
        })

    } else {
      this.toastr.error("Please Enter the OTP");
    }
  }
}

  bookinglist(){
    this.router.navigate(['event-bookinglist'])
  }

  successAlert() {
    Swal.fire({
      title: "Your Booking Details Saved.",
      icon: "success",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    }).then((result) =>{
      if(result.isConfirmed) {
        this.router.navigate(["event-bookingarea"])
      }
    });
  }
  updateAlert() {
    Swal.fire({
      title: "Your Booking Details Updated Sucessfully.",
      icon: "success",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    }).then((result) =>{
      if(result.isConfirmed) {
        this.router.navigate(["event-bookingarea"])
      }
    });
  }
  errorAlert() {
    Swal.fire({
      title: "Sorry, Please Try Again Your Registration Process",
      icon: "error",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    });
  }
}
