import {Component, HostBinding} from '@angular/core';
import {DateTime} from 'luxon';
import packageInfo from '../../../../../package.json';

@Component({
  selector: 'app-event-footer',
  templateUrl: './event-footer.component.html',
  styleUrls: ['./event-footer.component.scss']
})
export class EventFooterComponent {
  public appVersion = packageInfo.version;
  public currentYear: string = DateTime.now().toFormat('y');
}
