import {Component, OnInit} from '@angular/core';
import {DateTime} from 'luxon';

@Component({
  selector: 'privacy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})

export class PrivacyPolicyComponent implements OnInit {
  public currentYear: string = DateTime.now().toFormat('y');

  ngOnInit() {
    window.scrollTo(0, 0);
  }
}
