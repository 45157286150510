import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { AppService } from "@services/app.service";
import { EventPlace } from '@modules/event-place-management/model/eventplace';
import { pipe, Subject, takeUntil } from "rxjs";
import Swal from "sweetalert2";
import { NgxSpinnerService } from "ngx-spinner";


@Component({
  selector: 'app-event-place-registration',
  templateUrl: './event-place-registration.component.html',
  styleUrls: ['./event-place-registration.component.scss']
})

export class EventPlaceRegistrationComponent implements OnInit {
  images: string = "";
  eventPlaceDetails: EventPlace[] = [];
  eventAutoDetails: EventPlace[] = [];
  eventDropdown: string[] = [];
  eventId: string | undefined;
  getemailId: string[] = [];
  testType: string | undefined;
  image: string = ""
  isUpdate = false;
  isVisible = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  fileList: File[] = [];
  listOfFiles: any[] = [];
  fileUploadStatus: string = "Exist";
  nextButton: boolean = false;
  formData = new FormData();
  selectedShape: string = 'square';
  isSquare = true;
  isRectangle = false;
  isCircle = false;


  constructor(private router: Router, private appService: AppService, private toastr: ToastrService,
    private spinner: NgxSpinnerService) {
  }

  eventPlace = new UntypedFormGroup({
    epid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epnickname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epimage: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    phonenumber: new UntypedFormControl('', [Validators.required, Validators.nullValidator, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
    emailid: new UntypedFormControl('', [Validators.required, Validators.nullValidator, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
    epdescription: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epaddress1: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epaddress2: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epnearbylandmark: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epcity: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    gstno: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    governmentregno: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epstate: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    eppincode: new UntypedFormControl('', [Validators.required, Validators.nullValidator, Validators.pattern("^((\\+91-?)|0)?[0-9]{6}$")]),
    epcountry: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    gst: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    subplaceavailable: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    activestatus: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epaircond: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    activeyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    deleteyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    createdby: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    eventregistrationno: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    pancardno: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    logotype: new UntypedFormControl('', [Validators.required, Validators.nullValidator])
  })

  eventSubPlace = new UntypedFormGroup({
    epid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epsubid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epsubname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epsubnickname: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epsubdesc: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epsubaircond: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    emailid: new UntypedFormControl('', [Validators.required, Validators.nullValidator, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
    phonenumber: new UntypedFormControl('', [Validators.required, Validators.nullValidator, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
    activestatus: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    activeyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    deleteyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    createdby: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epmainplace: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    subplacerequired: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
  })

  public eventplacementErrors = {
    epname: '',
    epnickname: '',
    epimage: '',
    phonenumber: '',
    emailid: '',
    //epdescription: '',
    epaddress1: '',
    epaddress2: '',
    epgstno: '',
    epgovtregno: '',
    epnearbylandmark: '',
    epcity: '',
    epstate: '',
    eppincode: '',
    epcountry: '',
    gst: '',
    eventregistrationno: '',
    pancardno: '',
    //activestatus: '',
    epaircond: '',
    subplaceavailable: '',
  }

  keyPress(event: any, field: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
      if (field == 'phone') {
        this.eventplacementErrors.phonenumber = "Please enter digit only.";
      }
      if (field == 'pincode') {
        this.eventplacementErrors.eppincode = "Please enter digit only.";
      }
    } else {
      if (field == 'phone') {
        this.eventplacementErrors.phonenumber = "";
      }
      if (field == 'pincode') {
        this.eventplacementErrors.eppincode = "";
      }
    }
  }


  ngOnInit() {
    this.isVisible = true;
    this.getEventPlaceByuserId();
    this.getEmail();
    this.eventPlace.controls['epcountry'].setValue("India")
    this.fileList = [];
  }

  saveType() {
    if (this.testType !== this.eventPlace.setValue['epid']) {
      this.isVisible = false;
    }
  }

  saveType1() {
    this.isVisible = false;
  }

  formValidation1() {
    this.eventplacementErrors.epname = "";
    this.eventplacementErrors.epnickname = "";
    this.eventplacementErrors.phonenumber = "";
    let hasError = false;

    if (this.eventPlace.get('epname')?.invalid) {
      this.eventplacementErrors.epname = "*Name is required";
      hasError = true;
    }
    if (this.eventPlace.get('epnickname')?.invalid) {
      this.eventplacementErrors.epnickname = "*Nick Name is required";
      hasError = true;
    }
    if (this.eventPlace.get('phonenumber')?.invalid) {
      this.eventplacementErrors.phonenumber = "*Phone Number is required";
      hasError = true;
    }


    if (!hasError) {
      this.saveEventPlace("Draft");
    }
  }

  /*EventPlace Logo file change function*/
  onSubmit() {
    this.formValidation();
  }

  onFileChange(event: any) {
    this.fileList = [];
    for (var i = 0; i <= event.target.files.length - 1; i++) {
      var selectedFile = event.target.files[i];
      console.log("select file size ::" + selectedFile);
      var fileSizeInMb = Math.round((selectedFile.size * 100) / (1024 * 1024)) / 100;
      console.log("select file convert size ::" + fileSizeInMb);
      if (fileSizeInMb <= 10) {
        if (event.target.files && event.target.files[0]) {
          var reader = new FileReader();
          reader.onload = (event: any) => {
            this.images = event.target.result;
            this.eventPlace.patchValue({});
          };
          reader.readAsDataURL(event.target.files[0]);
        }
        this.fileList.push(selectedFile);
        this.listOfFiles.push(selectedFile.name);
        this.fileUploadStatus = "Uploaded";
        console.log("File list::", this.fileList)
      } else {
        this.toastr.error("File size is large. Please upload below 10MP")
      }
    }
  }

  onShapeChanged(shape: string) {
    if (shape == "square") {
      this.isSquare = true;
      this.isCircle = false;
      this.isRectangle = false;
    } else if (shape == "rectangle") {
      this.isSquare = false;
      this.isCircle = false;
      this.isRectangle = true;
    } else if (shape == "circle") {
      this.isSquare = false;
      this.isCircle = true;
      this.isRectangle = false;
    }
  }

  onGSTNo(value: string) {
    if (value) {
      this.eventPlace.get('gst').enable();
    } else {
      this.eventPlace.get('gst').disable();
    }
  }

  /*EventPlace formvalidation function*/

  formValidation() {
    this.eventplacementErrors.epname = "";
    this.eventplacementErrors.epnickname = "";
    this.eventplacementErrors.epimage = "";
    this.eventplacementErrors.phonenumber = "";
    this.eventplacementErrors.emailid = "";
    //this.eventplacementErrors.epdescription = "";
    this.eventplacementErrors.epaddress1 = "";
    this.eventplacementErrors.epaddress2 = "";
    this.eventplacementErrors.epnearbylandmark = "";
    this.eventplacementErrors.epcity = "";
    // this.eventplacementErrors.epgstno = "";
    // this.eventplacementErrors.epgovtregno = "";
    this.eventplacementErrors.epstate = "";
    this.eventplacementErrors.eppincode = "";
    this.eventplacementErrors.epcountry = "";
    //this.eventplacementErrors.activestatus = "";
    this.eventplacementErrors.epaircond = "";
    // this.eventplacementErrors.gst = "";
    this.eventplacementErrors.subplaceavailable = "";
    // this.eventplacementErrors.eventregistrationno = "";
    // this.eventplacementErrors.pancardno = "";


    let hasError = false;

    let inputValue = this.eventPlace.controls['epname'].value;
    let outputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
    this.eventPlace.controls['epname'].setValue(outputValue)

    if (this.eventPlace.get('epname')?.invalid) {
      this.eventplacementErrors.epname = "*Name is required";
      hasError = true;
    }
    let inputValue1 = this.eventPlace.controls['epnickname'].value;
    let outputValue1 = inputValue1.charAt(0).toUpperCase() + inputValue1.slice(1);
    this.eventPlace.controls['epnickname'].setValue(outputValue1)

    if (this.eventPlace.get('epnickname')?.invalid) {
      this.eventplacementErrors.epnickname = "*Nick Name is required";
      hasError = true;
    }
    if (!this.isUpdate) {
      if (this.eventPlace.get('epimage')?.invalid) {
        this.eventplacementErrors.epimage = "*Logo is required";
        hasError = true;
      }
    }
    if (this.eventPlace.get('phonenumber')?.invalid) {
      if (this.eventPlace.get('phonenumber').errors.required) {
        this.eventplacementErrors.phonenumber = "*Phone Number is required";
      }
      if (this.eventPlace.get('phonenumber').errors.pattern) {
        this.eventplacementErrors.phonenumber = "*Please, Enter 10 digit Phone number.";
      }
      hasError = true;
    }
    var emailId = this.eventPlace.get('emailid').value
    if (!this.isUpdate) {
      if (this.getemailId.includes(emailId)) {
        this.eventplacementErrors.emailid = "*Email id is already existing";
        hasError = true;
      }
    }
    if (this.eventPlace.get('emailid')?.invalid) {
      if (this.eventPlace.get('emailid').errors.required) {
        this.eventplacementErrors.emailid = "* Email id is required";
      }
      if (this.eventPlace.get('emailid').errors.pattern) {
        this.eventplacementErrors.emailid = "*Enter Valid Email.(Ex:example@gmail.com)";
      }
      hasError = true;
    }

    let inputValue2 = this.eventPlace.controls['epdescription'].value;
    let outputValue2 = inputValue2.charAt(0).toUpperCase() + inputValue2.slice(1);
    this.eventPlace.controls['epdescription'].setValue(outputValue2)

    //         if (this.eventPlace.get('epdescription')?.invalid) {
    //             this.eventplacementErrors.epdescription = "*Description is required";
    //         }
    if (this.eventPlace.get('epaddress1')?.invalid) {
      this.eventplacementErrors.epaddress1 = "*Address1 is required";
      hasError = true;
    }
    if (this.eventPlace.get('epaddress2')?.invalid) {
      this.eventplacementErrors.epaddress2 = "*Address2 is required";
      hasError = true;
    }
    // if (this.eventPlace.get('gstno')?.invalid) {
    //   this.eventplacementErrors.epgstno = "*GST Number is required";
    //   hasError = true;
    // }
    // if (this.eventPlace.get('governmentregno')?.invalid) {
    //   this.eventplacementErrors.epgovtregno = "*Govt Reg No is required";
    //   hasError = true;
    // }
    if (this.eventPlace.get('epnearbylandmark')?.invalid) {
      this.eventplacementErrors.epnearbylandmark = "*Landmark is required";
      hasError = true;
    }
    let inputValue3 = this.eventPlace.controls['epcity'].value;
    let outputValue3 = inputValue3.charAt(0).toUpperCase() + inputValue3.slice(1);
    this.eventPlace.controls['epcity'].setValue(outputValue3)
    if (this.eventPlace.get('epcity')?.invalid) {
      this.eventplacementErrors.epcity = "*City is required";
      hasError = true;
    }
    let inputValue4 = this.eventPlace.controls['epstate'].value;
    let outputValue4 = inputValue4.charAt(0).toUpperCase() + inputValue4.slice(1);
    this.eventPlace.controls['epstate'].setValue(outputValue4)
    if (this.eventPlace.get('epstate')?.invalid) {
      this.eventplacementErrors.epstate = "*State is required";
      hasError = true;
    }
    if (this.eventPlace.get('eppincode')?.invalid) {
      if (this.eventPlace.get('eppincode').errors.required) {
        this.eventplacementErrors.eppincode = "*Pincode number is required";
      }
      if (this.eventPlace.get('eppincode').errors.pattern) {
        this.eventplacementErrors.eppincode = "*Please, Enter 6 digit numbers only.";
      }
      hasError = true;
    }
    if (this.eventPlace.get('epcountry')?.invalid) {
      this.eventplacementErrors.epcountry = "*Country is required";
      hasError = true;
    }
    // if (this.eventPlace.get('gst')?.invalid) {
    //   this.eventplacementErrors.gst = "*Gst is required";
    //   hasError = true;
    // }
    if (this.eventPlace.get('subplaceavailable')?.invalid) {
      this.eventplacementErrors.subplaceavailable = "*Sub Place is required";
      hasError = true;
    }
    //     if (this.eventPlace.get('activestatus')?.invalid) {
    //       this.eventplacementErrors.activestatus = "*Active field is required";
    //       hasError = true;
    //     }
    if (this.eventPlace.get('epaircond')?.invalid) {
      this.eventplacementErrors.epaircond = "*Air conditioned is required";
      hasError = true;
    }
    // if (this.eventPlace.get('eventregistrationno')?.invalid) {
    //   this.eventplacementErrors.eventregistrationno = "*Event Registration No is required";
    //   hasError = true;
    // }
    // if (this.eventPlace.get('pancardno')?.invalid) {
    //   this.eventplacementErrors.pancardno = "*Pancard No is required";
    //   hasError = true;
    // }


    if (!hasError) {
      this.saveEventPlace("Active");
    }

  }

  getEventPlaceByuserId() {
    this.appService
      .getEventPlaceByuserId(localStorage.getItem('token'))
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("Event place Dropdwon::", data);
        // @ts-ignore
        this.eventPlaceDetails = data;
        for (var ep of this.eventPlaceDetails) {
          this.eventDropdown.push(ep.epname)
        }
      })
  }

  getEventPlaceDetails() {
    this.spinner.show()
    console.log("getid::", this.eventId);
    this.appService
      .getEventPlaceDetails(this.eventId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("GetKitchen Details::", data);
        this.spinner.hide();
        // @ts-ignore
        this.eventAutoDetails = data;
        this.isUpdate = true;
        var localimage: string = ""
        for (var event of this.eventAutoDetails) {

          this.eventPlace.controls["epname"].setValue(event.epname);
          this.eventPlace.controls["epnickname"].setValue(event.epnickname);
          this.eventPlace.controls["logotype"].setValue(event.logotype);
          this.onShapeChanged(event.logotype)
          this.eventPlace.controls["phonenumber"].setValue(event.phonenumber);
          this.eventPlace.controls["emailid"].setValue(event.emailid);
          this.eventPlace.controls["epdescription"].setValue(event.epdescription);
          this.eventPlace.controls["epaddress1"].setValue(event.epaddress1);
          this.eventPlace.controls["epaddress2"].setValue(event.epaddress2);
          this.eventPlace.controls["epnearbylandmark"].setValue(event.epnearbylandmark);
          this.eventPlace.controls["epcity"].setValue(event.epcity);
          this.eventPlace.controls["epstate"].setValue(event.epstate);
          this.eventPlace.controls["eppincode"].setValue(event.eppincode);
          this.eventPlace.controls["epcountry"].setValue(event.epcountry);
          this.eventPlace.controls["gst"].setValue(event.gst);
          this.eventPlace.controls["subplaceavailable"].setValue(event.subplaceavailable);
          this.eventPlace.controls["activestatus"].setValue(event.activestatus);
          this.eventPlace.controls["epaircond"].setValue(event.epaircond);
          this.eventPlace.controls["gstno"].setValue(event.gstno);
          this.eventPlace.controls["governmentregno"].setValue(event.governmentregno);
          this.eventPlace.controls["eventregistrationno"].setValue(event.eventregistrationno);
          this.eventPlace.controls["pancardno"].setValue(event.pancardno);
          this.eventPlace.controls["epimage"].setValue(event.epimage);
          localimage = event.epimage
          console.log("logo::", localimage)
        }
        this.image = localimage
        console.log("logo1::", this.image)
      }, (error: any) => {
        console.log("Something went wrong");
        this.spinner.hide()
      })

  }

  /*EventPlace registration save and update API call*/
  saveEventPlace(status: string) {
    this.spinner.show()
    for (const file of this.fileList) {
      this.formData.append('file', file, file.name);
    }
    if (this.isUpdate) {
      this.eventPlace.controls["createdby"].setValue(localStorage.getItem("token"));
      this.eventPlace.controls["epid"].setValue(this.eventId)
      this.eventPlace.controls["activeyn"].setValue("Yes");
      this.eventPlace.controls["deleteyn"].setValue("No");
      this.eventPlace.controls["activestatus"].setValue(status);
      if (this.eventPlace.get("governmentregno").value == "" || this.eventPlace.get("gstno").value == null) {
        this.eventPlace.controls["governmentregno"].setValue("");
      }
      if (this.eventPlace.get("gstno").value == "" || this.eventPlace.get("gstno").value == null) {
        this.eventPlace.controls["gstno"].setValue("");
      }
      if (this.eventPlace.get("eventregistrationno").value == "" || this.eventPlace.get("eventregistrationno").value == null) {
        this.eventPlace.controls["eventregistrationno"].setValue("");
      }
      if (this.eventPlace.get("pancardno").value == "" || this.eventPlace.get("pancardno").value == null) {
        this.eventPlace.controls["pancardno"].setValue("");
      }
      console.log("update::", this.eventPlace.value)
      this.appService.updateEventPlace(this.eventPlace.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          this.spinner.hide()
          console.log("eventPlace ::", data);
          if (data.subplaceavailable == "No") {
            localStorage.setItem("subPlaceAvailable", "No")
          } else {
            localStorage.setItem("subPlaceAvailable", "Yes")
          }
          this.nextButton = true;
          if (data.activestatus == "Draft") {
            this.draftAlert();
          } else {
            this.UpdateAlert();
          }
          console.log("file list Value::", this.fileList.length)
          if (this.fileList.length > 0) {
            this.appService.logoFileUpload(this.formData, this.eventId)
              .pipe(takeUntil(this.destroy$))
              .subscribe(data => {
                console.log("File Upload Successfully")
              }, (error: any) => {
                console.log("Something went wrong. File not upload")
              })
          }
        }, (err: any) => {
          this.spinner.hide()
          this.errorAlert();
        },
          () => console.log('HTTP request completed.'));
    } else {
      this.eventPlace.controls["createdby"].setValue(localStorage.getItem("token"));
      this.eventPlace.controls["activeyn"].setValue("Yes");
      this.eventPlace.controls["deleteyn"].setValue("No");
      this.eventPlace.controls["activestatus"].setValue(status);
      if (this.eventPlace.get("governmentregno").value == "" || this.eventPlace.get("gstno").value == null) {
        this.eventPlace.controls["governmentregno"].setValue("");
      }
      if (this.eventPlace.get("gstno").value == "" || this.eventPlace.get("gstno").value == null) {
        this.eventPlace.controls["gstno"].setValue("");
      }
      if (this.eventPlace.get("eventregistrationno").value == "" || this.eventPlace.get("eventregistrationno").value == null) {
        this.eventPlace.controls["eventregistrationno"].setValue("");
      }
      if (this.eventPlace.get("pancardno").value == "" || this.eventPlace.get("pancardno").value == null) {
        this.eventPlace.controls["pancardno"].setValue("");
      }
      this.appService.saveEventPlace(this.eventPlace.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          this.spinner.hide()
          console.log("eventPlace ::", data);
          var eventData: EventPlace = data;
          if (this.eventPlace.get("subplaceavailable").value == "No") {
            localStorage.setItem("subPlaceAvailable", "No")
            this.eventSubPlace.controls["epsubname"].setValue(eventData.epname)
            this.eventSubPlace.controls["epsubnickname"].setValue(eventData.epnickname)
            this.eventSubPlace.controls["epsubdesc"].setValue(eventData.epdescription)
            this.eventSubPlace.controls["epsubaircond"].setValue(eventData.epaircond)
            this.eventSubPlace.controls["emailid"].setValue(eventData.emailid)
            this.eventSubPlace.controls["phonenumber"].setValue(eventData.phonenumber)
            this.eventSubPlace.controls["activestatus"].setValue(status);
            this.eventSubPlace.controls["createdby"].setValue(localStorage.getItem("token"));
            this.eventSubPlace.controls["epid"].setValue(eventData.epid);
            this.eventSubPlace.controls["activeyn"].setValue("Yes");
            this.eventSubPlace.controls["deleteyn"].setValue("No");
            this.eventSubPlace.controls['epmainplace'].setValue("Yes")
            console.log("event sub place details::", this.eventSubPlace.value)
            this.appService.saveSubPlace(this.eventSubPlace.value)
              .pipe(takeUntil(this.destroy$))
              .subscribe((data) => {
                console.log("eventSubPlace details::", data);
                // @ts-ignore
              }, (err: any) => {
                this.spinner.hide()
              },
                () => console.log('HTTP request completed.'));
          } else {
            localStorage.setItem("subPlaceAvailable", "Yes")
          }
          this.nextButton = true;
          if (data.activestatus == "Draft") {
            this.draftAlert();
          } else {
            this.successAlert();
          }
          this.appService.logoFileUpload(this.formData, eventData.epid)
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
              console.log("File Upload Successfully")
            }, (error: any) => {
              console.log("Something went wrong. File not upload")
            })
          // @ts-ignore
        }, (err: any) => {
          this.errorAlert();
        },
          () => console.log('HTTP request completed.'));
    }

  }

  /*EventPlace already save email id API function*/
  getEmail() {
    this.appService.getEmail()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("EPUserEmailid::", data);
        //@ts-ignore
        this.eventPlaceDetails = data;
        console.log("email::", this.eventPlaceDetails)
        for (var ep of this.eventPlaceDetails) {
          console.log("emailid::", ep)
          this.getemailId.push(ep.emailid)
          console.log("Testemailid::", this.getemailId)
        }
      })
  }

  /*EventPlace Dropdown Autofetch function*/
  onShowevent(event) {
    const value = event.target.value;
    console.log("Event Name::", value)
    for (var EPid of this.eventPlaceDetails) {
      if (value == EPid.epname) {
        this.eventId = EPid.epid;
        this.testType = EPid.epid;
        console.log("ep id::", this.eventId)
        console.log("ep id1::", this.testType)
        this.getEventPlaceDetails();
        this.getEventPlaceLogo();
      }
    }
  }

  getEventPlaceLogo() {
    this.appService.getEventPlaceLogo(this.eventId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: Blob) => {
        const imageBlob: Blob = data;
        const imageUrl = URL.createObjectURL(imageBlob);
        this.displayImage(imageUrl);
        console.log("Logo Get", imageBlob)
      }, (error: any) => {
        console.log("something went wrong", error)
      })
  }

  displayImage(imageUrl: string) {
    const imgElement = document.createElement('img');
    imgElement.src = imageUrl;
    console.log("imagesss::", imgElement)
    // this.images = imageUrl;
    const imagePlace = document.getElementById("imagesUrl")
    const existingImg = imagePlace.querySelector('img');
    if (existingImg) {
      imagePlace.removeChild(existingImg);
    }

    // Add the new img element
    imagePlace.appendChild(imgElement);
  }


  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  /*EventPlace successAlert popup messagse function*/
  successAlert() {
    Swal.fire({
      title: "Your Registration Sucessfully Completed.",
      text: "Welcome to EventPlace",
      icon: "success",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.eventPlace.reset();
        this.images = "";
      }
    });
  }

  /*EventPlace DraftAlert popup messagse function*/
  draftAlert() {
    Swal.fire({
      title: "Your Registration successfully saved as draft.",
      text: "Welcome to EventPlace",
      icon: "success",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.eventPlace.reset();
        this.images = "";
      }
    });
  }

  /*EventPlace UpdateAlert popup messagse function*/
  UpdateAlert() {
    Swal.fire({
      title: "Your Registration Updated Sucessfully.",
      icon: "success",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.eventPlace.reset();
        this.images = "";
      }
    });
  }

  /*EventPlace errorAlert popup messagse function*/
  errorAlert() {
    Swal.fire({
      title: "Sorry, Please Try Again Your Registration Process",
      icon: "error",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    });
  }

  Navigate() {
    if (localStorage.getItem("subPlaceAvailable") == "Yes") {
      this.router.navigate(["/main/sub-place"])
      localStorage.removeItem("subPlaceAvailable")
    } else if (localStorage.getItem("subPlaceAvailable") == "No") {
      this.router.navigate(["/main/rooms"])
      localStorage.removeItem("subPlaceAvailable")
    }
  }

}



