import { Component, OnInit, HostListener } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { AppService } from "@services/app.service";
import { FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Subject, pipe, takeUntil } from "rxjs";
import { DatePipe } from '@angular/common';
import { EventPaymentDetails } from "../model/event-payment-details";
import { EventAmount } from "../model/eventamount";
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";
import { EventPlace } from "@modules/event-place-management/model/eventplace";
import { BookingareaDataService } from "@modules/ep-booking-management/model/bookingareaDataService";
import { EventAreaIncidental } from "@modules/ep-booking-management/model/eventarea-incidental";
import { EventPlaceBooking } from "@modules/ep-booking-management/model/eventplace-booking";
import { NgxSpinnerService } from "ngx-spinner";
import { EventAreaMisc } from "../model/eventarea-misc";
import { EventAreaAmenities } from "../model/eventarea-amenities";
import { EventAreaActivity } from "../model/eventarea-activity";


@Component({
  selector: 'app-event-bookinpayment',
  templateUrl: './event-bookingpayment.component.html',
  styleUrls: ['./event-bookingpayment.component.scss']
})

export class EventBookingPaymentComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  eventAreaAmount: EventAmount[] = [];
  eventAutoDetails: EventPlace[] = [];
  gstcharges: string[] = [];
  totalPayment: number = 0;
  netPayment: number = 0;
  netPaymenttax: number = 0;
  localcgst: number = 0;
  localsgst: number = 0;
  totaltax: number = 0;
  cgst: number = 0;
  sgst: number = 0;
  roomdisable = false;
  parkingdisable = false;
  kitchendisable = false;
  openspacedisable = false;
  acitvitydisable = false;
  amenitydisable = false;
  incidentaldisable = false;
  miscdisable = false;
  otherdisable = true;
  isAmenities = false;
  isMisc = false;
  isActivity = false;
  isIncidental = false;
  startDate: string = "";
  startDateFirstHF: string = "";
  startDateSecondHF: string = "";
  endDate: string = "";
  endDateFirstHF: string = "";
  endDateSecondHF: string = "";
  isChanged = false;
  currentInc: EventAreaIncidental[] = [];
  updatePayment: EventPaymentDetails[] = [];
  savePayment: EventPaymentDetails[] = [];
  fetchPayments: EventPaymentDetails[] = [];
  currentEventData: EventPlaceBooking[] = [];
  // savepaymentdata: EventPaymentDetails | undefined;
  currentpaymentdata: EventPaymentDetails | undefined;
  curretntsavePayment : EventPaymentDetails | undefined
  eventId: string = "";
  epId: string = "";
  epSubId: string = "";
  miscDetails: EventAreaMisc[] = []
  amenitiedDetails: EventAreaAmenities[] = []
  activityDetails: EventAreaActivity[] = []
  getIncdetails: EventAreaIncidental[] = []
  amenityCharges: string = ""
  getMiscCharges: string = ""
  getActivityCharges: string = ""
  getIncidentalCharges: string = ""
  allAmenityCharges: string = ""
  otherCharges: string = ""
  isUpdate = false;
  endFirstHalf = false;
  endsecondHalf = false;
  getmailId: string = "";

  constructor(private router: Router, private appService: AppService,
    private toaster: ToastrService, private datePipe: DatePipe,
    private dataService: BookingareaDataService, private spinner: NgxSpinnerService) {
  }

  eventHallPaymentDetails = new FormGroup({
    eventhallpayment: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    roomcharges: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    kitchencharges: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    openspacecharges: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    parkingcharges: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    activitycharges: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    amenitiescharges: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    incidentalcharges: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    misccharges: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    totalcapacity: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    totalamountpay: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    cgst: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    sgst: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    totalinclusiveoftax: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    advancepaid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    netvaluetopay: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epsubid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    epid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    activeyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    deleteyn: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    createdby: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    mailid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    eventid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    modifiedby: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    othercharges: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    eventemailid: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
  })
  public eventHallPaymentDetailsError = {
    netvaluetopay: '',
    advancepaid: '',
  }

  ngOnInit() {
    if (this.dataService.bookingData) {
      this.spinner.show()
      this.isUpdate = true;
      // console.log("this.dataService.updateInc:", this.dataService.updateInc)
      console.log("Current Booking data::", this.dataService.bookingData)
      // @ts-ignore
      // this.currentInc = this.dataService.updateInc;
      // @ts-ignore
      this.currentEventData = this.dataService.bookingData;
      for (let event of this.currentEventData) {
        this.eventId = event.eventid;
        this.epId = event.epid;
        this.epSubId = event.epsubid;
        console.log("event Id:", this.eventId)
      }
      // this.eventId = localStorage.getItem('epEventId');
      // this.epId = localStorage.getItem('epId');
      // this.epSubId = localStorage.getItem('epSubId');
      for (let event of this.currentEventData) {
        const startDate = event.startdate;
        this.startDate = this.datePipe.transform(startDate, 'dd-MM-yyyy');
        const endDate = event.enddate
        this.endDate = this.datePipe.transform(endDate, 'dd-MM-yyyy');
        if (this.startDate == this.endDate) {
          if (event.starthalf1 == "true") {
            this.startDateFirstHF = "Yes"
            this.endDateFirstHF = "Yes"
          } else {
            this.startDateFirstHF = "No"
            this.endDateFirstHF = "No"
          } if (event.starthalf2 == "true") {
            this.startDateSecondHF = "Yes"
            this.endDateSecondHF = "Yes"
          } else {
            this.startDateSecondHF = "No"
            this.endDateSecondHF = "No"
          }
        } else {
          if (event.starthalf1 == "true") {
            this.startDateFirstHF = "Yes"
          } else {
            this.startDateFirstHF = "No"
          }
          if (event.starthalf2 == "true") {
            this.startDateSecondHF = "Yes"
          } else {
            this.startDateSecondHF = "No"
          }
          if (event.endhalf1 == "true") {
            console.log("True HF SF")
            this.endDateFirstHF = 'Yes'
          }
          else {
            this.endDateFirstHF = 'No'
          }
          if (event.endhalf2 == "true") {
            this.endDateSecondHF = "Yes"
          } else {
            this.endDateSecondHF = "No"
          }
          this.endsecondHalf = true
          this.endFirstHalf = true
        }
      }
      this.getAmenities()
      this.getMisc()
      this.getAcivityCharges()
      this.getIncidental()
      this.getAmountDetais()
      setTimeout(() => {
        this.getPaymentByEventId();
      }, 2000);
    } else if (localStorage.getItem('token')) {
      this.isChanged = true;
      // const reloadFlag = 'reloadFlag';
      // const hasReloaded = sessionStorage.getItem(reloadFlag);

      // if (!hasReloaded) {
      //   sessionStorage.setItem(reloadFlag, 'true');
      //   location.reload();
      // } else {
      //   sessionStorage.removeItem(reloadFlag);
      // }
      console.log("epId::", localStorage.getItem('epId'))
      console.log("epSubId::", localStorage.getItem('epSubId'))
      const startDate = localStorage.getItem('epStartDate');
      this.startDate = this.datePipe.transform(startDate, 'dd-MM-yyyy');
      const endDate = localStorage.getItem('epEndDate');
      this.endDate = this.datePipe.transform(endDate, 'dd-MM-yyyy');
      this.functionhalfdate();
      this.getEventPlaceDetails();
      this.eventId = localStorage.getItem('epEventId');
      this.epId = localStorage.getItem('epId');
      this.epSubId = localStorage.getItem('epSubId');

      this.getAmenities()
      this.getMisc()
      this.getAcivityCharges()
      this.getIncidental()
      this.getAmountDetais()
    }


  }

  ngOnDestroy() {
    if (this.fetchPayments.length !== 0) {
      this.dataService.paymentdata = this.currentpaymentdata;
      console.log("paymentssas")
    } else {
      this.dataService.savepaymentdata = this.curretntsavePayment;
    }
  }

  getPaymentByEventId() {
    this.spinner.show()
    this.appService.getPaymentDetails(this.eventId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        console.log("payment Details:", data)
        this.fetchPayments = data;
        this.spinner.hide()
        for (let pay of this.fetchPayments) {
          // this.eventHallPaymentDetails.controls['roomcharges'].setValue(pay.roomcharges)
          // this.eventHallPaymentDetails.controls['parkingcharges'].setValue(pay.parkingcharges)
          this.eventHallPaymentDetails.controls['othercharges'].setValue(pay.othercharges)
          // this.eventHallPaymentDetails.controls['kitchencharges'].setValue(pay.kitchencharges)
          // this.eventHallPaymentDetails.controls['openspacecharges'].setValue(pay.openspacecharges)
          this.eventHallPaymentDetails.controls['activitycharges'].setValue(Number(this.getActivityCharges))
          this.eventHallPaymentDetails.controls['amenitiescharges'].setValue(Number(this.amenityCharges))
          this.eventHallPaymentDetails.controls['incidentalcharges'].setValue(Number(this.getIncidentalCharges))
          this.eventHallPaymentDetails.controls['misccharges'].setValue(Number(this.getMiscCharges))
          this.eventHallPaymentDetails.controls['totalcapacity'].setValue(pay.totalcapacity)
          this.eventHallPaymentDetails.controls['netvaluetopay'].setValue(String(pay.netvaluetopay))
          this.eventHallPaymentDetails.controls['advancepaid'].setValue(Number(pay.advancepaid).toFixed(2))
          this.eventHallPaymentDetails.controls['totalamountpay'].setValue(String(pay.totalamountpay));
          this.eventHallPaymentDetails.controls['totalinclusiveoftax'].setValue(String(pay.totalinclusiveoftax));
          this.eventHallPaymentDetails.controls['cgst'].setValue(pay.cgst);
          this.eventHallPaymentDetails.controls['sgst'].setValue(pay.sgst);
          this.eventHallPaymentDetails.controls['eventhallpayment'].setValue(Number(pay.eventhallpayment));
          this.eventHallPaymentDetails.controls['mailid'].setValue(pay.mailid);
          this.getmailId = pay.mailid
          this.eventHallPaymentDetails.controls['epid'].setValue(pay.epid);
          this.eventHallPaymentDetails.controls['epsubid'].setValue(pay.epsubid);
          this.eventHallPaymentDetails.controls['eventid'].setValue(pay.eventid);
          this.eventHallPaymentDetails.controls['activeyn'].setValue(pay.activeyn);
          this.eventHallPaymentDetails.controls['deleteyn'].setValue(pay.deleteyn);
          this.eventHallPaymentDetails.controls['modifiedby'].setValue(localStorage.getItem('token'));

          // if (pay.roomcharges != "") {
          //   this.roomdisable = true;
          // }
          // if (pay.parkingcharges != "") {
          //   this.parkingdisable = true;
          // }
          // if (pay.kitchencharges != "") {
          //   this.kitchendisable = true;
          // }
          // if (pay.openspacecharges != "") {
          //   this.openspacedisable = true;
          // }
          const activityCharges = this.getActivityCharges;


          if (
            (typeof activityCharges === 'string' && activityCharges !== "") ||
            (typeof activityCharges === 'number' && activityCharges !== null && activityCharges !== 0)
          ) {
            this.acitvitydisable = true;
          }

          const amenityCharges = this.amenityCharges;
          if (
            (typeof amenityCharges === 'string' && amenityCharges !== "") ||
            (typeof amenityCharges === 'number' && amenityCharges !== null && amenityCharges !== 0)
          ) {
            this.amenitydisable = true;
          }


          if (localStorage.getItem('incidentalCharge') != "") {
            this.incidentaldisable = true;
          }

          const MiscCharges = this.getMiscCharges;

          if (
            (typeof MiscCharges === 'string' && MiscCharges !== "") ||
            (typeof MiscCharges === 'number' && MiscCharges !== null && MiscCharges !== 0)
          ) {
            this.miscdisable = true;
          }
          this.addamount();
        }
      }, (error: any) => {
        this.spinner.hide()
        console.log("Something went wrong");
      })
    this.spinner.hide()
  }

  functionhalfdate() {
    if (this.startDate == this.endDate) {
      if (localStorage.getItem('startFirstHF') == "true") {
        this.startDateFirstHF = "Yes"
        this.endDateFirstHF = "Yes"
      } else {
        this.startDateFirstHF = "No"
        this.endDateFirstHF = "No"
      } if (localStorage.getItem('startSecondHF') == "true") {
        this.startDateSecondHF = "Yes"
        this.endDateSecondHF = "Yes"
      } else {
        this.startDateSecondHF = "No"
        this.endDateSecondHF = "No"
      }
    } else {
      if (localStorage.getItem('startFirstHF') == "true") {
        this.startDateFirstHF = "Yes"
      } else {
        this.startDateFirstHF = "No"
      }
      if (localStorage.getItem('startSecondHF') == "true") {
        this.startDateSecondHF = "Yes"
      } else {
        this.startDateSecondHF = "No"
      }
      if (localStorage.getItem('endFirstHF') == "true") {
        console.log("True HF SF")
        this.endDateFirstHF = 'Yes'
      } else {
        this.endDateFirstHF = 'No'
      }
      if (localStorage.getItem('endSecondHF') == "true") {
        this.endDateSecondHF = "Yes"
      } else {
        this.endDateSecondHF = "No"
      }
      this.endsecondHalf = true
      this.endFirstHalf = true
    }
  }

  onSubmit() {
    this.formValidation();
  }
  formValidation() {
    this.eventHallPaymentDetailsError.netvaluetopay = "";
    this.eventHallPaymentDetailsError.advancepaid = "";
    let hasError = false;
    if (this.eventHallPaymentDetails.get('netvaluetopay')?.invalid) {
      this.eventHallPaymentDetailsError.netvaluetopay = "*NetValuePay is required";
      hasError = true;
    }
    if (this.eventHallPaymentDetails.get('advancepaid')?.invalid) {
      this.eventHallPaymentDetailsError.advancepaid = "*Advance paid is required";
      hasError = true;
    }
    if (!hasError) {
      this.saveEventPayment();
    }
  }

  getAmountDetais() {
    this.spinner.show()
    this.appService
      .getAmountDetails(localStorage.getItem('epId'), localStorage.getItem('epEventId'))
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide()
        console.log("Test data::", data);
        // @ts-ignore
        this.eventAreaAmount = data;
        console.log("evntAreaAMount::", this.eventAreaAmount)
        for (var ep of this.eventAreaAmount) {
          this.eventHallPaymentDetails.controls['roomcharges'].setValue(Number(ep.roomamount));
          this.eventHallPaymentDetails.controls['parkingcharges'].setValue(Number(ep.parkingamount))
          this.eventHallPaymentDetails.controls['kitchencharges'].setValue(Number(ep.kitchenamount))
          this.eventHallPaymentDetails.controls['openspacecharges'].setValue(Number(ep.openspaceamount))
          this.eventHallPaymentDetails.controls['activitycharges'].setValue(Number(this.getActivityCharges))
          this.eventHallPaymentDetails.controls['amenitiescharges'].setValue(Number(this.amenityCharges))
          this.eventHallPaymentDetails.controls['incidentalcharges'].setValue(Number(this.getIncidentalCharges))
          this.eventHallPaymentDetails.controls['misccharges'].setValue(Number(this.getMiscCharges))
          this.eventHallPaymentDetails.controls['totalcapacity'].setValue(localStorage.getItem('capacity'))
          if (ep.roomamount != "") {
            this.roomdisable = true;
          }
          if (ep.parkingamount != "") {
            this.parkingdisable = true;
          }
          if (ep.kitchenamount != "") {
            this.kitchendisable = true;
          }
          if (ep.openspaceamount != "") {
            this.openspacedisable = true;
          }
          const activityCharges = this.getActivityCharges;


          if (
            (typeof activityCharges === 'string' && activityCharges !== "") ||
            (typeof activityCharges === 'number' && activityCharges !== null && activityCharges !== 0)
          ) {
            this.acitvitydisable = true;
          }

          const amenityCharges = this.amenityCharges;
          if (
            (typeof amenityCharges === 'string' && amenityCharges !== "") ||
            (typeof amenityCharges === 'number' && amenityCharges !== null && amenityCharges !== 0)
          ) {
            this.amenitydisable = true;
          }

          if (ep.incidentalamount != "") {
            this.incidentaldisable = true;
          }

          const MiscCharges = this.getMiscCharges;

          if (
            (typeof MiscCharges === 'string' && MiscCharges !== "") ||
            (typeof MiscCharges === 'number' && MiscCharges !== null && MiscCharges !== 0)
          ) {
            this.miscdisable = true;
          }
        }
      }, (error: any) => {
        this.spinner.hide()
        console.log("Something went wrong");
      })
  }
  addamount() {
    const localhallPayment = (this.eventHallPaymentDetails.get('eventhallpayment').value);
    const localroomamount = this.eventHallPaymentDetails.get('roomcharges').value;
    const localparkingamount = this.eventHallPaymentDetails.get('parkingcharges').value;
    const localkitchenamount = this.eventHallPaymentDetails.get('kitchencharges').value;
    const localopenspaceamount = this.eventHallPaymentDetails.get('openspacecharges').value;
    const localacitvityamount = this.eventHallPaymentDetails.get('activitycharges').value;
    const localamenityamount = this.eventHallPaymentDetails.get('amenitiescharges').value;
    const localincidentalamount = this.eventHallPaymentDetails.get('incidentalcharges').value;
    const localmiscamount = this.eventHallPaymentDetails.get('misccharges').value;
    const localothercharges = this.eventHallPaymentDetails.get('othercharges').value;


    this.totalPayment = (Number(localhallPayment)) + Number(localparkingamount) + Number(localkitchenamount) + Number(localroomamount) +
      Number(localopenspaceamount) + Number(localacitvityamount) + Number(localamenityamount) + Number(localincidentalamount) + Number(localmiscamount) + Number(localothercharges);
    this.eventHallPaymentDetails.controls['totalamountpay'].setValue(this.totalPayment.toFixed(2));
    console.log("totalpayment::", this.totalPayment)
    if (this.dataService.bookingData != undefined) {
      this.cgst = Number(this.eventHallPaymentDetails.get('cgst').value);
      this.sgst = Number(this.eventHallPaymentDetails.get('sgst').value);
    } else {
      this.cgst = (this.totalPayment * Number(this.localcgst)) / 100;
      this.sgst = (this.totalPayment * Number(this.localcgst)) / 100;
      this.eventHallPaymentDetails.controls['cgst'].setValue(this.cgst)
      this.eventHallPaymentDetails.controls['sgst'].setValue(this.sgst)
    }
    console.log("cgst:", this.cgst)
    console.log("sgst:", this.sgst)
    console.log("total payment:", this.totalPayment)
    this.totaltax = this.totalPayment + this.cgst + this.sgst;
    console.log("total tax:", this.totaltax)
    this.eventHallPaymentDetails.controls['totalinclusiveoftax'].setValue((this.totaltax).toFixed(2));
    if (this.dataService.bookingData != undefined) {
      this.updateAdvCalculation();
    }

  }
  totalAmount(event) {
    this.addamount();

  }
  advancAmount(event) {
    const localadvances = this.eventHallPaymentDetails.get('advancepaid').value;
    this.netPayment = Number(this.totaltax) - Number(localadvances);
    this.eventHallPaymentDetails.controls['netvaluetopay'].setValue(this.netPayment.toFixed(2))
  }
  updateAdvCalculation() {
    const localadvances = this.eventHallPaymentDetails.get('advancepaid').value;
    this.netPayment = Number(this.totaltax) - Number(localadvances);
    this.eventHallPaymentDetails.controls['netvaluetopay'].setValue((this.netPayment.toFixed(2)))
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getAmenities() {
    this.appService.getAmenities(this.epId, this.epSubId, this.eventId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("eventAmnites::", data)
        //@ts-ignore
        this.amenitiedDetails = data;
        var localAmenit: string[] = []
        for (let ep of this.amenitiedDetails) {
          localAmenit.push(ep.qtyrequired)
          console.log("AmenitCharge::", localAmenit)
        }
        //@ts-ignore
        this.allAmenityCharges = localAmenit
        const totalValue = localAmenit
          .map(value => parseInt(value, 10))
          .reduce((sum, value) => sum + value, 0);
        console.log("Total Value:", totalValue);
        //@ts-ignore
        this.amenityCharges = totalValue
        console.log("Total Amenities Value:", this.amenityCharges);
      }, (error: any) => {
        console.log("Something went worng")
      },
        () => console.log('HTTP request completed.'))
  }




  getMisc() {
    this.appService.getMisc(this.epId, this.epSubId, this.eventId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("eventAmnites::", data)
        //@ts-ignore
        this.miscDetails = data;
        var localmisc: string[] = []
        for (let ep of this.miscDetails) {
          localmisc.push(ep.qtyrequired)
          console.log("MiscCharge::", localmisc)
        }
        const totalValue = localmisc
          .map(value => parseInt(value, 10))
          .reduce((sum, value) => sum + value, 0);
        console.log("Total Value:", totalValue);
        //@ts-ignore
        this.getMiscCharges = totalValue
        console.log("Total Misc Value:", this.getMiscCharges);
      }, (error: any) => {
        console.log("Something went worng")
      },
        () => console.log('HTTP request completed.'))
  }

  getAcivityCharges() {
    this.appService.getActivity(this.epId, this.epSubId, this.eventId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("eventActivity::", data)
        this.activityDetails = data
        var localAcitvity: string[] = []
        for (let ep of this.activityDetails) {
          localAcitvity.push(ep.qtyrequired)
          console.log("MiscCharge::", localAcitvity)
        }
        const totalValue = localAcitvity
          .map(value => parseInt(value, 10))
          .reduce((sum, value) => sum + value, 0);
        console.log("Total Value:", totalValue);
        //@ts-ignore
        this.getActivityCharges = totalValue
        console.log("Total Misc Value:", this.getActivityCharges);
      }, (error: any) => {
        console.log("Something went worng")
      },
        () => console.log('HTTP request completed.'))
  }

  getIncidental() {
    this.appService.getIncByEventId(this.eventId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("getIncidental::", data)
        //@ts-ignore
        this.getIncdetails = data
        var localincdental: string[] = []
        for (let ep of this.getIncdetails) {
          localincdental.push(ep.qtyrequired)
          console.log("MiscCharge::", localincdental)
        }
        const totalValue = localincdental
          .map(value => parseInt(value, 10))
          .reduce((sum, value) => sum + value, 0);
        console.log("Total Value:", totalValue);
        //@ts-ignore
        this.getIncidentalCharges = totalValue
        console.log("Total incidental Value:", this.getIncidentalCharges);
      }, (error: any) => {
        console.log("Something went worng")
      },
        () => console.log('HTTP request completed.'))
  }

  getEventPlaceDetails() {
    console.log("getid::", localStorage.getItem('epId'));
    this.appService
      .getEventPlaceDetails(localStorage.getItem('epId'))
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        console.log("GetKitchen Details::", data);
        // @ts-ignore
        this.eventAutoDetails = data;
        for (let ep of this.eventAutoDetails) {
          this.gstcharges.push(ep.gst)
          console.log("GstCharge::", this.gstcharges);
          this.localcgst = Number(this.gstcharges) / 2;
          this.localsgst = Number(this.gstcharges) / 2;
        }
        this.getAmountDetais();
      })
  }

  saveEventPayment() {
    this.spinner.show()
    if (this.fetchPayments.length !== 0) {
      this.eventHallPaymentDetails.controls["mailid"].setValue(this.getmailId);
      this.eventHallPaymentDetails.controls["activeyn"].setValue("Yes");
      this.eventHallPaymentDetails.controls["deleteyn"].setValue("No");
      this.eventHallPaymentDetails.controls["createdby"].setValue(localStorage.getItem("token"));
      this.eventHallPaymentDetails.controls["epid"].setValue(localStorage.getItem('epId'));
      this.eventHallPaymentDetails.controls["epsubid"].setValue(localStorage.getItem('epSubId'));
      this.eventHallPaymentDetails.controls["eventid"].setValue(localStorage.getItem('epEventId'))
      this.eventHallPaymentDetails.controls["eventemailid"].setValue(localStorage.getItem('eventEmailId'))
      console.log("values::", this.eventHallPaymentDetails.value)
      // @ts-ignore
      this.appService.updateEventPayment(this.eventHallPaymentDetails.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          //@ts-ignore
          this.updatePayment = data
          //@ts-ignore
          this.currentpaymentdata = this.updatePayment
          this.spinner.hide()
          console.log("save amount details::", data)
          this.UpdateAlert();
        }, (err: any) => {
          this.errorAlert();
          this.spinner.hide()
        },
          () => console.log('HTTP request completed.'));
    } else {
      this.spinner.show()
      this.eventHallPaymentDetails.controls["activeyn"].setValue("Yes");
      this.eventHallPaymentDetails.controls["deleteyn"].setValue("No");
      this.eventHallPaymentDetails.controls["createdby"].setValue(localStorage.getItem("token"));
      this.eventHallPaymentDetails.controls["epid"].setValue(localStorage.getItem('epId'));
      this.eventHallPaymentDetails.controls["epsubid"].setValue(localStorage.getItem('epSubId'));
      this.eventHallPaymentDetails.controls["eventid"].setValue(localStorage.getItem('epEventId'))
      this.eventHallPaymentDetails.controls["eventemailid"].setValue(localStorage.getItem('eventEmailId'))
      console.log("values::", this.eventHallPaymentDetails.value)
      //@ts-ignore
      this.appService.saveEventPayment(this.eventHallPaymentDetails.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          console.log("save amount details::", data)
          //@ts-ignore
          this.savePayment = data
          //@ts-ignore
          this.curretntsavePayment = this.savePayment
          this.spinner.hide()
          this.successAlert();
        }, (err: any) => {
          this.errorAlert();
          this.spinner.hide()
        },
          () => console.log('HTTP request completed.'));
      // window.location.reload();
      localStorage.removeItem('incidentalCharge')
    }
  }

  openAmenitiesCharge() {
    this.isAmenities = true
  }

  closeAmenities() {
    this.isAmenities = false
  }

  openMiscCharge() {
    this.isMisc = true
  }

  closeMisc() {
    this.isMisc = false
  }

  openActivity() {
    this.isActivity = true
  }

  closeActivity() {
    this.isActivity = false
  }

  openIncCharge() {
    this.isIncidental = true
  }

  closeIncCharges() {
    this.isIncidental = false
  }

  successAlert() {
    Swal.fire({
      title: "Payment Details Saved Sucessfully.",
      icon: "success",
      showDenyButton: true,
      confirmButtonColor: "#4fab69",
      denyButtonColor: "#c73636",
      confirmButtonText: "Print",
      denyButtonText: "Cencel",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem('eventEmailId')
        this.router.navigate(["event-booking-payment-print"])
      } else {
        this.router.navigate(["dash"])
      }
    });
  }

  UpdateAlert() {
    Swal.fire({
      title: "Payment Details Updated Sucessfully.",
      icon: "success",
      showDenyButton: true,
      confirmButtonColor: "#4fab69",
      denyButtonColor: "#c73636",
      confirmButtonText: "Print",
      denyButtonText: "Cencel",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem('eventEmailId')
        this.router.navigate(["event-booking-payment-print"])
      } else {
        this.router.navigate(["dash"])
      }
    });
  }

  errorAlert() {
    Swal.fire({
      title: "Sorry, Please Try Again Your Registration Process",
      icon: "error",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate_animated animate_fadeInDown",
      },
      hideClass: {
        popup: "animate_animated animate_fadeOutUp",
      },
    });
  }
}










