<div class="card card-outline card-primary">
  <div class="card-header text-center">
    <table class="table table-borderless">
      <tr>
        <div class="row" style="display: flex; align-items: center;">
          <img src="assets/img/logo1.png" style="height:60px;width:70px;">
          <h5 class="mb-0 m-2" style="text-align: center;font-weight: bold;">Event Place Management</h5>
        </div>
      </tr>
    </table>
    <!-- <img src="assets/img/logo1.png" style="height: 60px; width: 70px;">
    <a  class="h1"><b>E</b>vent <b>P</b>lace <b>M</b>anagement</a> -->
  </div>
  <div class="card-body">
    <p class="login-box-msg">Sign in to start your session</p>

    <form (ngSubmit)="onSubmit()" [formGroup]="LoginFrom">
      <div class="input-group mb-3">
        <input
          class="form-control"
          formControlName="epuseremailidpy"
          placeholder="Enter the email Id"
          type="text"
        />
        <div class="input-group-append">
          <div class="input-group-text">
            <span class="fas fa-envelope"></span>
          </div>
        </div>
      </div>
      <span class="text-danger" *ngIf="LoginErrors.epuseremailidpy">{{LoginErrors.epuseremailidpy}}</span>
      <div class="input-group mb-3">
        <input
          class="form-control"
          formControlName="epuserloginpassword" 
          placeholder="Enter the password"
          type="password"
        />
        <div class="input-group-append">
          <div class="input-group-text">
            <i class="toggle-password fa fa-lock-open" data-toggle="tooltip" data-placement="top"
               title="Click to Show Password" (click)="togglePasswordVisibility()"
               [ngClass]="{'fa-lock-open': isPasswordVisible, 'fa-lock': !isPasswordVisible}"
               aria-hidden="true"></i>
          </div>
        </div>
      </div>
      <span class="text-danger" *ngIf="LoginErrors.epuserloginpassword">{{LoginErrors.epuserloginpassword}}</span>
      <div class="text-center">
          <button type="submit" class="btn  btn-primary btn mr-2 rounded-0">Login</button>
      </div>
    </form>

    <div class="text-center mt-1">
    <label class="">Not a Member?
      <a (click)="registarion()" class="text-danger"  data-toggle="tooltip" data-placement="top"
      title="New User Registration Screen">
        Register
      </a>
    </label>
  </div>
  </div>
</div>
<ngx-spinner>
  <div class="custom-loader"></div>
</ngx-spinner>
