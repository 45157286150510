<div class="container-fuild sticky-top">
<app-event-header></app-event-header>
<nav class="p-2 bg-primary">
  <div class="row">
    <div class="col-md-2">
  <label class="text-dark">Function Booking Date:</label>
</div>
<div class="col-md-10">
  <label class="col-md-2">Start Date - {{startDate}}</label>
  <label class="col-md-2">First Half - {{startDateFirstHF}}</label>
  <label class="col-md-2">Second Half - {{startDateSecondHF}}</label>
  <label class="col-md-2">End Date - {{endDate}}</label>
  <label class="col-md-2" *ngIf="endFirstHalf">First Half - {{endDateFirstHF}}</label>
  <label class="col-md-2" *ngIf="endsecondHalf">Second Half - {{endDateSecondHF}}</label>
</div>
</div>
</nav>
</div>
  <form (ngSubmit)="onSubmit()" [formGroup]="eventRequired" id="eventRequired">
    <div class="container-fuild" *ngIf="Requireddisable">
      <div class="card container-md mt-3"style="background-color:#f4f6f9">
      <div class="row col-md-12">
          <div class="col-md-1"  *ngIf="roomsdetailsdisable"></div>
          <div class="col-md-4 mt-3"  *ngIf="roomsdetailsdisable">
            <div class="card ">
              <div class="card-header">
                <h5 style="text-align: center;">Room Required Details</h5>
              </div>
              <div class="card-body">
                <div class="form-group row">
                  <div class="col-md-6">
                    <label for="">Available Normal Rooms</label>
                  </div>
                  <div class="col-md-5">
                    <input type="text" class="roomsbox mr-1" value={{normalRooms}} disabled>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-6">
                    <label for="">Available AC Rooms</label>
                  </div>
                  <div class="col-md-5">
                    <input type="text" class="roomsbox mr-5" value={{acRooms}} disabled>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-md-6   col-form-label"><strong>NO.Of Normal Rooms</strong></label>
                  <div class="col-md-6 ">
                    <input type="text" (keypress)="isInputNumber($event)" class="form-control" id="normalrooms"
                      name="rooms" formControlName="no_normalrooms" (change)="roomsdisable($event)" placeholder=" "
                      maxlength="4">
                    <span *ngIf="eventbookingareaErrors.no_normalrooms"
                      class="error-text">{{eventbookingareaErrors.no_normalrooms}}</span>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-md-6  col-form-label"><strong>NO.Of AC Rooms</strong></label>
                  <div class="col-md-6">
                    <input type="text" (keypress)="isInputNumber($event)" class="form-control" id="acrooms"
                      name="acrooms" formControlName="no_acrooms" (change)="roomsdisable($event)" placeholder=" "
                      maxlength="4">
                    <span *ngIf="eventbookingareaErrors.no_acrooms"
                      class="error-text">{{eventbookingareaErrors.no_acrooms}}</span>
                  </div>
                </div>

              </div>

            </div>
          </div>
          <div class="col-md-1"  *ngIf="roomsdetailsdisable"></div>
         <!-----------------------------------------------------Card-2------------------------------------------------->
          <div class="col-md-1" *ngIf="parkingdetailsdisable"></div>
          <div class="col-md-4 mt-3"  *ngIf="parkingdetailsdisable">
            <div class="card ">
              <div class="card-header">
                <h5 style="text-align: center;">Parking Details </h5>
              </div>
              <div class="card-body">
                <div class="form-group row">
                  <label class="col-7  col-form-label"><strong>Parking Details Required</strong></label>
                  <div class="form-check mt-1">
                    <input class="form-check-input" type="radio" formControlName="parkingrequired" value="Yes"
                      (change)="parkingdisable($event)">
                    <label class="form-check-label">Yes</label>
                  </div>
                  <div class="form-check mt-1">
                    <input class="form-check-input ml-1" type="radio" formControlName="parkingrequired" value="No"
                      (change)="parkingdisable($event)">
                    <label class="form-check-label ml-4">No</label>
                  </div>
                </div>
                <div class="row mt-5"></div>
                <div class="form-group row">
                  <label class="col col-form-label"><strong>Select Parking </strong></label>
                  <div class="col-md-6">
                    <select class="form-control" formControlName="selectparking">
                      <option value="" disabled selected hidden>Select Parking</option>
                      <option *ngFor="let parms of eventParkingDropDown" value="{{parms}}">{{parms}}</option>
                    </select>
                    <span *ngIf="eventbookingareaErrors.selectparking"
                      class="error-text">{{eventbookingareaErrors.selectparking}}</span>
                  </div>
                </div>
                <div class="form-group row mt-4">
                  <div class="col-2"></div>
                  <!-- <div class="form-check col-8">
                    <input class="form-check-input" type="checkbox" value="twowheeler" formControlName="two_wheelar"
                      required>
                    <label class="form-check-label ">2 Wheeler Parking</label>

                  </div> -->
                </div>
                <div class="form-group row mt-4">
                  <!-- <div class="col-2"></div>
                  <div class="form-check col-8">
                    <input class="form-check-input " type="checkbox" value="fourwheeler" formControlName="four_wheelar"
                      required>
                    <label class="form-check-label ">4 Wheeler Parking</label>
                  </div> -->
                </div>

              </div>
              <div class="row mt-4"></div>
            </div>
           
        </div>
        <div class="col-md-1"  *ngIf="parkingdetailsdisable"></div>
        <!-----------------------------------------------------Card-3------------------------------------------------->
        <div class="col-md-1"  *ngIf="kitchendetailsdisable"></div>
          <div class="col-md-4 mt-3" *ngIf="kitchendetailsdisable">
            <div class="card ">
              <div class="card-header">
                <h5 style="text-align: center;">Kitchen Details </h5>
              </div>
              <div class="card-body">
                <div class="form-group row">
                  <label class="col-7  col-form-label"><strong>Kitchen Details Required</strong></label>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" formControlName="kitchenrequired"
                      (change)="kitchendisable($event)" value="Yes">
                    <label class="form-check-label">Yes</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" formControlName="kitchenrequired"
                      (change)="kitchendisable($event)" value="No">
                    <label class="form-check-label">No</label>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-7 col-form-label"><strong>Select Kitchen </strong></label>
                  <div class="col-md-5">
                    <select class="form-control" formControlName="selectkitchen">
                      <option value="" disabled selected hidden>Select Kitchen</option>
                      <option *ngFor="let parms of eventKitchenDropDown" value="{{parms}}">{{parms}}</option>
                    </select>
                    <span *ngIf="eventbookingareaErrors.selectkitchen"
                      class="error-text">{{eventbookingareaErrors.selectkitchen}}</span>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-7 col-form-label"><strong>Stove Required</strong></label>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" formControlName="stoverequired" value="yes">
                    <label class="form-check-label">Yes</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" formControlName="stoverequired" value="no">
                    <label class="form-check-label">No</label>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-7  col-form-label"><strong> Grinders Required</strong></label>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" formControlName="grainderrequired" value="yes">
                    <label class="form-check-label">Yes</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" formControlName="grainderrequired" value="no">
                    <label class="form-check-label">No</label>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-7  col-form-label"><strong>Fridge Required</strong></label>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" formControlName="fridgerequired" value="yes">
                    <label class="form-check-label">Yes</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" formControlName="fridgerequired" value="no">
                    <label class="form-check-label">No</label>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-7 col-form-label"><strong>Washing Required</strong></label>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" formControlName="washingrequired" value="yes">
                    <label class="form-check-label">Yes</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" formControlName="washingrequired" value="no">
                    <label class="form-check-label">No</label>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="col-md-1" *ngIf="kitchendetailsdisable"></div>
          <!---------------------------------------------------Card-4---------------------------------------------------->
          <div class="col-md-1" *ngIf="openSpacedisable"></div>
          <div class="col-md-4 mt-2" *ngIf="openSpacedisable">
            <div class="card ">
              <div class="card-header">
                <h5>Open Space Details </h5>
              </div>
              <div class="card-body">
                <div class="form-group row">
                  <label class="col-7 col-form-label"><strong>Open Space Required</strong></label>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" formControlName="openspacerequired" value="Yes"
                      (change)="openspacedisable($event)">
                    <label class="form-check-label">Yes</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" formControlName="openspacerequired" value="No"
                      (change)="openspacedisable($event)">
                    <label class="form-check-label">No</label>
                  </div>
                </div>
                <div class="row mt-4"></div>
                <div class="form-group row">
                  <label class="col-md-5 mt-4  col-form-label"><strong>select Open Space </strong></label>
                  <div class="col-md-6 mt-4">
                    <select class="form-control" id="Opendetailes" formControlName="selectopenspace">
                      <option value="none">select Open Space</option>
                      <option *ngFor="let Parms of openSpace" value="{{Parms}}">{{Parms}}</option>
                    </select>
                    <span *ngIf="eventbookingareaErrors.selectopenspace"
                      class="error-text">{{eventbookingareaErrors.selectopenspace}}</span>
                  </div>
                </div>
                <div class="row mt-4"></div>
                <!-- <div class="form-group row">
                  <label class="col-md-5 mt-5  col-form-label"><strong> Space Details</strong></label>
                  <div class="col-md-6">
                    <textarea rows="3" class="form-control rounded-1 mt-4" cols="15"
                      formControlName="spacedetails"></textarea>
                  </div>
                </div> -->
                <div class="row mt-5"></div>
                <div class="row mt-4"></div>
              </div>
            </div>
          </div>
          <div class="col-md-1" *ngIf="openSpacedisable"></div>
        <div class="row col-12">
          <div class="col-5"></div>
          <div class="card-body">
            <button type="submit" [style.display]="isVisible ? 'none' : 'hidden'"  [disabled]="saveButtoReq == false" class="btn btn-primary">{{this.isUpdate == true ? "Update" :
              "Save"}}</button>
          </div>
        </div>
    </div>
  </div>
    </div>
  </form>

  <!---------------------------------------------------Card-5----------------------------------------------------->
<div class="conatainer-fuild">
  <div class="row col-md-12" >
  <div class="col-md-1" *ngIf="activitydetailsdisable"></div>
    <div class="col-md-5 mt-2" *ngIf="activitydetailsdisable">
      <form (ngSubmit)="onSubmit()" [formGroup]="eventAreaActivity" id="eventAreaActivity">
        <div class="card ">
          <div class="card-header">
            <div class="row ">
              <h5 style="width: 80% !important;">
                Activity Details
              </h5>
              <h5 style="width: 10% !important;" >
                <i class="fa fa-plus" (click)="addVendor();"></i>
              </h5>
            </div>
          </div>
          <div class="card-body">


            <div id="activity">
              <div id="vendorcharges"></div>
              <div class="form-group row">
                <label class="col-md-6  col-form-label"><strong>Activity Required</strong></label>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" formControlName="activityrequired" id="activityrequired"
                    value="Yes" (change)="activitydisable($event);">
                  <label class="form-check-label">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" formControlName="activityrequired" id="activityrequired"
                    value="No" (change)="activitydisable($event);">
                  <label class="form-check-label">No</label>
                </div>
              </div>
              <!-- <div class="form-group row">
                <div class="form-group row" *ngFor="let vendor of activityDetails">
                  <label class="col-md-3  col-form-label"><strong>Activity Name</strong></label>
                  <div class="col-md-3">
                    <input type="text" class="form-control" value="{{vendor.selectactivity}}"
                    (change)="selectActivityValue(vendor,$event)" placeholder=" ">
                  </div>
                  <label class="col-md-3  col-form-label"><strong>Vendor Name</strong></label>
                  <div class="col-md-3">
                    <input type="text" class="form-control" value="{{vendor.selectvendor}}"
                      (change)=" selectVendorValue(vendor,$event)">
                  </div>
                </div>
              </div> -->
              <div>
                <div class="form-group row" *ngFor="let vendor of multiActivity;let i = index;">
                  <label class="col-md-3   col-form-label"><strong>Select Activity </strong></label>
                  <div class="col-md-3">
                    <select class="form-control" id="actdetailes1" (change)="selectActivityValue(vendor,$event)" formControlName="selecteactivityValue{{i}}">
                      <option value="" selected>Select Activity</option>
                      <option *ngFor="let parms of ActivityDetails" value="{{parms.epactivityname}}">
                        {{parms.epactivityname}}</option>
                    </select>
                  </div>
                  <label class="col-md-3   col-form-label"><strong>Select Vendor </strong></label>
                  <div class="col-md-3 ">
                    <select class="form-control" id="Vendordrop1" (change)="selectVendorValue(vendor,$event)" formControlName="selectedvendorValue{{i}}">
                      <option value="" selected>Select Vendor</option>
                      <option *ngFor="let parms of eventVendorPlace" value="{{parms.epvendorname}}">
                        {{parms.epvendorname}}</option>
                    </select>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="row">
            <div class="card-body text-center">
              <!-- <button type="button" [style.display]="isVisibleAct ? 'none' : 'hidden'"  [disabled]="saveButton == false" class="btn btn-primary"
                (click)="saveEventAreaActivity()">save</button> -->
                <button type="button" [style.display]="isVisibleAct ? 'none' : 'hidden'"  [disabled]="saveButton == false" class="btn btn-primary" 
                (click)="saveEventAreaActivity()">{{this.isActivityUpdate == true ? "Update" :  "Save"}}</button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!---------------------------------------------------Card-6----------------------------------------------------->
    <div class="col-md-1" *ngIf="amintiesdetailsdiable"></div>
    <div class="col-md-5 mt-2" *ngIf="amintiesdetailsdiable">
      <form (ngSubmit)="onSubmit()" [formGroup]="eventAreaAmenities" id="eventAreaAmenities">
        <div class="card ">
          <div class="card-header">
            <div class="row ">
              <h5 style="width: 80% !important;">
                Amenities Details
              </h5>
              <h5 style="width: 10% !important;" >
                <i class="fa fa-plus" (click)="addAmenities()"></i>
              </h5>
            </div>
          </div>
          <div class="card-body">
            <div id="amenities ">
              <div class="form-group row">
                <label class="col-md-7 col-form-label"><strong>Amenities Required </strong></label>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" formControlName="amenitiesrequired" value="Yes"
                    (change)="amenitiesdisable($event)">
                  <label class="form-check-label">Yes</label>
                </div>

                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" formControlName="amenitiesrequired" value="No"
                    (change)="amenitiesdisable($event)">
                  <label class="form-check-label">No</label>
                </div>

              </div>
              <!-- <div class="">
                <div class="form-group row" *ngFor="let amt of this.amenitiedDetails">
                  <label class="col-md-6  col-form-label"><strong>Amenities Name</strong></label>
                  <div class="col-md-5">
                    <input type="text" class="form-control" value="{{amt.selectamenities}}"
                    (change)="selectActivityValue(amt,$event)" placeholder=" ">
                  </div>
                </div>
              </div> -->
              <div class="form-group row mt-3" *ngFor="let amenities of multiAmenities;let i = index">
                <label class="col-md-6  col-form-label"><strong>select Amenities </strong></label>
                <div class="col-md-5">
                  <select class="form-control" id="Amenitiesdrop1" (change)="selectAmenitiesValue(amenities,$event)" formControlName="selectedamenitiesValue{{i}}">
                    <option value="" disabled selected >Select Aminities</option>
                    <option *ngFor="let parms of AminitiesDetails" value="{{parms.epgenamenitiesname}}">
                      {{parms.epgenamenitiesname}}</option>
                  </select>
                </div>
              </div>
              <div id="amenities"></div>
              <div class="row">
                <div class="card-body text-center">
                  <!-- <button type="button" [style.display]="isVisibleAmt ? 'none' : 'hidden'"  [disabled]="amenitSaveButton == false" class="btn btn-primary"
                    (click)="saveEventAreaAmenities()">save</button> -->
                    <button type="button" [style.display]="isVisibleAmt ? 'none' : 'hidden'"  [disabled]="amenitSaveButton == false" class="btn btn-primary" 
                (click)="saveEventAreaAmenities()">{{this.isAmenitiesUpdate == true ? "Update" :  "Save"}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

  <!--------------------------------------------------Card-7------------------------------------------------------>
  <div class="col-md-1" *ngIf="incidentaldetailsdisable"></div>
    <div class="col-md-5 mt-2" *ngIf="incidentaldetailsdisable">
      <form (ngSubmit)="onSubmit()" [formGroup]="eventAreaIncidental" id="eventAreaIncidental">
        <div class="card ">
          <div class="card-header">
            <div class="row ">
              <h5 style="width: 80% !important;">
                Incidental Charges Details
              </h5>
              <h5 style="width: 10% !important;" *ngIf="addNewIncidental">
                <i class="fa fa-plus " (click)="addIncidental()"></i>
              </h5>
            </div>
          </div>
          <div class="card-body">
            <div class="form-group row">
              <label class="col-md-7  col-form-label"><strong>Incidental Charges Required</strong></label>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio"  formControlName="incchargerequired"
                  value="Yes" (change)="incidentaldisable($event); ">
                <label class="form-check-label">Yes</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio"  formControlName="incchargerequired"
                  value="No" (change)="incidentaldisable($event)">
                <label class="form-check-label">No</label>
              </div>
            </div>
            <div class="form-group row" *ngFor="let incid of multiIncidental;let i = index">
              <label class="col-md-2  col-form-label"><strong>select Inc Charges </strong></label>
              <div class="col-md-2">
                <select class="form-control"  (change)="selectincidentalValue(incid,$event)" formControlName="selectIncidental{{i}}">
                  <option value="" disabled selected>Select Inc-Charges</option>
                  <option *ngFor="let parms of IncidentChargesDetails" value="{{parms.epincidentalname}}">
                    {{parms.epincidentalname}}</option>
                </select>
              </div>
              <label class="col-md-2  col-form-label"><strong>Starting Unit</strong></label>
              <div class="col-md-2">
                <input type="text" (keypress)="isInputNumber($event)" class="form-control" [(ngModel)]="incid.startingunit"
                  (change)=" selectIncidentalStartingUnitValue(incid,$event)" placeholder=" " maxlength="4"  formControlName="selectStartingunit{{i}}">
              </div>
              <label class="col-md-2  col-form-label"><strong>Ending Unit </strong></label>
              <div class="col-md-2">
                <input type="text" (keypress)="isInputNumber($event)" class="form-control" [(ngModel)]="incid.endingunit"
                  (change)=" selectIncidentalEndingUnitValue(incid,$event)" placeholder=" " maxlength="4"  formControlName="selectEndingunit{{i}}">
              </div>
              <!-- <label class="col-md-3 col-form-label"><strong>Qty Required </strong></label>
              <div class="col-md-2">
                <input type="text" (keypress)="isInputNumber($event)" class="form-control"
                  (change)=" selectIncidentalQtyValue(incid,$event)" placeholder=" " maxlength="4">
              </div> -->
            </div>
            <!-- <div class="form-group row">
              <label class="col-md-4  col-form-label"><strong>select Inc Charges </strong></label>
              <div class="col-md-3">
                <select class="form-control" id="Incidentaldrop2" formControlName="selectinccharges">
                  <option *ngFor="let parms of IncidentCharges" value="{{parms}}">{{parms}}</option>
                  <option>select Inc Charges</option>
                </select>
              </div>
              <label class="col-md-3 col-form-label"><strong>Qty Required </strong></label>
              <div class="col-md-2">
                <input type="text" (keypress)="isInputNumber(event)" class="form-control" formControlName="qtyrequired"
                       placeholder=" " maxlength="4">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-4  col-form-label"><strong>select Inc Charges </strong></label>
              <div class="col-md-3">
                <select class="form-control" id="Incidentaldrop3" formControlName="selectinccharges">
                  <option *ngFor="let parms of IncidentCharges" value="{{parms}}">{{parms}}</option>
                  <option>select Inc Charges</option>
                </select>
              </div>
              <label class="col-md-3 col-form-label"><strong>Qty Required </strong></label>
              <div class="col-md-2">
                <input type="text" (keypress)="isInputNumber(event)" class="form-control" formControlName="qtyrequired"
                       placeholder=" " maxlength="4">
              </div>
            </div> -->
            <div id="incidental"></div>
            <div class="row">
              <div class="card-body text-center">
                <button type="button" [style.display]="isVisibleInc ? 'none' : 'hidden'"  [disabled]="incidentalSaveButton == false" class="btn btn-primary"
                  (click)="saveEventAreaIncidental()">{{this.isUpdateinc == true ? "Update" : "Save"}}</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
<!--------------------------------------------------Card-7.1------------------------------------------------------>

    <!-- <div class="col-md-1" *ngIf="incidentaldetailsdisable1"></div>
    <div class="col-md-5 mt-2" *ngIf="incidentaldetailsdisable1">
      <form (ngSubmit)="onSubmit()" [formGroup]="eventAreaIncidental" id="eventAreaIncidental">
        <div class="card ">
          <div class="card-header">
            <div class="row ">
              <h5 style="width: 80% !important;">
                Incidental Charges Details
              </h5>
            </div>
          </div>
          <div class="card-body">
            <div class="form-group row" *ngFor="let incid of multiIncidental">
              <label class="col-md-2  col-form-label"><strong>select Inc Charges </strong></label>
              <div class="col-md-2">
                <input type="text" class="form-control" value="{{incid.selectinccharges}}"
                (change)="selectincidentalValue(incid,$event)" placeholder=" ">
              </div>
              <label class="col-md-2  col-form-label"><strong>Starting Unit</strong></label>
              <div class="col-md-2">
                <input type="text" (keypress)="isInputNumber($event)" class="form-control" value="{{incid.startingunit}}"
                  (change)=" selectIncidentalStartingUnitValue(incid,$event)" placeholder=" " maxlength="4">
              </div>
              <label class="col-md-2  col-form-label"><strong>Ending Unit </strong></label>
              <div class="col-md-2">
                <input type="text" (keypress)="isInputNumber($event)" class="form-control" value="{{incid.endingunit}}"
                  (change)=" selectIncidentalEndingUnitValue(incid,$event)" placeholder=" " maxlength="4">
              </div>
            </div>
            <div id="incidental"></div>
            <div class="row">
              <div class="card-body text-center">
                <button type="button" [disabled]="incidentalSaveButton == false" class="btn btn-primary"
                  (click)="updateincdetails()">Update</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div> -->
    <!--------------------------------------------------------Card-8----------------------------------------------->
    <div class="col-md-1" *ngIf="miscdetailsdisable"></div>
    <div class="col-md-5 mt-2" *ngIf="miscdetailsdisable">
      <form (ngSubmit)="onSubmit()" [formGroup]="eventAreaMisc" id="eventAreaMisc">
        <div class="card ">
          <div class="card-header">
            <div class="row ">
              <h5 style="width: 80% !important;">
                Misc Charges Details
              </h5>
              <h5 style="width: 10% !important;">
                <i class="fa fa-plus " (click)="addMisc()"></i>
              </h5>
            </div>
          </div>
          <div class="card-body">
            <div class="form-group row">
              <label class="col-md-7  col-form-label"><strong>Misc Charges Required</strong></label>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" formControlName="mischargesreq" value="Yes"
                  (change)="miscdisable($event)">
                <label class="form-check-label">Yes</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" formControlName="mischargesreq" value="No"
                  (change)="miscdisable($event)">
                <label class="form-check-label">No</label>
              </div>
            </div>
            <!-- <div class="">
              <div class="form-group row" *ngFor="let misc of getmiscDetails">
                <label class="col-md-6  col-form-label"><strong>Miscellaneous Name</strong></label>
                <div class="col-md-1"></div>
                <div class="col-md-5">
                  <input type="text" class="form-control" value="{{misc.selectmischarges}}"
                  (change)="selectMiscValue(misc,$event)" placeholder=" ">
                </div>
              </div>
            </div> -->
            <div class="form-group row" *ngFor="let misc of multiMisc;let i = index">
              <label class="col-md-6  col-form-label"><strong>select Misc Charges </strong></label>
              <div class="col-md-5">
                <select class="form-control" (change)="selectMiscValue(misc,$event)" formControlName="selectedValue{{i}}">
                  <option value="" disabled selected>Select Misc Charges</option>
                  <option *ngFor="let parms of eventMiscChargePlace" value="{{parms.epmiscellaneouschargename}}">
                    {{parms.epmiscellaneouschargename}}</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="card-body text-center">
                <!-- <button type="button" [style.display]="isVisibleMisc ? 'none' : 'hidden'"  [disabled]="miscSaveButton == false" class="btn btn-primary"
                  (click)="saveEventAreaMisc()">save</button> -->
                  <button type="button" [style.display]="isVisibleMisc ? 'none' : 'hidden'"  [disabled]="miscSaveButton == false" class="btn btn-primary" 
                  (click)="saveEventAreaMisc()">{{this.isMiscsUpdate == true ? "Update" :  "Save"}}</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
    <!-- <div class="col-md-2"></div>
    <div class="col-md-2">
      <button type="sumbit" class="btn btn-success btn-circle"><i class='fas fa-arrow-right'
                                                                  style='font-size:30px; color:white;'></i></button>
    </div> -->
    <div class="form-group row col-md-12 mt-3">
      <div class="col-md-4"></div>
      <div class="col-md-3 text-center">
        <button type="button" class="btn btn-success" (click)="saveCharges()" *ngIf="paymentdiable">
          {{this.isChanged == true ? "Return ToLogin Page " : "Proceed To Payment"}}</button>
      </div>  
    </div>

  <div class="row mt-5"></div>
    <!-- <div class="text-center">
      <button type="button" class="btn btn-success" (click)="navigate()" *ngIf="paymentUpdate">Proceed To Payment</button>
    </div> -->
 
    <div class="form-group row col-md-12 mt-3">
      <div class="col-md-4"></div>
      <div class="col-md-3 text-center">
      </div>
    </div>
    <div [style.display]="isVisibleAlert ? 'block' : 'none'" class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <button class="btn btn-secondary">
          <h4 class="modal-title mt-3" style="text-align: center">
            <span style="border-bottom: 3px double;">NOTE</span>
          </h4>
        </button>
          <div class="modal-header">
          <div class="modal-body">
            <h6 class="modal-title">
              <!--<p style="font-size: 90%;">To update your existing event place details please select the name in below dropdown and click 'ok'
                For new registration, please skip this message by clicking 'close' button</p>-->
              <h5>
                Click yes to proceed further with each activity
              </h5>
              <!-- <li style="font-size: 90%;">
                For new registration, please skip this message by clicking 'Close' button
              </li> -->
            </h6>
          </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="saveType()">Ok</button>
          </div>
        </div>
      </div>
    </div>
<div class=""></div>
<app-event-footer></app-event-footer>
<ngx-spinner>
  <div class="custom-loader"></div>
</ngx-spinner>
