import {Component, OnInit} from '@angular/core';
import{Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  bookingBox: boolean = false;
  allBoxes: boolean = false;

  constructor(private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    const reloadFlag = 'reloadFlag';
    const hasReloaded = sessionStorage.getItem(reloadFlag);

    if (!hasReloaded) {
      sessionStorage.setItem(reloadFlag, 'true');
      location.reload();
    } else {
      sessionStorage.removeItem(reloadFlag);
    }
    const userRole = localStorage.getItem("userRole");
    if (userRole == "ADMIN") {
      this.allBoxes = false;
      this.bookingBox = true;
    } else if (userRole == "OWNER") {
      this.allBoxes = true;
      this.bookingBox = true;
    } else if (userRole == "VSSPL ADMIN") {
      this.allBoxes = true;
      this.bookingBox = true;
    }
  }
}
