export class MiscClass{
    forEach(arg0: (value: any, index: any) => void) {
        throw new Error('Method not implemented.');
      }
      splice(index: any, arg1: number) {
        throw new Error('Method not implemented.');
      }
    reqid : string;
    miscactivity : number;
    misid : string;
    mischargesreq : string;
    selectmischarges : string;
    qtyrequired : string;
    activeyn : string;
    deleteyn : string;
    createdtime : string;
    modifiedtime : string;
    createdby : string;
    modifiedby : string;
    epsubid : string;
    epid : string;
    eventid: string;
    selectedValue: string;
}