import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';

interface Data {
  name: string;
  age: number;
  address: string;

  // tslint:disable-next-line:no-any
  [key: string]: any;
}

@Component({
  selector: 'app-ui-kit',
  templateUrl: './ui-kit.component.html',
  styleUrls: ['./ui-kit.component.scss']
})

export class UiKitComponent implements OnInit {

  listOfSearchName: string[] = [];
  listOfSearchAddress: string[] = [];
  listOfFilterName = [{ text: 'Joe', value: 'Joe' }, { text: 'Jim', value: 'Jim' }];
  listOfFilterAddress = [{ text: 'London', value: 'London' }, { text: 'Sidney', value: 'Sidney' }];
  listOfData: Data[] = [
    {
      name: 'John Brown',
      age: 32,
      address: 'New York No. 1 Lake Park'
    },
    {
      name: 'Jim Green',
      age: 42,
      address: 'London No. 1 Lake Park'
    },
    {
      name: 'Joe Black',
      age: 32,
      address: 'Sidney No. 1 Lake Park'
    },
    {
      name: 'Jim Red',
      age: 32,
      address: 'London No. 2 Lake Park'
    }
  ];
  listOfDisplayData = [...this.listOfData];
  mapOfSort: { [key: string]: string | null } = {
    name: null,
    age: null,
    address: null
  };
  sortName: string | null = null;
  sortValue: string | null = null;

  sort(sortName: string, value: string): void {
    this.sortName = sortName;
    this.sortValue = value;
    for (const key in this.mapOfSort) {
      this.mapOfSort[key] = key === sortName ? value : null;
    }
    this.search(this.listOfSearchName, this.listOfSearchAddress);
  }

  search(listOfSearchName: string[], listOfSearchAddress: string[]): void {
    this.listOfSearchName = listOfSearchName;
    this.listOfSearchAddress = listOfSearchAddress;
    const filterFunc = (item: Data) =>
      (this.listOfSearchAddress.length
        ? this.listOfSearchAddress.some(address => item.address.indexOf(address) !== -1)
        : true) &&
      (this.listOfSearchName.length ? this.listOfSearchName.some(name => item.name.indexOf(name) !== -1) : true);
    const listOfData = this.listOfData.filter((item: Data) => filterFunc(item));
    if (this.sortName && this.sortValue) {
      this.listOfDisplayData = listOfData.sort((a, b) =>
        this.sortValue === 'ascend'
          ? a[this.sortName!] > b[this.sortName!]
            ? 1
            : -1
          : b[this.sortName!] > a[this.sortName!]
            ? 1
            : -1
      );
    } else {
      this.listOfDisplayData = listOfData;
    }
  }

  resetFilters(): void {
    this.listOfFilterName = [{ text: 'Joe', value: 'Joe' }, { text: 'Jim', value: 'Jim' }];
    this.listOfFilterAddress = [{ text: 'London', value: 'London' }, { text: 'Sidney', value: 'Sidney' }];
    this.listOfSearchName = [];
    this.listOfSearchAddress = [];
    this.search(this.listOfSearchName, this.listOfSearchAddress);
  }

  resetSortAndFilters(): void {
    this.sortName = null;
    this.sortValue = null;
    this.mapOfSort = {
      name: null,
      age: null,
      address: null
    };
    this.resetFilters();
    this.search(this.listOfSearchName, this.listOfSearchAddress);
  }

  constructor(private render: Renderer2, private el: ElementRef) {

  }

  ngOnInit(): void {
    //throw new Error('Method not implemented.');
    // this.searchData();
  }

  toggleSelect() {
    var g = document.getElementsByClassName('minimal');
    console.log(g);
    // g.remove('minimal');
    // g.add('notminimal');
  }
  // @ts-ignore
  test(event) {
    console.log(event);
    const hasClass = event.target.classList.contains('minimal');
    if (hasClass) {
      event.srcElement.classList.remove("minimal");
      event.srcElement.classList.add("not-minimal");
    }
    else {
      event.srcElement.classList.remove("not-minimal");
      event.srcElement.classList.add("minimal");
    }

  }
  // @ts-ignore
  selectFocusOut(event) {
    const hasClass = event.target.classList.contains('not-minimal');
    if (hasClass) {
      event.srcElement.classList.remove("not-minimal");
      event.srcElement.classList.add("minimal");
    }

  }


  // constructor(private render: Renderer2, private el: ElementRef) {

  // }

  // ngOnInit() {
  // }
  // @ViewChild('arrow', { read: ElementRef, static: false }) arrow: ElementRef;

  // // ChangeScreen(event: any) {
  // //   @ViewChild('arrow', { read: ElementRef, static: false }) arrow: ElementRef;
  // //   // this.render.addClass(event.target, "abcd");
  // //   if (this.arrow.nativeElement.classList.contains('minimal')) {
  // //     alert();
  // //     this.arrow.nativeElement.classList.remove('minimal');
  // //     this.arrow.nativeElement.classList.add('notminimal')

  // //   }
  // // }

  // ChangeScreen(event: any) {

  //   // if (this.arrow.nativeElement.classList.contains('minimal')) {
  //   //   this.arrow.nativeElement.classList.remove('minimal');
  //   //   this.arrow.nativeElement.classList.add('notminimal');
  //   // }
  //   // else {
  //   //   this.arrow.nativeElement.classList.remove('notminimal');
  //   //   this.arrow.nativeElement.classList.add('minimal')
  //   // }
  // }

  // toggleSelect() {
  //   var g = document.getElementsByClassName('minimal');
  //   console.log(g);
  //   // g.remove('minimal');
  //   // g.add('notminimal');
  // }

  // test(event) {
  //   // console.log(event);
  //   const hasClass = event.target.classList.contains('minimal');
  //   if (hasClass) {
  //     event.srcElement.classList.remove("minimal");
  //     event.srcElement.classList.add("not-minimal");
  //   }
  //   else {
  //     event.srcElement.classList.remove("not-minimal");
  //     event.srcElement.classList.add("minimal");
  //   }
  //   let myTag = this.el.nativeElement.querySelector("select");
  //   console.log(myTag);
  //   myTag.classList.remove('minimal');
  //   myTag.classList.remove('not-minimal');

  //   const ele = this.arrow.nativeElement.click();

  // }

  // selectFocusOut(event) {
  //   const hasClass = event.target.classList.contains('not-minimal');
  //   if (hasClass) {
  //     event.srcElement.classList.remove("not-minimal");
  //     event.srcElement.classList.add("minimal");
  //   }

  // }

  tab: any = 'tab1';
  tab1: any
  tab2: any
  tab3: any
  tab4: any
  tab5: any
  // @ts-ignore
  Clicked: boolean
  // @ts-ignore
  onClick(check) {

    if (check == 1) {
      this.tab = 'tab1';
    } else if (check == 2) {
      this.tab = 'tab2';
    } else if (check == 3) {
      this.tab = 'tab3';
    }
    else if (check == 4) {
      this.tab = 'tab4';
    }
    else {
      this.tab = 'tab5';
    }
  }

}
