<div class="row">
  <div class="col-md-1"></div>
  <div class="col-md-11">
    <h3 class="font-italic mt-3">Activities Mapping</h3>
  </div>
</div>
<form  [formGroup]="activitiesMapping" (ngSubmit)="onSubmit()" id="activitiesMapping">
  <div class="container">
    <div class="card-body">
  <div class="row" >
    <div class="col-md-3">
      <div class="form-group">
        <label for="epid" class="form-label">Event Place</label>
  <select class="form-control "  formControlName="epid" (change)="onShowevent($event)" >
       <option *ngFor="let parms of eventDropdown" value="{{parms}}">{{parms}}</option>
         <option value="" disabled selected hidden >Select Event place</option>
  </select>
  <span *ngIf="EPActivitiesMappinErrors.epid"
        class="error-text">{{EPActivitiesMappinErrors.epid}}</span>
        </div>
      </div>
      <div class="col-md-1" ></div>
        <div class="col-md-3" >
          <div class="form-group">
            <label for="epsubid" class="form-label">Event Sub Place</label>
          <select class="form-control"  id="epsubid" formControlName="epsubid" disabled="false" (change)="onShowSubPlaceDetails($event)" >
            <option value=""  selected  >Select Event Sub Place</option>
            <option *ngFor="let parms of eventSubDropDown" value="{{parms}}">{{parms}}</option>                  

               </select>
               <span *ngIf="EPActivitiesMappinErrors.epsubid"
        class="error-text">{{EPActivitiesMappinErrors.epsubid}}</span>
               </div>
               </div>
               <div class="col-md-1" ></div>
        <div class="col-md-3" >
          <div class="form-group">
            <label for="epactivityid" class="form-label">Activities</label>
          <select class="form-control"  id="epactivityid" formControlName="epactivityid" disabled="false"  (change)="onActivitydetails($event)">
            <option value=""  selected  >Select Event Activity</option>
            <option *ngFor="let parms of eventactivityDropDown" value="{{parms}}">{{parms}}</option>                  
               </select>
               <span *ngIf="EPActivitiesMappinErrors.epactivityid"
        class="error-text">{{EPActivitiesMappinErrors.epactivityid}}</span>
               </div>
               </div>
                <div class="col-md-1" >
                <button type="submit" class="btn btn-danger" style="margin-top: 30px;">Map</button>
              </div>
               </div>
               </div>
                <div class="row table" >
                  <div class="col-md-4">
                    <div class="card-body">
                      <div class="card card-primary">
                        <div class="card-header">
                          <h3 class="card-title">Event Place</h3>
                        </div>
                          <div class="card-body">
                            <div class="form-group row">
                            <label for="epname" class="col-md-5" >EPName</label>
                            <div class="col-md-7">
                              <input type="text" class="form-control" id="epname" formControlName ="epname" readonly>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label for="epnickname" class="col-md-5">NickName</label>
                            <div class="col-md-7">
                              <input type="text" class="form-control" id="epnickname" formControlName ="epnickname" readonly >
                            </div>
                          </div>
                          <div class="form-group row">
                            <label for="epaddress1" class="col-md-5">Address 1</label>
                            <div class="col-md-7">
                              <textarea type="text" class="form-control" id="epaddress1" formControlName ="epaddress1" readonly></textarea>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label for="epaddress2" class="col-md-5">Address 2</label>
                            <div class="col-md-7">
                              <textarea type="text" class="form-control" id="epaddress2" formControlName ="epaddress2" readonly></textarea>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label for="epcity" class="col-md-5">City</label>
                            <div class="col-md-7">
                              <input type="text" class="form-control" id="epcity" formControlName ="epcity" readonly>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label for="epstate" class="col-md-5">State</label>
                            <div class="col-md-7">
                              <input type="text" class="form-control" id="epstate" formControlName ="epstate" readonly>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label for="eppincode" class="col-md-5">Pincode</label>
                            <div class="col-md-7">
                              <input type="text" class="form-control" id="eppincode" formControlName ="eppincode" readonly>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label for="epcountry" class="col-md-5">Country</label>
                            <div class="col-md-7">
                              <input type="text" class="form-control" id="epcountry" formControlName ="epcountry" readonly>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label for="epnearbylandmark" class="col-md-5">Near By Land Mark</label>
                            <div class="col-md-7">
                              <input type="text" class="form-control" id="epnearbylandmark" formControlName ="epnearbylandmark" readonly>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label for="epdescription" class="col-md-5">Description</label>
                            <div class="col-md-7">
                              <textarea type="text" class="form-control" id="epdescription" formControlName ="epdescription" readonly></textarea>
                            </div>
                          </div>
                          <div class="form-group row">
                                <label for="activestatus" class="col-md-5">Active</label>
                                <div class="col-md-7">
<!--                                <select class="form-control" id="activestatus"  formControlName ="activestatus" readonly>-->
<!--                                    <option value="" disabled selected hidden>Select Menu</option>-->
<!--                                    <option>Yes</option>-->
<!--                                    <option>No</option>-->
<!--                                </select>-->
                                  <input type="text" class="form-control" id="activestatus" formControlName ="activestatus" readonly>
                                <!-- <select class="form-control" id="activestatus"  formControlName ="activestatus" readonly>
                                    <option value="" disabled selected hidden>Select Menu</option>
                                    <option>Yes</option>
                                    <option>No</option>
                                </select> -->

                              </div>
                            </div>
                            <div class="form-group row">
                                <label for="epaircond" class="col-md-5">Air Conditioned</label>
                                <div class="col-md-7">
                                <!-- <select class="form-control" id="epaircond"  formControlName ="epaircond" readonly>
                                    <option value="" disabled selected hidden>Select Menu</option>
                                    <option>Yes</option>
                                    <option>No</option>
                                </select> -->
                                <input type="text" class="form-control" id="epaircond" formControlName ="epaircond" readonly>
                              </div>
                            </div>
                          <div class="form-group row">
                            <label for="phonenumber" class="col-md-5">Phone Number</label>
                            <div class="col-md-7">
                              <input type="text" class="form-control" id="phonenumber" formControlName ="phonenumber" readonly>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label for="emailid" class="col-md-5">Email id</label>
                            <div class="col-md-7">
                              <input type="text" class="form-control" id="emailid" formControlName ="emailid" readonly>
                            </div>
                          </div>
                          </div>
                      </div>
                      </div>
                  </div>
                  <div class="col-md-4">
                  <div class="card-body">
                      <div class="card card-primary">
                        <div class="card-header">
                          <h3 class="card-title">Event Sub-Place</h3>
                        </div>
                        <div class="card-body">
                          <div class="form-group row">
                          <label for="epsubname" class="col-md-5">EPsubName</label>
                          <div class="col-md-7">
                            <input type="text" class="form-control" id="epsubname" formControlName ="epsubname" readonly>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="epsubnickname" class="col-md-5">subNick Name</label>
                          <div class="col-md-7">
                            <input type="text" class="form-control" id="epsubnickname" formControlName ="epsubnickname" readonly>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="epsubdesc" class="col-md-5">subDescription</label>
                          <div class="col-md-7">
                            <input type="text" class="form-control" id="epsubdesc" formControlName ="epsubdesc" readonly>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="epsubemailid" class="col-md-5">subEmail Id</label>
                          <div class="col-md-7">
                            <input type="text" class="form-control" id="epsubemailid" formControlName ="epsubemailid" readonly>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="subactivestatus" class="col-md-5">subUser Status</label>
                          <div class="col-md-7">
                          <!-- <select class="form-control" id="subactivestatus"  formControlName ="subactivestatus" readonly>
                              <option value="" disabled selected hidden>Select Menu</option>
                              <option>Yes</option>
                              <option>No</option>
                          </select> -->
                          <input type="text" class="form-control" id="subactivestatus" formControlName ="subactivestatus" readonly>
                        </div>
                      </div>
                      <div class="form-group row">
                          <label for="epsubaircond" class="col-md-5">subAirCond</label>
                          <div class="col-md-7">
                          <!-- <select class="form-control" id="epsubaircond"  formControlName ="epsubaircond" readonly>
                            <option value="" disabled selected hidden>Select Menu</option>
                              <option>Yes</option>
                              <option>No</option>
                          </select> -->
                          <input type="text" class="form-control" id="epsubaircond" formControlName ="epsubaircond" readonly>
                        </div>
                      </div>
                        </div>
                        </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="card-body">
                          <div class="card card-primary">
                            <div class="card-header">
                              <h3 class="card-title">Activities Details</h3>
                            </div>
                              <div class="card-body">
                                <div class="form-group row">
                                <label for="epactivityname" class="col-md-5">Activity Name</label>
                                <div class="col-md-7">
                                  <input type="text" class="form-control" id="epactivityname" formControlName ="epactivityname" readonly>
                                </div>
                              </div>
                              <div class="form-group row">
                                <label for="epactivitiescharges" class="col-md-5">Activities Charges</label>
                                <div class="col-md-7">
                                  <input type="text" class="form-control" id="epactivitiescharges" formControlName ="epactivitiescharges" readonly>
                                </div>
                              </div>
                              <div class="form-group row">
                                <label for="activitiesactivestatus" class="col-md-5">Activity Status</label>
                                <div class="col-md-7">
                                <!-- <select class="form-control" id="activitiesactivestatus"  formControlName="activitiesactivestatus"  readonly>
                                    <option value="" disabled selected hidden>Select Menu</option>
                                    <option>Active</option>
                                    <option>Inactive</option>
                                </select> -->
                                <input type="text" class="form-control" id="activitiesactivestatus" formControlName ="activitiesactivestatus" readonly>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label for="epactivity3rdparty" class="col-md-5">Activity 3rd Party</label>
                              <div class="col-md-7">
                              <!-- <select class="form-control" id="epactivity3rdparty"  formControlName ="epactivity3rdparty" readonly>
                                  <option value="" disabled selected hidden>Select Menu</option>
                                  <option>Yes</option>
                                  <option>No</option>
                              </select> -->
                              <input type="text" class="form-control" id="epactivity3rdparty" formControlName ="epactivity3rdparty" readonly>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label for="epactivitychargable" class="col-md-5">Activity Chargeable</label>
                            <div class="col-md-7">
                            <!-- <select class="form-control" id="epactivitychargable"  formControlName ="epactivitychargable" readonly>
                                <option value="" disabled selected hidden>Select Menu</option>
                                <option>Yes</option>
                                <option>No</option>
                            </select> -->
                            <input type="text" class="form-control" id="epactivitychargable" formControlName ="epactivitychargable" readonly>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="epactivityshow" class="col-md-5">Activity Show</label>
                          <div class="col-md-7">
                          <!-- <select class="form-control" id="epactivityshow"  formControlName ="epactivityshow" readonly>
                              <option value="" disabled selected hidden>Select Menu</option>
                              <option>Yes</option>
                              <option>No</option>
                          </select> -->
                          <input type="text" class="form-control" id="epactivityshow" formControlName ="epactivityshow" readonly>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="epactivitydesc" class="col-md-5">Description</label>
                        <div class="col-md-7">
                          <textarea type="text" class="form-control" id="epactivitydesc" formControlName ="epactivitydesc" readonly></textarea>
                        </div>
                      </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        <div class="col-md-12 table-responsive-md">
                          <table class="table table-bordered">
                             <thead style="background-color: rgb(249, 204, 78); text-align: center;">
                                <tr>
                                  <th>S.No</th>
                                   <th>Event Place</th>
                                   <th>Event Sub Place</th>
                                   <th>Activites Details</th>
                                </tr>
                             </thead>
                             <tbody>
                              <tr class="text-center" *ngFor="let map of this.getActivitiesMappingId; let i = index;">
                                <td>{{i + 1}}</td>
                                <td>{{map.epid}}</td>
                                <td>{{map.epsubid}}</td>
                                <td>{{map.epactivityid}}</td>
                              </tr>
                             </tbody>
                          </table>
                       </div>
               </div>
               </form>
