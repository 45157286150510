import {Component, HostBinding, OnInit} from '@angular/core';
import {AppService} from '@services/app.service';
import {UiState} from '@/store/ui/state';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {DateTime} from 'luxon';
import {Observable, pipe, Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-event-header',
  templateUrl: './event-header.component.html',
  styleUrls: ['./event-header.component.scss']
})

export class EventHeaderComponent implements OnInit {
  public ui: Observable<UiState>;
  public searchForm: UntypedFormGroup;

  constructor(private appService: AppService) {
  }

  ngOnInit(): void {
  }
}
